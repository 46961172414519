import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Typography, Box, Button, FormControlLabel, Switch, CircularProgress, Alert, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material'; 
import { Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Grid, Icon, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Autocomplete } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; 
import SaveIcon from '@mui/icons-material/Save';
import NotificationsIcon from '@mui/icons-material/Notifications';
import "react-datepicker/dist/react-datepicker.css";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import CloseIcon from '@mui/icons-material/Close';
import { Edit as EditIcon } from '@mui/icons-material';

import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PersonIcon from '@mui/icons-material/Person';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import verificareToken from '../VerificareToken';
import JurisprudentaAsociataTermen from './JurisprudentaAsociataTermen';  
import GavelIcon from '@mui/icons-material/Gavel';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 

import { format, parseISO, parse, isPast } from 'date-fns';

 
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ora ${hours}:${minutes}`;
}

function formatDateFaraora(dateString) {

  if (dateString === '0000-00-00 00:00:00') {
    return 'Data nu este disponibilă';
  }
  
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  //const hours = String(date.getHours()).padStart(2, '0');
  //const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year}`;
}

export default function TermeneDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
  const { id } = useParams();
  const [termene, setTermene] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [stadiuCurentId, setStadiuCurentId] = useState(null);
  const [expandedTermen, setExpandedTermen] = useState({});
  const [groupedTermene, setGroupedTermene] = useState({});

  //jurisprudentaAsociata
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleOpenModalJurisprudenta = (id) => {
    setSelectedId(id);
    setOpenModal(true);
  };
  const [editIndex, setEditIndex] = useState(null);
  const [isEditing, setIsEditing] = useState({});
  const [isStadiiLoading, setStadiiLoading] = useState(true);
  const [expandAll, setExpandAll] = useState(false);

  const [dataConvertitaTermen, setDataConvertitaTermen] = useState(null);

  const [termenTrecut, setTermenTrecut] = useState(false);

  const [formData, setFormData] = useState({
    dataTermen: new Date(),
    complet: '',
    sala: '',
    solutie: '',
    sumar: '',
    atentionare: 0,
    dataDocument: new Date(), 
    numarDocument: '',
    idStadiuDosar: null, 
    idUser: null,
  });
  const [idStadiuDosar, setIdStadiuDosar] = useState(null); 
  const [idUser, setIdUser] = useState(null); 
  const {token} = verificareToken();

  const [selectedTermenEvent, setSelectedTermenEvent] = useState(null); 
  const [editedNotificare, setEditedNotificare] = useState({
    descriere: '',
    panaladata: '',
    notificare: 0,
    via_email: 0,
    via_push: 0,
    via_sms: 0,
    reminder: 1,
    recurent: 0,
    tipNotificare: '',
  });

   const [newNotificare, setNewNotificare] = useState({
    descriere: '',
    panaladata: '',
    notificare: 0,
    via_email: 0,
    via_push: 0,
    via_sms: 0,
    reminder: 1,
    recurent: 0,
    tipNotificare: '',
  });
  
  const [createModalOpen2, setCreateModalOpen2] = useState(false);
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  
  const [notificareId, setSelectedNotificareId] = useState(null);
  
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    fetchEvenimenteMultiple();
  }, []);
  
  const fetchEvenimenteMultiple = async () => {
    
  };

  const handleDeleteNotificareTermen = (idNotificare, eveniment) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteNotificareTermenOk(idNotificare, eveniment);
      }
    });
  };
 
  const handleDeleteNotificareTermenOk = async (idNotificare, eveniment) => {
    try {
      // Ștergere notificare
      const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la ștergerea notificării: ', response.statusText);
        return;
      }
  
      // Actualizare evenimente
      await fetchNotificariTermen(eveniment);
      fetchEvenimenteMultiple();
    } catch (error) {
      console.error('Eroare la ștergerea notificării: ', error);
    }
  };
const [selectedTermeneExport, setSelectedTermenEventExport] = useState(null);

const handleExportToGoogleCalendar = () => {
  console.log('aaa', selectedTermeneExport);
  if (selectedTermeneExport) {
     const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=TermenDosar-Client-${encodeURIComponent(selectedTermeneExport.societateClient)}-${encodeURIComponent(selectedTermeneExport.numarDosar)}-${encodeURIComponent(selectedTermeneExport.complet)}&dates=${encodeURIComponent(selectedTermeneExport.dataTermen)}&details=${encodeURIComponent(selectedTermeneExport.solutie)}`;
    window.open(googleCalendarUrl, '_blank');
  }
};
const handleExportToIosCalendar = () => {
  if (selectedTermeneExport) {
    const iCalEvent = {
      title: selectedTermeneExport.numarDosar,
      start: selectedTermeneExport.dataTermen, 
      description: selectedTermeneExport.solutie
    };

    const iCalData = encodeURIComponent([
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${window.location.href}`,
      `DTSTART:${moment(iCalEvent.start).format('YYYYMMDDTHHmmss')}`, 
      `SUMMARY:${iCalEvent.title}`,
      `DESCRIPTION:${iCalEvent.description}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n'));

    const iCalBlob = new Blob([iCalData], { type: 'text/calendar;charset=utf-8' });
    const iCalUrl = URL.createObjectURL(iCalBlob);
    const link = document.createElement('a');
    link.href = iCalUrl;
    link.download = 'event.ics';
    link.click();
  }
};

const fetchTermene = async () => {
  const response = await fetch(`${window.API_URL}infoTermene/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  console.log('Date initiale:', data);

  const fetchedTermene = (data.data.termene || []).map((termen) => ({
    ...termen,
    atentionari: termen.atentionare === 1,
    dataDocument: termen.dataDocument === '0000-00-00' ? '' : termen.dataDocument,
    numarDocument: termen.numarDocument || '',
    id: termen.id,
  }));

  const groupedTermene = groupTermeneByStadiu(fetchedTermene);

  setGroupedTermene(groupedTermene);
  setTermene(fetchedTermene);
  setLoading(false);
};

  
const handleCreateModalOpen2 = () => {
  setCreateModalOpen2(true);  
  setNewNotificare({
      descriere: '',
      panaladata: getCurrentDate(),
      notificare: 0,
      via_email: 0,
      via_push: 0,
      via_sms: 0,
      reminder: 1,
      recurent: 0,
      tipNotificare: '',
    });
  };
 

  const [stadiiDosarData, setStadiiDosarData] = useState([]);

const fetchStadiuDosar = async () => {
  try {
    const response = await fetch(`${window.API_URL}infoStadiiDosar/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log('Stadii dosar:', data.data.stadiiDosar);
    setStadiiDosarData(data.data.stadiiDosar);

    const stadiuCurent = data.data.stadiiDosar.find(stadiu => stadiu.id === data.data.stadiuCurent);
    if (stadiuCurent) {
      
      setStadiuCurentId(stadiuCurent.id);
      setFormData(prevFormData => ({
        ...prevFormData,
        idStadiuDosar: stadiuCurent.id,
      }));
    }

  } catch (error) {
    console.error('Error:', error);
   } finally {
    setStadiiLoading(false);  
  }
};
  
  const fetchUser = async () => {
    try {
      const response = await fetch(`${window.API_URL}user`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log('Utilizator:', data.data.user); 
      
      setIdUser(data.data.user.id);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchTermene();
    fetchStadiuDosar();
    fetchUser();
  }, [id]);
 
  useEffect(() => {
    setIdStadiuDosar(formData.idStadiuDosar); //actualizez idStadiuDosar dupa ce adaug un termen
  }, [formData.idStadiuDosar]);

  const handleSwitchChange = (index) => (event) => {
    const newTermene = [...termene];
    newTermene[index].atentionari = event.target.checked;
    setTermene(newTermene);
  };

  useEffect(() => {
    if (stadiiDosarData.length > 0) {
      const stadiuCurent = stadiiDosarData.find(stadiu => stadiu.id === stadiuCurentId);
      if (stadiuCurent) {
        setFormData(prevFormData => ({
          ...prevFormData,
          idStadiuDosar: stadiuCurent.id,
        }));
      }
    }
  }, [stadiiDosarData, stadiuCurentId]);
   
  const handleDataDocumentChange = (index) => (event) => {
    const date = new Date(event.target.value);
    const formattedDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
    const newTermene = [...termene];
    newTermene[index].dataDocument = formattedDate.toISOString();
    setTermene(newTermene);
  };

  const handleNumarDocumentChange = (index) => (event) => {
    const newTermene = [...termene];
    newTermene[index].numarDocument = event.target.value;
    setTermene(newTermene);
  };

  const handleRevocare = (index) => () => {
    const newTermene = [...termene];
    newTermene[index].atentionari = false;
    newTermene[index].dataDocument = '';
    newTermene[index].numarDocument = '';
    setTermene(newTermene);
    setOpenIndex(null);
  };

  const handleSalvare = (index) => async () => { 
    const termen = termene[index];
    const idTermen = termen.id;
    const url = `${window.API_URL}updateTermenDosar/${idTermen}`;

    const dataTermenAdjusted = new Date(new Date(termen.dataTermen).getTime() + 3 * 60 * 60 * 1000);
    const dataDocumentAdjusted = termen.dataDocument ? new Date(new Date(termen.dataDocument).getTime() + 3 * 60 * 60 * 1000) : null;

    const dataTermenISO = dataTermenAdjusted.toISOString();
    const dataDocumentISO = dataDocumentAdjusted ? dataDocumentAdjusted.toISOString() : null;
 
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          atentionare: termen.atentionari ? 1 : 0,
          activitate: termen.activitate,
          idUser: termen.iduser,
          complet: termen.complet,
          sala: termen.sala,
          tipsolutie: termen.tipsolutie,
          sumarsolutie: termen.sumarsolutie,
          dataDocument: dataDocumentISO,
          numarDocument: termen.numarDocument,
          dataTermen: dataTermenISO,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.status === 200) {
        Swal({
          icon: 'success',
          title: 'Termenul a fost salvat cu succes!',
        });
        
      setIsEditing((prev) => ({ ...prev, [index]: false }));
      fetchTermene();
      } else {
        Swal({
          icon: 'warning',
          title: 'Atenție!',
          text: 'A apărut o eroare la salvarea termenului!',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal({
        icon: 'warning',
        title: 'Atenție!',
        text: 'A apărut o eroare la salvarea termenului!',
      });
    }
    finally { 
        setEditIndex(null);
      }
  };
   

  const handleDelete = (idTermen) => {
    Swal({
      title: 'Sunteți sigur?',
      text: 'Nu mai puteți reveni asupra acestei acțiuni!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${window.API_URL}termene/${idTermen}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              const newTermene = termene.filter((termen) => termen.id !== idTermen);
              setTermene(newTermene);
              fetchTermene();
              Swal('Șters!', 'Termenul a fost șters cu succes.', 'success');
            } else {
              Swal('Atenție!', 'A apărut o eroare la ștergerea termenului.', 'warning');
            }
          })
          .catch((error) => {
            console.error('Error:', error);
            Swal('Atenție!', 'A apărut o eroare la ștergerea termenului.', 'warning');
          });
      } else {
        Swal('Anulat', 'Ștergerea termenului a fost anulată.', 'warning');
      }
    });
  };

 

  const handleOpenModal = () => {
    setFormData({
      ...formData,
      dataTermen: new Date(),
      dataDocument: new Date(),
    });
    setModalOpen(true);
  };
  

  const handleCloseModal = () => {
    setModalOpen(false); 
    setSelectedTermenEvent(null);  
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    if (idStadiuDosar === null) {
      await fetchStadiuDosar();
    }
    let dataTermenAjustata = new Date(formData.dataTermen);
    dataTermenAjustata = new Date(dataTermenAjustata.getTime() - dataTermenAjustata.getTimezoneOffset() * 60000);
  
    const formDataAjustata = {
      ...formData,
      dataTermen: dataTermenAjustata.toISOString().split('T')[0], 
    };

    try {
      formData.idStadiuDosar = Number(formData.idStadiuDosar);
      //formData.idStadiuDosar = idStadiuDosar;
      formData.idUser = idUser;
  
      const response = await fetch(`${window.API_URL}termene`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          dataTermen: dataTermenAjustata,
          idStadiuDosar: formData.idStadiuDosar,
          idUser: formData.idUser,
        }),
      });
      const data = await response.json();
      console.log('Response:', data);
      if (response.ok && data.status === 201) { 
        Swal({
          icon: 'success',
          title: 'Termenul a fost adăugat cu succes!',
        }); 
        setFormData({
          dataTermen: '',
          complet: '',
          sala: '',
          solutie: '',
          sumar: '',
          atentionare: event.target.checked ? 1 : 0,
          dataDocument: '',
          numarDocument: '',
        }); 
        handleCloseModal(); 
        setLoading(true);
        fetchTermene();
        fetchStadiuDosar();
        fetchUser();
      } else { 
        Swal({
          icon: 'warning',
          title: 'Atenție!',
          text: 'A apărut o eroare la adăugarea termenului!',
        });
        console.log('eroare date transmise', formData);
      }
    } catch (error) {
      console.error('Error:', error); 
      Swal({
        icon: 'warning',
        title: 'Atenție!',
        text: 'A apărut o eroare la adăugarea termenului!',
      });
      console.log('eroare date transmise', formData);
    }
  };
  
  const handleEventClick = async (event) => {
    try {
      console.log('Termen selectat:', event);
      const eventId = event.id; 
  
         
        const response = await fetch(`${window.API_URL}getTermen/${eventId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          console.error('Eroare la preluare date termen: ', response.statusText);
          return;
        }
  
        const { data } = await response.json();
        setSelectedTermenEvent({ termen: data }); 
        setSelectedTermenEventExport(data.termen);
        
        
        const termenData = data?.termen?.dataTermen;
        const termenInPast = termenData && isPast(parse(termenData, 'yyyy-MM-dd HH:mm:ss', new Date()));
        setTermenTrecut(termenInPast);
 
        const formatData2 = 'yyyy-MM-dd HH:mm:ss';
        const formatData = 'dd-MM-yyyy HH:mm';
        const dataConvertitaTermen = parse(termenData, formatData2, new Date());

        const dataAfisare = format(dataConvertitaTermen, 'dd-MM-yyyy HH:mm');

        const dataConvertitaTermenFinala = parse(dataAfisare, formatData, new Date()); 


        setDataConvertitaTermen(dataConvertitaTermen);

        fetchNotificariTermen(eventId); 
        const responseNotificari = await fetch(`${window.API_URL}notificari`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!responseNotificari.ok) {
          console.error('Eroare la preluare date notificare: ', responseNotificari.statusText);
          return;
        }
  
        const { data: notificariData } = await responseNotificari.json();
  
       
  
    } catch (error) {
      console.error('Eroare la preluare date notificare: ', error);
    }
  };

  const fetchNotificariTermen = async (termenId) => {
    try {
      const response = await fetch(`${window.API_URL}getTermen/${termenId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la preluare date notificare: ', response.statusText);
        return;
      }
      console.log(response);
      const { data } = await response.json();
 
      setSelectedTermenEvent((prevEvent) => {
        if (prevEvent && prevEvent.termen) {
          return {
            ...prevEvent,
            termen: {
              ...prevEvent.termen,
              notificariTermen: data.notificariTermen,
            },
          };
        }
        return prevEvent;
      });
      
    } catch (error) {
      console.error('Eroare la preluare date notificare: ', error);
    }
  };

const handleCreateNotificare = async (tipEveniment) => {
  try {
    let tipNotificare;
 
    tipNotificare = 'termen'; 
    
    const idTermen2 = selectedTermenEvent?.termen?.termen.tipTermen === 'termen' ? selectedTermenEvent?.termen?.termen.id : null;
    const idTermen = selectedTermenEvent?.notificare?.tip === 'termen' ? selectedTermenEvent?.notificare?.idTermen : null;
    
    const notificareData = {
      descriere: newNotificare.descriere,
      panaladata: newNotificare.panaladata,
      notificare: newNotificare.notificare,
      via_sms: newNotificare.via_sms,
      via_email: newNotificare.via_email,
      via_push: newNotificare.via_push,
      reminder: newNotificare.reminder,
      recurent: newNotificare.recurent,
      tipNotificare,
      ...(idTermen !== null && { idTermen }), // daca am idTermen de pe api get /notificari pun valoarea idTermen - dar nu il mai folsoesc sa il am preventiv
      ...(idTermen2 !== null && { idTermen: idTermen2 }), // daca am idTermen de pe get /termeneCalendar atunci ii pun idTermen setat de idTermen2
    };

    console.log(idTermen2);
    // Filtrarea prop obiectului pentru a elimina cele cu valoare null
    const filteredNotificareData = Object.entries(notificareData).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // creare notificare cu tipul corespunzator
    const response = await fetch(`${window.API_URL}notificari`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(filteredNotificareData),
    });

    if (!response.ok) {
      console.error('Eroare la crearea notificării: ', response.statusText);
      return;
    }

    // Actualizare evenimente
    fetchEvenimenteMultiple();  
    fetchNotificariTermen(selectedTermenEvent?.termen?.termen.id); 
    // închidere modal de creare notificare
    handleCreateModalClose();
  } catch (error) {
    console.error('Eroare la crearea notificării: ', error);
  }
  
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, [name]: value }));
setNewNotificare((prevNotificare) => ({ ...prevNotificare, [name]: value }));
};


const handleCreateModalClose = () => {
  setCreateModalOpen2(false);
};

const handleEditModalOpenTermen = async (idNotificare, eveniment) => {
  console.log('N', idNotificare);
  console.log('E', eveniment);
  try {
   const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
     },
   });

   if (!response.ok) {
     console.error('Eroare la preluare date notificare: ', response.statusText);
     return;
   }
   console.log(response);
   const { data } = await response.json();

   setEditedNotificare({
     ...editedNotificare,
     id: data.notificare.id,
     descriere: data.notificare.descriere || '',
     panaladata: data.notificare.panaladata || '',
     notificare: data.notificare.notificare || 0,
     via_email: data.notificare.via_email || 0,
     via_push: data.notificare.via_push || 0,
     via_sms: data.notificare.via_sms || 0,
     reminder: data.notificare.reminder || 0,
     recurent: data.notificare.recurent || 0,
   });
   setSelectedNotificareId(idNotificare);
   setEditModalOpen(true);

   await fetchNotificariTermen(eveniment);
   fetchEvenimenteMultiple();

 } catch (error) {
   console.error('Eroare la preluare date notificare: ', error);
 }

 };
 
 
 const handleSaveEditTermen = async (eveniment) => {
  try {
    const updates = {
      id: notificareId,
      descriere: editedNotificare.descriere,
      panaladata: editedNotificare.panaladata,
      notificare: editedNotificare.notificare,
      via_email: editedNotificare.via_email,
      via_push: editedNotificare.via_push,
      via_sms: editedNotificare.via_sms,
      reminder: editedNotificare.reminder,
      recurent: editedNotificare.recurent,
    };

    console.log('Valorile notificării actualizate:', updates);

    const response = await fetch(`${window.API_URL}notificari/${updates.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updates),
    });

    if (!response.ok) {
      console.error('Eroare la salvarea editării notificării: ', response.statusText);
      return;
    }

    console.log('Notificarea a fost actualizată cu succes.');

    fetchEvenimenteMultiple(); 
    await fetchNotificariTermen(eveniment);
    setEditModalOpen(false);
  } catch (error) {
    console.error('Eroare la salvarea editării notificării: ', error);
  }
};


 const handleEditModalClose = () => {
   setEditModalOpen(false);
 }; 

 const handleUserChange = (index, newUser) => {
  const updatedTermene = [...termene];
  updatedTermene[index] = {...updatedTermene[index], iduser: newUser ? newUser.id : null};
  setTermene(updatedTermene);
};



const handleActivitateChange = (index, newActivitate) => {
  const updatedTermene = [...termene];
  updatedTermene[index].activitate = newActivitate;
  setTermene(updatedTermene);
};

const [users, setUsers] = useState([]);
const [selectedUser, setSelectedUser] = useState(null);
const fetchUsers = async () => {
  const response = await fetch(`${window.API_URL}users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  setUsers(data.data.users);
};

useEffect(() => {
  fetchUsers();
}, []);
useEffect(() => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    dataTermen: new Date(),  
    dataDocument: new Date(),  
  }));
}, []);

const groupTermeneByStadiu = (termene) => {
  const sortedTermene = termene.sort((a, b) => new Date(b.dataTermen) - new Date(a.dataTermen));

  const groupedTermene = {};

  sortedTermene.forEach((termen) => {
    const stadiu = termen.stadiudosar.stadiu.denumire;
    if (!groupedTermene[stadiu]) {
      groupedTermene[stadiu] = [];
    }
    groupedTermene[stadiu].push(termen);
  });

  return groupedTermene;
};


const handleAccordionChange = (id) => (event, isExpanded) => {
  setExpandedTermen((prevExpandedTermen) => ({
    ...prevExpandedTermen,
    [id]: isExpanded,
  }));
};

const handleExpandAll = () => {
  const newExpandedState = {};
  Object.keys(groupedTermene).forEach((stadiu) => {
    groupedTermene[stadiu].forEach((termen) => {
      newExpandedState[termen.id] = !expandAll;
    });
  });
  setExpandedTermen(newExpandedState);
  setExpandAll(!expandAll);
};


const handleEdit = (index) => () => {
  setIsEditing((prev) => ({ ...prev, [index]: true }));
};

const handleDataDocumentChange2 = (index) => (newValue) => {
  const newTermene = [...termene];
  newTermene[index].dataDocument = newValue ? newValue.toISOString() : '';
  setTermene(newTermene);
};

const fixTimezoneOffset = (date) => {
  if (!date) return "";
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
}
const getTzOffsetMs = () => {
  const targetTz = 3;  
  return (new Date().getTimezoneOffset() + (targetTz * 60)) * 60 * 1000;
}

const toTargetTime = (localTime) => {
  return localTime.getTime() - getTzOffsetMs();
}

const toLocalTime = (targetTime) => {
  return new Date(targetTime.getTime() + getTzOffsetMs());
}

const handleFieldChange = (index, field, value) => {
  console.log(`handleFieldChange - before conversion: index=${index}, field=${field}, value=${value}`);

  const newTermene = [...termene];
  if (value) {
    if (field === 'dataTermen' || field === 'dataDocument') {
      if (value instanceof Date && !isNaN(value)) {
        const targetTime = new Date(toTargetTime(value));
        console.log(`handleFieldChange - after conversion: targetTime=${targetTime}`);
        newTermene[index][field] = targetTime.toISOString();
      } else {
        newTermene[index][field] = null;
      }
    } else {
      newTermene[index][field] = value;
    }
  } else {
    newTermene[index][field] = null;
  }
  setTermene(newTermene);
};
const handleCancel = (index) => {
  setIsEditing((prev) => ({ ...prev, [index]: false }));
};
  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh'
      }}>
        <CircularProgress />
      </div>
    );
  }
  console.log('stadiiDosarData', stadiiDosarData);
  if (!isLoading && !isStadiiLoading && stadiiDosarData.length === 0) {
    return (
      <div style={{width:'100%', display:'block', height:'50px'}}>    
        <Alert severity="error">Dosarul nu conține nici un stadiu, prin urmare nu poate avea termene. <br /> Un stadiu poate fi adăugat din stânga, pe zona de "<b>Stadiu</b>" după care puteți configura termene pe dosar.</Alert>
      </div>
    );
  }

  if (termene.length === 0) {
    return (
      <div>
      <div style={{width:'100%', display:'block', height:'50px'}}>
      
    {areDrept(DreptDeModificareDosare) ? ( 
      <Button 
        disabled={!areDrept(DreptDeModificareDosare)}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        style={{ marginTop: '10px', minWidth:'200px', float:'right' }}
        onClick={handleOpenModal}
        size="small"
      >
          Adăugare termen
      </Button> 
      ) : null }
        <Button color="warning" variant="outlined" onClick={() => handleOpenModalJurisprudenta(id)} startIcon={<GavelIcon />}>Jurisprudență Asociată</Button>
          <JurisprudentaAsociataTermen
            open={openModal}
            onClose={() => setOpenModal(false)}
            id={selectedId}
          />
    </div>
    {Array.isArray(termene) && termene.length === 0 && (
      <div>
        <Alert severity="info">Nu există termene definite pentru acest dosar.</Alert>
      </div>
    )}
    <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
      <DialogTitle className='titluModal mb-3'>
          <Typography variant="h6">Adăugare termen nou</Typography>
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
      </DialogTitle>
          <form onSubmit={handleFormSubmit}> 
        <DialogContent>
          <FormControl fullWidth variant="outlined" style={{ marginBottom: '10px' }}>
            <InputLabel htmlFor="stadiuDosar">Stadiu dosar</InputLabel>
            <Select
              value={formData.idStadiuDosar || ''}
              onChange={(event) => setFormData({ ...formData, idStadiuDosar: event.target.value })}
              required
              label="Stadiu dosar"
              inputProps={{
                name: 'stadiuDosar',
                id: 'stadiuDosar',
              }}
            >
              <MenuItem value={null} disabled>
                Selectați un stadiu
              </MenuItem>
              {stadiiDosarData.map((stadiu) => (
                <MenuItem key={stadiu.id} value={stadiu.id}>
                  {stadiu.numeStadiu}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <LocalizationProvider className='mt-3' dateAdapter={AdapterDateFns} adapterLocale={ro}>
                <DateTimePicker
                  label="Dată și oră termen"
                  value={formData.dataTermen}
                  onChange={(newValue) => {
                    setFormData({ ...formData, dataTermen: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                  inputFormat="dd.MM.yyyy HH:mm"
                  ampm={false} 
                  mask="__.__.____ __:__"
                  className='maxWidth'
                />
              </LocalizationProvider>
            <Box className='mt-2' display="flex" justifyContent="space-between">
              <TextField
                label="Complet"
                variant="outlined"
                style={{ flex: 1, marginRight: 10 }}
                value={formData.complet}
                onChange={(event) => setFormData({ ...formData, complet: event.target.value })}
              />
              <TextField
                label="Sală"
                variant="outlined"
                style={{ flex: 1 }}
                value={formData.sala}
                onChange={(event) => setFormData({ ...formData, sala: event.target.value })}
              />
            </Box>
            <TextField
              className='mt-2'
              label="Soluție"
              variant="outlined"
              fullWidth
              value={formData.solutie}
              onChange={(event) => setFormData({ ...formData, solutie: event.target.value })}
            />
            <TextField
              className='mt-2'
              label="Descriere"
              variant="outlined"
              fullWidth
              multiline
              maxRows={4}
              value={formData.sumar}
              onChange={(event) => setFormData({ ...formData, sumar: event.target.value })}
            />
           <FormControlLabel style={{display:'none'}}
              control={
                <Switch
                  checked={formData.atentionare === 1} 
                  onChange={(event) => {
                    setFormData({
                      ...formData, 
                      atentionare: event.target.checked ? 1 : 0
                    })
                  }}
                />
              }
              label="Atenționare termen de judecată"
            />

            <Box className="mt-3" display="flex" justifyContent="space-between"> 
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                <DatePicker
                  label="Dată Document"
                  value={formData.dataDocument}
                  selected={formData.dataDocument}
                  onChange={(newValue) => {
                    setFormData({ ...formData, dataDocument: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                  inputFormat="dd.MM.yyyy" 
                  format="dd.MM.yyyy" 
                  mask="__.__.____"
                  className='maxWidth'
                />
              </LocalizationProvider>

              <TextField
                label="Numar Document"
                variant="outlined"
                style={{ width: '100%' }}
                value={formData.numarDocument}
                onChange={(event) => setFormData({ ...formData, numarDocument: event.target.value })}
              />
            </Box>
            </DialogContent>    
            <DialogActions>
                <Button variant="contained" color="success" size='medium' type="submit">Adăugare termen</Button>
            </DialogActions>
        </form>
      </Dialog>
      </div>
    );
  }

  

  return (
    <div>
      <div style={{width:'100%', display:'block', height:'50px'}}>
        {areDrept(DreptDeModificareDosare) ? ( 
          <>
          <Button 
            disabled={!areDrept(DreptDeModificareDosare)}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            style={{ marginTop: '10px', minWidth:'200px', float:'right' }}
            onClick={handleOpenModal}
            size="small"
          >
              Adăugare termen
          </Button>   
           </>
        ) : null }
      <Button color="warning" variant="outlined" size="small" style={{marginTop: '10px'}} onClick={() => handleOpenModalJurisprudenta(id)} startIcon={<GavelIcon />}>Jurisprudență Asociată</Button>
        <JurisprudentaAsociataTermen
          open={openModal}
          onClose={() => setOpenModal(false)}
          id={selectedId}
        />
        
      <Button
        variant="contained"
        color="warning"
        onClick={() => handleExpandAll()}
        style={{ marginTop: '10px', minWidth: '200px', marginRight:'10px', float: 'right' }}
        size="small"
      >
        {expandAll ? '▲ Restrângere termene' : '▼ Expandare termene'}
      </Button>

      </div>
      
      {Array.isArray(termene) && termene.length > 0 && (
      Object.keys(groupedTermene).map((stadiu, stadiuIndex) => (
      <div key={stadiuIndex}>
        <Typography className='denumireStadiu' variant="h6" style={{ marginTop: '20px' }}>↳ {stadiu}</Typography> 
        {groupedTermene[stadiu].map((termen, index) => (
          <Accordion
            key={termen.id}
            expanded={expandedTermen[termen.id] || false}
            onChange={handleAccordionChange(termen.id)}
            style={{ marginBottom: '10px' }}
          >
          <AccordionSummary className={termen.user_managed === 1 ? "termenAdaugat" : ""} expandIcon={<ExpandMoreIcon />}>
          {termen.user_managed === 1 && (
            <Tooltip title="Termen adăugat sau modificat de utilizator">
              <PersonIcon style={{ marginRight: 8, color: 'white' }} />
            </Tooltip>
          )}
          <Typography>{formatDate(termen.dataTermen)} - {termen.tipsolutie} - {termen.complet} (Stadiu {termen.stadiudosar.stadiu.denumire} - {formatDateFaraora(termen.stadiudosar.data)})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleEventClick(termen)} startIcon={<NotificationsIcon />} variant='contained' size="small" color="warning" className='pull-right mb-3' >Administrare notificări la termen</Button>
              <Box marginBottom={2} marginTop={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                  <DateTimePicker
                    label="Dată și oră termen"
                    value={termen.dataTermen ? toLocalTime(new Date(termen.dataTermen)) : null}
                    onChange={(newValue) => {
                      console.log(`DateTimePicker - selected value: ${newValue}`);
                      handleFieldChange(index, 'dataTermen', newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                    inputFormat="dd.MM.yyyy HH:mm"
                    ampm={false}
                    mask="__.__.____ __:__"
                    className='maxWidth'
                    disabled={!isEditing[index]}
                  />
                </LocalizationProvider>

              </Box>
              <Box display="flex" justifyContent="space-between">
              <TextField
                label="Complet"
                variant="outlined"
                style={{ flex: 1, marginRight: 10 }}
                value={termen.complet}
                onChange={(event) => handleFieldChange(index, 'complet', event.target.value)}
                disabled={!isEditing[index]}
              />
              <TextField
                label="Sală"
                variant="outlined"
                style={{ flex: 1 }}
                value={termen.sala || "-"}
                onChange={(event) => handleFieldChange(index, 'sala', event.target.value)}
                disabled={!isEditing[index]}
              />
              </Box>
              <Box marginBottom={2} marginTop={2}>
              <TextField
                label="Soluție"
                variant="outlined"
                value={termen.tipsolutie}
                onChange={(event) => handleFieldChange(index, 'tipsolutie', event.target.value)}
                fullWidth
                disabled={!isEditing[index]}
              />
              </Box>
              <Box marginBottom={2}>
              <TextField
                  label="Descriere"
                  variant="outlined"
                  value={termen.sumarsolutie}
                  onChange={(event) => handleFieldChange(index, 'sumarsolutie', event.target.value)}
                  fullWidth
                  multiline
                  maxRows={4}
                  disabled={!isEditing[index]}
                />
              </Box>
              <Box marginBottom={2}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <TextField
                  label="Activitate depusă"
                  variant="outlined"
                  value={termen.activitate || ''}
                  onChange={(e) => handleActivitateChange(index, e.target.value)}
                  fullWidth
                  multiline
                  maxRows={4}
                  disabled={!isEditing[index]}
                />

                <Autocomplete 
                  options={users}
                  getOptionLabel={(option) => `${option.fullname} (${option.name})`}
                  value={users.find(user => user.id === termen.iduser) || null}
                  onChange={(event, newValue) => {
                    handleUserChange(index, newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Selectați un consilier juridic" />}
                  style={{ width: 300 }}
                  disabled={!termen.activitate || !isEditing[index]}
                />
              </div>
              </Box>
              <Box marginBottom={2} style={{display:'none'}}>
                <FormControlLabel control={<Switch disabled={!areDrept(DreptDeModificareDosare)} checked={termen.atentionari} onChange={handleSwitchChange(index)} />} label="Atenționare termen de judecată" />
              </Box>
              <Box display="flex" justifyContent="space-between"> 
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                <DatePicker
                  label="Dată document"
                  value={termen.dataDocument ? new Date(termen.dataDocument) : null}
                  onChange={(newValue) => handleFieldChange(index, 'dataDocument', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  className='maxWidth'
                  disabled={!isEditing[index]}
                />
              </LocalizationProvider>
                <TextField
                  label="Număr document"
                  variant="outlined"
                  style={{ width: '100%' }}
                  value={termen.numarDocument || ""}
                  onChange={handleNumarDocumentChange(index)} 
                  disabled={!isEditing[index]}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
                <Box display="flex">
                  {areDrept(DreptDeModificareDosare) && !isEditing[index] ? (
                    <>
                      <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={handleEdit(index)}
                        startIcon={<EditIcon />}
                        style={{ marginRight: 10 }}
                      >
                        Modificare
                      </Button> 
                    </>
                  ) : null}
                  {areDrept(DreptDeModificareDosare) && isEditing[index] ? (
                    <>
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={handleSalvare(index)}
                        startIcon={<SaveIcon />}
                        style={{ marginRight: 10 }}
                      >
                        Salvare
                      </Button>
                      <Button
                        variant="text"
                        color="warning"
                        size="small"
                        onClick={() => handleCancel(index)}
                        style={{ marginRight: 10 }}
                      >
                        Anulare
                      </Button>
                    </>
                  ) : null}
                </Box>
               

              {areDrept(DreptDeModificareDosare) ? (
                <Button
                  variant="text"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(termen.id)}
                  disabled={!areDrept(DreptDeModificareDosare)}
                  startIcon={<DeleteIcon />}
                >
                  Ștergere
                </Button>
              ) : null}
            </Box>

            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      </div>
    )))} 

      
<Modal id="modalTermene" open={selectedTermenEvent !== null} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      p: 2,
      outline: 'none',
    }}
  >
    <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
      <CloseIcon />
    </IconButton>

    <div className="headerTermen">
      <div>
        <h3>Termen: {selectedTermenEvent?.termen?.termen.sumarSolutie}</h3>
        <p>Soluție:</p>
        <p style={{maxHeight:'100px', overflow:'auto', border:'1px solid #CCC', background:'#FAFAFA', padding:'10px', marginBottom:'10px'}}>{selectedTermenEvent?.termen?.termen.solutie}</p>
        <p>Complet: {selectedTermenEvent?.termen.termen?.complet}</p>
        <p>Data termen: {selectedTermenEvent?.termen?.termen.dataTermen ? format(parseISO(selectedTermenEvent?.termen?.termen.dataTermen), 'dd.MM.yyyy hh:mm') : ''}</p>
        <p>
          Număr dosar:
          <a href={`/editare-dosar/${selectedTermenEvent?.termen?.termen.idDosar}`} >
            {selectedTermenEvent?.termen?.termen.numarDosar}
          </a>
        </p>
        <p>Client dosar: {selectedTermenEvent?.termen?.termen.clientDosar}</p>
        <p>Parte adversă: {selectedTermenEvent?.termen?.termen.parteAdversa}</p>
      </div>


      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        size="small"
                        className="google-button"
                        onClick={handleExportToGoogleCalendar}
                        style={{ marginRight: '10px' }}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faGoogle} />
                        </Icon>
                        <Typography variant=" ">Export în Google Calendar</Typography>
                      </Button> 

                      <Button
                        variant="contained"
                        size="small"
                        className="ios-button"
                        onClick={handleExportToIosCalendar}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faApple} />
                        </Icon>
                        <Typography variant=" ">Export în iOS Calendar</Typography>
                      </Button>

                    </Box>

    </div>
    <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
        <Grid item>
        <Typography variant="body1">Administrare notificări pe termen</Typography>
      </Grid>
      <Grid item>  
      { termenTrecut  ? (
          <Alert severity="warning">Nu se mai pot adăuga atenționări pentru termene trecute.</Alert>
        ) : (
          <Button variant="contained" color="warning"  size='small' startIcon={<AddCircleOutlineIcon />} onClick={handleCreateModalOpen2}>
            Adăugare notificare pentru termen
          </Button>
        )}
      </Grid>
      </Grid>
  
      {selectedTermenEvent?.termen?.notificariTermen && selectedTermenEvent.termen.notificariTermen.length > 0 ? (
            <TableContainer style={{maxHeight:'300px'}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow> 
                    <TableCell>Descriere</TableCell>
                    <TableCell className='text-center'>De la data</TableCell>
                    <TableCell className='text-center'>Până la data</TableCell>                              
                    <TableCell className="text-center">Notificare</TableCell>                         
                    <TableCell className="text-center">SMS</TableCell>                         
                    <TableCell className="text-center">E-Mail</TableCell>                         
                    <TableCell className="text-center">Push</TableCell>
                    <TableCell className="text-center">Înainte cu câte zile</TableCell>
                    <TableCell className="text-center">În fiecare zi?</TableCell>

                    <TableCell className='text-center'>Acțiuni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                 {selectedTermenEvent?.termen?.notificariTermen.map((notificare) => (
                    <TableRow key={notificare.id}>  
                      <TableCell>{notificare.descriere}</TableCell>
                      <TableCell className='text-center'>{notificare.deladata}</TableCell>
                      <TableCell className='text-center'>{notificare.panaladata}</TableCell>
                      
                      <TableCell className='text-center'>{notificare.notificare === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_sms === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_email === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_push === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.reminder === 1 ? "1 zi" : `${notificare.reminder} zile`}</TableCell>
                      <TableCell className='text-center'>{notificare.recurent === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>
                      <Tooltip title="Editare" arrow>
                        <IconButton onClick={() => handleEditModalOpenTermen(notificare.id, selectedTermenEvent?.termen?.termen.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ștergere" arrow>
                      <IconButton onClick={() => handleDeleteNotificareTermen(notificare.id, selectedTermenEvent?.termen?.termen.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>              
                 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> 

      ) : (
        selectedTermenEvent?.termen?.notificariTermen ? (
        <div className='text-center'>
              <Alert severity="info">Nu există notificări definite pentru acest termen.</Alert>
         </div>
         ) : null
     )}
    <Button variant=" " className="pull-right mt-4" size="small" onClick={handleCloseModal}>
      Închidere eveniment
    </Button>
  </Box>
   
   
</Modal>

<Dialog open={createModalOpen2} onClose={handleCreateModalClose}>
<DialogTitle className='titluModal mb-3'>
      <Typography variant="h6">Creare notificare termen</Typography>

      <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCreateModalClose}>
        <CloseIcon />
      </IconButton>
  </DialogTitle>
<DialogContent>
          <div className="createNotificare">
            <Typography variant="h6">Creare notificare termen</Typography>
            <Box sx={{ mt: 2 }}>
            <TextField 
              name="descriere"
              label="Descriere"
              value={newNotificare.descriere}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            </Box>
            <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Până la data"
                value={newNotificare.panaladata ? new Date(newNotificare.panaladata) : null}
                onChange={(newValue) => {
                  setNewNotificare((prevNotificare) => ({ ...prevNotificare, panaladata: newValue }));
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="dd/MM/yyyy"
              />
            </LocalizationProvider>
             </Box>
             <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box>
                <Typography variant="subtitle2">Notificare</Typography>
              <Switch
                name="notificare"
                checked={newNotificare.notificare === 1}
                onChange={(e) =>
                  setNewNotificare((prevNotificare) => ({ ...prevNotificare, notificare: e.target.checked ? 1 : 0 }))
                }
                color="primary"
              />
              
              </Box>
              <Box>
              <Typography variant="subtitle2">SMS</Typography>
              <Switch
                name="via_sms"
                checked={newNotificare.via_sms === 1}
                onChange={(e) =>
                  setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: e.target.checked ? 1 : 0 }))
                }
                color="primary"
                disabled={newNotificare.notificare !== 1}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">E-Mail</Typography>
              <Switch
                name="via_email"
                checked={newNotificare.via_email === 1}
                onChange={(e) =>
                  setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_email: e.target.checked ? 1 : 0 }))
                }
                color="primary"
                disabled={newNotificare.notificare !== 1}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">Push</Typography>
              <Switch
                name="via_pushnotificare"
                checked={newNotificare.via_push === 1}
                onChange={(e) =>
                  setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_push: e.target.checked ? 1 : 0 }))
                }
                color="primary"
                disabled={newNotificare.notificare !== 1}
              />
              </Box> 
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select name="reminder" value={newNotificare.reminder} onChange={handleInputChange} fullWidth margin="normal">
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              name="recurent"
              checked={newNotificare.recurent === 1}
              onChange={(e) =>
                setNewNotificare((prevNotificare) => ({ ...prevNotificare, recurent: e.target.checked ? 1 : 0 }))
              }
              color="primary"
            />
            </Box> 
          </div>
        </DialogContent>
        <DialogActions>
              <Button variant="contained" fullWidth color="success" onClick={() => handleCreateNotificare('termen')}>
                Creare notificare pentru termen
              </Button> 
        </DialogActions>
      </Dialog>

      
<Dialog open={editModalOpen} onClose={handleEditModalClose} maxWidth="sm" fullWidth>
<DialogTitle className='titluModal mb-3'>
      <Typography variant="h6">Editare notificare</Typography>

      <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleEditModalClose}>
        <CloseIcon />
      </IconButton>
  </DialogTitle>
  <DialogContent> 
          <TextField
            label="Descriere"
            variant="outlined"
            name="descriere"
            value={editedNotificare.descriere}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Dată notificare"
              value={editedNotificare.panaladata ? new Date(editedNotificare.panaladata) : null}
              onChange={(newValue) => {
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, panaladata: newValue }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              inputFormat="dd/MM/yyyy"
              className='maxWidth'
              maxDate={dataConvertitaTermen}
            />
          </LocalizationProvider>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2">Notificare</Typography>
            <Switch
              checked={editedNotificare.notificare === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, notificare: value }));
              }}
            />
            </Box>
            <Box>
            <Typography variant="subtitle2">SMS</Typography>
            <Switch
              checked={editedNotificare.via_sms === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: value }));
              }}
              disabled={editedNotificare.notificare !== 1}
            />
            </Box>
            <Box>
            <Typography variant="subtitle2">E-Mail</Typography>
            <Switch
              checked={editedNotificare.via_email === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_email: value }));
              }}
              disabled={editedNotificare.notificare !== 1}
            />
            </Box>
            <Box>
            <Typography variant="subtitle2">PUSH</Typography>
            <Switch
              checked={editedNotificare.via_push === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_push: value }));
              }}
              disabled={editedNotificare.notificare !== 1}
            />
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select
            fullWidth
              value={editedNotificare.reminder}
              onChange={(event) => {
                const value = event.target.value;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, reminder: value }));
              }}
            >
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              checked={editedNotificare.recurent === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, recurent: value }));
              }}
            />
          </Box>

        </DialogContent>
        <DialogActions>

        <Button variant="contained" color="success" fullWidth 
          onClick={() => handleSaveEditTermen(selectedTermenEvent?.termen?.termen.id)}>
            Salvare
          </Button>
        </DialogActions>
      </Dialog>
{/*modal 2*/} 
<Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
      <DialogTitle className='titluModal mb-3'>
          <Typography variant="h6">Adăugare termen nou</Typography>
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
      </DialogTitle>
          <form onSubmit={handleFormSubmit}> 
        <DialogContent>
          <FormControl fullWidth variant="outlined" style={{ marginBottom: '10px' }}>
            <InputLabel htmlFor="stadiuDosar">Stadiu dosar</InputLabel>
            <Select
              value={formData.idStadiuDosar || ''}
              onChange={(event) => setFormData({ ...formData, idStadiuDosar: event.target.value })}
              required
              label="Stadiu dosar"
              inputProps={{
                name: 'stadiuDosar',
                id: 'stadiuDosar',
              }}
            >
              <MenuItem value={null} disabled>
                Selectați un stadiu
              </MenuItem>
              {stadiiDosarData.map((stadiu) => (
                <MenuItem key={stadiu.id} value={stadiu.id}>
                  {stadiu.numeStadiu}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <LocalizationProvider className='mt-3' dateAdapter={AdapterDateFns} adapterLocale={ro}>
                <DateTimePicker
                  label="Dată și oră termen"
                  value={formData.dataTermen}
                  onChange={(newValue) => {
                    setFormData({ ...formData, dataTermen: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                  inputFormat="dd.MM.yyyy HH:mm"
                  ampm={false} 
                  mask="__.__.____ __:__"
                  className='maxWidth'
                />
              </LocalizationProvider>
            <Box className='mt-2' display="flex" justifyContent="space-between">
              <TextField
                label="Complet"
                variant="outlined"
                style={{ flex: 1, marginRight: 10 }}
                value={formData.complet}
                onChange={(event) => setFormData({ ...formData, complet: event.target.value })}
              />
              <TextField
                label="Sală"
                variant="outlined"
                style={{ flex: 1 }}
                value={formData.sala}
                onChange={(event) => setFormData({ ...formData, sala: event.target.value })}
              />
            </Box>
            <TextField
              className='mt-2'
              label="Soluție"
              variant="outlined"
              fullWidth
              value={formData.solutie}
              onChange={(event) => setFormData({ ...formData, solutie: event.target.value })}
            />
            <TextField
              className='mt-2'
              label="Descriere"
              variant="outlined"
              fullWidth
              multiline
              maxRows={4}
              value={formData.sumar}
              onChange={(event) => setFormData({ ...formData, sumar: event.target.value })}
            />
           <FormControlLabel style={{display:'none'}}
              control={
                <Switch
                  checked={formData.atentionare === 1} 
                  onChange={(event) => {
                    setFormData({
                      ...formData, 
                      atentionare: event.target.checked ? 1 : 0
                    })
                  }}
                />
              }
              label="Atenționare termen de judecată"
            />

            <Box className="mt-3" display="flex" justifyContent="space-between"> 
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                <DatePicker
                  label="Dată Document"
                  value={formData.dataDocument}
                  selected={formData.dataDocument}
                  onChange={(newValue) => {
                    setFormData({ ...formData, dataDocument: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                  inputFormat="dd.MM.yyyy" 
                  format="dd.MM.yyyy" 
                  mask="__.__.____"
                  className='maxWidth'
                />
              </LocalizationProvider>

              <TextField
                label="Numar Document"
                variant="outlined"
                style={{ width: '100%' }}
                value={formData.numarDocument}
                onChange={(event) => setFormData({ ...formData, numarDocument: event.target.value })}
              />
            </Box>
            </DialogContent>    
            <DialogActions>
                <Button variant="contained" color="success" size='medium' type="submit">Adăugare termen</Button>
            </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
