import React, { useState, useEffect, useRef } from 'react';
import verificareToken from './VerificareToken';
import { Tooltip, Alert } from '@mui/material';

const NotificariAplicatie = () => {
    
  const { token } = verificareToken();
  const [notificari, setNotificari] = useState({
    termene: [],
    notificariDosar: [],
    notificariTermen: [],
    notificariUser: []
  });

  const [tabCurent, setTabCurent] = useState('termene');
  const [showDropdown, setShowDropdown] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const dropdownRef = useRef(null); 
  const handleMouseEnter = () => {
    // Anulează timer-ul atunci când mouse-ul intră în dropdown
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    // Setează un timer pentru a închide dropdown-ul
    const id = setTimeout(() => {
      setShowDropdown(false);
    }, 100);
    setTimerId(id);
  };


  const handleMouseMoveOutside = (event) => {
    // Verifică dacă mouse-ul este în afara dropdown-ului
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
 
  const fetchNotificari = async () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    try {
      const response = await fetch(`${window.API_URL}notificariAplicatie`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setNotificari(data.data);
      } else {
        console.error('Failed to fetch notifications');
      }
    } catch (error) {
      console.error('Error while fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotificari();
  }, []);

  const handleNotificareClick = async (idNotificare, tipNotificari) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const tip = tipNotificari === 'termene' ? 'termen' : 'notificare';
  try {
    await fetch(`${window.API_URL}setClick?idNotificare=${idNotificare}&tip=${tip}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      fetchNotificari();
    } catch (error) {
      console.error('Error while updating notification:', error);
    }
  };
 
  function extrageDataFaraOra(dataCuOra) {
    const dataFaraOra = dataCuOra.split(' ')[0];
    const dataCuPuncte = dataFaraOra.replace(/-/g, '.');
    return dataCuPuncte;
  }
  
  const renderNotificari = (tipNotificari) => {
    if (notificari[tipNotificari].length === 0) {
        return (
          <Alert severity="warning">
            Nu există notificări în această categorie.
          </Alert>
        );
      }

    return notificari[tipNotificari].map(notificare => (
<div
          key={notificare.id}
          className={`notificare ${!notificare.dataluatlacunostinta ? 'necitita' : ''}`}
          onClick={() => handleNotificareClick(notificare.id, tipNotificari)}
        >
          <div className="notificare-content">
            {renderContentBasedOnType(notificare, tipNotificari)}
            {!notificare.dataluatlacunostinta && <div className="notificare-status"></div>}
          </div>
        </div>
    ));
  };
  
  const renderContentBasedOnType = (notificare, tipNotificari) => {
    switch (tipNotificari) {
     
        // pentru termene
        case 'termene':
        return (
          <div className="notificare-detalii">
            <p><strong>Nr. dosar:</strong> {notificare.nrdosar}</p>
            <p><strong>Data termen:</strong> {new Date(notificare.datatermen).toLocaleString('ro-RO')}</p>
          </div>
        );
      case 'notificariDosar':
        // pentru notificările dosar
        return (
          <div className="notificare-detalii">
            <p><strong>Client:</strong> {notificare.client}</p>
            <p><strong>Info:</strong> {notificare.descriere}</p>
            <p><strong>Nr. dosar:</strong> {notificare.numarDosar}</p>
          </div>
        );
      case 'notificariTermen':
        // pentru notificările termen
        return (
          <div className="notificare-detalii">
            <p><strong>Client:</strong> {notificare.client}</p>
            <p><strong>Info:</strong> {notificare.descriere}</p>
            <p><strong>Nr. dosar:</strong> {notificare.numarDosar}</p>
            <p><strong>Data:</strong> {extrageDataFaraOra(notificare.dataTermen)}</p> 
          </div>
        );
      case 'notificariUser':
        // pentru notificările utilizator
        return (
          <div className="notificare-detalii">
            <p><strong>Info:</strong> {notificare.descriere}</p>
            <p><strong>Data:</strong> {extrageDataFaraOra(notificare.dataSfarsit)}</p> 
          </div>
        );
      default:
        return <div>Tip de notificare necunoscut</div>;
    }
  };
  
  
  const countNecitite = (tipNotificari) => {
    return notificari[tipNotificari].filter(n => !n.dataluatlacunostinta).length;
  };
  const totalNecitite = Object.keys(notificari).reduce((total, tip) => total + countNecitite(tip), 0);

  return (
    <div className="notificari-container" 
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <div 
      className="nav-link" 
      style={{ cursor: 'pointer', padding:'8px 12px' }}
    >
        <i className="fa fa-bell" style={{fontSize:'1.4rem'}}></i>
        {totalNecitite > 0 && <Tooltip title={`Aveți ${totalNecitite} notificări necitite`} placement='left'><span className="badge" style={{marginTop:'7px', marginRight:'8px', padding:'5px 5px'}}>{totalNecitite}</span></Tooltip>}
      </div>
{showDropdown && (
    <div className="notificari-aplicatie dropdown-notificari" 
    ref={dropdownRef}>
      <ul className="taburi-notificari">
        <li className={tabCurent === 'termene' ? 'activ' : ''} onClick={() => setTabCurent('termene')}>
          Termene <span className='nr'>{countNecitite('termene')}</span>
        </li>
        <li className={tabCurent === 'notificariDosar' ? 'activ' : ''} onClick={() => setTabCurent('notificariDosar')}>
          Dosare <span className='nr'>{countNecitite('notificariDosar')}</span>
        </li>
        <li className={tabCurent === 'notificariTermen' ? 'activ' : ''} onClick={() => setTabCurent('notificariTermen')}>
          Notificări termene <span className='nr'>{countNecitite('notificariTermen')}</span>
        </li>
        <li className={tabCurent === 'notificariUser' ? 'activ' : ''} onClick={() => setTabCurent('notificariUser')}>
          Proprii <span className='nr'>{countNecitite('notificariUser')}</span>
        </li>
      </ul>

      <div className="continut-notificari">
        {renderNotificari(tabCurent)}
      </div>
    </div>
    )}
    </div>

  );
};

export default NotificariAplicatie;
