import React, { useState } from 'react';
import { Button, Menu, MenuItem, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const links = [
  { href: 'https://doc.tribunalularad.ro/autentificare?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Arad' },
  { href: 'https://www.ca-bacau.ro/EcrisPub/?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Bacău' },
  { href: 'https://www.curteadeapelcluj.ro/index.php/dosare/info-dosar.html?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Cluj' },
  { href: 'https://infodosar.curteapelconstanta.eu/Detalii_Dosar.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Constanța' },
  { href: 'https://doc.curteadeapelcraiova.eu/autentificare?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Craiova' },
  { href: 'http://info.ca-iasi.ro:8080/Dosare_Ecris_RT.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Iași' },
  { href: 'http://infodosar.curteadeapelmures.ro/Detalii_Dosar.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Mureș' },
  { href: 'http://infodosar.curteadeapeloradea.ro/Detalii_Dosar.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Oradea' },
  { href: 'http://www.curteadeapelpitesti.ro/Detalii_Dosar.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Pitești' },
  { href: 'https://doc.caploiesti.ro/autentificare?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Ploiești' },
  { href: 'http://www.tribunalulsibiu.ro/Home/InfoDosar?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Sibiu' },
  { href: 'http://infodosar.curteadeapelsuceava.ro/Detalii_Dosar.aspx?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Suceava' },
  { href: 'https://doc.curteapeltimisoara.ro/autentificare?id=2%2F103%2F2003%2Fa6&idinstanta=32', title: 'Timișoara' },
];

const CaDosar = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
      
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        return (
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              startIcon={<MenuIcon />}
              variant="contained"
              size='small'
              color='warning'
            >
              Documente Curtea de Apel
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {links.map((link, index) => (
                <MenuItem key={index} onClick={handleClose}>
                  <Link style={{width:'100%'}} href={link.href} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    {link.title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      };
export default CaDosar;
