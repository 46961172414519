import React, { useState, useEffect } from 'react';
import { Dialog, Alert, Box, DialogTitle, DialogContent, Tooltip, DialogActions, List, ListItem, ListItemText, Checkbox, Button, TextField, Typography, IconButton, Snackbar, Divider  } from '@mui/material';
import verificareToken from './VerificareToken';
import GetAppIcon from '@mui/icons-material/GetApp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AdministrarePartajari from './AdministrarePartajari';
import CloseIcon from '@mui/icons-material/Close';

const PartajareDosarDialog = ({ iddosar, iduser, open, onClose }) => {  
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  const [documente, setDocumente] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [descriere, setDescriere] = useState('');
  const [fullname, setFullname] = useState('');
  const [linkGenerat, setLinkGenerat] = useState(null);  
  const [openLinkDialog, setOpenLinkDialog] = useState(false);   
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [numarDosar, setNumarDosar] = useState(null); 
  const { token } = verificareToken();

  const [openAdmin, setOpenAdmin] = useState(false);
  const handleOpenAdmin = () => {
    setOpenAdmin(true);
  };

  const handleCloseAdmin = () => {
    setOpenAdmin(false);
  };

    // Funcția de a prelua detaliile utilizatorului
    const fetchUserDetails = async () => {
      const apiUrl = window.API_URL;
      try {
        const userResponse = await fetch(`${apiUrl}user`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!userResponse.ok) {
          throw new Error('Eroare la preluarea utilizatorului');
        }
  
        const userData = await userResponse.json();
        setFullname(userData.data.user.fullname);  
      } catch (error) {
        setError('A apărut o eroare la preluarea utilizatorului.');
      }
    };
  // Funcția de a prelua informațiile dosarului
  const fetchDosarDetails = async () => {
    const apiUrl = window.API_URL;
    try {
      const dosarResponse = await fetch(`${apiUrl}dosare/${iddosar}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!dosarResponse.ok) {
        throw new Error('Eroare la preluarea detaliilor dosarului');
      }

      const dosarData = await dosarResponse.json();
      const dosarInfo = dosarData.data.sumarDosar;

      // Folosim nrdosar sau nrinterndosar
      if (dosarInfo.nrdosar) {
        setNumarDosar(dosarInfo.nrdosar);
      } else {
        setNumarDosar(dosarInfo.nrinterndosar);
      }
    } catch (error) {
      setError('A apărut o eroare la preluarea detaliilor dosarului.');
    }
  };
  // Funcția de a prelua documentele din dosar
  const fetchDocumenteDosar = async () => {
    const apiUrl = window.API_URL;
    setLoading(true);
    setError(null);
    try {
      const documenteResponse = await fetch(`${apiUrl}documenteDosar/${iddosar}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!documenteResponse.ok) {
        throw new Error('Eroare la preluarea documentelor');
      }

      const documenteData = await documenteResponse.json();
      setDocumente(documenteData.documente);
    } catch (error) {
      setError('A apărut o eroare la preluarea documentelor.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setDocumente('');
      setSelectedDocs([]);
      setDescriere('');
      fetchDocumenteDosar();
      fetchUserDetails();
      fetchDosarDetails(); 
    }
  }, [open]);

  // Funcție pentru a selecta documentele de partajat individual
  const handleSelectDocument = (category, doc) => {
    const exists = selectedDocs.find(d => d.name === doc.name && d.category === category);
    if (exists) {
      setSelectedDocs(selectedDocs.filter(d => !(d.name === doc.name && d.category === category)));
    } else {
      setSelectedDocs([...selectedDocs, { name: doc.name, category }]);
    }
  };

  // Funcție pentru a selecta/deselecta toate documentele dintr-o categorie
  const handleSelectAllInCategory = (categoryDocs, category) => {
    const allSelected = categoryDocs.every(doc => selectedDocs.find(d => d.name === doc.name && d.category === category));
    if (allSelected) {
      setSelectedDocs(selectedDocs.filter(d => d.category !== category));
    } else {
      const newSelections = categoryDocs
        .filter(doc => !selectedDocs.find(d => d.name === doc.name && d.category === category))
        .map(doc => ({ name: doc.name, category }));
      setSelectedDocs([...selectedDocs, ...newSelections]);
    }
  };

  // Funcție pentru a salva partajarea și a genera link-ul
  const handleGenerateLink = async () => {
    try {
      const apiUrl = window.API_URL;
      const generateLinkResponse = await fetch(`${apiUrl}partajareDosar/${iddosar}/${iduser}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          documente_selectate: selectedDocs,
          descriere,
          fullname,
          numardosar: numarDosar,
        }),
      });

      if (!generateLinkResponse.ok) {
        throw new Error('Eroare la salvarea partajării');
      }

      const linkData = await generateLinkResponse.json();
      setLinkGenerat(linkData.cheie);  

      onClose(); 
      setOpenLinkDialog(true);  
    } catch (error) {
      console.error('Eroare la generarea linkului:', error);
    }
  };

  // Funcția pentru descărcarea fișierului
  const handleDownload = (docName, category) => { 
    const appUrl = window.APP_URL;
    const downloadUrl = `${appUrl}/fisiere/in/${iddosar}/${category}/${docName}`; 
    window.open(downloadUrl, '_blank');
  };
  // Funcția pentru copierea link-ului în clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(linkPartajare);
    setSnackbarOpen(true);  
  };

  // Închiderea Snackbar-ului
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isEmptyDocumentSet = () => {
    return (
      documente &&
      documente.continut.length === 0 &&
      documente.prealabil.length === 0 &&
      documente.prov.length === 0
    );
  };
  
  const applicationUrl = window.APP_URL;
  const linkPartajare = `${applicationUrl}/docPartajare/${linkGenerat}`;
  return (
    <>   
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle className="titluModal">Partajare documente dosar {numarDosar}</DialogTitle>
        <DialogContent className="mt-3">
        {loading ? (
          <Typography>Încărcare documente...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : isEmptyDocumentSet() ? (
          <Alert severity="info">Nu există nici un document atașat la dosar. Aveți posibilitatea de a atașa documente la dosar din secțiunea <a href={`editare-dosar/${iddosar}`}>editare dosar</a></Alert>
        ) : (
            <>
              <TextField
                label="Descriere pentru client"
                fullWidth
                value={descriere}
                onChange={(e) => setDescriere(e.target.value)}
                margin="normal"
              />
              {documente && (
                <>
                  {/* Documente din categoria "continut" */}
                  <Typography className="document-category-header" variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Checkbox
                      checked={documente.continut.every(doc => selectedDocs.find(d => d.name === doc.name && d.category === 'continut'))}
                      onChange={() => handleSelectAllInCategory(documente.continut, 'continut')}
                      disabled={documente.continut.length === 0} // Disable dacă nu există documente
                    />
                    <span>Documente dosar</span>
                  </Typography>
                  <Divider />
                  <List>
                    {documente.continut.map((doc, index) => (
                      <ListItem key={index}>
                        <Checkbox
                          checked={!!selectedDocs.find(d => d.name === doc.name && d.category === 'continut')}
                          onChange={() => handleSelectDocument('continut', doc)}
                        />
                        <ListItemText primary={doc.name} />
                      </ListItem>
                    ))}
                  </List>

                  {/* Documente din categoria "prealabil" */}
                  <Typography className="document-category-header" variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Checkbox
                      checked={documente.prealabil.every(doc => selectedDocs.find(d => d.name === doc.name && d.category === 'prealabil'))}
                      onChange={() => handleSelectAllInCategory(documente.prealabil, 'prealabil')}
                      disabled={documente.prealabil.length === 0} // Disable dacă nu există documente
                    />
                    <span>Documente prealabile</span>
                  </Typography>
                  <Divider />
                  {documente.prealabil.length > 0 ? (
                    <List>
                      {documente.prealabil.map((doc, index) => (
                        <ListItem key={index}>
                          <Checkbox
                            checked={!!selectedDocs.find(d => d.name === doc.name && d.category === 'prealabil')}
                            onChange={() => handleSelectDocument('prealabil', doc)}
                          />
                          <ListItemText primary={doc.name} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>Nu există documente prealabile</Typography>
                  )}

                  {/* Documente din categoria "prov" */}
                  <Typography className="document-category-header" variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Checkbox
                      checked={documente.prov.every(doc => selectedDocs.find(d => d.name === doc.name && d.category === 'prov'))}
                      onChange={() => handleSelectAllInCategory(documente.prov, 'prov')}
                      disabled={documente.prov.length === 0} // Disable dacă nu există documente
                    />
                    <span>Documente proveniență</span>
                  </Typography>
                  <Divider />
                  {documente.prov.length > 0 ? (
                    <List>
                      {documente.prov.map((doc, index) => (
                        <ListItem key={index}>
                          <Checkbox
                            checked={!!selectedDocs.find(d => d.name === doc.name && d.category === 'prov')}
                            onChange={() => handleSelectDocument('prov', doc)}
                          />
                          <ListItemText primary={doc.name} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>Nu există documente de proveniență</Typography>
                  )}
                </>
              )}
            </>  
          )}
        </DialogContent>
        <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="text" size='small' color="warning" onClick={handleOpenAdmin}>
            Administrare partajări
          </Button>

          <Box>
            <Button onClick={handleGenerateLink} disabled={!descriere.trim()} color="success">
              Generare link
            </Button>
            <Button onClick={onClose} color="secondary">
              Anulare
            </Button>
          </Box>
        </Box>
      </DialogActions>
      </Dialog>

      <Dialog open={openAdmin} fullWidth maxWidth="sm">
      <DialogTitle className='titluModal'>
          Administrare partajări
          <IconButton
            aria-label="close"
            onClick={handleCloseAdmin}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='mt-3'>
          <AdministrarePartajari iduser={iduser} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdmin} color="secondary">Închidere</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pentru afisarea linkului si documentele selectate */}
      <Dialog open={openLinkDialog} fullWidth maxWidth="md">
      <DialogTitle className='titluModal'>
        Link generat și fișiere partajate
          <IconButton
            aria-label="close"
            onClick={() => setOpenLinkDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle> 
        <DialogContent className='mt-3'>
        <Typography variant="h6"  style={{textAlign:'center'}}>Link generat cu succes</Typography>
          <Typography variant="body1" style={{ wordBreak: 'break-all', textAlign:'center' }}>
            <a href={linkPartajare} target="_blank" rel="noopener noreferrer">{linkPartajare}</a>
            <Tooltip title="Copierea link-ului generat">
                <IconButton onClick={handleCopyLink} color="default" aria-label="copy-link">
                <FileCopyIcon />
                </IconButton>
            </Tooltip>
          </Typography>
                    <hr></hr>
          <Typography variant="h6" style={{ marginTop: '16px' }} className='document-category-header'>Fișiere partajate:</Typography>
          <List className='listaDocumente'>
            {selectedDocs.map((doc, index) => (
              <ListItem key={index}>
                <ListItemText primary={doc.name} secondary={`Categorie: ${doc.category}`} style={{textTransform:'capitalize'}} />
                <Tooltip title="Descărcare document">
                <IconButton onClick={() => handleDownload(doc.name, doc.category)}>
                  <GetAppIcon />
                </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="text" size='small' color="warning" onClick={handleOpenAdmin}>
            Administrare partajări
          </Button>

          <Box>
          <Button onClick={() => setOpenLinkDialog(false)} color="primary">Închidere</Button>
          </Box>
        </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Link copiat în clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default PartajareDosarDialog;
