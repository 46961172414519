import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Switch
} from '@mui/material';
import Swal from 'sweetalert';

import verificareToken from '../VerificareToken';
import Autocomplete from '@mui/material/Autocomplete';

function TransformareDosarInstanta({ open, onClose, idDosar }) {
  const [numarDosar, setNumarDosar] = useState('');
  const [codInstanta, setCodInstanta] = useState('');
  const [anDosar, setAnDosar] = useState('');
  const [idInstanta, setIdInstanta] = useState('');
  const [accesoriu, setAccesoriu] = useState('');
  const [instante, setInstante] = useState([]);  
  const [sincronizeaza, setSincronizeaza] = useState(true);
  const [erori, setErori] = useState({ numarDosar: '', codInstanta: '', anDosar: '' });
  useEffect(() => {
    if (open) {
      setNumarDosar('');
      setCodInstanta('');
      setAnDosar('');
      setAccesoriu('');
      setIdInstanta('');
      setInstantaInput('');
      setErori({ numarDosar: '', codInstanta: '', anDosar: '' });
    }
  }, [open]);

    const { token } = verificareToken();
  const [instantaInput, setInstantaInput] = useState('');
  
  const valideazaCampuri = () => {
    let eroriTemp = { numarDosar: '', codInstanta: '', anDosar: '' };
    let esteValid = true;
  
    // Verific daca campul "Număr dosar" este completat si valid
    if (!numarDosar) {
      eroriTemp.numarDosar = 'Număr dosar este obligatoriu.';
      esteValid = false;
    } else if (numarDosar <= 0) {
      eroriTemp.numarDosar = 'Numărul dosarului trebuie să fie mai mare decât 0.';
      esteValid = false;
    }
  
    // Verific daca campul "Cod instanță" este completat si valid
    if (!instantaInput) {
      eroriTemp.instantaInput = 'Cod instanță este obligatoriu.';
      esteValid = false;
    } else if (instantaInput <= 0) {
      eroriTemp.instantaInput = 'Codul instanței trebuie să fie mai mare decât 0.';
      esteValid = false;
    }
  
    // Verific daca campul "An dosar" este completat si valid
    if (!anDosar) {
      eroriTemp.anDosar = 'An dosar este obligatoriu.';
      esteValid = false;
    } else if (!/^\d{4}\*{0,2}$/.test(anDosar)) {
      // Verifică dacă anul are exact 4 cifre, opțional urmat de una sau două asteriscuri
      eroriTemp.anDosar = 'Anul dosarului trebuie să fie format din 4 cifre, urmate opțional de una sau două *.';
      esteValid = false;
    }
    setErori(eroriTemp);
    return esteValid;
  }

  // verific dacă dosarul exista
  const verificaDosar = () => {
    if (!valideazaCampuri()) {
        return;
    }
    fetch(`${window.API_URL}verificareByNumarUnicDosar?numarDosar=${numarDosar}&instantaDosar=${instantaInput}&anDosar=${anDosar}&accesoriu=${accesoriu}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      switch (data.data.isFoundOnPortal) {
        case 0: // Cazul 1: Dosarul nu există pe portal
          Swal({
            title: "Atenție",
            text: `Numărul dosarului ${numarDosar}/${instantaInput}/${anDosar} nu se găsește pe portalul instanțelor din România.`,
            icon: "warning",
            buttons: ["Renunță", "Transformă oricum"],
          })
          .then((willTransform) => {
            if (willTransform) transformaInInstanta();
          });
          break;
        case 1: // Cazul 2: Dosarul există în baza de date
          if (data.data.isFoundInDb === 1) {
            Swal({
              title: "Informație",
              text: data.data.messageUsers,
              icon: "info",
              buttons: ["Renunță", "Continuă cu transformarea"],
            })
            .then((willContinue) => {
              if (willContinue) transformaInInstanta();
            });
          } else {
            // Cazul 3: Dosarul a fost găsit pe portal, dar nu există la niciun utilizator
            transformaInInstanta();
          }
          break;
        default:
          // Gestionează alte cazuri sau erori neașteptate
          Swal("Atenționare", "A apărut o eroare neașteptată.", "warning");
      }
    })
    .catch(error => {
      console.error('Error:', error);
      Swal("Atenționare", "A apărut o eroare la verificarea dosarului.", "warning");
    });
  };

  // transform dosarul daca e ok
  const transformaInInstanta = () => {
    const sincronizareValoare = sincronizeaza ? 1 : 0;
    fetch(`${window.API_URL}transformInInstanta/${idDosar}?numarDosar=${numarDosar}&instantaDosar=${instantaInput}&anDosar=${anDosar}&accesoriu=${accesoriu}&sincronizare=${sincronizareValoare}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      if (response.ok) {
        Swal('Succes', 'Dosarul a fost transformat în instanță cu succes!', 'success')
        .then((value) => {
        window.location.href = `/editare-dosar/${idDosar}`;
        });
        onClose();  
      } else {
        response.json().then(data => {
          Swal('Atenționare', data.message, 'warning');
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      Swal('Atenționare', 'A apărut o eroare la transformarea dosarului în instanță.', 'warning');
    });
  };

  useEffect(() => {
    // incarc instantele din api
    fetch(`${window.API_URL}instante`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setInstante(data.data.instante))
      .catch(error => console.error('Error:', error));
 
  }, [token]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className='titluModal'>Transformare dosar în instanță</DialogTitle>
      <DialogContent>
      <div className="row-container-inst mt-3">
            <TextField
                margin="dense"
                label="Număr dosar"
                type="number"
                fullWidth
                value={numarDosar}
                onChange={(e) => setNumarDosar(e.target.value)}
                required
                error={!!erori.numarDosar}
                helperText={erori.numarDosar}
                inputProps={{ min: "0" }}
            />  
          <span>/</span>
          <TextField className='mt-2'
                autoFocus
                margin="dense"
                label="Cod Instanță"
                type="number"
                fullWidth
                required
                value={instantaInput} 
                onChange={(e) => {
                const inputCod = e.target.value;
                setInstantaInput(inputCod); //setez instanta si apoi o caut dupa cod
                const instantaCautata = instante.find((instanta) => instanta.cod === inputCod.toString());
                
                if (instantaCautata) {
                    setIdInstanta(instantaCautata.id); //setez id instanta daca am
                } else {
                    setIdInstanta(null);
                }
                }}
                error={!!erori.instantaInput}
                helperText={erori.instantaInput}
                inputProps={{ min: "0" }}
            />
          <span>/</span>
          <TextField
            className='mt-2'
            margin="dense"
            label="An dosar"
            type="text"
            required
            fullWidth
            value={anDosar}
            onChange={(e) => setAnDosar(e.target.value)}
            error={!!erori.anDosar}
            helperText={erori.anDosar} 
          /><span>/</span>
          <TextField
            className='mt-2'
            margin="dense"
            label="Accesoriu dosar"
            type="text"
            fullWidth
            value={accesoriu}
            onChange={(e) => setAccesoriu(e.target.value)} 
          />
        </div>
        
            <FormControl fullWidth className='mt-2'>
                <InputLabel shrink={Boolean(instantaInput)}>Instanța</InputLabel>
                <Autocomplete
                options={instante} 
                getOptionLabel={(instanta) => instanta.denumire}
                value={instante.find((instanta) => instanta.id === idInstanta) || null}
                onChange={(_, newValue) => {
                    setIdInstanta(newValue?.id || null);
                    setInstantaInput(newValue?.cod || ''); // daca primesc cod din campul de mai sus setez in dropdown instanta curenta
                    
                console.log('instantaInput', instantaInput);
                }}
                renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
                <FormControlLabel
                control={<Switch checked={sincronizeaza} onChange={(e) => setSincronizeaza(e.target.checked)} />}
                label="Sincronizați dosarul cu portalul just.ro"
            />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Anulare
        </Button>
        <Button onClick={verificaDosar} color="success">
          Verifică și Transformă
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TransformareDosarInstanta;
