import React, { useState, useEffect } from 'react';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { IconButton, Tooltip } from '@mui/material';

const PageExpander = ({ initialWideState, onToggle }) => {
  const saveState = (isWide) => {
    const stateValue = isWide ? '1' : '0';
    document.cookie = `isWide=${stateValue}; path=/`;  
    localStorage.setItem('isWide', stateValue);  
    sessionStorage.setItem('isWide', stateValue);  
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const loadState = () => {
    const cookieState = getCookie('isWide');
    const localStorageState = localStorage.getItem('isWide');
    const sessionStorageState = sessionStorage.getItem('isWide');
  
    const states = [cookieState, localStorageState, sessionStorageState];
    const stateCounts = { '0': 0, '1': 0 };
  
    states.forEach(state => {
      if (state === '1' || state === '0') {
        stateCounts[state]++;
      }
    });
  
    return stateCounts['1'] > stateCounts['0'] ? true : false;
  };

  const [isWide, setIsWide] = useState(() => initialWideState || loadState());

  useEffect(() => {
    saveState(isWide);
    if (onToggle) {
      onToggle(isWide); // Notifică părintele despre schimbare
    }
  }, [isWide, onToggle]);

  return (
    <Tooltip 
      title={isWide ? "Restrângere vizualizare pagină" : "Extindere vizualizare pe toată pagina"}
    >
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setIsWide((prev) => !prev);
        }}
        size="small"
        style={{ 
          marginRight: '10px', 
          borderRadius: '50%', 
          border: '1px solid #ccc',  
          padding: '5px',  
          backgroundColor: '#f5f5f5',   
          transition: 'background-color 0.3s ease-in-out'
        }}
      >
        {isWide ? <CloseFullscreenIcon style={{fontSize:'14px'}} /> : <OpenInFullIcon style={{fontSize:'14px'}} />}
      </IconButton>
    </Tooltip>
  );
};

export default PageExpander;
