import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, IconButton, InputAdornment, Box, Typography, Button, Tooltip, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, Grid, List, ListItem, ListItemText, Switch, FormControlLabel, Alert } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid'; 
import { useParams } from 'react-router-dom';
import verificareToken from '../../VerificareToken';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import CloseIcon from '@mui/icons-material/Close';

function stripHtml(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent.replace(/\s+/g, ' ').trim() || "";
}

export default function CautareActe() {
  
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  const [tipuriActe, setTipuriActe] = useState([]);
  const [acte, setActe] = useState([]);
  const [selectedAct, setSelectedAct] = useState(null);
  const [searchData, setSearchData] = useState({ idTipAct: "", numar: "", an: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const isFetching = useRef(false);
  const [startDate, setStartDate] = useState(new Date());
  const [idArtError, setIdArtError] = useState(false);

  const [isSearchInitiated, setIsSearchInitiated] = useState(false);

  const { token } = verificareToken();

  const { id } = useParams();

  const [toggle, setToggle] = useState(true);
  const [idArt, setIdArt] = useState('');
  const [referinta, setReferinta] = useState('totActul');  

  const currentYear = new Date().getFullYear();
  const yearsGenerati = Array.from(new Array(currentYear - 1830 + 1), (val, index) => 1830 + index);

  const years = yearsGenerati.reverse();

  const handleScrollEnd = () => {
    setPage(prevPage => prevPage + 1);  
  };

  const handleReferintaChange = (event, newReferinta) => {
    if (newReferinta !== null) {
      setReferinta(newReferinta);
    }
  };

  useEffect(() => {
    const fetchTipuriActe = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${window.API_URL}tipuriActe`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
          const data = await response.json();
          setTipuriActe(data.data.tipuriActe);
        } else {
          throw new Error('Error fetching tipuriActe');
        }
      } catch (error) {
        console.error(error);
        Swal("Atenționare", "Nu am putut prelua tipurile de acte.", "warning");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTipuriActe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSelectedAct(null);
    setPage(1);
    setActe([]);
    setHasMore(true);
    setIsSearchInitiated(true);
    setIsLoading2(true);

    const filteredSearchData = Object.entries(searchData).reduce((acc, [key, value]) => {
      if (value !== '' && value != null) acc[key] = value;
      return acc;
    }, {});
    
    if (Object.keys(filteredSearchData).length === 0) {
      Swal("Atenție!", "Trebuie să selectați cel puțin o opțiune!", "warning");
      return;
    }

    await fetchActeByPage(1, filteredSearchData);
    setIsLoading2(false);
  };

  const fetchActeByPage = async (currentPage, searchParams = searchData) => {
    if (isFetching.current) return;
    isFetching.current = true;
    setIsSearchLoading(true);

    try {
      const queryParams = new URLSearchParams({ ...searchParams, page: currentPage }).toString();
      const response = await fetch(`${window.API_URL}cautareActe?${queryParams}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.acte.length > 0) {
          setActe((prevActe) => [...prevActe, ...data.data.acte]);
          setHasMore(true); // Continuăm scroll-ul dacă mai există rezultate
        } else {
          setHasMore(false); // Oprim scroll-ul dacă nu mai există rezultate
          Swal("Atenționare", "Căutarea nu a returnat niciun rezultat.", "warning");
          setIsSearchLoading(false);
        }
      } else {
        throw new Error('Error fetching acte');
      }
    } catch (error) {
      console.error(error);
      setHasMore(false);
      setIsSearchLoading(false);
    } finally {
      setIsSearchLoading(false);
      isFetching.current = false;
    }
  };

  useEffect(() => {
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isSearchLoading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
    const endMarker = document.querySelector("#acteEndMarker");

    if (endMarker) observer.observe(endMarker);

    return () => observer.disconnect();
  }, [hasMore, isSearchLoading]);

  useEffect(() => {
    if (isSearchInitiated && page > 1) {
      fetchActeByPage(page);
    }
  }, [page, isSearchInitiated]);


  const formattedDate = selectedAct?.dataVi ? new Date(selectedAct.dataVi).toLocaleDateString('ro-RO') : '';
  const dataEmiterii = selectedAct ? selectedAct.dataEmiterii.split("-").reverse().join("-") : '';
  
  const minDate = new Date(selectedAct?.dataEmiterii);
  
  useEffect(() => {
    if (selectedAct) {
      setStartDate(new Date()); 
    }
  }, [selectedAct]);

  const handleViewAct = async () => {
    if (selectedAct && startDate) {
      const formattedDate = `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`;
  
      const url = `${window.API_URL}creareCaleILegis?idAct=${selectedAct.id}&data=${formattedDate}`;
  
      try {
        const response = await fetch(url, {
          method: 'GET', 
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json', 
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          const newCaleiLegis = data.data.caleilegis;
   
  
          window.open(newCaleiLegis, "_blank");
        } else {
          throw new Error('Eroare la obținerea căii iLegis');
        }
      } catch (error) {
        console.error(error);
        Swal("Atenționare", "Nu am putut obține calea iLegis.", "warning");
      }
    } else {
      Swal("Atenție!", "Nu ați selectat nici un act sau nu ați setat o dată!", "warning");
    }
  };
  

  const handleAdaugareAct = async () => {
    const formattedDate = `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`;
  
    if (referinta === 'articol' && idArt.trim() === "") {
      setIdArtError(true);
      Swal("Atenție!", "Trebuie să completați numărul articolului!", "warning");
      return;
    } else {
      setIdArtError(false);  
    }

    if (selectedAct) {
      const base_url = `${window.API_URL}salveazaActeDosar/${id}`;
      const url = new URL(base_url);
      url.searchParams.append('idAct', selectedAct.id); 
      url.searchParams.append('info', selectedAct.info); 
      url.searchParams.append('data', formattedDate);
  
      if (referinta === 'articol' && idArt.trim() !== "") {
        url.searchParams.append('articol', idArt);
      }
      
      try {
        const response = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
            console.log(base_url);
          const data = await response.json();
           
          console.log(data);
          Swal("Succes!", "Actul a fost salvat cu succes.", "success");
        } else {
          Swal("Eroare!", "Actul nu a putut fi salvat. Încercați din nou.", "error");
        }
  
      } catch (error) {
        Swal("Eroare!", "A apărut o eroare la salvarea actului. Încercați din nou.", "error");
      }
  
    } else {
      Swal("Atenție!", "Nu ați selectat nici un act!", "warning");
    }
  };
  

  const handleToggleChange = (event) => {
    setToggle(event.target.checked);
  };

  const handleIdArtChange = (event) => {
    const value = event.target.value;
    setIdArt(value);
  
    if (value.trim() !== "") {
      setIdArtError(false);
    }
  };
  
  const handleChange = e => {
    const inputVal = e.target.value;
    const numar = parseInt(inputVal, 10);
  
    if (inputVal === '') {
      setSearchData({ ...searchData, numar: '' });
    } else if (!isNaN(numar) && numar >= 0) {
      setSearchData({ ...searchData, numar: numar.toString() });
    } else {
      Swal({
        icon: 'warning',
        title: 'Atenție',
        text: 'Se pot introduce doar cifre!',
      });
    }
  };
  
  if (isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>;
  }

  return (
    <React.Fragment>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <div>
            
            <Autocomplete className='mb-3 mt-4'
                id="tip-act-autocomplete"
                options={tipuriActe}
                getOptionLabel={(option) => option.tip}
                fullWidth
                value={tipuriActe.find(tip => tip.id === searchData.idTipAct) || null}
                onChange={(event, newValue) => {
                    setSearchData({ ...searchData, idTipAct: newValue ? newValue.id : '' });
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Tip Act" variant="outlined" />
                )}
            /> 
 
          <TextField
              className='mb-3 mt-1'
              fullWidth
              label="Numar act"
              type="text"   
              value={searchData.numar}
              onChange={handleChange}
              InputProps={{
                endAdornment: searchData.numar !== '' && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchData({ ...searchData, numar: '' })}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />


          <Autocomplete
            id="year-autocomplete"
            options={years}
            getOptionLabel={(option) => option.toString()}
            value={searchData.an} 
            onChange={(event, newValue) => {
              setSearchData({ ...searchData, an: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Anul actului" variant="outlined" />
            )}
            fullWidth
            className='mb-4 mt-1'
          />

       
              <Button type="submit" fullWidth variant="contained" color="primary" startIcon={<ArrowForwardIcon />}>Cauta</Button>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ maxHeight: '185px', overflowY: 'auto', minHeight: '100px' }}>
            {isLoading2 ? (
               <></>
            ) : (!searchData.idTipAct && !searchData.numar && !searchData.an) ? (
              <Typography>
                <Alert severity="info">Pentru a afișa lista de acte, trebuie să selectați cel puțin un criteriu și să efectuați căutarea. </Alert>
              </Typography>
            ) : (
              <List component="nav" >
                {acte.length > 0 ? (
                  acte.map((act, index) => (
                    <ListItem
                      button
                      key={index}
                      selected={selectedAct === act}
                      onClick={() => setSelectedAct(act)}
                      style={{
                        backgroundColor: selectedAct === act ? '#efefef' : 'transparent',
                        borderLeft: selectedAct === act ? '5px solid #3f51b5' : 'none'
                      }}
                    >
                      <ListItemText primary={act.info} />
                    </ListItem>
                  ))
                ) : (
                  <Typography><b>Nu au fost găsite acte.</b></Typography>
                )}
              </List>

            )}
                 {isSearchLoading && <CircularProgress style={{ display: 'block', margin: '0 auto' }} />}
                 <div id="acteEndMarker" style={{ height: '1px' }}></div> 
          </div> 
          {acte.length > 0 && (
            <>

{selectedAct && (
  <div className="datePickerContainer" style={{borderTop:'2px solid #9c27b0'}}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
    <DatePicker
      label="Selectați la ce dată doriți să vizualizați forma actului"
      value={startDate}
      onChange={(newValue) => {
        setStartDate(newValue);
      }}
      renderInput={(params) => <TextField {...params} fullWidth required />}
      inputFormat="dd/MM/yyyy"
      minDate={minDate}
      className="maxWidth inputMic mb-2 mt-3"
    />
  </LocalizationProvider>


  </div>
)}


            <Button variant="contained" color="secondary" onClick={handleViewAct} fullWidth>
              Vizualizare act
            </Button>

          </>
          )}
          <br></br><br></br>
        </Grid>
        <Grid item xs={12}>
          {selectedAct?.header && acte.length > 0 && (
            <TextField
              disabled
              label={`Rezumatul actului selectat (Data intrării în vigoare: ${formattedDate})`}
              multiline
              fullWidth
              value={stripHtml(selectedAct.header)}
            />
          )}
        </Grid>
        {acte.length > 0 && (
            <Grid container spacing={3} alignItems="center" style={{ marginTop:'5px'}}>
                
                <Grid item xs={2}>
                  <span style={{marginTop: '20px', marginLeft: '20px', fontWeight:'bold'}}>Referință la:</span>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={referinta}
                    exclusive
                    onChange={handleReferintaChange}
                    aria-label="Referință la"
                    disabled={!areDrept(DreptDeModificareDosare)}
                  >
                    <ToggleButton value="totActul" aria-label="Tot actul">
                      Tot actul
                    </ToggleButton>
                    <ToggleButton value="articol" aria-label="Articolul">
                      Articolul
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={7}> 
                    
                   {referinta === 'articol' && (
                      <TextField
                        error={idArtError}
                        helperText={idArtError ? "Acest câmp este obligatoriu!" : ""}
                        disabled={!areDrept(DreptDeModificareDosare)}
                        label="Număr articol"
                        type="number"
                        value={idArt}
                        onChange={handleIdArtChange}
                        inputProps={{ min: 0 }}
                        fullWidth
                        size="small"
                    />
                    )}
                </Grid>

            <Grid item xs={12}> 
                <Button variant="contained" color="success"   disabled={!areDrept(DreptDeModificareDosare)} onClick={handleAdaugareAct} fullWidth>
                    Adăugare Act
                </Button>
            </Grid>

        </Grid>
        )}

      </Grid>
      </React.Fragment>
  );
}
 