import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, Typography, Button, Tooltip, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, Grid, List, ListItem, ListItemText, Switch, FormControlLabel, Alert } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid'; 
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import verificareToken from '../../VerificareToken';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 
import CloseIcon from '@mui/icons-material/Close';

function stripHtml(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent.replace(/\s+/g, ' ').trim() || "";
}

export default function CautarePJ() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const [tipuriActe, setTipuriActe] = useState([]);
    const [judete, setJudete] = useState([]);
    const [tipEmitenti, setTipEmitenti] = useState([]);
    const [materiiPJ, setMateriiPJ] = useState([]);
    const [acte, setActe] = useState([]);
    const [selectedAct, setSelectedAct] = useState(null);
    const [searchData, setSearchData] = useState({ idTipAct: "", judet: "", numar: "", an: "", expresie:"", tipemitent: "", materia: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);  
 
      const { token } = verificareToken();

    const { id } = useParams();

    const currentYear = new Date().getFullYear();
    const yearsGenerati = Array.from(new Array(currentYear - 1830 + 1), (val, index) => 1830 + index);
  
    const years = yearsGenerati.reverse();
  

    useEffect(() => {
      async function fetchData() {
        setIsLoading(true);
        try {
          const tipuriActeResponse = await fetch(`${window.API_URL}tipuriActePJ`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const tipuriActeData = await tipuriActeResponse.json();
          setTipuriActe(tipuriActeData.data.tipuriActe);
    
          const judeteResponse = await fetch(`${window.API_URL}judetePJ`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const judeteData = await judeteResponse.json();
          setJudete(judeteData.data.judete);
    
          const materiiResponse = await fetch(`${window.API_URL}materiiPJ`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const materiiData = await materiiResponse.json();
          setMateriiPJ(materiiData.data.materiiPJ);

          const tipEmitentiResponse = await fetch(`${window.API_URL}tipEmitentiPJ`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const tipEmitentiData = await tipEmitentiResponse.json();
          setTipEmitenti(tipEmitentiData.data.tipuriEmitenti); 
              
        } catch (error) {
          console.error(error);
          Swal("Atenționare", "Nu am putut prelua datele.", "warning");
        } finally {
          setIsLoading(false);
        }
      }
    
      fetchData();
    }, [token]);
    
    
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const filteredSearchData = Object.entries(searchData).reduce((acc, [key, value]) => {
        if (value !== '' && value != null) {
          acc[key] = value;
        }
        return acc;
      }, {});
    
      if (Object.keys(filteredSearchData).length === 0) {
        Swal("Atenție!", "Trebuie să selectați cel puțin o opțiune!", "warning");
        return;
      }
    
      setIsSearchLoading(true);
    
      try {
        const queryParams = new URLSearchParams(filteredSearchData).toString();
        const response = await fetch(`${window.API_URL}infoPracticaJudiciara?${queryParams}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          setActe(data.data.spete);
        } else {
          throw new Error('Error fetching acte');
        }
      } catch (error) {
        console.error(error);
        Swal("Atenționare", "Căutarea nu a returnat niciun rezultat.", "warning");
      } finally {
        setIsSearchLoading(false);
      }
    };
    
  const formattedDate = selectedAct?.data ? new Date(selectedAct.data).toLocaleDateString('ro-RO') : '';

  const handleViewAct = () => {
    if (selectedAct) {
    
      window.open(selectedAct.caleAfisarePJ, "_blank");
    } else {
      Swal("Atenție!", "Nu ați selectat nici un act!", "warning");
    }
  };

  const handleAdaugareAct = async () => {
    if (selectedAct) {
      const base_url = `${window.API_URL}salveazaPJDosar/${id}`;
      const url = new URL(base_url);
      url.searchParams.append('idAct', selectedAct.id); 
      url.searchParams.append('info', selectedAct.info); 
      url.searchParams.append('idMaterie', selectedAct.idmaterie);
      url.searchParams.append('anAct', selectedAct.anact);
      url.searchParams.append('idEmitent', selectedAct.idemitent);
      url.searchParams.append('idTipAct', selectedAct.idtipact);
      url.searchParams.append('tipAct', selectedAct.tipact);
      url.searchParams.append('nrDosar', selectedAct.nrdosar); 

      try {
        const response = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
            console.log(base_url);
          const data = await response.json();
           
          console.log(data);
          Swal("Succes!", "Actul a fost salvat cu succes.", "success");
        } else {
          Swal("Atenție!", "Speța există deja salvată la acest dosar.", "warning");
        }
  
      } catch (error) {
        Swal("Eroare!", "A apărut o eroare la salvarea actului. Încercați din nou.", "error");
      }
  
    } else {
      Swal("Atenție!", "Nu ați selectat nici un act!", "warning");
    }
  };
  console.log("tipuriActe", tipuriActe); 
  console.log("judete", judete);
  console.log("materii", materiiPJ);
  console.log("idtipEmitenti", tipEmitenti);

  if (isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>;
  }

  return (
    <React.Fragment>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className="mb-2">
                <Grid item xs={6}>

                <TextField
                  label="Numar act"
                  type="number"
                  value={searchData.numar}
                  onChange={(e) => {
                    const numar = Math.max(0, parseInt(e.target.value, 10) || '');
                    setSearchData({ ...searchData, numar: numar });
                  }}
                  InputProps={{
                    endAdornment: searchData.numar !== '' ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchData({ ...searchData, numar: '' })}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                    inputProps: { min: 0 }  
                  }}
                  fullWidth
                />

                </Grid>
                <Grid item xs={6}>
                <Autocomplete
                    id="year-autocomplete"
                    options={years}
                    getOptionLabel={(option) => option.toString()}
                    value={searchData.an}
                    onChange={(event, newValue) => {
                      setSearchData({ ...searchData, an: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Anul actului" variant="outlined" />
                    )}
                    fullWidth
                  />

                </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-2">
                <Grid item xs={6}>
                <Autocomplete
                    id="tipact-autocomplete"
                    options={tipuriActe}
                    getOptionLabel={(option) => option.nume}
                    value={tipuriActe.find(tip => tip.id === searchData.idTipAct) || null}
                    onChange={(event, newValue) => {
                        setSearchData({ ...searchData, idTipAct: newValue?.id || '' });
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Tip Act"
                        fullWidth
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={6}>
                <Autocomplete
                    id="judet-autocomplete"
                    options={judete}
                    getOptionLabel={(option) => option.nume}
                    value={judete.find(judet => judet.id === searchData.judet) || null}
                    onChange={(event, newValue) => {
                        setSearchData({ ...searchData, judet: newValue?.id || '' });
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Județ"
                        fullWidth
                        />
                    )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3">
                <TextField 
                label="Expresie" 
                fullWidth
                value={searchData.expresie}
                onChange={e => setSearchData({ ...searchData, expresie: e.target.value })}
                />
            </Grid> 
            <Grid item xs={12} className="mt-3">
                <Autocomplete
                id="tipemitent-autocomplete"
                options={tipEmitenti}
                getOptionLabel={(option) => option.nume}
                value={tipEmitenti.find(tipemitent => tipemitent.id === searchData.tipemitent) || null} 
                onChange={(event, newValue) => {
                    setSearchData({ ...searchData, tipemitent: newValue?.id || '' });
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Tip emitent"
                    fullWidth
                    />
                )}
                />
            </Grid>
            <Grid item xs={12} className="mt-3">
                <Autocomplete
                id="materia-autocomplete"
                options={materiiPJ}
                getOptionLabel={(option) => option ? option.nume : ""}
                value={materiiPJ.find(materia => materia.id === searchData.materia) || null}
                onChange={(event, newValue) => {
                    setSearchData({ ...searchData, materia: newValue?.id || '' }); 
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Materia"
                    fullWidth
                    />
                )}
                />
            </Grid>
            <Grid item xs={12} className="mt-3">
                <Button type="submit" fullWidth variant="contained" color="primary" startIcon={<ArrowForwardIcon />}>Cauta</Button>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ maxHeight: '325px', overflowY: 'auto', minHeight: '100px' }}>
            {isSearchLoading ? (
              <CircularProgress />
            ) : (!searchData.idTipAct && !searchData.numar && !searchData.an && !searchData.judet && !searchData.expresie && !searchData.tipemitent && !searchData.materia) ? (
              <Typography>
                <Alert severity="info">Pentru a afișa lista de acte, trebuie să selectați cel puțin un criteriu și să efectuați căutarea. </Alert>
              </Typography>
            ) : (
              <List component="nav">
              {acte.length > 0 ? (
                acte.map((act, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedAct === act}
                    onClick={() => setSelectedAct(act)}
                    style={{
                      backgroundColor: selectedAct === act ? '#efefef' : 'transparent',
                      borderLeft: selectedAct === act ? '5px solid #3f51b5' : 'none'
                    }}
                  >
                    <ListItemText primary={`${act.tipact} nr. ${act.numaract} (${act.info})`} />
                  </ListItem>
                ))
              ) : (
                <Typography><b>Nu au fost găsite acte.</b></Typography>
              )}
            </List>
            
            )}
          </div><br></br>
          {acte.length > 0 && (
            <Button variant="contained" color="secondary" onClick={handleViewAct} fullWidth>
              Vizualizare act
            </Button>
          )}
          <br></br><br></br>
        </Grid>
        <Grid item xs={12}>
          {selectedAct?.info && acte.length > 0 && (
            <TextField
              disabled
              label={`Rezumatul actului selectat (Data speței: ${formattedDate})`}
              multiline
              fullWidth
              value={stripHtml(selectedAct.info)}
            />
          )}
        </Grid>
        {acte.length > 0 && (
          <Grid container spacing={3} alignItems="center" style={{ marginTop:'5px'}}>
 
            <Grid item xs={12}> 
                <Button disabled={!areDrept(DreptDeModificareDosare)} variant="contained" color="success" onClick={handleAdaugareAct} fullWidth>
                    Adăugare Act
                </Button>
            </Grid>

          </Grid>
        )}

      </Grid>
      </React.Fragment>
  );
}
 