import React, { createContext, useContext, useEffect, useState } from 'react';
import { Howl } from 'howler';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook';

const SoundContext = createContext();

export const SoundProvider = ({ children }) => {
  const { preferinte } = usePreferinteUtilizator();
  const allowsounds = preferinte.allowsounds;
  const [permitereSunet, setPermitereSunet] = useState(false);

  useEffect(() => {
    if (allowsounds !== undefined) {
      const permitSunete = allowsounds ? 'true' : 'false';
      document.cookie = `permitSunete=${permitSunete}; path=/`;
    }
  }, [allowsounds]);

  const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const updatePermitereSunet = () => {
    const cookie = getCookieValue('permitSunete') === 'true';
    setPermitereSunet(cookie);
  };

  useEffect(() => {
    updatePermitereSunet(); 
    const intervalId = setInterval(updatePermitereSunet, 1000);  
    return () => clearInterval(intervalId); 
  }, []);

  const sounds = {
    click: new Howl({
      src: ['/sunete/click.mp3'],
    }),
    open: new Howl({
      src: ['/sunete/open.wav'],
    }),
    flip: new Howl({
      src: ['/sunete/flip.wav'],
    }),
    switch: new Howl({
      src: ['/sunete/switch.wav'],
    }),
    close: new Howl({
      src: ['/sunete/close.mp3'],
    }),
    warning: new Howl({
      src: ['/sunete/warning.mp3'],
    }),
    success: new Howl({
      src: ['/sunete/ok.mp3'],
    }),
    info: new Howl({
      src: ['/sunete/info.wav'],
    }),
    notificare: new Howl({
      src: ['/sunete/notificare2.wav'],
    }),
  };

  const playSound = (sound) => {
    if (!permitereSunet) {
      return; // Nu redau sunetul daca permitereSunet este fals
    }

    if (sounds[sound]) {
      sounds[sound].play();
    }
  };

  return (
    <SoundContext.Provider value={{ playSound }}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = () => {
  return useContext(SoundContext);
};
