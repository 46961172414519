import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Grid, Typography, Box, CircularProgress, IconButton, Button, MenuItem, Select, InputLabel, FormControl, Autocomplete } from '@mui/material';
import verificareToken from '../VerificareToken';
import CalculateIcon from '@mui/icons-material/Calculate';
import Swal from 'sweetalert';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 

import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');  
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export default function InfoDosar({ changeTab }) {
  const { areDrept } = DrepturiUtilizator();
 
  //setez drepturile pe aceasta zona
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { id } = useParams();
    
    const [sePoateModifica, setSePoateModifica] = useState(true);  
    const [nuPoateModifica, setNuPoateModifica] = useState(true);  
    const [dataCreare, setDataCreare] = useState(null);
    const [dosarData, setDosarData] = useState(null);
    const [isLoading, setLoading] = useState(true);  
    const [debitRecuperat, setDebitRecuperat] = useState('');
    const [debitDosar, setDebitDosar] = useState('');
    const [comentarii, setComentarii] = useState('');
    const [clienti, setClienti] = useState([]); 
    const [clientSelectat, setClientSelectat] = useState(''); 
    const [tipParte, setTipParte] = useState('1');
    const [tipParteSelectatManual, setTipParteSelectatManual] = useState('');
    const [tipParteSelectat, setTipParteSelectat] = useState(null);
    const [tipDosar, setTipDosar] = useState(false);  
    const [dateParte, setDateParte] = useState({ 
      societate: '',
      cui: '',
      cnp: '',
      adresa: '',
      banca: '',
      iban: '',
      telefon: '',
      email: '',
      numeprenume: '',
      codabonat: '',
      registrucomert: '' 
    });
  
      const { token } = verificareToken();
    const [minusValoareRecuperata, setMinusValoareRecuperata] = useState('');

    const handleMinusChange = (e) => {
      //setMinusValoareRecuperata(e.target.value);
      const value = e.target.value;
      if (!isNaN(value) && parseInt(value, 10) >= 0) {
        setMinusValoareRecuperata(value);
      }
      
    };
  
    function handleConfirmareAdaugareScadere() {
      const valoare = parseFloat(minusValoareRecuperata) || 0;
      const valoareNumerica = parseFloat(valoare) || 0;
      let mesaj;
    
      if (valoareNumerica > 0) {
        mesaj = `Sunteți sigur că doriți să adăugați ${valoareNumerica} lei la valoarea totală recuperată?`;
      } else if (valoareNumerica < 0) {
        mesaj = `Sunteți sigur că doriți să scădeți ${Math.abs(valoareNumerica)} lei din valoarea totală de recuperat?`;
      } else {
        // Valoare zero sau invalidă
        Swal('Atenționare', 'Introduceți o valoare validă.', 'warning');
        return;
      }
      Swal({
        title: "Confirmare",
        text: mesaj,
        type: "warning",
        icon: 'warning',
        buttons: ['Renunțare', 'Da, confirm!'], 
      }).then((isConfirm) => {
        if (isConfirm) {
          handleAdaugare(); 
        }
      });
      
    }

    const handleAdaugare = () => {
      const valoareCurenta = parseFloat(sumarDosar.valoarerecuperata) || 0;
      const valoareAdaugata = parseFloat(minusValoareRecuperata) || 0;
      const valoareRecuperataNoua = valoareCurenta + valoareAdaugata;
    
      handleInputChange({ target: { name: 'valoarerecuperata', value: valoareRecuperataNoua } }, 'valoarerecuperata');
    };
    
    
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const handlePhoneChange = (event) => {
      const value = event.target.value;
      const regex = /^[0-9]*$/; 
  
      if (regex.test(value) || value === '') {
        setPhone(value);
        setIsPhoneValid(true);
      } else {
        setIsPhoneValid(false);
      }
    };
    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  
      return regex.test(email);
    };
    
    const handleEmailChange = (event) => {
      const { name, value } = event.target;
      handleInputChangeClient(event);  
      setIsEmailValid(validateEmail(value)); 
    };
    const handlePhoneBlur = () => {
      setIsPhoneValid(phone.length === 10);  
    };
    const handleInputChange = (event, fieldName) => {
      if (fieldName === 'datacreare') {
        setDataCreare(event.target.value);
      } else {
        setDosarData(prevState => ({
          ...prevState,
          sumarDosar: {
            ...prevState.sumarDosar,
            [fieldName]: event.target.value
          }
        }));
      }
    };
    
    const handleChange = (e) => {
      setTipParte(e.target.value);
      setTipParteSelectatManual(e.target.value); 
    };

    const fetchDosar = async () => {
      try {
          const response = await fetch(`${window.API_URL}dosare/${id}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          });

          if (!response.ok) {
              throw new Error('Error fetching dosar data');
          }

          const data = await response.json();
          console.log(data);
          setDosarData(data.data);
          setTipDosar(data.data.isDosarInstanta);
          setDateParte(data.data.sumarDosar.dateclient);
          setTipParte(data.data.sumarDosar.dateclient.idtipparte.toString());
          if (data.data.sumarDosar.datacreare) {
            const parts = data.data.sumarDosar.datacreare.split("-");
            const convertedDate = new Date(parts[0], parts[1] - 1, parts[2]);
            setDataCreare(convertedDate);
        }
          setLoading(false);  
      } catch (error) {
          console.error(error);
      }
  };

    useEffect(() => {
      const fetchDosar = async () => {
        try {
            const response = await fetch(`${window.API_URL}dosare/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
  
            if (!response.ok) {
                throw new Error('Error fetching dosar data');
            }
  
            const data = await response.json();
            console.log(data);
            setDosarData(data.data);
            setTipDosar(data.data.isDosarInstanta);
            setDateParte(data.data.sumarDosar.dateclient);
            setTipParte(data.data.sumarDosar.dateclient.idtipparte.toString());
            if (data.data.sumarDosar.datacreare) {
              const parts = data.data.sumarDosar.datacreare.split("-");
              const convertedDate = new Date(parts[0], parts[1] - 1, parts[2]);
              setDataCreare(convertedDate);
          }
            setLoading(false);  
        } catch (error) {
            console.error(error);
            setLoading(false);  
        }
    };
        fetchDosar();
    }, [id]);


     // preiau lista clientilor
     useEffect(() => {
      fetch(`${window.API_URL}clienti`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => setClienti(data.data.clienti))
        .catch(error => console.error('Error:', error));
    }, [token]); // Dependență: token


    if (isLoading) {
        return <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh',
          width: '100% !important' 
        }}>
          <CircularProgress />
        </div>;  
    }

    const { sumarDosar } = dosarData; 
    const dataum = formatDate(sumarDosar.dataum);

    const handleEditareFisa = () => {
      setSePoateModifica(false);
    };
    const handleActualizareDate = async (idDosar) => {
      const formattedDate = dataCreare ? `${dataCreare.getFullYear()}-${(dataCreare.getMonth() + 1).toString().padStart(2, '0')}-${dataCreare.getDate().toString().padStart(2, '0')}` : '';
      const tipPartePentruPatch = tipParteSelectat !== null ? tipParteSelectat : tipParte;

        const apiUrl = `${window.API_URL}dosare/${idDosar}`;
        const updatedData = {
          dataCreare: formattedDate,
          numarDosar: dosarData.sumarDosar.numardosar,
          instantaDosar: dosarData.sumarDosar.instantadosar,
          anDosar: dosarData.sumarDosar.andosar,
          accesoriu: dosarData.sumarDosar.accesoriidosar,

          ...dateParte,  
      
          idtipparte: tipPartePentruPatch,
          valoaredosar: dosarData.sumarDosar.valoaredosar,
          valoarerecuperata: dosarData.sumarDosar.valoarerecuperata,
          comentarii: dosarData.sumarDosar.comentarii
        };

        if (dosarData.sumarDosar.nrinterndosar) {
          updatedData.numarIntern = dosarData.sumarDosar.nrinterndosar;
        }

        console.log('updatedData', updatedData);

        try {
          const response = await fetch(apiUrl, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          });
      
          if (!response.ok) {
            throw new Error('Eroare la actualizarea dosarului');
          }
      
          const responseData = await response.json();
          console.log('Dosar actualizat:', responseData);
          Swal("Actualizat", "Datele au fost actualizate cu succes.", "success");
          setSePoateModifica(true);  
        } catch (error) {
          console.error('Eroare la actualizare:', error);
        }
    };
    const handleRenuntareActualizare = () => {
      setSePoateModifica(true);
      setClientSelectat(null); 
      fetchDosar();
    };

 
    const handleInputChangeClient = (event) => {
      const { name, value } = event.target;
      setDateParte(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
 

    return (
      <div>

        <Grid container spacing={2}>
         
        <Grid item xs={12} className="separator mb-3">
            Date dosar
        </Grid>  
          
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Dată creare dosar pe portal just.ro" 
                value={dataCreare}
                disabled={sePoateModifica}
                onChange={(newValue) => {
                  setDataCreare(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small" 
                    disabled={!sePoateModifica}
                  />
                )}
                inputFormat="dd/MM/yyyy" 
                mask="__/__/____"
                className="maxWidth inputMic"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
          <TextField
            fullWidth
            label="Număr intern"
            variant="outlined"
            disabled={sePoateModifica}
            value={sumarDosar.nrinterndosar}
            size="small"
            onChange={(e) => handleInputChange(e, 'nrinterndosar')}
          />
        </Grid>
     {tipDosar && (
      <>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Număr dosar"
            variant="outlined"
            disabled={nuPoateModifica}
            value={sumarDosar.numardosar}
            size="small"
            onChange={(e) => handleInputChange(e, 'numardosar')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Instanță dosar"
            variant="outlined"
            disabled={nuPoateModifica}
            value={sumarDosar.instantadosar}
            onChange={(e) => handleInputChange(e, 'instantadosar')}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="An dosar"
            variant="outlined"
            disabled={nuPoateModifica}
            value={sumarDosar.andosar}
            onChange={(e) => handleInputChange(e, 'andosar')}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Accesorii"
            variant="outlined"
            disabled={sePoateModifica}
            value={sumarDosar.accesoriidosar}
            size="small"
            onChange={(e) => handleInputChange(e, 'accesoriidosar')}
          />
        </Grid>
        </>
        )}  
        <Grid item xs={12} className="separator mb-3">
          Date client 
        </Grid>  

        <Grid item xs={12}> 
        <Autocomplete
          style={{marginTop:'5px', marginBottom:'6px'}}
          value={clientSelectat}
          disabled={sePoateModifica}
          onChange={(event, newValue) => {
            if (newValue) {
              setClientSelectat(newValue);  
              fetch(`${window.API_URL}client/${newValue.idclient}`, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(response => response.json())
              .then(data => {
                const tipParteDinDate = data.data.client.idtipparte.toString();
                setTipParte(tipParteDinDate);   //actz tipul de parte bazat pe datele clientului
                setTipParteSelectatManual(tipParteDinDate);   //afisez selecția campurilor automat cu datele clientului
                setDateParte(data.data.client);
              })
              .catch(error => console.error('Error:', error));
            } else {
              setClientSelectat(null); 
              setDateParte({ 
                societate: '',
                cui: '',
                cnp: '',
                adresa: '',
                banca: '',
                iban: '',
                telefon: '',
                email: '',
                numeprenume: '',
                codabonat: '',
                registrucomert: '' 
              });
              setTipParteSelectatManual('');
            }
          }}
          options={clienti}
          getOptionLabel={(option) => option.client || ''}
          renderInput={(params) => (
            <TextField {...params} label="Selectează Client" variant="outlined" size="small" />
          )}
        />


          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth key={`tip-parte-${tipParte}`}>
              <InputLabel id="tipParte-label">Tip parte</InputLabel>
              <Select
                labelId="tipParte-label"
                id="tipParte-select"
                value={tipParte}
                label="Tip parte"
                onChange={(e) => {
                  handleChange(e);  
                  setTipParteSelectat(e.target.value);  
              }}
                size="small"
                disabled={sePoateModifica}
              >
                <MenuItem value="1">Persoană fizică</MenuItem>
                <MenuItem value="2">Persoană juridică</MenuItem>
                <MenuItem value="3">Abonat</MenuItem>
                <MenuItem value="4">Societate de asigurări</MenuItem>
                <MenuItem value="5">Externă</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Câmpuri pentru Persoană Fizică */}
          {tipParte === '1' && (
            <>
              <Grid item xs={6} style={{marginTop: '-7px'}}>
                <TextField
                  margin="dense"
                  label="Nume și prenume"
                  name="numeprenume"
                  fullWidth
                  value={dateParte?.numeprenume}
                  onChange={handleInputChangeClient}
                  size="small"
                  disabled={sePoateModifica}
                  InputLabelProps={{
                    shrink: dateParte?.numeprenume ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
              <TextField
                margin="dense"
                label="CNP"
                name="cnp"
                fullWidth
                value={dateParte?.cnp}
                onChange={handleInputChangeClient}
                size="small"
                disabled={sePoateModifica}
                InputLabelProps={{
                  shrink: dateParte?.cnp ? true : false,
                }}
                type="number" 
                inputProps={{ min: 0 }}
              />
              </Grid>
            </>
          )}

          {/* Câmpuri pentru Persoană Juridică, Societate de Asigurări și Externă */}
          {(tipParte === '2' || tipParte === '4' || tipParte === '5') && (
            <>
              <Grid item xs={6} style={{marginTop: '-7px'}}>
                <TextField
                  margin="dense"
                  label="Reprezentant legal"
                  name="numeprenume"
                  fullWidth
                  value={dateParte?.numeprenume}
                  onChange={handleInputChangeClient}
                  size="small"
                  disabled={sePoateModifica}
                  InputLabelProps={{
                    shrink: dateParte?.numeprenume ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="Societate"
                  name="societate"
                  fullWidth
                  value={dateParte?.societate}
                  onChange={handleInputChangeClient}
                  size="small"
                  disabled={sePoateModifica}
                  InputLabelProps={{
                    shrink: dateParte?.societate ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="CUI"
                  name="cui"
                  fullWidth
                  value={dateParte?.cui}
                  onChange={handleInputChangeClient}
                  size="small"
                  disabled={sePoateModifica}
                  InputLabelProps={{
                    shrink: dateParte?.cui ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  label="Reg. Com."
                  name="registrucomert"
                  fullWidth
                  value={dateParte?.registrucomert}
                  onChange={handleInputChangeClient}
                  size="small"
                  disabled={sePoateModifica}
                  InputLabelProps={{
                    shrink: dateParte?.registrucomert ? true : false,
                  }}
                />
              </Grid>
            </>
          )}

          {/* Câmpuri pentru Abonat */}
          {tipParte === '3' && (
            <Grid item xs={6} style={{marginTop: '-7px'}}>
              <TextField
                margin="dense"
                label="Cod abonat"
                name="codabonat"
                fullWidth
                value={dateParte?.codabonat}
                onChange={handleInputChangeClient}
                size="small"
                disabled={sePoateModifica}
                InputLabelProps={{
                  shrink: dateParte?.codabonat ? true : false,
                }}
              />
            </Grid>
          )}

          {/* Câmpuri comune pentru toate tipurile de parte */}
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Adresa"
              name="adresa"
              fullWidth
              value={dateParte?.adresa}
              onChange={handleInputChangeClient}
              size="small"
              disabled={sePoateModifica}
              InputLabelProps={{
                shrink: dateParte?.adresa ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Banca"
              name="banca"
              fullWidth
              value={dateParte?.banca}
              onChange={handleInputChangeClient}
              size="small"
              disabled={sePoateModifica}
              InputLabelProps={{
                shrink: dateParte?.banca ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Cod IBAN"
              name="iban"
              fullWidth
              value={dateParte?.iban}
              onChange={handleInputChangeClient}
              size="small"
              disabled={sePoateModifica}
              InputLabelProps={{
                shrink: dateParte?.iban ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            
            <TextField
              margin="dense"
              label="Telefon"
              name="telefon"
              fullWidth
              value={dateParte?.telefon}
              onChange={(e) => {
                handleInputChangeClient(e);  
                handlePhoneChange(e);        
              }}            
              onBlur={handlePhoneBlur}
              size="small"
              type="tel"
              error={!isPhoneValid}
              disabled={sePoateModifica}
              helperText={!isPhoneValid ? "Numărul de telefon nu este valid" : ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
          <TextField
            margin="dense"
            label="Email"
            name="email"
            fullWidth
            value={dateParte?.email}
            onChange={(e) => {
              handleInputChangeClient(e);  
              handleEmailChange(e);        
            }}  
            size="small"
            disabled={sePoateModifica}
            error={!isEmailValid}
            helperText={!isEmailValid ? "Introduceți o adresă de email validă" : ""}
            InputLabelProps={{
              shrink: dateParte?.email ? true : false,
            }}
          />
          </Grid>

         

          <Grid item xs={12} className="separator">
              Debit dosar
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Debit dosar"
              type="text"
              fullWidth
              value={sumarDosar.valoaredosar}
              size="small"
              disabled={sePoateModifica}
              onChange={(e) => handleInputChange(e, 'valoaredosar')}
              name="valoaredosar"
            />
          </Grid> 
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Debit recuperat"
              type="text"
              fullWidth
              value={sumarDosar.valoarerecuperata}
              size="small"
              disabled={sePoateModifica}
              onChange={(e) => handleInputChange(e, 'valoarerecuperata')}
              name="valoarerecuperata"
            />
          </Grid> 
          <Grid item xs={3}>
            <TextField
              margin="dense"
              label="Debit parțial recuperat"
              type="number"
              fullWidth
              size="small"
              disabled={sePoateModifica}
              onChange={handleMinusChange}
              name="minusValoareRecuperata"
              value={minusValoareRecuperata}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton className='mt-2' onClick={handleConfirmareAdaugareScadere}><CalculateIcon /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Observații"
              multiline 
              rows={2}
              fullWidth
              value={sumarDosar.comentarii}
              size="small"
              disabled={sePoateModifica}
              onChange={(e) => handleInputChange(e, 'comentarii')}
              name="comentarii"
            />
          </Grid>
          <Grid item xs={6}>
          <Button onClick={() => changeTab(18)}>Câmpuri personalizate dosar</Button> | &nbsp;
          {areDrept(DreptDeModificareDosare) && (
            <Button size="small" onClick={() => handleEditareFisa()}>Editare fișă dosar</Button>
          )}
          </Grid>
          <Grid item xs={6} style={{textAlign:'right'}}>
            <Button size="small" onClick={() => handleRenuntareActualizare()} disabled={sePoateModifica} color="error" style={{marginRight:'10px'}}>Anulare</Button>
            <Button size="small" onClick={() => handleActualizareDate(id)} disabled={sePoateModifica} variant="contained" color="success">Salvare</Button>
          </Grid>
          
          
      </Grid>
      </div>
 
);


 }
 

 