import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ListItemText } from '@mui/material';

const Folder = ({ folder, onSelectFolder, selectedFolder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFolderClick = () => {
    if (folder.children && folder.children.length > 0) {
      setIsOpen(prevState => !prevState);
    }
    onSelectFolder(folder);
  };

  return (
    <List disablePadding>
      <ListItem
        button
        onClick={handleFolderClick}
        style={{
          paddingLeft: `${folder.depth * 20}px`,
          backgroundColor: selectedFolder?.id === folder.id ? 'lightblue' : 'inherit',  
        }}
      >
        <ListItemIcon>
          {isOpen ? (
            <FolderOpenIcon onClick={handleFolderClick} style={{ cursor: 'pointer' }} />
          ) : (
            folder.children && folder.children.length > 0 ? (
              <FolderIcon onClick={handleFolderClick} style={{ cursor: 'pointer' }} />
            ) : (
              <FolderIcon style={{ cursor: 'default' }} />
            )
          )}
        </ListItemIcon>
        <ListItemText
          primary={folder.name}
          primaryTypographyProps={{ variant: 'body2' }}
          secondary={
            folder.dosare_count !== null
              ? folder.dosare_count === 1
                ? `(1 dosar)`
                : folder.dosare_count === 0
                ? ''
                : `(${folder.dosare_count} dosare)`
              : ''
          }
          secondaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
        />


        {folder.children && folder.children.length > 0 && (
          <ListItemIcon
            onClick={handleFolderClick}
            style={{ cursor: 'pointer' }}
          >
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
        )}
      </ListItem>
      {isOpen && folder.children && folder.children.map(child => (
        <Folder key={child.id} folder={child} onSelectFolder={onSelectFolder} selectedFolder={selectedFolder} />
      ))}
    </List>
  );
};

export default Folder;
