import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import DateCont from './utilizator/DateCont';
import Sintagme from './utilizator/Sintagme'; 
import ImportManual from './utilizator/ImportManual'; 
import Stocare from './utilizator/Stocare'; 
import LogActivitate from './utilizator/LogActivitate'; 
import LogNotificariUser from './utilizator/LogNotificariUser'; 
import verificareToken from './VerificareToken';

import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import CampuriCustom from './CampuriCustom';

  const SetariView = () => {
    window.dispatchEvent(new Event('authChange'));
   useEffect(() => {
    window.dispatchEvent(new Event('authChange'));
  }, []); 
  
    const { token } = verificareToken();
    const { areDrept } = DrepturiUtilizator();
    const DreptDeImportAutomatDosare = 'DreptDeImportAutomatDosare';
    const DreptDeImportManualDosare = 'DreptDeImportManualDosare';
    const AccesLaToataAplicatia = 'AccesLaToataAplicatia';
   
    const [activeazaCustomFields, setActiveazaCustomFields] = useState(null); 

    const fetchData = async () => {
      try {
        const response = await fetch(`${window.API_URL}getSetting?settingName=ActiveazaCustomFields`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const fetchedData = await response.json();
        
        setActiveazaCustomFields(fetchedData.data.value);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      fetchData();
      //console.log('activeazaCustomFields',activeazaCustomFields);  
    }, []);

    
  return (
    <div  className="card container mx-auto p-3 mt-5">
        <h3 className="text-center mb-4"> Date cont și setări aplicație </h3>
    <Tabs>
      <TabList>
        <Tab>Date cont</Tab>
        {areDrept(DreptDeImportAutomatDosare) && (
          <Tab>Import automat</Tab>
        )}
        {areDrept(DreptDeImportManualDosare) && (
         <Tab>Import manual</Tab>
        )}
        <Tab>Spatiu stocare</Tab>
        {activeazaCustomFields === '1' && (
          <Tab>Câmpuri customizate</Tab>
        )}
        <Tab>Log activitate utilizator</Tab>
        <Tab>Log notificări utilizator</Tab>
      </TabList>
      <TabPanel>
        <DateCont />
      </TabPanel>
      {areDrept(DreptDeImportAutomatDosare) && (
      <TabPanel>
        <Sintagme />
      </TabPanel>
       )}
       {areDrept(DreptDeImportManualDosare) && (
      <TabPanel>
        <ImportManual />
      </TabPanel>
       )}
       <TabPanel>
         <Stocare />
       </TabPanel>
      <TabPanel>
        <CampuriCustom />
      </TabPanel>
      <TabPanel>
        <LogActivitate />
      </TabPanel>
      <TabPanel>
        <LogNotificariUser />
      </TabPanel>
    </Tabs>
    </div>
  );
}

export default SetariView;
