import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Tooltip, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, Grid, List, ListItem, ListItemText, Switch, FormControlLabel, Alert, InputAdornment, Snackbar } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid'; 
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear'; 
import IconButton from '@mui/material/IconButton';  
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import verificareToken from '../../VerificareToken';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessIcon from '@mui/icons-material/Business';
import DateFirma from './dateFirma';
import InsolventaFirma from './insolventaFirma';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 

function stripHtml(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent.replace(/\s+/g, ' ').trim() || "";
}

export default function CautareBPI() {
    const [selectedAct, setSelectedAct] = useState(null);
    const [searchData, setSearchData] = useState({ cui: "", materia: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);  
 
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    
      const { token } = verificareToken();

    const { id } = useParams();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedFirma, setSelectedFirma] = useState(null);
  
    const handleOpenDialog = (cuiFirma) => {
      setSelectedFirma(cuiFirma);
      setDialogOpen(true);
    };
    
    const handleCloseDialog = () => {
      setDialogOpen(false);
    };

    const [dialogOpenInsolventa, setDialogOpenInsolventa] = useState(false);
    const [selectedFirmaInsolventa, setSelectedFirmaInsolventa] = useState(null);
  
    const handleOpenDialogInsolventa = (cuiFirma) => {
      setSelectedFirmaInsolventa(cuiFirma);
      setDialogOpenInsolventa(true);
    };
    
    function handleCloseDialogInsolventa() {
      setSelectedFirmaInsolventa(null);
      setIsLoading(false);
      setDialogOpenInsolventa(false);
    }
  
const [cui, setCui] = useState('');
const [denumire, setDenumire] = useState('');
const [parteSelectata, setParteSelectata] = useState(null);
const [rezultateCautare, setRezultateCautare] = useState([]);
const [parti, setParti] = useState([]);

const clearCui = () => {
    setCui('');
    
    setSelectedAct(null);
  };
  
  const clearDenumire = () => {
    setDenumire('');
    
    setSelectedAct(null);
  };
  
  const handleCuiChange = (event) => {
    const value = event.target.value;
    if (!value || value.match(/^\d+$/)) {
      setCui(Math.max(0, parseInt(value, 10)).toString());
      setSearchData({ ...searchData, cui: Math.max(0, parseInt(value, 10)).toString(), denumire: '' });
    }
  };
  
  const handleDenumireChange = (event) => {
    setDenumire(event.target.value);
    setSearchData({ ...searchData, denumire: event.target.value, cui: '' });
  };
  
  const handleParteSelectata = (event, newValue) => {
    setParteSelectata(newValue);
    if (newValue) {
      setDenumire(newValue.nume);
      setCui('');
    } else {
      setDenumire('');
    }
  };
useEffect(() => {
  const fetchCuiData = async () => {
    if (cui) {
      setIsSearchLoading(true);
      try {
        const response = await fetch(`${window.API_URL}getFirmeByCui?cui=${cui}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setRezultateCautare(data.data.firme);
          setSelectedAct(null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSearchLoading(false);
      }
    }
  };

  const timer = setTimeout(() => {
    fetchCuiData();
  }, 500);  

  return () => clearTimeout(timer);  
}, [cui]);

useEffect(() => {
  const fetchDenumireData = async () => {
    if (denumire) {
      setIsSearchLoading(true);
      
    const denumireCurata = denumire.replace(/^SC\s+/, '');
      try {
        const response = await fetch(`${window.API_URL}getFirmeBPI?denumire=${denumireCurata}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setRezultateCautare(data.data.firme || []);
          setSelectedAct(null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSearchLoading(false);
      }
    }
  };

  const timer = setTimeout(() => {
    fetchDenumireData();
  }, 500);  

  return () => clearTimeout(timer);  
}, [denumire]);


    useEffect(() => {
      async function fetchData() {
        setIsLoading(true);
        try { 
          const partiResponse = await fetch(`${window.API_URL}getPartiDosar/${id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const partiData = await partiResponse.json();
          setParti(partiData.data.parti); 
              
        } catch (error) {
          console.error(error);
          Swal("Atenționare", "Nu am putut prelua datele.", "warning");
        } finally {
          setIsLoading(false);
        }
      }
    
      fetchData();
    }, [token]);
    
    const handleAdaugareMonitorizare = async () => {
      if (selectedAct) {
        const base_url = `${window.API_URL}adaugamonitorizare`;
        const url = new URL(base_url);
        url.searchParams.append('cui', selectedAct.CodFiscal); 
        url.searchParams.append('iddosar', id);   
        
        try {
          const response = await fetch(url.toString(), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            Swal("Succes!", "Firma a fost adăugată la monitorizare cu succes.", "success");
          } else {
            if (response.status === 422) {
              Swal("Firma există deja la lista de monitorizare", "", "warning");
            } else {
              Swal("Eroare!", "Firma nu a putut fi adăugată la monitorizare, încercați din nou.", "error");
            }
          }
    
        } catch (error) {
          Swal("Eroare!", "A apărut o eroare la salvarea actului. Încercați din nou.", "error");
        }
    
      } else {
        Swal("Atenție!", "Nu ați selectat nici o firmă!", "warning");
      }
    };
    
 

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = (text) => {
    copy(text);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const renderCopyTextField = (label, value) => (
    <TextField
      label={label}
      size='small'
      fullWidth
      value={value || ''}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Copiați informația">
              <IconButton
                onClick={() => handleCopy(value)}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );

  const handleSubmit = (event) => {
    event.preventDefault();  
  }
  if (isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>;
  }

  return (
    <React.Fragment>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>

               <Grid item xs={12} className="mt-3">
                  <TextField 
                      InputProps={{
                          endAdornment: cui ? (
                          <IconButton onClick={clearCui}>
                              <ClearIcon />
                          </IconButton>
                          ) : null,
                          inputProps: { min: 0 },
                          type: "number"
                      }}
                      label="CUI Societate" 
                      fullWidth
                      value={cui}
                      onChange={handleCuiChange}
                      disabled={denumire !== '' || parteSelectata !== null}
                  />
                </Grid>
                <Grid item xs={12} className="mt-3">
                <TextField 
                    InputProps={{
                        endAdornment: denumire ? (
                        <IconButton onClick={clearDenumire}>
                            <ClearIcon />
                        </IconButton>
                        ) : null
                    }}
                    label="Denumire Societate" 
                    fullWidth
                    value={denumire}
                    onChange={handleDenumireChange}
                    disabled={cui !== ''}
                />
            </Grid>
            <Grid item xs={12} className="mt-3">
            <Autocomplete
                id="parti-autocomplete"
                options={parti}
                getOptionLabel={(option) => option.nume}
                value={parteSelectata}
                onChange={handleParteSelectata}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Părți dosar curent"
                    fullWidth
                    disabled={cui !== ''} 
                />
                )}
            />
            </Grid>
            
            <Grid item xs={12} className="mt-3">
                <Button type="submit" fullWidth variant="contained" color="primary" startIcon={<ArrowForwardIcon />}>Căutare</Button>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ maxHeight: '325px', overflowY: 'auto', minHeight: '100px' }}>
            {isSearchLoading ? (
              <CircularProgress />
            ) : (!cui && !denumire) ? ( 
              <Typography>
                <Alert severity="info">Pentru a afișa rezultatele căutării, trebuie să completați ori denumirea ori CUI-ul. </Alert>
              </Typography>
            ) : (
              <List component="nav">
               {rezultateCautare && rezultateCautare.length > 0 ? (
                rezultateCautare.map((rezultatCautare, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedAct === rezultatCautare}
                    onClick={() => setSelectedAct(rezultatCautare)}
                    style={{
                      backgroundColor: selectedAct === rezultatCautare ? '#efefef' : 'transparent',
                      borderLeft: selectedAct === rezultatCautare ? '5px solid #3f51b5' : 'none'
                    }}
                  >
                    <ListItemText primary={rezultatCautare.NumeFirma} />
                  </ListItem>
                ))
              ) : (
                <Typography><b>Nu au fost găsite rezultate.</b></Typography>
              )}
            </List>
            
            )}
          </div><br></br>  
        </Grid> 
        {rezultateCautare?.length > 0 && (
      <Grid container spacing={3} className="p-4">
        <Grid item xs={12}>
          {renderCopyTextField("Nume firmă", selectedAct?.NumeFirma)}
        </Grid> 
        <Grid container spacing={2} style={{paddingRight:'25px', paddingLeft:'25px'}} className="mt-2"> 
          <Grid item xs={10}>
           {renderCopyTextField("Număr înregistrare la Registrul Comerțului", selectedAct?.NrInmatriculare)}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={`Vizualizare date firmă: ${selectedAct?.NumeFirma}${selectedAct?.CodFiscal}`}>
               <Button className='no-print' style={{marginTop:'2px'}} fullWidth startIcon={<BusinessIcon />} size="small" variant="contained" color="secondary" onClick={() => handleOpenDialog(selectedAct?.CodFiscal)} disabled={!selectedAct} >Date firmă</Button>
            </Tooltip>
                <DateFirma open={dialogOpen} onClose={handleCloseDialog} cui={selectedFirma} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{paddingRight:'25px', paddingLeft:'25px'}} className="mt-2"> 
          <Grid item xs={10}>
            {renderCopyTextField("Cod unic de identificare", selectedAct?.CodFiscal)}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={`Vizualizare date insolvență firmă: ${selectedAct?.NumeFirma}${selectedAct?.CodFiscal}`}>
               <Button className='no-print' style={{marginTop:'2px'}} fullWidth startIcon={<CurrencyExchangeIcon />} size="small" variant="contained" color="secondary" onClick={() => handleOpenDialogInsolventa(selectedAct?.CodFiscal)} disabled={!selectedAct} >Insolvență</Button>
            </Tooltip>
                <InsolventaFirma open={dialogOpenInsolventa} onClose={handleCloseDialogInsolventa} cui={selectedFirmaInsolventa} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {renderCopyTextField("Județ", selectedAct?.Judet)}
        </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Informația a fost copiată"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />

            <Grid item xs={12}> 
                <Button variant="contained"  disabled={!areDrept(DreptDeModificareDosare)} color="success" onClick={handleAdaugareMonitorizare} fullWidth>
                    Adăugare firmă la monitorizare
                </Button>
            </Grid>

          </Grid>
        )}

      </Grid>
      </React.Fragment>
  );
}
 