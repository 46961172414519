import React, {  } from 'react';
import './App.css';
import { Navigate } from 'react-router-dom';
import verificareToken from './VerificareToken';
import { SoundProvider } from './sunete';
import GlobalEventListener from './GlobalEventListener';

function App() {
    
  const { token, ok2goval } = verificareToken();

  if (!ok2goval && !token) {
    return <Navigate to="/logout" />;
  } else {
    return <Navigate to="/panou" />;
  }
 
  return (
    <SoundProvider>
    <GlobalEventListener />
    <div className="App">
    </div>
    </SoundProvider>
  );
}

export default App;
