import React, { useState, useEffect } from 'react';
import DateCont from './utilizator/DateCont';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Button } from '@mui/material';

import ManualUtilizare from './ManualUtilizare';
import Faq from './Faq';
import CloseIcon from '@mui/icons-material/Close';

const Footer = () => {
    const [dateCont, setDateCont] = useState({});
   //manual
   const [openManual, setOpenManual] = useState(false);
   const handleOpenManual = () => setOpenManual(true);
   const handleCloseManual = () => setOpenManual(false);
 
   function setDenAppVers(value) {
    function setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
  
    setCookie('DenAppVers', value, 30);
  
    localStorage.setItem('DenAppVers', value);
  
    sessionStorage.setItem('DenAppVers', value);
  }

    const fetchData = async () => {
        try {
          const response = await fetch(`${window.API_URL}infoaplicatie`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
              },
          });
          const {data} = await response.json();
          setDateCont(data);
          setDenAppVers(data.appVersion);
          //console.log('[Api] -> InfoAplicatie:', data);
        } catch (error) {
          console.error(error);
        }
      };

    const [modalOpen, setModalOpen] = useState(false);
    const [anCurent, setAnCurent] = useState(new Date().getFullYear());
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };

    
    useEffect(() => {
        setAnCurent(new Date().getFullYear());
        fetchData();
    }, []);
    
//manual 
useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'F1') {
      event.preventDefault();
      handleOpenManual();
    }
  };
  window.addEventListener('keydown', handleKeyDown);

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, []);

const [openFaq, setOpenFaq] = useState(false);  
const handleOpenFaq = () => setOpenFaq(true);
const handleCloseFaq = () => setOpenFaq(false);

  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top" id="margineSus0px">
        <div className="col-md-5">
          <span className="mb-2 mb-md-0 text-muted" style={{paddingLeft:'10px'}}>© {anCurent} JuristPRO - <a href="https://www.ctce.ro" target="_blank" className="link">SC CTCE SA - Soluții Software</a></span>
        </div>
        <a href="https://www.ctce.ro"  target="_blank" className="col-md-2 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          <img src="https://www.ctce.ro/mail/juristpro/logomicctce.png" style={{ maxWidth: '70%', width: '100px' }} alt="CTCE Logo" />
        </a>
        <ul className="nav col-md-5 justify-content-end">
          <li className="nav-item">
           <a   style={{ cursor: 'pointer'}} className="nav-link px-2 text-muted"   title="Istoric versiuni aplicație"   onClick={toggleModal} >   Versiune </a>     
          </li>
          <li className="nav-item">
            <a
              onClick={handleOpenManual}
              style={{ textDecoration: 'none', cursor: 'pointer'}}
              className="nav-link px-2 text-muted"
              title="Deschideți manualul de folosire" 
            >
              Manual
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={handleOpenFaq}
              style={{ textDecoration: 'none', cursor: 'pointer'}}
              className="nav-link px-2 text-muted"
              title="Deschideți manualul de folosire"
            >
              Întrebări și răspunsuri
            </a>
          </li>

          <li className="nav-item">
            <a href="https://www.ilegis.ro" className="nav-link px-2 text-muted" style={{ textDecoration: 'none' }} target="_blank" title="Aplicația legislativă">Legislație</a>
          </li>
        </ul>
      </footer>

    
      <ManualUtilizare open={openManual} onClose={handleCloseManual} />
      <Dialog 
        open={modalOpen}
        onClose={toggleModal}
        maxWidth={false}
        fullWidth
        PaperProps={{
              style: {
              maxWidth: '300px',  
              width: '100%', 
              },
          }}
        >
        <DialogTitle className='titluModal'>
          Versiune aplicație
          <IconButton
            aria-label="close"
            onClick={toggleModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12, 
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='mt-3'>
            <p>
            Versiune aplicație: <b>{dateCont?.appVersion}</b>
            </p>
            <p>Versiune DB: <b>{dateCont?.dbVersion}</b></p>
        </DialogContent>
        <DialogActions>
            <Button onClick={toggleModal} color="primary">
            Inchidere
            </Button>
        </DialogActions>
        </Dialog>

        <Dialog 
          open={openFaq} 
          onClose={handleCloseFaq}
          maxWidth={false}
          fullWidth
          PaperProps={{
              style: {
              maxWidth: '1100px',  
              width: '100%', 
              },
          }}
        >
          <DialogTitle className='titluModal'>Întrebări și răspunsuri</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseFaq}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12, 
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Faq /> 
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFaq} color="primary">Închidere</Button>
          </DialogActions>
        </Dialog>

       

    </div>
  );
};

export default Footer;
