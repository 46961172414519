import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, IconButton, Switch, FormControlLabel, FormGroup, Divider, Grid, Box, Typography, Tooltip } from '@mui/material';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import { userEventEmitter } from '../EventEmitter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { size } from 'lodash';


function ProfilUtilizator() {
  window.dispatchEvent(new Event('authChange'));
  const [confirmParola, setConfirmParola] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [criteriaMet, setCriteriaMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const { token } = verificareToken();
  const [user, setUser] = useState({
    fullname: '',
    email: '',
    telefon: '',
    parola: '',
    confirmParola: '',
    editParola: false,
  });
  const [editParola, setEditParola] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(`${window.API_URL}user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setUser({ ...data.data.user, parola: '' });
      } else {
        Swal('Atenționare', 'Datele utilizatorului nu au fost găsite!', 'warning');
      }
    } catch (error) {
      console.error('Eroare la preluarea datelor:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const validatePassword = (password) => {
    const length = password.length >= 10;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[^a-zA-Z0-9]/.test(password);

    setCriteriaMet({ length, uppercase, lowercase, number, specialChar });

    const strength = [length, uppercase, lowercase, number, specialChar].filter(Boolean).length;
    setPasswordStrength(strength);

    return strength === 5;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setUser({ ...user, parola: newPassword });
    validatePassword(newPassword);
  };

  const generatePassword = () => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()';
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;
  
    // Asigurăm cel puțin un caracter din fiecare categorie
    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];
  
    const password = [
      getRandomChar(uppercaseChars), // O literă mare
      getRandomChar(lowercaseChars), // O literă mică
      getRandomChar(numberChars),    // O cifră
      getRandomChar(specialChars),   // Un caracter special
    ];
  
    // Completăm restul parolei cu caractere aleatorii
    for (let i = 4; i < 10; i++) {
      password.push(getRandomChar(allChars));
    }
  
    // Amestecăm caracterele
    setGeneratedPassword(password.sort(() => Math.random() - 0.5).join(''));
  };
  

  const chooseGeneratedPassword = () => {
    setUser({ ...user, parola: generatedPassword });
    setConfirmParola(generatedPassword);
    validatePassword(generatedPassword);
  };

  const validateAndSubmit = () => {
    if (editParola) {
      if (user.parola === confirmParola && validatePassword(user.parola)) {
        updateProfile();
      } else {
        Swal('Atenționare', 'Parolele nu coincid sau nu sunt suficient de puternice!', 'warning');
      }
    } else {
      updateProfile();
    }
  };

  const updateProfile = async () => {
    try {
      const queryParams = new URLSearchParams({
        fullname: user.fullname,
        email: user.email,
        telefon: user.telefon,
      });

      if (editParola) {
        queryParams.append('password', user.parola);
      }

      const response = await fetch(`${window.API_URL}updateUserProfile?${queryParams.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        Swal('Succes', 'Datele au fost actualizate cu succes!', 'success');
        window.dispatchEvent(new Event('authChange'));
        setEditParola(false);
      } else {
        const data = await response.json();
        Swal('Eroare', data.message, 'error');
      }
    } catch (error) {
      console.error('Eroare la actualizarea profilului:', error);
    }
  };

  const renderStrengthBlocks = () => {
    const colors = ['#FF3E3E', '#FF8C00', '#FFD700', '#ADFF2F', '#32CD32'];
    return (
      <Box display="flex" gap={1} mt={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            width="20%"
            height="10px"
            bgcolor={index < passwordStrength ? colors[index] : '#E0E0E0'}
          />
        ))}
      </Box>
    );
  };

  const renderCriteria = () => (
    <Box mt={2}>
      <Typography variant="h6">Criterii pentru o parolă validă:</Typography>
      <ul style={{ paddingLeft: '10px', marginBottom:'20px' }}>
        {[
          { text: ' - minim 10 caractere', met: criteriaMet.length },
          { text: ' - cel puțin o literă mare', met: criteriaMet.uppercase },
          { text: ' - cel puțin o literă mică', met: criteriaMet.lowercase },
          { text: ' - cel puțin o cifră', met: criteriaMet.number },
          { text: ' - cel puțin un caracter special (!@#$%^&*)', met: criteriaMet.specialChar },
        ].map((criterion, index) => (
          <li
            key={index}
            style={{
              color: criterion.met ? 'green' : 'red',
              fontWeight: criterion.met ? 'bold' : 'normal',
            }}
          >
            {criterion.text}
          </li>
        ))}
      </ul>
    </Box>
  );

  const isUpdateButtonDisabled = () => {
    if (editParola) {
      // Dacă modificarea parolei este activată, verificăm criteriile
      return (
        !Object.values(criteriaMet).every((met) => met) || // Toate criteriile trebuie să fie îndeplinite
        user.parola !== confirmParola // Parolele trebuie să coincidă
      );
    }
    // Dacă modificarea parolei nu este activată, butonul este întotdeauna activ
    return false;
  };

  useEffect(() => {
    if (editParola) {
      validatePassword(user.parola);
    }
  }, [user.parola, editParola]);
  
  return (
    <div className="card container mx-auto p-5 mt-5">
      <h3>Profil utilizator</h3>
      <hr />

      <Grid container direction="column" spacing={2} className="mt-3">
        <TextField
          label="Nume complet"
          value={user.fullname}
          onChange={(e) => setUser({ ...user, fullname: e.target.value })}
        />
        <TextField
          className="mt-3"
          label="E-mail"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <TextField
          className="mt-3"
          label="Telefon"
          value={user.telefon}
          onChange={(e) => setUser({ ...user, telefon: e.target.value })}
        />
        <FormGroup className="mt-2">
          <FormControlLabel
            control={<Switch checked={editParola} onChange={(e) => setEditParola(e.target.checked)} />}
            label="Modificare parolă"
          />
          {editParola && (
            <>
              <Grid container spacing={2} alignItems="center" className="mt-3">
                <Grid item sm="5">
                <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: '8px',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            border: '1px solid #ddd',
            fontFamily: 'monospace',
            fontSize: '14px',
          }}
        >
          <span>{generatedPassword || 'Parola va apărea aici'}</span>
          {generatedPassword && (
        
           <Tooltip title="Copiere parolă generată">
             <IconButton
            variant="outlined"
            onClick={() => navigator.clipboard.writeText(generatedPassword)}
            disabled={!generatedPassword} 
            size='small'
          ><ContentCopyIcon style={{fontSize:'14px'}} /></IconButton>
           </Tooltip>
          )}
        </Box>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={generatePassword} size="small">
                    Generare parolă nouă
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={chooseGeneratedPassword}
                    disabled={!generatedPassword}
                    size="small"
                  >
                    Alegere parolă generată
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Parolă nouă"
                    type="password"
                    value={user.parola}
                    onChange={handlePasswordChange}
                    className="mt-3"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Confirmare parolă nouă"
                    type="password"
                    value={confirmParola}
                    onChange={(e) => setConfirmParola(e.target.value)}
                    className="mt-3"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>{renderStrengthBlocks()}</Grid>
              </Grid>
              {renderCriteria()}
            </>
          )}
        </FormGroup>
        <Divider />
        <Button
          variant="contained"
          color="secondary"
          onClick={validateAndSubmit}
          disabled={isUpdateButtonDisabled()}
        >
          Actualizare date
        </Button>

      </Grid>
    </div>
  );
}

export default ProfilUtilizator;
