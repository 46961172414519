import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Tooltip, CircularProgress, Alert } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import Swal from 'sweetalert'; 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import verificareToken from '../../VerificareToken';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 

export default function InfoActeDosar({ onChangeTab }) {
  const { id } = useParams();
  const [acte, setActe] = useState(null);
  const [actePJ, setActePJ] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { token } = verificareToken();
  
  const fetchActe = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${window.API_URL}infoActeDosar/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setActe(data.data.acteDosar);
    } catch (error) {
      console.error("A problem occurred while fetching the data: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchActe();
  }, [id]);

  const fetchActePJ = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${window.API_URL}infoPJDosar/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setActePJ(data.data.acteDosar);
    } catch (error) {
      console.error("A problem occurred while fetching the data: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchActePJ();
  }, [id]);

  const handleDeleteAct = async (idAct) => {
    Swal({
      title: "Sunteți sigur?",
      text: "Odată șters, actul va dispărea de pe dosar, va trebui să îl reintroduceți dacă mai este necesar!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          await fetch(`${window.API_URL}stergeAct/${idAct}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
          });
      
          await fetchActe();
          Swal("Actul a fost șters cu succes!", {
            icon: "success",
          });
        } catch (error) {
          console.error("A apărut o eroare la ștergerea actului: ", error);
        }
      } else {
        Swal("Actul nu a fost șters!");
      }
    });
  };

  const handleDeleteActPJ = async (idAct) => {
    Swal({
      title: "Sunteți sigur?",
      text: "Odată șters, actul va dispărea de pe dosar, va trebui să îl reintroduceți dacă mai este necesar!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          await fetch(`${window.API_URL}stergeActPJ/${idAct}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
          });
      
          await fetchActePJ();
          Swal("Actul a fost șters cu succes!", {
            icon: "success",
          });
        } catch (error) {
          console.error("A apărut o eroare la ștergerea actului: ", error);
        }
      } else {
        Swal("Actul nu a fost șters!");
      }
    });
  };

  const handleViewAct = async (idAct) => {
    const response = await fetch(`${window.API_URL}vizualizareAct/${idAct}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json(); 
    window.open(data.data.caleiLegis, "_blank");
  };

  const handleViewActPJ = async (idAct) => {
    const response = await fetch(`${window.API_URL}vizualizareActPJ/${idAct}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json(); 
    window.open(data.data.caleAfisarePJ, "_blank");
  };

  if (isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>;
  }

  return (
    <div> 

      <div className='headerActe mt-3 mb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Legislație Românească atașată dosarului</span>
        <Button variant="contained" color="warning" size='small' onClick={() => onChangeTab(1)}>+ Adăugare acte noi</Button>
      </div>

      {acte && acte.length > 0 ? (
        acte.map((act, index) => (
          <Box className="boxActe" key={index} border={1} borderRadius={2} padding={2} marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography> {act.info} {act.articol ? `(Referire la articolul nr. ${act.articol})` : ''}</Typography>
            <div>
            <Tooltip title="Ștergere act">
              <IconButton aria-label="delete" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleDeleteAct(act.id)} style={{ marginRight: '10px' }}>
                <DeleteIcon />
              </IconButton>
              </Tooltip>
              <Tooltip title={`Vizualizare act ${act.info}${act.articol ? ` cu referire la articolul nr. ${act.articol}` : ''}`}>
                <VisibilityIcon aria-label="Vizualizere act" variant="contained" onClick={() => handleViewAct(act.id)} size="small" style={{cursor: 'pointer'}}>
                  Vizualizere act
                </VisibilityIcon>
              </Tooltip>
            </div>
          </Box>
        ))
      ) : (
        <Alert severity="warning">Nu există atașată legislație Românească conexă.</Alert>
      )}

      <div className='headerActe mt-3 mb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Practică judiciară atașată dosarului</span>
        <Button variant="contained" color="warning" size='small' onClick={() => onChangeTab(2)}>+ Adăugare acte noi</Button>
      </div>

      {actePJ && actePJ.length > 0 ? (
        actePJ.map((actPJ, index) => (
          <Box className="boxActe" key={index} border={1} borderRadius={2} padding={2} marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography> {actPJ.tipAct}: {actPJ.info} (An speță {actPJ.anAct}) </Typography>
            <div>
            <Tooltip title="Ștergere act">
              <IconButton aria-label="delete" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleDeleteActPJ(actPJ.id)} style={{ marginRight: '10px' }}>
                <DeleteIcon />
              </IconButton>
              </Tooltip>
              <Tooltip title={`Vizualizare act ${actPJ.tipAct}: ${actPJ.info} (An speță ${actPJ.anAct})`}>
                <VisibilityIcon aria-label="Vizualizere act" variant="contained" onClick={() => handleViewActPJ(actPJ.contor)} size="small" style={{cursor: 'pointer'}}>
                  Vizualizere act
                </VisibilityIcon>
              </Tooltip>
            </div>
          </Box>
        ))
      ) : (
        <Alert severity="warning">Nu există atașată practică judiciară conexă.</Alert>
      )}
    </div>
  );

}