import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, CircularProgress, Alert, Box, TextField, Chip, FormControl, InputLabel, MenuItem, Checkbox, Select, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Table, TableCell, TableHead, TableBody, TableRow, TableContainer, Paper, Radio, FormControlLabel, RadioGroup, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import AdaugareUtilizatori from './AdministrareUtilizatori/AdaugareUtilizatori'; 
import GrupuriUtilizatori from './AdministrareUtilizatori/GrupuriUtilizatori'; 
import RoluriUtilizatori from './AdministrareUtilizatori/RoluriUtilizatori'; 
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import CheckIcon from '@mui/icons-material/Check';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function AdministrareUtilizatori({ }) {
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
  const { areDrept } = DrepturiUtilizator(); 
  const EditareGrupuriUtilizatori = 'EditareGrupuriUtilizatori';
  const AdaugareGrupuriUtilizatori = 'AdaugareGrupuriUtilizatori';
  const StergereGrupuriUtilizatori = 'StergereGrupuriUtilizatori';
  const AccesLaToataAplicatia = 'AccesLaToataAplicatia';
  const EditareRoluri = 'EditareRoluri';
  const AdaugareRoluri = 'AdaugareRoluri';
  const StergereRoluri = 'StergereRoluri'; 
  const EditareUtilizatori = 'EditareUtilizatori';
  const AdaugareUtilizatoriDrept = 'AdaugareUtilizatori';
  const StergereUtilizatori = 'StergereUtilizatori';
  
  const VizualizareGrupuriUtilizatori = 'VizualizareGrupuriUtilizatori';
  const VizualizareRoluri = 'VizualizareRoluri';
  const VizualizareUtilizatori = 'VizualizareUtilizatori';

  const [users, setUsers] = useState([]);
  const [grupuri, setGrupuri] = useState([]);
  const [grupuriMeniu, setGrupuriMeniu] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selected, setSelected] = useState({ userId: null, groupId: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGrupuri, setIsLoadingGrupuri] = useState(false);
  const [isLoadingRoluri, setIsLoadingRoluri] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openAdaugare, setOpenAdaugare] = useState(false);  
  const [openGrupuriUtilizatori, setOpenGrupuriUtilizatori] = useState(false);  
  const [openRoluriUtilizatori, setOpenRoluriUtilizatori] = useState(false);  
  const [userDetails, setUserDetails] = useState([]);  
  const [userRoles, setUserRoles] = useState([]);
  const [dateUser, setDateUser] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [schimbareParola, setSchimbareParola] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    fullname: '',
    telefon: '',
    password: '',
    user_group_ids: [],
    user_role_ids: [],   
  });
 
    const { token } = verificareToken();

  const [groupedRights, setGroupedRights] = useState({});

  const [isRightsModalOpen, setIsRightsModalOpen] = useState(false);
  const [isDosareModalOpen, setIsDosareModalOpen] = useState(false);
  const [isGrupuriDosareModalOpen, setIsGrupuriDosareModalOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userPermisiuni, setUserPermisiuni] = useState([]);

  const [dosare, setDosare] = useState([]);  
  const [selectedDosareIds, setSelectedDosareIds] = useState([]);  

  const [dreptId, setDreptId] = useState('');
  const [rolUser, setRolUser] = useState('');
  const [valoareNoua, setValoareNoua] = useState('');
  const [currentSelectingRightId, setCurrentSelectingRightId] = useState(null);

  const [radioSelections, setRadioSelections] = useState({});
  const [grupuriDosare, setGrupuriDosare] = useState([]);  
  const [selectedGrupuriDosareIds, setSelectedGrupuriDosareIds] = useState([]);  
  const [grupDeschis, setGrupDeschis] = useState({}); 
  const [grupDeschis2, setGrupDeschis2] = useState({}); 
  const [isSelectingDosare, setIsSelectingDosare] = useState(false);
  const [isSelectingGrupuri, setIsSelectingGrupuri] = useState(false);
  const [userPermissionsTrue, setUserPermissionsTrue] = useState([]);
 
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [criteriaMet, setCriteriaMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    const length = password.length >= 12; // Asigurăm o lungime de cel puțin 12 caractere
    const uppercase = /[A-Z]/.test(password); // Cel puțin o literă mare
    const lowercase = /[a-z]/.test(password); // Cel puțin o literă mică
    const number = /[0-9]/.test(password);    // Cel puțin o cifră
    const specialChar = /[^a-zA-Z0-9]/.test(password); // Cel puțin un caracter special
  
    // Actualizează criteriile îndeplinite
    setCriteriaMet({ length, uppercase, lowercase, number, specialChar });
  
    // Returnează numărul total de criterii îndeplinite
    const strength = [length, uppercase, lowercase, number, specialChar].filter(Boolean).length;
    setPasswordStrength(strength);
  
    return strength === 5; // Parola este complet validă dacă toate criteriile sunt îndeplinite
  };
  
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: newPassword,
    }));
    validatePassword(newPassword);
  };
  const generatePassword = () => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()';
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;
  
    // Asigurăm cel puțin un caracter din fiecare categorie
    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];
  
    const password = [
      getRandomChar(uppercaseChars), // O literă mare
      getRandomChar(lowercaseChars), // O literă mică
      getRandomChar(numberChars),    // Un număr
      getRandomChar(specialChars),   // Un caracter special
    ];
  
    // Completăm restul parolei cu caractere aleatorii din toate categoriile
    for (let i = 4; i < 12; i++) {
      password.push(getRandomChar(allChars));
    }
  
    // Amestecăm caracterele pentru a nu fi într-o ordine previzibilă
    setGeneratedPassword(password.sort(() => Math.random() - 0.5).join(''));
  };
  
  const renderStrengthBlocks = () => {
    const colors = ['#FF3E3E', '#FF8C00', '#FFD700', '#ADFF2F', '#32CD32'];
    return (
      <Box display="flex" gap={1} mt={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            width="20%"
            height="10px"
            bgcolor={index < passwordStrength ? colors[index] : '#E0E0E0'}
          />
        ))}
      </Box>
    );
  };
  const renderCriteria = () => (
    <Box mt={2}>
      <Typography variant="h6">Criterii pentru o parolă validă:</Typography>
      <ul style={{ paddingLeft: '10px', marginBottom: '20px' }}>
        {[
          { text: ' - minim 10 caractere', met: criteriaMet.length },
          { text: ' - cel puțin o literă mare', met: criteriaMet.uppercase },
          { text: ' - cel puțin o literă mică', met: criteriaMet.lowercase },
          { text: ' - cel puțin o cifră', met: criteriaMet.number },
          { text: ' - cel puțin un caracter special (!@#$%^&*)', met: criteriaMet.specialChar },
        ].map((criterion, index) => (
          <li
            key={index}
            style={{
              color: criterion.met ? 'green' : 'red',
              fontWeight: criterion.met ? 'bold' : 'normal',
            }}
          >
            {criterion.text}
          </li>
        ))}
      </ul>
    </Box>
  );
  const [generatedPassword, setGeneratedPassword] = useState('');

 
  const chooseGeneratedPassword = () => {
    setFormData({ ...formData, password: generatedPassword });
    validatePassword(generatedPassword); // Validează parola generată
  };
  

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchDosare = async () => {
    try {
      const response = await fetch(`${window.API_URL}dosare`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDosare(data.data.dosare.map((dosar) => ({ ...dosar, selected: false })));
      } else {
        throw new Error('Eroare la obținerea listei de dosare.');
      }
    } catch (error) {
      console.error('Eroare la obținerea listei de dosare: ', error);
    }
  };

  useEffect(() => {
    if (isRightsModalOpen) {
      fetchDosare();
    }
  }, [isRightsModalOpen]);
 

  const fetchGrupuri = async () => {
    try {
      const response = await fetch(`${window.API_URL}userGroups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setGrupuri(data.data.userGroups);
    } catch (error) {
      console.error('Problema la preluare grupuri: ', error);
    }
  };

  useEffect(() => {
      fetchGrupuri();
  }, []);
 
  const fetchGrupuriMeniu = async () => {
    try {
      const response = await fetch(`${window.API_URL}getGroupsWithUsersByAccess?showWithUsers=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setGrupuriMeniu(data.data.userGroups);
    } catch (error) {
      console.error('Problema la preluare grupuri: ', error);
    }
  };

  useEffect(() => {
    fetchGrupuriMeniu();
  }, []);
 

const handleToggleGrup = (id) => {
  setGrupDeschis2(prevState => ({
    ...prevState,
    [id]: !prevState[id] 
  }));
};



const handleDosarSelect = (dosarId) => {
  setDosare((prevDosare) => prevDosare.map((dosar) => {
    if (dosar && dosar.id === dosarId) {
      return { ...dosar, selected: !dosar.selected };
    }
    return dosar;
  }));

  setSelectedDosareIds((prevSelectedDosareIds) => {
    const isDosarSelected = prevSelectedDosareIds.includes(dosarId);
    if (isDosarSelected) {
      // daca dosarul este deja selectat il elimin din lista de dosare selectate
      return prevSelectedDosareIds.filter((id) => id !== dosarId);
    } else {
      // daca dosarul nu este selectat, il pun in lista de dosare selectate
      return [...prevSelectedDosareIds, dosarId];
    }
  });
};


const fetchGrupuriDosare = async () => {
  try {
    const response = await fetch(`${window.API_URL}dosarGroups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,  
      },
    });

    if (response.ok) {
      const data = await response.json();
      setGrupuriDosare(data.data.dosarGroups.map((grupdosar) => ({ ...grupdosar, selected: false })));
    } else {
      throw new Error('Eroare la obținerea listei de dosare.');
    }
  } catch (error) {
    console.error('Eroare la obținerea listei de dosare: ', error);
  }
};

const handleGrupuriDosarSelect = (grupdosarId) => {
  setGrupuriDosare((prevGrupuriDosare) => prevGrupuriDosare.map((grupdosar) => {
    if (grupdosar.id === grupdosarId) {
      return { ...grupdosar, selected: !grupdosar.selected };
    }
    return grupdosar;
  }));

  setSelectedGrupuriDosareIds((prevSelectedGrupuriDosareIds) => {
    const isGrupuriDosarSelected = prevSelectedGrupuriDosareIds.includes(grupdosarId);
    if (isGrupuriDosarSelected) {
      // daca grupul este deja selectat il elimin din lista de grupuri selectate
      return prevSelectedGrupuriDosareIds.filter((id) => id !== grupdosarId);
    } else {
      // daca grupul nu este selectat, il pun in lista de grupuri selectate
      return [...prevSelectedGrupuriDosareIds, grupdosarId];
    }
  });
};

useEffect(() => {
  fetchGrupuriDosare();
}, []);


  const fetchUsers = async () => {
    setIsLoading(true);
    setIsLoadingUsers(true);
    try {
      const response = await fetch(`${window.API_URL}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUsers(data.data.users);
    } catch (error) {
      console.error('A problem occurred while fetching users: ', error);
    }
    setIsLoading(false);
    setIsLoadingUsers(false);
  };


  const [allUserRoles, setAllUserRoles] = useState([]);


  
  const fetchAllUserRoles = async () => {
    try {
      const response = await fetch(`${window.API_URL}userRoles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setAllUserRoles(data.data.userRoles);
      } else {
        console.error('A apărut o eroare la aducerea tuturor rolurilor de utilizatori.');
      }
    } catch (error) {
      console.error('A apărut o eroare la aducerea tuturor rolurilor de utilizatori: ', error);
    }
  };
  
  useEffect(() => {
    fetchAllUserRoles();
  }, []);
  
  useEffect(() => {
    if (selectedUser) {
      fetchUserDetails(selectedUser.id);
    }
  }, [selectedUser]);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await fetch(`${window.API_URL}user/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data.data.user);
      } else {
        console.error('A apărut o eroare la aducerea detaliilor utilizatorului.');
      }
    } catch (error) {
      console.error('A apărut o eroare la aducerea detaliilor utilizatorului: ', error);
    }
  };
  const handleUserSelect = (user, groupId) => {
    setUserRoles([]);
    setSelected({ userId: user.id, groupId: groupId });
    setSelectedUser(user);
    setUserDetails(null); 
    setFormData({
      name: user.name || '',
      email: user.email || '',
      fullname: user.fullname || '',
      telefon: user.telefon || '',
      password: '',
      user_group_ids: [],
      user_role_ids: [],
    });
    setSchimbareParola(false);
    fetchUserGroups(user.id);
    fetchUserRoles(user.id);
  };
  
  const fetchUserGroups = async (userId) => {
    try {
      setIsLoadingGrupuri(true);
      const response = await fetch(`${window.API_URL}user/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserGroups(data.data.user.groups);
        setFormData((prevFormData) => ({
          ...prevFormData,
          user_group_ids: data.data.user.groups.map((group) => group.id) || [],
        }));
        setIsLoadingGrupuri(false);
      } else {
        console.error('A apărut o eroare la aducerea grupurilor utilizatorului.');
      }
    } catch (error) {
      console.error('A apărut o eroare la aducerea grupurilor utilizatorului: ', error);
    }
  };
  
  const fetchUserRoles = async (userId) => {
    setIsLoadingRoluri(true);
    try {
      const response = await fetch(`${window.API_URL}userAccess/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserRoles(data.data.userAccess);
        setFormData((prevFormData) => ({
          ...prevFormData,
          user_role_ids: data.data.userAccess.map(role => role.user_role_id),
        }));
        setIsLoadingRoluri(false);
      } else {
        console.error('A apărut o eroare la aducerea rolurilor utilizatorului.');
        setIsLoadingRoluri(false);
      }
    } catch (error) {
      console.error('A apărut o eroare la aducerea rolurilor utilizatorului: ', error);
      setIsLoadingRoluri(false);
    }
  };
  
 

  
  const handleOpenAdaugare = () => {
    setOpenAdaugare(true);
  };

  const handleCloseAdaugare = (success) => {
    setOpenAdaugare(false);
    if (success) {
      fetchUsers();
      fetchGrupuriMeniu();
    }
  };

  const handleOpenGrupuriUtilizatori = () => {
    if (!openGrupuriUtilizatori) {
      setOpenGrupuriUtilizatori(true); 
    } else {
      setOpenGrupuriUtilizatori(false); 
    }
  };
  

  const handleCloseGrupuriUtilizatori = (success) => {
    setOpenGrupuriUtilizatori(false);
    if (success) { 
      fetchGrupuri(); 
      fetchGrupuriMeniu();
    }
  };


  const handleOpenRoluriUtilizatori = () => {
    setOpenRoluriUtilizatori(true);
  };

  const handleCloseRoluriUtilizatori = (success) => {
    setOpenRoluriUtilizatori(false);
    if (success) {  
      setOpenGrupuriUtilizatori(false); 
      fetchAllUserRoles();
    }
  };


 //stergere user
 const handleDeleteUser = async (userId) => {
  Swal({
    title: 'Sunteți sigur?',
    text: 'Odată șters, utilizatorul va fi eliminat definitiv din sistem!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then(async (willDelete) => {
    if (willDelete) {
      try {
        const response = await fetch(`${window.API_URL}deleteUser/${userId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          Swal('Succes', 'Utilizatorul a fost șters cu succes!', 'success').then(() => {
            fetchUsers();
            fetchGrupuriMeniu();
            setSelectedUser(null); 
          });
        } else {
          Swal('Atenționare', 'A apărut o eroare la ștergerea utilizatorului.', 'warning');
        }
      } catch (error) {
        console.error('A apărut o eroare la ștergerea utilizatorului: ', error);
      }
    } else {
      Swal('Anulat', 'Ștergerea utilizatorului a fost anulată.', 'info');
    }
  });
};
  
 
 
const fetchDateUser = async (user) => {
  try {
    const response = await fetch(`${window.API_URL}user/${user.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setDateUser(data.data.user);
    } else {
      console.error('A apărut o eroare la aducerea grupurilor de utilizatori.');
    }
  } catch (error) {
    console.error('A apărut o eroare la aducerea grupurilor de utilizatori: ', error);
  }
}; 

 
const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

const handleSelectChange = (event, field) => {
  setFormData({
    ...formData,
    [field]: event.target.value,
  });
};
 

const handleSubmit = async () => {

  try {

    let url = `${window.API_URL}updateUser/${selectedUser.id}?name=${encodeURIComponent(formData.name)}&email=${encodeURIComponent(formData.email)}&fullname=${encodeURIComponent(formData.fullname)}&telefon=${encodeURIComponent(formData.telefon)}`;
   

    if (formData.user_group_ids && formData.user_group_ids.length > 0) {
      formData.user_group_ids.forEach((id) => {
        url += `&user_group_ids[]=${encodeURIComponent(id)}`;
      });
    } else {
      url += `&user_group_ids[]=[]`;
    }
 
    if (schimbareParola && formData.password) {
      url += `&password=${encodeURIComponent(formData.password)}`;
    }

    // Pasul 1: Actualizarea datelor utilizatorului
    const updateUserResponse = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (updateUserResponse.ok) {
      if (formData.user_role_ids.length > 0) {
        // Pasul 2: Actualizarea rolurilor utilizatorului
        const rolesParams = formData.user_role_ids.map(id => `user_role_ids[]=${id}`).join('&');
        const updateUserRolesResponse = await fetch(`${window.API_URL}setUserAccess?user_id=${selectedUser.id}&${rolesParams}`, {        
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

      if (updateUserRolesResponse.ok) {
 
      const selectedRoleIds = new Set(formData.user_role_ids);
      const existingRoleIds = new Set(userRoles.map(role => role.user_role_id));

 
      const rolesToAdd = [...selectedRoleIds].filter(id => !existingRoleIds.has(id));
      const rolesToRemove = [...existingRoleIds].filter(id => !selectedRoleIds.has(id));

   
      for (let roleId of rolesToAdd) {
          await fetch(`${window.API_URL}addUserRole?user_id=${selectedUser.id}&user_role_id=${roleId}`, {        
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
          });
      } 
      for (let roleId of rolesToRemove) {
          await fetch(`${window.API_URL}deleteUserAccess?user_id=${selectedUser.id}&user_role_id=${roleId}`, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
          });
      }

        Swal({
          title: 'Succes',
          text: 'Datele utilizatorului au fost actualizate cu succes!',
          icon: 'success',
        }).then();
        fetchUsers();
        fetchGrupuriMeniu();
        fetchUserRoles(selectedUser.id);
      } else {
        fetchGrupuriMeniu();
        const updateUserRolesData = await updateUserRolesResponse.json(); 
        const errorMessagesRole = Object.values(updateUserRolesData.message)
        .flat()
        .join('\n'); 
        
         throw new Error(errorMessagesRole || 'Eroare la actualizarea rolurilor utilizatorului.');
      }
    } else {
      for (let userRole of userRoles) {
        try {
          await fetch(`${window.API_URL}deleteUserAccess?user_id=${selectedUser.id}&user_role_id=${userRole.user_role_id}`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
        } catch (error) {
          console.error('Eroare la ștergerea rolului cu id:', userRole.user_role_id, error);
        }
      }

      Swal({
        title: 'Succes',
        text: 'Datele utilizatorului au fost actualizate cu succes! Rolurile au fost eliminate',
        icon: 'success',
      }).then();
      
      fetchUsers();
      
      fetchGrupuriMeniu();
      fetchUserRoles(selectedUser.id);
      setUserRoles([]);
    }
   }
    else {
      const updateUserData = await updateUserResponse.json();
      const errorMessages = Object.values(updateUserData.message)
        .flat()
        .join('\n'); 
      throw new Error(errorMessages || 'Eroare la actualizarea datelor utilizatorului.');
      
    }
  } catch (error) {
    Swal({
      title: 'Atenționare',
      text: error.toString(),
      icon: 'warning',
    });
    console.error('A apărut o eroare la actualizarea datelor utilizatorului: ', error);
  }
  
 
};

//drepturi speciale
const handleOpenRightsModal = async (user) => {
  if (isRightsModalOpen) return;

  let roleIds = [];
  try {
    const response = await fetch(`${window.API_URL}userAccess/${user}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (response.ok) {
      roleIds = data.data.userAccess.map((ur) => ur.user_role_id);
    } else {
      console.error('A apărut o eroare la aducerea accesului grupului.');
    }
  } catch (error) {
    console.error('A apărut o eroare la aducerea accesului grupului: ', error);
  }

  try {
    const params = new URLSearchParams({
      user_id: user,
    });
 
    userRoles.forEach(role => {
        params.append('user_role_ids[]', role.role.id);
    });
    //console.log('ce vine pe userRoles', userRoles);
    const url = `${window.API_URL}getRightsDosareList?${params.toString()}`;
    //console.log('URL cerere:', url); 

    const responseRightsList = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
     
    if (responseRightsList.ok) {

      
      const dataRightsList = await responseRightsList.json();
      setGrupDeschis(dataRightsList.data.user_group_id);
      const permissionsWithAccess = dataRightsList.data;
      setUserPermissionsTrue(permissionsWithAccess);
      setUserPermisiuni(permissionsWithAccess.userRoles);
      //console.log('permisiunile noi:', userPermisiuni);
      //console.log('userPermissions setate: ', userPermissionsTrue);
      const permissionsWithCheckedTrue = dataRightsList.data.userRoles.flatMap(role => role.rights_dosare).filter(drept => drept.checked === true);
      //console.log('cu bifa checked true: ', permissionsWithCheckedTrue);

      const newRadioSelections = {};
      let updatedRadioSelections = { ...radioSelections };
      const permissionsWithAccess2 = dataRightsList.data.userRoles.flatMap(role => role.rights_dosare).filter(drept => drept.necesita_tip_acces_dosare);

      for (let drept of permissionsWithAccess2) {
        const uniqueKey = `${drept.user_role_id}-${drept.id}`;
          try {
          const url = `${window.API_URL}getAlteDosareByRight?user_id=${user}&user_role_id=${drept.user_role_id}&user_right_id=${drept.id}`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          //console.log('drept', drept);
          const data = await response.json();
            if (drept.checked) {
              const accessType = data.data.access[0].tip_acces_la_dosare;
            updatedRadioSelections[`${drept.user_role_id}-${drept.id}`] = accessType;
            } else {
              // Pentru drepturile care nu sunt "checked" pun direct 'proprii'
              updatedRadioSelections[uniqueKey] = '';
            }
           
        } catch (error) {
          console.error(`Eroare la preluarea accesului la dosare pentru dreptul cu id=${drept.id}: `, error);
        }
      }
    
      setRadioSelections(updatedRadioSelections);
    } else {
      throw new Error('Răspunsul de la server nu este ok.');
    }

  } catch (error) {
    console.error('Eroare la preluarea drepturilor de utilizator: ', error);
  }

  setIsRightsModalOpen(true);
};


const handleDosareConfirm = async () => {
  const dosareSelectateIds = dosare.filter(dosar => selectedDosareIds.includes(dosar.id)).map(dosar => dosar.id);
 

  const baseUrl = window.API_URL;  
  const endpoint = dosareSelectateIds.length > 0 ? 'setUserAccessToDosare' : 'deleteUserAccessToDosare';
  const fullUrl = `${baseUrl}${endpoint}`;

  const params = new URLSearchParams({
      user_id: selectedUser.id,
      user_right_id: dreptId,
      user_role_id: rolUser,
      tip_acces_la_dosare: 'alte_dosare',
  });

  if (dosareSelectateIds.length > 0) {
    dosareSelectateIds.forEach(id => params.append('dosar_ids[]', id));
  }

  const urlWithParams = `${fullUrl}?${params.toString()}`;

  try {
      const response = await fetch(urlWithParams, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Eroare la actualizarea accesului la dosare.');
      }

      const data = await response.json();
      //console.log('Răspuns actualizare acces la dosare:', data);
      setSnackbarMessage("Drept actualizat cu succes!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Eroare la efectuarea cererii:', error);
    }

      try {
        // Pasul 1: toate drepturile de acces la dosare
        const response = await fetch(
          `${window.API_URL}getAlteDosareByRight?user_id=${selectedUser.id}&user_role_id=${rolUser}&user_right_id=${dreptId}`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== valoareNoua);
  
          // Pasul 2: elimin drepturile care nu corespund
          for (let access of otherRights) {
            const deleteResponse = await fetch(
              `${window.API_URL}deleteUserAccessToDosare?user_role_id=${rolUser}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${dreptId}&user_id=${selectedUser.id}`, 
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
              }
            );
  
            if (!deleteResponse.ok) {
              console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
            }
          }
        } else {
          throw new Error('Eroare la obținerea accesului la dosare.');
        }
      } catch (error) {
        console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
      }
    
  setIsSelectingDosare(false);
  setIsDosareModalOpen(false);
  setSelectedDosareIds([]);
};


    
    const handleGrupuriDosareConfirm = async () => {
      const grupuriDosareSelectateIds = grupuriDosare.filter(grupdosar => selectedGrupuriDosareIds.includes(grupdosar.id)).map(grupdosar => grupdosar.id);
      
      const baseUrl = window.API_URL;  
      const endpoint = grupuriDosareSelectateIds.length > 0 ? 'setUserAccessToDosare' : 'deleteUserAccessToDosare';
      const fullUrl = `${baseUrl}${endpoint}`;
      
      const params = new URLSearchParams({
          user_id: selectedUser.id,
          user_right_id: dreptId,
          user_role_id: rolUser,
          tip_acces_la_dosare: 'alte_grupuri_dosare',
      });
      
      if (grupuriDosareSelectateIds.length > 0) {
          grupuriDosareSelectateIds.forEach(id => params.append('dosar_group_ids[]', id));
      }
      
      const urlWithParams = `${fullUrl}?${params.toString()}`;
      
      try {
          const response = await fetch(urlWithParams, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
    
          if (!response.ok) {
            throw new Error('Eroare la actualizarea accesului la dosare.');
          }
    
          const data = await response.json();
          //console.log('Răspuns actualizare acces la grupuri de dosare:', data);
          setSnackbarMessage("Drept actualizat cu succes!");
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Eroare la efectuarea cererii:', error);
        }
 
          try {
            // Pasul 1: toate drepturile de acces la dosare
            const response = await fetch(
              `${window.API_URL}getAlteDosareByRight?user_id=${selectedUser.id}&user_role_id=${rolUser}&user_right_id=${dreptId}`, 
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
              }
            );
      
            if (response.ok) {
              const data = await response.json();
              const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== valoareNoua);
      
              // Pasul 2: elimin drepturile care nu corespund cu cel setat
              for (let access of otherRights) {
                const deleteResponse = await fetch(
                  `${window.API_URL}deleteUserAccessToDosare?user_role_id=${rolUser}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${dreptId}&user_id=${selectedUser.id}`, 
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                    },
                  }
                );
      
                if (!deleteResponse.ok) {
                  console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
                }
              }
            } else {
              throw new Error('Eroare la obținerea accesului la dosare.');
            }
          } catch (error) {
            console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
          }
  
      setIsSelectingGrupuri(false);
      setIsGrupuriDosareModalOpen(false);
      setSelectedGrupuriDosareIds([]);
    };

    const handleRadioChange = async (userRoleId, rightId, newValue) => {

      setRadioSelections(prev => ({ ...prev, [`${userRoleId}-${rightId}`]: newValue }));

      const uniqueKey = `${userRoleId}-${rightId}`;
      setRadioSelections(prevRadioSelections => ({
        ...prevRadioSelections,
        [uniqueKey]: newValue,
      }));
 
      setDreptId(rightId);
      setRolUser(userRoleId);
      setValoareNoua(newValue);

      if (!newValue) {
        // Dacă newValue nu este setat, înseamnă că nu avem un acces definit
        setRadioSelections(prevRadioSelections => ({
          ...prevRadioSelections,
          [uniqueKey]: null, // Setăm la null pentru a nu avea niciun Radio selectat
        }));
      }
      
      if (currentSelectingRightId === `${userRoleId}-${rightId}`) {
        setCurrentSelectingRightId(null);
        setIsSelectingDosare(false);
        setIsSelectingGrupuri(false);
      }
      if (newValue === 'alte_dosare') {
        //console.log('Caz pentru alte_dosare');
        setIsSelectingDosare(true); 
        setCurrentSelectingRightId(`${userRoleId}-${rightId}`);
        //ceva
      } else if (newValue === 'alte_grupuri_dosare') {
        setIsSelectingGrupuri(true); 
        setCurrentSelectingRightId(`${userRoleId}-${rightId}`);

        //console.log('Caz pentru alte_grupuri_dosare');
        //altceva
      } else {
        //console.log('Caz pentru orice altă valoare');
        try {
          const url = new URL(`${window.API_URL}setUserAccessToDosare`);
          url.search = new URLSearchParams({
            user_id: selectedUser.id,
            user_right_id: rightId,
            user_role_id: userRoleId,
            tip_acces_la_dosare: newValue
          }).toString();
    
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
            }
          });
    
          if (!response.ok) {
            throw new Error('Eroare la actualizarea accesului la dosare.');
          }
    
          const data = await response.json();
          //console.log('Răspuns actualizare acces la dosare:', data);
          setSnackbarMessage("Drept actualizat cu succes!");
          setSnackbarOpen(true);
    
        } catch (error) {
          console.error('Eroare la efectuarea cererii:', error);
        }
      }
      if (newValue !== 'alte_dosare' && newValue !== 'alte_grupuri_dosare') {
        try {
          // Pasul 1: toate drepturile de acces la dosare
          const response = await fetch(
            `${window.API_URL}getAlteDosareByRight?user_id=${selectedUser.id}&user_role_id=${userRoleId}&user_right_id=${rightId}`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== newValue);
    
            // Pasul 2: elimin drepturile care nu corespund
            for (let access of otherRights) {
              const deleteResponse = await fetch(
                `${window.API_URL}deleteUserAccessToDosare?user_role_id=${userRoleId}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${rightId}&user_id=${selectedUser.id}`, 
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
                }
              );
    
              if (!deleteResponse.ok) {
                console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
              }
            }
          } else {
            throw new Error('Eroare la obținerea accesului la dosare.');
          }
        } catch (error) {
          console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
        }
      }
    };

    
    const handleOpenDosareModal = async (userRoleId, rightId) => {
      setSelectedDosareIds([]);
      setIsDosareModalOpen(true); 

      await fetchDosare();
      const url = `${window.API_URL}getAlteDosareByRight`;
      const params = new URLSearchParams({
        user_id: selectedUser.id, 
        user_role_id: userRoleId,
        user_right_id: rightId,
      });
    
      try {
        const response = await fetch(`${url}?${params}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const accessObject = data.data.access.find(obj => obj.tip_acces_la_dosare === "alte_dosare");
          
          if (accessObject && accessObject.dosare && accessObject.dosare.length > 0) {
            const selectedDosareIds = accessObject.dosare.map(dosar => dosar.id);
            setSelectedDosareIds(selectedDosareIds);
            
            setDosare(dosare => dosare.map(dosar => {
              return {
                ...dosar,
                selected: selectedDosareIds.includes(dosar.id),
              };
            }));
          }
        } else {
          throw new Error('Răspunsul de la server nu este ok.');
        }
      } catch (error) {
        console.error('Eroare la preluarea dosarelor: ', error);
      }
    };
    
    const handleClose = (event, reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setIsDosareModalOpen(false);
       setSelectedDosareIds([]);
    }
    
    const handleOpenGrupuriDosareModal = async (userRoleId, rightId) => {
      setSelectedGrupuriDosareIds([]);
      setIsGrupuriDosareModalOpen(true);
      
      const url = `${window.API_URL}getAlteDosareByRight`;
      const params = new URLSearchParams({
        user_id: selectedUser.id, 
        user_role_id: userRoleId,
        user_right_id: rightId,
      });
    
      try {
        const response = await fetch(`${url}?${params}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const accessObject = data.data.access.find(obj => obj.tip_acces_la_dosare === "alte_grupuri_dosare");
          
          if (accessObject && accessObject.dosar_groups && accessObject.dosar_groups.length > 0) {
            const selectedGrupuriDosareIds = accessObject.dosar_groups.map(grupdosar => grupdosar.id);
            setSelectedGrupuriDosareIds(selectedGrupuriDosareIds);
            
            setGrupuriDosare(grupuriDosare => grupuriDosare.map(grupdosar => {
              return {
                ...grupdosar,
                selected: selectedGrupuriDosareIds.includes(grupdosar.id),
              };
            }));
          }
        } else {
          throw new Error('Răspunsul de la server nu este ok.');
        }
      } catch (error) {
        console.error('Eroare la preluarea grupurilor de dosare: ', error);
      }
    };
    const handleCloseGrupuriDosare = (event, reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setIsGrupuriDosareModalOpen(false);
       setSelectedGrupuriDosareIds([]);
    }


    const updateRadioSelectionsFromData = (userRights) => {
      const newRadioSelections = userRights.reduce((acc, curr) => {
        const uniqueKey = `${curr.user_role_id}-${curr.id}`;
        acc[uniqueKey] = curr.tip_acces_la_dosare || null; // Folosind null pentru a indica absența selecției
        return acc;
      }, {});
      setRadioSelections(newRadioSelections);
    };
    
    // Apoi, atunci când datele drepturilor sunt încărcate (de exemplu, într-un useEffect sau într-o funcție de încărcare):
    useEffect(() => {
      // presupunem că 'userPermisiuni' este datele pe care le primești
      updateRadioSelectionsFromData(userPermisiuni);
    }, [userPermisiuni]);
    
    const isUpdateButtonDisabled = () => {
      console.log("CriteriaMet:", criteriaMet); // Debugging
      console.log("FormData:", formData);
    
      if (isLoadingGrupuri || isLoadingRoluri) {
        return true; // Dezactivează butonul dacă încă se încarcă grupurile sau rolurile
      }
    
      if (schimbareParola) {
        const isPasswordValid =
          Object.values(criteriaMet).every((met) => met) && // Toate criteriile pentru parolă sunt îndeplinite
          formData.password; // Parola este completată
    
        console.log("IsPasswordValid:", isPasswordValid); // Debugging
        return !isPasswordValid; // Butonul este dezactivat dacă parola nu este validă
      }
    
      return false; // Butonul este activ dacă schimbarea parolei nu este bifată și încărcările sunt complete
    };
    
    

    useEffect(() => {
      if (schimbareParola) {
        validatePassword(formData.password);
      }
    }, [formData.password, schimbareParola]);

    
  return (
    <div  className="card container mx-auto p-5 mt-5">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h5">Administrare utilizatori</Typography>
            </Grid>
            
            <Grid item xs={8} container justifyContent="flex-end">
            {!areDrept(AdaugareUtilizatoriDrept && !areDrept(AccesLaToataAplicatia)) ? ( 
              <Button disabled={!areDrept(AdaugareUtilizatoriDrept)} variant="contained" color="secondary" style={{ marginRight: '8px' }} startIcon={<PersonAddIcon />} onClick={handleOpenAdaugare} size="small">
                 Adăugare Utilizator
              </Button> 
            ) : null } 
            {!areDrept(AdaugareGrupuriUtilizatori && !areDrept(AccesLaToataAplicatia)) ? ( 
              <Button disabled={!areDrept(AdaugareGrupuriUtilizatori)} variant="contained" color="secondary" style={{ marginRight: '8px' }} startIcon={<GroupIcon />} onClick={handleOpenGrupuriUtilizatori} size="small"> 
                  Grupuri utilizatori
                </Button>
            ) : null } 
            {!areDrept(AdaugareRoluri && !areDrept(AccesLaToataAplicatia)) ? ( 
              <Button disabled={!areDrept(AdaugareRoluri)} variant="contained" color="secondary" startIcon={<LockIcon />} onClick={handleOpenRoluriUtilizatori} size="small"> 
                Roluri utilizatori
              </Button>
            ) : null } 
            </Grid>
          </Grid>
          <hr />
    <Grid container spacing={2}>
 
    <Grid item xs={4}>
  {/* 
  {isLoadingUsers ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '500px' }}>
      <CircularProgress size={60} /> 
    </div>
  ) : (
    <div className="" style={{ maxHeight: '500px', overflowX: 'auto' }}>
      {users && users.length > 0 ? (
        users.map((user) => (
          <Box
            key={user.id}
            border={1}
            borderRadius={2}
            padding={1}
            marginBottom={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={selectedUser && selectedUser.id === user.id ? 'userSelectatActiv' : 'userSelectat'}
            style={{
              cursor: 'pointer', 
              borderRadius: '4px',
              pointerEvents: selectedUser && selectedUser.id === user.id ? 'none' : 'auto',
            }}
            onClick={() => {
              //console.log('am deschis userul:', user.id);
              handleUserSelect(user); 
            }}
          >
            <Typography
              style={{
                color: selectedUser && selectedUser.id === user.id ? '#FFFFFF' : '#666666',
              }}
            >
              {user.name} ({user.fullname})
            </Typography>
            <div> </div>
          </Box>
        ))
      ) : (
        <Alert severity="warning">Nu există utilizatori.</Alert>
      )}
    </div>
  )}
      */}
      <div style={{ maxHeight: '500px', overflowX: 'auto' }}>
       {grupuriMeniu && grupuriMeniu.length > 0 ? grupuriMeniu.map((grup) => (
      <div key={grup.id}>
        <ListItem button onClick={() => handleToggleGrup(grup.id)} className='headerLista'>
          {grup.name}
          {setGrupDeschis2[grup.id] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={grupDeschis2[grup.id]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {grup.users && grup.users.length > 0 ? (
            grup.users.map((user) => (
               <Box
               key={user.id}
               border={1}
               borderRadius={2}
               padding={1}
               marginBottom={1}
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               className={selected.userId === user.id && selected.groupId === grup.id ? 'userSelectatActiv' : 'userSelectat'}
               style={{
                 cursor: 'pointer', 
                 borderRadius: '4px',
                 pointerEvents: selected.userId === user.id && selected.groupId === grup.id ? 'none' : 'auto',
               }}
               onClick={() => {
                 //console.log('am deschis userul:', user.id);
                 //handleUserSelect(user); 
                 handleUserSelect(user, grup.id);
               }}
             >
               <Typography
                 style={{
                  color: selected.userId === user.id && selected.groupId === grup.id ? '#FFFFFF' : '#666666',
                }}
               >
                 {user.name} ({user.fullname})
               </Typography>
               <div> </div>
             </Box>
            
             
              ))
              ) : (
                <Alert severity="warning">Nu există utilizatori.</Alert>
              )}
            </List>
        </Collapse>
      </div>
    )) : <CircularProgress />}
    </div>
</Grid>
      <Grid item xs={8} className="setariUser">
      {selectedUser ? (
  <div className="container mx-auto p-1 mt-1">


    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Nume utilizator"
          type="text"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          disabled={!areDrept(EditareUtilizatori)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="dense"
          name="fullname"
          label="Nume complet"
          type="text"
          fullWidth
          value={formData.fullname}
          onChange={handleChange}
          disabled={!areDrept(EditareUtilizatori)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
          disabled={!areDrept(EditareUtilizatori)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          margin="dense"
          name="telefon"
          label="Telefon"
          type="text"
          fullWidth
          value={formData.telefon}
          onChange={handleChange}
          disabled={!areDrept(EditareUtilizatori)}
        />
      </Grid>
      <Grid item xs={12} style={{display:'flex'}}> 
        <FormControlLabel
          control={
            <Switch
              checked={schimbareParola}
              onChange={() => setSchimbareParola(!schimbareParola)}
              color="primary"
              disabled={!areDrept(EditareUtilizatori)}
            />
          }
          label="Schimbare parolă utilizator"
        />
        {schimbareParola && (
  <Box mt={2} className="card p-3">
    <Grid container spacing={2} alignItems="center">
      {/* Afișarea parolei generate */}
      <Grid item xs={12}>
 
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: '8px',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            border: '1px solid #ddd',
            fontFamily: 'monospace',
            fontSize: '14px',
          }}
        >
          <span>{generatedPassword || 'Parola va apărea aici'}</span>
          {generatedPassword && (
        
        <Tooltip title="Copiere parolă generată">
        <IconButton
       variant="outlined"
       onClick={() => navigator.clipboard.writeText(generatedPassword)}
       disabled={!generatedPassword} 
       size='small'
     ><ContentCopyIcon style={{fontSize:'14px'}} /></IconButton>
      </Tooltip>
          )}
        </Box>
      </Grid>

      {/* Butoane pentru generare și alegere parolă */}
      <Grid item xs={6}>
        <Button variant="outlined" onClick={generatePassword}>
          Generare parolă puternică
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={chooseGeneratedPassword}
          disabled={!generatedPassword}
        >
          Alege parolă generată
        </Button>
        
      </Grid>

      {/* Câmp pentru introducerea parolei */}
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="password"
          label="Parolă"
          type="password"
          fullWidth
          value={formData.password}
          onChange={handlePasswordChange}
        />
      </Grid>

      {/* Bara de putere a parolei */}
      <Grid item xs={12}>
        {renderStrengthBlocks()}
      </Grid>

      {/* Criterii pentru parolă */}
      <Grid item xs={12}>
        {renderCriteria()}
      </Grid>
    </Grid>
  </Box>
)}

      </Grid>  
        
      <Grid item xs={12}> 
        <FormControl fullWidth margin="dense">
        <InputLabel id="user-groups-label">Grupuri Utilizatori</InputLabel>
        <Select
          labelId="user-groups-label"
          id="user-groups"
          name="user_group_ids"
          label="Grupuri Utilizatori"
          multiple  
          disabled={isLoadingGrupuri || !EditareUtilizatori || !areDrept(AccesLaToataAplicatia)} 
          value={formData.user_group_ids}
          onChange={(event) => handleSelectChange(event, 'user_group_ids')}
          renderValue={(selected) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isLoadingGrupuri ? (
                <CircularProgress size={20} style={{ marginRight: '4px' }} /> 
              ) : (
                selected.map((value) => {
                  const groupName = grupuri.find((group) => group.id === value)?.name || 'Necunoscut';
                  return <Chip key={value} label={groupName} style={{ marginRight: '4px' }} />;
                })
              )}
            </div>
          )}
        >
   
          {grupuri.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              <Checkbox checked={formData.user_group_ids.indexOf(group.id) > -1} />
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12}> 
      <FormControl fullWidth margin="dense">
        <InputLabel id="user-roles-label">Roluri Utilizatori</InputLabel>
        <Select
          labelId="user-roles-label"
          id="user-roles"
          name="user_role_ids"
          label="Roluri Utilizatori"
          disabled={isLoadingRoluri || !EditareUtilizatori || !areDrept(AccesLaToataAplicatia)}
          multiple
          value={formData.user_role_ids}
          onChange={(event) => handleSelectChange(event, 'user_role_ids')}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={allUserRoles.find((role) => role.id === value)?.name} style={{ marginRight: '4px' }} />
              ))}
            </div>
          )}
        >
          {allUserRoles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              <Checkbox checked={formData.user_role_ids.indexOf(role.id) > -1} />
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} className="text-right">
      {areDrept(EditareUtilizatori) ? ( 
          <Button
          disabled={userRoles.length === 0 || isLoadingRoluri}
          style={{float:'right'}}
          onClick={() => handleOpenRightsModal(selectedUser.id)}
        >
          Configurare drepturi utilizator asupra dosarelor
        </Button>
        ) : null } 
    </Grid>
      <Grid container className='mt-5'>
      <Grid item xs={6}>
      {areDrept(EditareUtilizatori) ? ( 
        <Button
        variant="text"
        disabled={isUpdateButtonDisabled()}
        onClick={handleSubmit}
        color="success"
        startIcon={<CheckIcon />}
      >
        Actualizare utilizator
      </Button>
      
      ) : null } 
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end">
        {areDrept(StergereUtilizatori) ? ( 
          <Button
          variant="text"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          disabled={isLoadingGrupuri && isLoadingRoluri} 
          onClick={() => handleDeleteUser(selectedUser.id)}
        >
          Ștergere Utilizator
        </Button>
        ) : null } 
      </Grid>
    </Grid>

    </Grid>
    
    <Dialog open={isRightsModalOpen} onClose={() => setIsRightsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle className='titluModal'>Administrare Drepturi Utilizator</DialogTitle>
          <DialogContent className='drepturiAdmin'>
          <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          ContentProps={{
            style: { backgroundColor: 'green' }  
          }}
        />


{userPermisiuni.map(role => (
  <div key={role.id} className='colDrepturi'>
    <Typography  style={{ marginBottom: '16px' }} className='titluColDrepturi'>
      Grup {role.name}
    </Typography>
    {role.rights_dosare.map((rights_dosare) => (
              <div key={`${rights_dosare.user_role_id}-${rights_dosare.id}`}>
                <Typography style={{fontWeight:'bold', fontSize:'16px'}}>{rights_dosare.category}: {rights_dosare.name}</Typography>
                <RadioGroup
                   //value={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`]}
                   onChange={(e) => handleRadioChange(rights_dosare.user_role_id, rights_dosare.id, e.target.value)}
                 
                  className="drepturiUser"
                >
                  <FormControlLabel
                    control={<Radio checked={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] === 'proprii'} />}
                    label="Proprii"
                    value="proprii" 
                  />
                  <FormControlLabel
                    control={<Radio checked={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] === 'toate_din_departament'} />}
                    label="Toate dosarele din departament"
                    value="toate_din_departament"
                  />
                  <FormControlLabel
                    control={<Radio checked={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] === 'toate_din_aplicatie'} />}
                    label="Toate din aplicatie"
                    value="toate_din_aplicatie"
                    disabled={!areDrept(AccesLaToataAplicatia)}
                  />
                  <div style={{display:'flex'}}>
                    
                  <FormControlLabel
                    control={<Radio checked={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] === 'alte_dosare'} />}
                    label="Dosare proprii și altele"
                    value="alte_dosare"
                  />
                 <Button size="small" 
                  onClick={() => {
                    handleRadioChange(rights_dosare.user_role_id, rights_dosare.id,  'alte_dosare')
                    handleOpenDosareModal(rights_dosare.user_role_id, rights_dosare.id)
                  }}
                  disabled={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] !== 'alte_dosare'}
                   >
                   Alege dosare
                  </Button>
                 
                  </div>
                   
                  <div style={{display:'flex'}}>
                    <FormControlLabel
                    control={<Radio checked={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] === 'alte_grupuri_dosare'} />}
                    label="Alte grupuri de dosare"
                    value="alte_grupuri_dosare"
                    disabled={!areDrept(AccesLaToataAplicatia)}
                  />
                   <Button 
                   size="small"  
                   onClick={() => {
                    handleRadioChange(rights_dosare.user_role_id, rights_dosare.id,  'alte_grupuri_dosare')
                    fetchGrupuriDosare()
                    handleOpenGrupuriDosareModal(rights_dosare.user_role_id, rights_dosare.id)
                  }}
                   disabled={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] !== 'alte_grupuri_dosare' && !areDrept(AccesLaToataAplicatia)}
                   >
                      Alege grupuri
                    </Button>
                
                  </div>
                </RadioGroup>
              </div>
        ))}
        </div>
      ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsRightsModalOpen(false)} color="primary">
              Renunțare
            </Button>
          </DialogActions>
        </Dialog>


        

        <Dialog className='modalAlegeDosare' disableEscapeKeyDown  open={isDosareModalOpen} onClose={handleClose}  maxWidth="sm" fullWidth>
          <DialogTitle className='titluModal'>Alege dosare</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {/* Lista de dosare din dreapta */}
              <Grid item xs={6}>
                <Typography variant="h6">Dosare disponibile</Typography>
                <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
                {dosare.map((dosar) => {
                  if (!dosar) return null; 
                  return (
                <li key={dosar.id} className={dosar.selected ? "none" : ""}>
                  <Checkbox  size="small"
                    checked={dosar.selected}
                    onChange={() => handleDosarSelect(dosar.id)}
                  />
                  {dosar.numardosarfinal || dosar.numarintern} (Client {dosar.client})
                </li>
               );
              })}
              </ul>

              </Grid>
              {/* Lista de dosare selectate în stanga */}
              <Grid item xs={6}>
                <Typography variant="h6">Dosare selectate</Typography>
                <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
                {selectedDosareIds.map((dosarId) => {
                  const dosar = dosare.find((d) => d.id === dosarId);
                  return (
                    <li key={dosar.id}>
                      <Checkbox  size="small"
                        checked
                        onChange={() => handleDosarSelect(dosar.id)}
                      />
                      {dosar.numardosarfinal || dosar.numarintern} (Client {dosar.client})
                    </li>
                  );
                })}
                </ul>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDosareModalOpen(false)} color="primary">
              Renunțare
            </Button>
            <Button onClick={handleDosareConfirm} color="primary">
              Confirmare
            </Button>
          </DialogActions>
        </Dialog>

 
      <Dialog className='modalAlegeGrupuriDosare' disableEscapeKeyDown  open={isGrupuriDosareModalOpen} onClose={handleCloseGrupuriDosare}  maxWidth="sm" fullWidth>
        <DialogTitle className='titluModal'>Alege grupuri dosare</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* Lista de grupuri dosare din dreapta */}
            <Grid item xs={6}>
              <Typography variant="h6">Grupuri dosare disponibile</Typography>
              <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
              {grupuriDosare.map((grupdosar) => (
              <li key={grupdosar.id} className={grupdosar.selected ? "none" : ""}>
                <Checkbox  size="small"
                  checked={grupdosar.selected}
                  onChange={() => handleGrupuriDosarSelect(grupdosar.id)}
                />
                {grupdosar.name}
              </li>
            ))}
            </ul>

            </Grid>
            {/* Lista de grupuri dosare selectate în stanga */}
            <Grid item xs={6}>
              <Typography variant="h6">Dosare selectate</Typography>
              <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
              {selectedGrupuriDosareIds.map((grupdosarId) => {
                const grupdosar = grupuriDosare.find((d) => d.id === grupdosarId);
                return (
                  <li key={grupdosar.id}>
                    <Checkbox  size="small"
                      checked
                      onChange={() => handleGrupuriDosarSelect(grupdosar.id)}
                    />
                    {grupdosar.name}
                  </li>
                );
              })}
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsGrupuriDosareModalOpen(false)} color="primary">
            Renunțare
          </Button>
          <Button onClick={handleGrupuriDosareConfirm} color="primary">
            Confirmare
          </Button>
        </DialogActions>
        </Dialog>



  </div>
) : (
  <div className="container mx-auto p-5 mt-5">
    {isLoadingUsers ? (
      <Alert severity="warning">Vă rugăm așteptați, se încarcă utilizatorii</Alert>  
    ) : (
      <Alert severity="info">Selectați un utilizator pentru a vizualiza detalii.</Alert>
    )}
  </div>
)}


      </Grid>

      <AdaugareUtilizatori open={openAdaugare} onClose={handleCloseAdaugare} />
      <GrupuriUtilizatori open={openGrupuriUtilizatori} onClose={handleCloseGrupuriUtilizatori} />
      <RoluriUtilizatori open={openRoluriUtilizatori} onClose={handleCloseRoluriUtilizatori} />
      
    </Grid>
    
    </div>
  );
}

export default AdministrareUtilizatori;
