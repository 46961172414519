import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Tooltip, CircularProgress, Alert } from '@mui/material';
import Swal from 'sweetalert'; 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessIcon from '@mui/icons-material/Business';

import DateFirma from './dateFirma';
import InsolventaFirma from './insolventaFirma';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 

import verificareToken from '../../VerificareToken';

export default function FirmeMonitorizateBPI() {
  const { id } = useParams();
  const [firme, setFirme] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);
 
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFirma, setSelectedFirma] = useState(null);

  const handleOpenDialog = (cuiFirma) => {
    setSelectedFirma(cuiFirma);
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setSelectedFirma(null);
    setDialogOpen(false);
  };
  
  const [dialogOpenInsolventa, setDialogOpenInsolventa] = useState(false);
  const [selectedFirmaInsolventa, setSelectedFirmaInsolventa] = useState(null);

  const handleOpenDialogInsolventa = (cuiFirma) => {
    setSelectedFirmaInsolventa(cuiFirma);
    setDialogOpenInsolventa(true);
  };
  
  
  function handleCloseDialogInsolventa() {
    setSelectedFirmaInsolventa(null);
    setDialogOpenInsolventa(false);
  }
  


    const { token } = verificareToken();
  
  const fetchFirme = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${window.API_URL}firmeMonitorizate/dosar/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFirme(data.data.firmeMonitorizate);
    } catch (error) {
      console.error("A problem occurred while fetching the data: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFirme();
  }, [id]);

   

  const handleDeleteFirma = async (cuiFirma) => {
    Swal({
      title: "Sunteți sigur?",
      text: "Odată eliminată de la monitorizare firma nu va mai fi monitorizată!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          await fetch(`${window.API_URL}stergemonitorizare/${cuiFirma}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
          });
      
          await fetchFirme();
          Swal("Firma a fost eliminată de la monitorizare!", {
            icon: "success",
          });
        } catch (error) {
          console.error("A apărut o eroare la eliminarea firmei de la monitorizare: ", error);
        }
      } else {
        Swal("Firma nu a fost eliminată!");
      }
    });
  };
  

  if (isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>;
  }

  return (
    <div className='mt-3'> 
      {firme && firme.length > 0 ? (
        firme.map((firma, index) => (
          <Box className="boxActe" key={index} border={1} borderRadius={2} padding={2} marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography> {firma.denumire} </Typography>
            <div>
              <Tooltip title={`Vizualizare date firmă: ${firma.denumire}${firma.codcui}`}>
                <IconButton onClick={() => handleOpenDialog(firma.codcui)} aria-label="Vizualizere firmă">
                  <BusinessIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Vizualizare firmă în BPI: ${firma.denumire}${firma.codcui}`}>
                <IconButton onClick={() => handleOpenDialogInsolventa(firma.codcui)} aria-label="Vizualizere firmă în BPI">
                  <CurrencyExchangeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminare de la monitorizare">
                <IconButton aria-label="delete" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleDeleteFirma(firma.id)} style={{ marginRight: '10px' }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
            {dialogOpen && selectedFirma === firma.codcui && (
              <DateFirma open={dialogOpen} onClose={handleCloseDialog} cui={selectedFirma} />
            )}
            {dialogOpenInsolventa && selectedFirmaInsolventa === firma.codcui && (
              <InsolventaFirma open={dialogOpenInsolventa} onClose={handleCloseDialogInsolventa} cui={selectedFirmaInsolventa} />
            )}
          </Box>
        ))
      ) : (
        <Alert severity="warning">Nu există firme monitorizate pe acest dosar.</Alert>
      )}
    </div>
  );

}