import React, { useEffect, useState } from 'react';
import { useSound } from './sunete';

const GlobalEventListener = () => {
  const { playSound } = useSound();

  const [alertVisible, setAlertVisible] = useState({
    warning: false,
    success: false,
    info: false,
  });

  useEffect(() => {
    const handleClick = (event) => {
      //console.log('Event target:', event.target); 
      const tagName = event.target.tagName.toLowerCase();
      const isNavLink = event.target.classList.contains('nav-link');
      const isSwitch = event.target.closest('.MuiSwitch-root');
      const isMuiButton = event.target.closest('.MuiButtonBase-root');
      const isMuiTab = event.target.closest('.MuiTab-root');
      const isRoleTab = event.target.getAttribute('role') === 'tab';

      if (tagName === 'button' || tagName === 'a' || event.target.getAttribute('onClick') || isNavLink || isMuiButton) {
        playSound('click');
      }

      if (isSwitch) {
        playSound('swipe');
      }

      if (isRoleTab || isMuiTab) {
        playSound('flip');
      }
    };

    const handleDialogOpen = () => {
      playSound('open');
    };

    const handleDialogClose = () => {
      playSound('close');
    };

    const checkForSwalAlert = () => {
      const swalAlertWarning = document.querySelector('.swal-overlay--show-modal .swal-icon--warning');
      const swalAlertSuccess = document.querySelector('.swal-overlay--show-modal .swal-icon--success');
      const swalAlertInfo = document.querySelector('.swal-overlay--show-modal .swal-icon--info');
      
      if (swalAlertWarning && !alertVisible.warning) {
        playSound('warning');
        setAlertVisible(prevState => ({ ...prevState, warning: true }));
      } else if (!swalAlertWarning && alertVisible.warning) {
        setAlertVisible(prevState => ({ ...prevState, warning: false }));
      }

      if (swalAlertSuccess && !alertVisible.success) {
        playSound('success');
        setAlertVisible(prevState => ({ ...prevState, success: true }));
      } else if (!swalAlertSuccess && alertVisible.success) {
        setAlertVisible(prevState => ({ ...prevState, success: false }));
      }

      if (swalAlertInfo && !alertVisible.info) {
        playSound('info');
        setAlertVisible(prevState => ({ ...prevState, info: true }));
      } else if (!swalAlertInfo && alertVisible.info) {
        setAlertVisible(prevState => ({ ...prevState, info: false }));
      }

 
    };

    // Verificăm la fiecare 500ms dacă apare alerta
    const intervalId = setInterval(checkForSwalAlert, 500);

    document.addEventListener('click', handleClick);
    document.addEventListener('dialogopen', handleDialogOpen);
    document.addEventListener('dialogclose', handleDialogClose);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('click', handleClick);
      document.removeEventListener('dialogopen', handleDialogOpen);
      document.removeEventListener('dialogclose', handleDialogClose);
    };
  }, [playSound, alertVisible ]);

  return null;
};

export default GlobalEventListener;
