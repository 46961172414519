import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';


import CautareActe from './legislatie/cautareActe';
import InfoActeDosar from './legislatie/infoActeDosar';
import CautarePJ from './legislatie/cautarePJ';
import verificareToken from '../VerificareToken';

import HelpDialog from '../HelpDialog';

export default function LegislatieConexa() {
  const [currentTab, setCurrentTab] = useState(0);

  const { token } = verificareToken();
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <div>
       <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center" 
        >
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="↳ Listă legislație conexă" />
            <Tab label="↳ Adăugare legislație Românească" />
            <Tab label="↳ Adăugare practică judiciară" />
          </Tabs>
          
          <HelpDialog
              title="ATAȘARE LEGISLAȚIE LA DOSAR"
              contentType="video"
              content="https://www.ctce.ro/important/juristpronext/tutoriale//integrare-ilegis.mp4" 
              tooltip="Ajutor: Cum se atașează acte normative și jurisprudență la dosar?"
          /> 
      </Box>  
      <Box>
        {currentTab === 0 && (
          <div>
            <InfoActeDosar onChangeTab={handleChangeTab} />
          </div>
        )}
        {currentTab === 1 && (
          <div>
            <CautareActe />
          </div>
        )}
        {currentTab === 2 && (
          <div>
            <CautarePJ />
          </div>
        )}
      </Box>
    </div>
  );
}

