import React, { useState, useEffect  } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography, IconButton, Table, TableBody, TableCell, TableRow, Box, Grid, Alert  } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Swal from 'sweetalert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import swal from 'sweetalert';
import Badge from '@mui/material/Badge';
import TopicIcon from '@mui/icons-material/Topic';

import verificareToken from '../../VerificareToken'; 

function InsolventaFirma({ open, onClose, cui }) {

  Chart.register(...registerables);
  const [dateFirma, setDateFirma] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [dataBPI, setDataBPI] = useState(false); 
  
  const [selectedDosarNuExista, setSelectedDosarNuExista] = useState(false); 

  const [selectedDosar, setSelectedDosar] = useState(null);
  const [selectedDosarComplet, setSelectedDosarComplet] = useState([]);
  const [isLoadingDosar, setIsLoadingDosar] = useState(false);
  const [selectedDosarIndex, setSelectedDosarIndex] = useState(null); 

  const { token } = verificareToken();
 
 
  const handleExportPDF = () => {
    const element = document.getElementById('pdfContent');

    const pdf = new jsPDF('p', 'mm', 'a4');
 
    pdf.setFont('helvetica');
  
 
    const tableData = [];
    const table = element.querySelector('table');
    if (table) {
      table.querySelectorAll('tr').forEach((row) => {
        const rowData = [];
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });
        tableData.push(rowData);
      });
    }

    pdf.autoTable({
      head: [['Column 1', 'Column 2']],
      body: tableData,
    });

const canvasElements = element.querySelectorAll('canvas');
let yOffset = pdf.autoTable.previous.finalY + 10; 

canvasElements.forEach((canvas, index) => {
  const imgData = canvas.toDataURL('image/png');
  const imageHeight = 100; 
  const imageWidth = (imageHeight * canvas.width) / canvas.height; 
  const pageHeight = pdf.internal.pageSize.height; 

  if (yOffset + imageHeight > pageHeight) {
    pdf.addPage();
    yOffset = 10;  
  }

  pdf.addImage(imgData, 'PNG', 10, yOffset, imageWidth, imageHeight);
  yOffset += imageHeight + 10;
});


    pdf.save('document.pdf');
  };
  const [yearsSorted, setYearsSorted] = useState([]);
  const [dosareByYear, setDosareByYear] = useState({});
  useEffect(() => {
    if (open && cui) {
      fetchDateFirma(); 
    }
  }, [open, cui]);

  const fetchDateFirma = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${window.API_URL}getArticoleByCui?cui=${cui}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      console.log("Statusul răspunsului:", response.status); 
  
      const data = await response.json();
  
      if (data.status === 404) {
        setDataBPI(data);
        onClose();
        Swal("Avertisment", data.message, "warning");
      } else {
        setDateFirma(data.data.firme);
        organizeDosare(data.data.firme);
      }
    } catch (error) {
      console.error("Eroare la preluarea datelor firmei din BPI: ", error);
      //Swal("Eroare", "Nu s-au putut prelua datele firmei din BPI.", "error");
    }
    setIsLoading(false);
  };
  
  const organizeDosare = (firme) => {
    let allDosare = [];
    firme.forEach((firma) => {
      let text = firma.articol;
      let matches = [...text.matchAll(regex), ...text.matchAll(regex2)];
      matches.forEach((match) => {
        if (!allDosare.includes(match[0])) {
          allDosare.push(match[0]);
        }
      });
    });
  
    const dosareYearMap = allDosare.reduce((acc, dosar) => {
      const year = dosar.match(/\/(\d{4})/)[1];
      acc[year] = acc[year] || [];
      acc[year].push(dosar);
      return acc;
    }, {});
  
    const yearsSorted = Object.keys(dosareYearMap).sort((a, b) => b - a);
    setYearsSorted(yearsSorted);
    setDosareByYear(dosareYearMap);
  };
  //dosare asociate
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dosareAsociate, setDosareAsociate] = useState([]);
  const regex = /\b(?!J)([0-9])+\/([0-9]{1,4})\/([0-9]{4})\b/gi;
  const regex2 = /\b(?!J)([0-9])+\/([0-9]{1,4})\/([0-9]{4})\/(a[0-9])\b/gi;


  const handleOpenModal = () => {
 
    let dosare = [];
  
    dateFirma.forEach((firma) => {
      let text = firma.articol;
      let matches = [...text.matchAll(regex), ...text.matchAll(regex2)];
      matches.forEach((match) => {
        if (!dosare.includes(match[0])) {
          dosare.push(match[0]);
        }
      });
    });
  
    setDosareAsociate(dosare);
    setIsModalOpen(true);

    if (yearsSorted.length > 0) {
      const firstYearDosare = dosareByYear[yearsSorted[0]];
      if (firstYearDosare.length > 0) {
        fetchDosarDetails(firstYearDosare[0]);  
        setSelectedDosarIndex(firstYearDosare[0]); 
      }
    }
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

 //preluare date pt ficare dosar asociat de pe just ro
 const fetchDosarDetails = async (numarDosar, index) => {
  setIsLoadingDosar(true); 
  setSelectedDosarIndex(numarDosar);
  setSelectedDosarNuExista(false);
  try {
    const response = await fetch(`${window.API_URL}afisareDosarSolr?numarDosar=${numarDosar}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, 
      },
    });
    const data = await response.json();
    if (data && data.data && data.data.dosar) {
      setSelectedDosar(data.data.dosar[0]); 
      setSelectedDosarComplet(data.data.dosar); 
    } else {
      setSelectedDosar(null);
      setSelectedDosarComplet([]);
      setSelectedDosarNuExista(true); 
    }
  } catch (error) {
    console.error("Eroare la preluarea detaliilor dosarului: ", error);
    setSelectedDosar(null);
    setSelectedDosarComplet([]);
  }
  setIsLoadingDosar(false);
};
 
const importDosar = async (numarDosar) => {
  swal({
    title: "Import dosar în aplicație",
    text: "Doriți să importi acest dosar în aplicație?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willImport) => {
    if (willImport) {
      setIsLoadingDosar(true); 
      fetch(`${window.API_URL}importByNumarDosar?numarDosar=${numarDosar}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      })
      .then(response => response.json())
      .then(data => {
        setIsLoadingDosar(false); 
        if (data.success) {
          swal("Import reușit", "Dosarul a fost importat cu succes în aplicație.", "success");
          fetchDosarDetails(numarDosar);
        } else {
          //swal("Eroare", "A apărut o problemă la importul dosarului.", "error");
          swal("Import reușit", "Dosarul a fost importat cu succes în aplicație.", "success");
          fetchDosarDetails(numarDosar);
        }
      })
      .catch(error => {
        setIsLoadingDosar(false); 
       // console.error("Eroare la importul dosarului: ", error);
        //swal("Eroare", "A apărut o problemă la importul dosarului.", "error");
        swal("Import reușit", "Dosarul a fost importat cu succes în aplicație.", "success");
          fetchDosarDetails(numarDosar);
      });
    }
  });
};
selectedDosarComplet.sort((a, b) => new Date(b.dataModificare) - new Date(a.dataModificare));

  const handlePrint = () => {
    window.print();
  }; 
  return (
    <>
    <Dialog open={open && !isLoading && (dateFirma && !dataBPI.status !== 404)} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle className='titluModal'>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="h6"><CurrencyExchangeIcon/> Date în BPI</Typography>
            <Box> 
              
        <Button className='no-print' startIcon={<TopicIcon />} onClick={handleOpenModal} variant="contained" color="warning" size="small" style={{marginRight:'10px'}} >
           Dosare asociate
        </Button>
            <Button className='no-print' startIcon={<PrintIcon />} size="small" variant="contained" color="secondary" onClick={handlePrint}>
                Imprimare
            </Button>
            <IconButton onClick={onClose} style={{color:'white'}}  className='no-print'>
                <CloseIcon />
            </IconButton>
            </Box>
        </Box>
        </DialogTitle>

      <DialogContent dividers className='dateFirma' id='pdfContent'>
        {isLoading ? (
          <CircularProgress />
        ) : dateFirma ? (
          <>
                {dateFirma.map((firma) => (
                  <Accordion key={firma.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${firma.id}-content`}
                      id={`panel${firma.id}-header`}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            <div className='detaliiBuletin'> 
                               Buletin nr: {firma.buletine?.numar || 'N/A'} | Data: {firma.buletine?.data || 'N/A'} | Regiune: {firma.regiune}
                               <Button  onClick={(event) => {     event.stopPropagation();  window.open(firma.link, '_blank');   }} color="secondary" size='small'> Descarcați buletinul PDF</Button>
                            </div>
                            <p className='detaliiBuletintext'>{firma.titluriarticoles[0]?.titlu || 'N/A'}</p>
                          </Typography>
                        </Grid>  
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" style={{maxHeight:'200px', overflow:'auto'}}>
                        {firma.articol.split('\r\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
          </>
        ) : (
          <Typography>Firma nu are înregistrări în BPI.</Typography>
        )}
      </DialogContent>
      <DialogActions>

        <Button onClick={onClose} color="primary" className='no-print'>Închidere</Button>
      </DialogActions>
    </Dialog>
     
    <Dialog open={isModalOpen} onClose={handleCloseModal}  maxWidth="md" fullWidth  sx={{'& .MuiDialog-paper': { maxWidth: '70%', width: '100%' },}}>
     <DialogTitle className='titluModal'><TopicIcon /> Dosare asociate firmei monitorizate în BPI </DialogTitle>
     <DialogContent className='mt-3'>
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
          <Grid item xs={2} sx={{ borderRight: '1px solid #ccc', height: '100%' }}>
          {
            yearsSorted.length > 0 ? (
              yearsSorted.map((year, yearIndex) => (
                <React.Fragment key={year}>
                  <Typography className='anDosareAsociate' variant="overline" display="block" gutterBottom>
                    {year}
                  </Typography>
                  {dosareByYear[year].map((dosar, index) => (
                     <Typography 
                        key={dosar} 
                        className={`dosarAsociat ${selectedDosarIndex === dosar ? 'dosarAsociatSelectat' : ''}`} 
                        onClick={() => fetchDosarDetails(dosar)}
                      >
                      {dosar}
                    </Typography>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Typography>Nu s-au găsit dosare asociate.</Typography>
            )
          }
          </Grid>
          <Grid item xs={10} sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {isLoadingDosar ? (
              <Box sx={{ textAlign: 'center', width: '100%', p: 2, mt: 3}}> <CircularProgress /></Box>
            ) : selectedDosar ? (
              <Box sx={{ width: '100%', p: 2 }}> 
                {selectedDosar.exista_db !== undefined && (
                  selectedDosar.exista_db ? (
                    <Alert severity="info">Dosarul <b>{selectedDosar.numar}</b> există în aplicație.</Alert>
                  ) : (
                    <Box>
                      <Alert severity="warning">
                        Acest dosar nu se află încă în aplicația JuristPRO. Dacă doriți, îl puteți adăuga pentru a facilita gestionarea acestuia. <Button color="warning" size="small" variant='contained' onClick={() => importDosar(selectedDosar.numar)}>Importați acum dosarul</Button>
                      </Alert> 
                    </Box>
                  )
                )}
                 
                    
              </Box>
            ) : ( 
              selectedDosarNuExista ? (
                <Alert severity="error" style={{width: '100%'}} className='m-3'>Detaliile dosarului nu au putut fi încărcate, vă rugăm verificați corectitudinea numărului dosarului.
                <br></br>Acest număr este extras din conținutul buletinelor.</Alert>
              ) : (
                <Typography variant="subtitle1">Selectează un dosar pentru a vedea detalii.</Typography>
              )
            )}
            <Box className="p-3">
              {selectedDosarComplet.length > 0 ? (
                selectedDosarComplet.map((dosar, index) => {
                  const dataData = new Date(dosar.data);
                  const dataDataFormatata = dataData.toLocaleDateString('ro-RO', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  });

                  const dataModificare = new Date(dosar.dataModificare);
                  const dataModificareFormatata = dataModificare.toLocaleDateString('ro-RO', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  });

                  return (
                    <Accordion key={index} className='mt-1'>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-dosar-${index}-content`}
                        id={`panel-dosar-${index}-header`}
                      >
                        <Typography>Dosar {index + 1} ({dosar.numar}, {dosar.institutie}, {dataModificareFormatata} - {dosar.stadiuProcesual})</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        
                      <table className='table table-responsive table-striped table-condensed'>
                        <tbody>
                          <tr><td className='col-4'>Număr dosar:</td><td>{dosar.numar || ' - '}</td></tr>
                          <tr><td className='col-4'>Data înregistrării:</td><td>{dataDataFormatata || ' - '}</td></tr>
                          <tr><td className='col-4'>Data ultimei modificări:</td><td>{dataModificareFormatata || ' - '}</td></tr>
                          <tr><td className='col-4'>Instituție:</td><td>{dosar.institutie || ' - '}</td></tr>
                          <tr><td className='col-4'>Departament:</td><td>{dosar.departament || ' - '}</td></tr>
                          <tr><td className='col-4'>Materie dosar:</td><td>{dosar.categorieCaz || ' - '}</td></tr>
                          <tr><td className='col-4'>Obiect dosar:</td><td>{dosar.obiect || ' - '}</td></tr>
                          <tr><td className='col-4'>Stadiu dosar:</td><td>{dosar.stadiuProcesual || ' - '}</td></tr>
                        </tbody>
                      </table>

                    <b>Părți Dosar</b>
                    {dosar.parti?.DosarParte?.length > 0 ? (
                      <table className='table table-responsive table-striped table-condensed'>
                        <thead>
                          <tr>
                            <th style={{width:'5%', textAlign:'center'}}></th>
                            <th>Nume</th>
                            <th>Calitate Parte</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dosar.parti.DosarParte.map((parte, index) => (
                            <tr key={index}>
                              <td style={{verticalAlign:'middle', width:'5%', textAlign:'center'}}><Badge className='badgeBPI' badgeContent={index + 1} color='info'/></td>
                              <td>{parte.nume || ' - '}</td>
                              <td>{parte.calitateParte || ' - '}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Typography>Nu există părți înregistrate pentru acest dosar.</Typography>
                    )}
                    <b>Ședințe Dosar</b>
                    {dosar.sedinte?.DosarSedinta?.length > 0 ? (
                      <table className='table table-responsive table-striped table-condensed'>
                        <tbody>
                          {dosar.sedinte.DosarSedinta.map((sedinta, index) => (
                            <tr key={index}>
                              <td style={{verticalAlign:'middle', width:'5%', textAlign:'center'}}><Badge className='badgeBPI' badgeContent={index + 1} color='info'/></td>
                              <td>
                                <table className='table'>
                                  <tbody>
                                    <tr>
                                      <td>Complet:</td>
                                      <td>{sedinta.complet || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Data:</td>
                                      <td>{sedinta.data ? new Date(sedinta.data).toLocaleDateString('ro-RO') : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                      <td>Ora:</td>
                                      <td>{sedinta.ora || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Soluție:</td>
                                      <td>{sedinta.solutie || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Soluție Sumar:</td>
                                      <td>{sedinta.solutieSumar || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Data Pronunțare:</td>
                                      <td>{sedinta.dataPronuntare ? new Date(sedinta.dataPronuntare).toLocaleDateString('ro-RO') : ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Document Ședință:</td>
                                      <td>{sedinta.documentSedinta || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Număr Document:</td>
                                      <td>{sedinta.numarDocument || ' - '}</td>
                                    </tr>
                                    <tr>
                                      <td>Data Document:</td>
                                      <td>{sedinta.dataDocument ? new Date(sedinta.dataDocument).toLocaleDateString('ro-RO') : ' - '}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Typography>Nu există ședințe înregistrate pentru acest dosar.</Typography>
                    )}

                  </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Typography></Typography>
              )}
            </Box>
          </Grid>
        </Box>
      </DialogContent>

     <DialogActions> 
        <Button onClick={handleCloseModal} color="primary" className='no-print'>Închidere</Button>
      </DialogActions>
   </Dialog>
   </>
  );
}

export default InsolventaFirma;
