import React, { useState, useEffect, useRef  } from 'react';
import verificareToken from './VerificareToken';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import roLocale from './js/ro';
import Modal from '@mui/material/Modal';
import { Alert, Dialog, Autocomplete, Chip, DialogContent, InputAdornment, Tooltip, Grid, Typography, IconButton, Button, TextField, Switch, Select, MenuItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, FormControl, FormControlLabel, InputLabel, CircularProgress, DialogTitle, DialogActions } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook'; 
import SearchIcon from '@mui/icons-material/Search';
import Swal from 'sweetalert';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, parse, isPast, endOfDay  } from 'date-fns';
import { ro } from 'date-fns/locale';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import HelpDialog from './HelpDialog';
const CalendarEvenimenteMultiple = () => {
  
  const { token } = verificareToken();
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
 
  const [isLoading, setLoading] = useState(true); 
  const [view, setView] = useState('dayGridMonth');
  const calendarRef = useRef(null);
    
  const [groupedOptions, setGroupedOptions] = useState([]);
  const { areDrept } = DrepturiUtilizator(); 
  const AdaugareUtilizatoriDrept = 'AdaugareUtilizatori'; 
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventExport, setSelectedEventExport] = useState(null);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedTermeneExport, setSelectedTermenEventExport] = useState(null);

  const [createModalOpen2, setCreateModalOpen2] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [notificareId, setSelectedNotificareId] = useState(null);
  const [termenModalOpen, setTermenModalOpen] = useState(false);
  const [selectedTermenEvent, setSelectedTermenEvent] = useState(null); 
  const [selectedTermenEvent2, setSelectedTermenEvent2] = useState(null); 
  const [notificariTermenDorite, setNotificariTermenDorite] = useState([]);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [adaugareEvenimentUtilizatorOpen, setAdaugareEvenimentUtilizatorOpen] = useState(false);
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [userNotificari, setUserNotificari] = useState([]); 
  const [filterText, setFilterText] = useState(''); 
  
  const [termenTrecut, setTermenTrecut] = useState(false);
  const [selectedDateFinal, setSelectedDateFinal] = React.useState(new Date());
  //culori evenimente calendar
  const { preferinte, loaded } = usePreferinteUtilizator();
  const colorCT = preferinte.colorCT;
  const colorCA = preferinte.colorCA;  
  const colorCU = preferinte.colorCU;  
  //end 
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleViewChange = (newView) => {
    setView(newView);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(newView);
    }
  };

  useEffect(() => {
    const updateButtonClasses = () => {
      const buttons = document.querySelectorAll('.fc-button');
      buttons.forEach(button => {
        button.classList.remove('fc-button-active', 'fc-button-inactive');
      });

      const activeButtonSelector = {
        dayGridMonth: '.fc-myMonthButton-button',
        listWeek: '.fc-myWeekButton-button',
        listDay: '.fc-myDayButton-button'
      }[view];

      if (activeButtonSelector) {
        document.querySelector(activeButtonSelector)?.classList.add('fc-button-active');
      }

      buttons.forEach(button => {
        if (!button.classList.contains('fc-button-active')) {
          button.classList.add('fc-button-inactive');
        }
      });
    };

    updateButtonClasses();
  }, [view]);
  
  const [dataConvertitaTermen, setDataConvertitaTermen] = useState(null);
  
  const [editedNotificare, setEditedNotificare] = useState({
    descriere: '',
    panaladata: '',
    notificare: 0,
    via_email: 0,
    via_push: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
    tipNotificare: '',
  });

   const [newNotificare, setNewNotificare] = useState({
    descriere: '',
    panaladata: new Date(),
    notificare: 0,
    via_email: 0,
    via_push: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
    tipNotificare: '',
  });

 
 
  useEffect(() => {


    if (selectedTermenEvent?.termen?.termen.dataTermen) {
      console.log('Updated selectedTermenEvent:', selectedTermenEvent);
  
      const rawDate = selectedTermenEvent.termen.termen.dataTermen;
      const parsedDate = parse(rawDate, 'yyyy-MM-dd HH:mm:ss', new Date());
  
      setSelectedDateFinal(parsedDate);  
      console.log('selecteddateFinal', format(parsedDate, 'dd.MM.yyyy'));  
    }

    
  }, [selectedTermenEvent]);
  
 
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); 
 
const [showTerms, setShowTerms] = useState(true);
const [showFolders, setShowFolders] = useState(true);
const [showUserNotifications, setShowUserNotifications] = useState(true);
const [dataInceputNoua, setDataInceputNoua] = useState(null);
const [dataSfarsitNoua, setDataSfarsitNoua] = useState(null);

const fetchUsers = async () => {
  try {
    const response = await fetch(`${window.API_URL}users?users_from_all_common_groups=1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    const usersData = data.data.users;

    // Extrag toate grupurile unice și includ 'Fără grup'
    const allGroups = { 0: 'FĂRĂ GRUP' }; // 0 pentru utilizatorii fără grup
    usersData.forEach((user) => {
      user.groups.forEach((group) => {
        allGroups[group.id] = group.name;
      });
    });

    // Opțiunile pentru Autocomplete
    const options = [];

    // Adăugăm grupurile ca opțiuni
    // Transformăm allGroups într-un array pentru a-l sorta
const sortedGroupIds = Object.keys(allGroups).sort((a, b) => {
  return allGroups[a].localeCompare(allGroups[b]); // Sortăm după nume
});

// Adăugăm grupurile ordonate în opțiuni
sortedGroupIds.forEach((groupId) => {
  options.push({
    type: 'group',
    id: parseInt(groupId),
    name: allGroups[groupId],
  });
});
    // Creăm o hartă a utilizatorilor pe baza grupurilor
    const usersGroupedByGroup = {};
    const addedUserIds = new Set(); // Set pentru a evita duplicatele

 // Adăugăm utilizatorii la grupurile lor
usersData.forEach((user) => {
  user.groups.forEach((group) => {
    if (!usersGroupedByGroup[group.id]) {
      usersGroupedByGroup[group.id] = [];
    }
    // Adaugă un obiect de utilizator pentru fiecare grup
    usersGroupedByGroup[group.id].push({
      type: 'user',
      id: user.id,
      name: user.fullname,
      groups: [group.id], // Adăugăm doar grupul curent
    });
  });

  // Adăugăm utilizatorii fără grup în grupul "Fără grup"
  if (user.groups.length === 0) {
    if (!usersGroupedByGroup[0]) {
      usersGroupedByGroup[0] = [];
    }
    usersGroupedByGroup[0].push({
      type: 'user',
      id: user.id,
      name: user.fullname,
      groups: [0], // grupul 'Fără grup'
    });
  }
});


// Combinăm utilizatorii în opțiuni
Object.keys(usersGroupedByGroup).forEach((groupId) => {
  // Sortăm utilizatorii din fiecare grup după nume
  const sortedUsers = usersGroupedByGroup[groupId].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  sortedUsers.forEach((user) => {
    options.push(user);
  });
});

    console.log('options', options);
    setUsers(usersData);
    setGroupedOptions(options);
  } catch (error) {
    console.error('Eroare la preluarea utilizatorilor', error);
  }
};


useEffect(() => {
  fetchUsers();
}, []);


const applyFilter = () => {
  fetchEvenimenteMultiple(startDate, endDate);
};

  const fetchEvenimenteMultiple = async (startDate, endDate) => {

    let notificari = `${window.API_URL}notificari?startDate=${startDate}&endDate=${endDate}`;   
    let termeneCalendar = `${window.API_URL}termeneCalendar?startDate=${startDate}&endDate=${endDate}`;  

    if (selectedUsers.length > 0) {
      const userIds = selectedUsers.map(user => `userIds[]=${user.id}`).join('&');
      notificari += `&${userIds}`;
      termeneCalendar += `&${userIds}`;
    }
     try {
      const notificariResponse = await fetch(notificari, { 
      method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const termeneResponse = await fetch(termeneCalendar, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!notificariResponse.ok || !termeneResponse.ok) {
        console.error('Eroare la preluare date evenimente multiple');
        return;
      }

      const notificariData = await notificariResponse.json();
      const termeneData = await termeneResponse.json();

      const evenimenteNotificari = parseEvenimenteMultiple(notificariData.data);
      const evenimenteTermene = parseEvenimenteTermene(termeneData.data);
 
      const evenimente = evenimenteTermene.concat(evenimenteNotificari);

      //console.log('Evenimente totale:', evenimente);
      setEvents(evenimente);
      setLoading(false);

      setDataInceputNoua(startDate);
      setDataSfarsitNoua(endDate);
    } catch (error) {
      console.error('Eroare la preluare date evenimente multiple: ', error);
    }
  };
  const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
  const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0];
 
  useEffect(() => { 
    fetchEvenimenteMultiple(startDate, endDate);
  }, [startDate, endDate]);

  const parseEvenimenteTermene = (data) => {
    const evenimente = [];
  
    if (!data || !data.termene || !Array.isArray(data.termene)) {
      return evenimente;
    }
  
    data.termene.forEach((eveniment) => {
      const hasDataTermen = eveniment.dataTermen && eveniment.dataTermen.trim() !== '';

      let start, end;

      if (hasDataTermen) {
          // Dacă există 'dataTermen', folosim doar această dată pentru 'start'
          start = moment(eveniment.dataTermen, 'DD-MM-YYYY HH:mm').toDate();
      } else {
          // Dacă nu există 'dataTermen', folosim 'deladata' și 'panaladata' cu timp implicit
          start = eveniment.deladata ? moment(`${eveniment.deladata} 00:00`, 'YYYY-MM-DD HH:mm').toDate() : null;
          end = eveniment.panaladata ? moment(`${eveniment.panaladata} 23:59`, 'YYYY-MM-DD HH:mm').toDate() : null;
      }
    

      const evenimentTermen = {
        id: eveniment.id,
        start, // deja setat mai sus în cod
        end, // Adăugăm 'end' în obiect, va fi 'null' dacă nu există 'panaladata'
        description: `${eveniment.client} vs ${eveniment.parteAdversa}`,
        title: `${eveniment.descriere ? '🗣 ' + (eveniment.descriere === 'Atentionare Termen dosar' ? 'AT' : eveniment.descriere) + ' - ' : ''} 🏛 ${eveniment.numarDosar} - Termen la dosar`,
        tip: 'termen',
        visible: true,
        client: `${eveniment.client}`,
        parteAdversa: `${eveniment.parteAdversa}`,
    };
    
    // Adăugăm 'deladata', 'panaladata', și 'idtermen' dacă există
    if (eveniment.deladata) {
        evenimentTermen.deladata = eveniment.deladata;
    }
    
    if (eveniment.panaladata) {
        evenimentTermen.panaladata = eveniment.panaladata;
    }
    
    if (eveniment.idtermen) {
        evenimentTermen.idtermen = eveniment.idtermen;
    }
    
  
      evenimente.push(evenimentTermen);
    });
    console.log('Evenimente termene:', evenimente);
    return evenimente;
  };
  
  const parseEvenimenteMultiple = (data) => {
    const evenimente = [];
  
    if (!data || typeof data !== 'object') {
      return evenimente;
    }
  
    if (Array.isArray(data.notificariDosar)) {
      data.notificariDosar.forEach((eveniment) => {
        
        const start = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const end = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const dataInceput = moment(eveniment.dataInceput, 'DD-MM-YYYY HH:mm').toDate();
        const dataSfarsit = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
  
        const evenimentDosar = {
          id: eveniment.id,
          start,
          end,
          dataInceput,
          dataSfarsit,
          description: eveniment.numarDosar && eveniment.numarDosar.length > 4 ? 
          `${eveniment.client} vs ${eveniment.parteAdversa}` : 
          `${eveniment.client} - Dosar consultață`,
          title: eveniment.numarDosar && eveniment.numarDosar.length > 4 ? 
          `🗀 ${eveniment.descriere ? eveniment.descriere + ' - ' : ''}Număr dosar: ${eveniment.numarDosar}` : 
          `🗀 ${eveniment.descriere ? eveniment.descriere + ' - ' : ''}Număr dosar consultanță: ${eveniment.numarIntern}`,
          tip: 'dosar', 
          visible: true,
          client: `${eveniment.client}`,
          parteAdversa: `${eveniment.parteAdversa}`,
        };
      console.log('Evenimente dosar:', evenimente);
        evenimente.push(evenimentDosar);
      });
    }
  
    if (Array.isArray(data.notificariUser)) {
      data.notificariUser.forEach((eveniment) => {
        const start = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const end = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const dataInceput = moment(eveniment.dataInceput, 'DD-MM-YYYY HH:mm').toDate();
        const dataSfarsit = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
  
        const evenimentUtilizator = {
          id: eveniment.id,
          start,
          end,
          dataSfarsit,
          dataInceput,
          title: `🗣 ${eveniment.descriere}`,
          description: '',
          tip: 'user', 
          visible: true,
        };
      //  console.log('Evenimente user:', evenimente);
        evenimente.push(evenimentUtilizator);
      });
    }
  
    return evenimente;
  };
  

  useEffect(() => {
    const updateEventVisibility = () => {
      const updatedEvents = events.map(event => {
        return {
          ...event,
          visible: ((event.tip === 'termen' && showTerms) ||
                    (event.tip === 'dosar' && showFolders) ||
                    (event.tip === 'user' && showUserNotifications))
        };
      });
      setEvents(updatedEvents);
    };
  
    updateEventVisibility();
  }, [showTerms, showFolders, showUserNotifications]);

  
  const renderEventContent = (eventInfo) => {
    const { event, view } = eventInfo;
    let formattedTip = '';
    let backgroundColor = '';
    let additionalClass = '';
    const currentView = view.type;
    const clientVsParte = event.extendedProps.client && event.extendedProps.parteAdversa ? ` - (${event.extendedProps.client} vs ${event.extendedProps.parteAdversa})` : '';
    
    const tooltipTitle = `${event.title}${clientVsParte}`;
  
    let parteAdversa2 = event.extendedProps.parteAdversa ?? '';
    parteAdversa2 = parteAdversa2.trim() === 'undefined' ? '' : parteAdversa2;

    const parteAdv = parteAdversa2 ? ` - (${parteAdversa2})` : '';

    const tooltipTitleAdv = `${event.title}${parteAdv}`;
    const titlu = `${event.title}${parteAdv}`; 
    
    if (!event.extendedProps.visible) {
      return null; // Ascund evenimentele care nu sunt vizibile
    }
  
    switch (event.extendedProps.tip) {
      case 'termen':
        formattedTip = 'Termen';
        backgroundColor = colorCT;
        additionalClass = 'termenEveniment';
        break;
      case 'user':
        formattedTip = 'Utilizator';
        backgroundColor = colorCU;
        additionalClass = 'userEveniment';
        break;
      case 'dosar':
        formattedTip = 'Dosar';
        backgroundColor = colorCA;
        additionalClass = 'dosarEveniment';
        break;
      default:
        formattedTip = event.extendedProps.tip;
        backgroundColor = 'gray';
        additionalClass = '';
    }
  
    if (event.extendedProps.tip === 'termen') {
      // Pentru 'termen', nu afișăm 'formattedTip'
      if (titlu.length > 20 && currentView === 'dayGridMonth') {
        return (
          <Tooltip title={tooltipTitleAdv}>
            <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
              {titlu.slice(0, 20)}...
            </div>
          </Tooltip>
        );
      }
  
      return (
        <Tooltip title={tooltipTitleAdv}>
          <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
            {titlu}
          </div>
        </Tooltip>
      );
    } else {
      // Pentru celelalte tipuri, afișăm 'formattedTip'
      if (titlu.length > 20 && currentView === 'dayGridMonth') {
        return (
          <Tooltip title={`${formattedTip}: ${tooltipTitleAdv}`}>
            <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
              {titlu.slice(0, 20)}...
            </div>
          </Tooltip>
        );
      }
  
      return (
        <Tooltip title={`${formattedTip}: ${tooltipTitleAdv}`}>
          <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
            {titlu}
          </div>
        </Tooltip>
      );
    }
  };
  
 
  const handleEventClick = async (event) => {
    try {
      console.log('Selected event:', event);
      const hasDeladataPanaladata = event.event._def.extendedProps.deladata && event.event._def.extendedProps.panaladata;

      // Stabilim 'eventId' în funcție de existența 'deladata' și 'panaladata'
      const eventId = hasDeladataPanaladata ? event.event._def.extendedProps.idtermen : event.event.id;
  
      const tipTermen = event.event._def.extendedProps.tip;
  
    if (tipTermen === 'termen') {
      const response = await fetch(`${window.API_URL}getTermen/${eventId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          console.error('Eroare la preluare date termen: ', response.statusText);
          return;
        }
  
        const { data } = await response.json();
        setSelectedTermenEvent({ termen: data });
        setSelectedTermenEventExport(event.event);

        const termenData = data?.termen?.dataTermen;
        const termenInPast = termenData && isPast(parse(termenData, 'yyyy-MM-dd HH:mm:ss', new Date()));
        setTermenTrecut(termenInPast);
 
        const formatData2 = 'yyyy-MM-dd HH:mm:ss';
        const formatData = 'dd-MM-yyyy HH:mm';
        const dataConvertitaTermen = parse(termenData, formatData2, new Date());

        const dataAfisare = format(dataConvertitaTermen, 'dd-MM-yyyy HH:mm');

        const dataConvertitaTermenFinala = parse(dataAfisare, formatData, new Date()); 


        setDataConvertitaTermen(dataConvertitaTermen);
        console.log('SelectedTermenEvent',selectedTermenEvent);
        console.log('termenData',termenData);
        console.log('termenInPast',termenInPast);
        console.log('termenTrecut',termenTrecut); 
        console.log('dataConvertitaTermen', dataConvertitaTermenFinala);

   
        console.log('aici intra4');
        fetchNotificariTermen(eventId); 
        const responseNotificari = await fetch(`${window.API_URL}notificari`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!responseNotificari.ok) {
          console.error('Eroare la preluare date notificare: ', responseNotificari.statusText);
          return;
        }
  
        const { data: notificariData } = await responseNotificari.json();
  
        setSelectedTermenEvent2({ ...event, notificare: { notificariTermen: notificariData.notificariTermen } });
       
      } else {
         
        console.log('Nu este un termen!');
        const responseNotificari = await fetch(`${window.API_URL}notificari/${eventId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!responseNotificari.ok) {
          console.error('Eroare la preluare date notificare: ', responseNotificari.statusText);
          return;
        }
  
        const { data } = await responseNotificari.json();
        setSelectedEvent({ ...event, notificare: { ...data.notificare, notificariTermen: data.notificariTermen, notificariDosar: data.notificariDosar } });
        setSelectedEventExport(event.event); 

      }
    } catch (error) {
      console.error('Eroare la preluare date notificare: ', error);
    }
  };

 

  const fetchNotificariTermen = async (termenId) => {
    try {
      const response = await fetch(`${window.API_URL}getTermen/${termenId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la preluare date notificare: ', response.statusText);
        return;
      }
      console.log(response);
      const { data } = await response.json();
      setSelectedTermenEvent2((prevEvent) => {
        if (prevEvent && prevEvent.termen) {
          return {
            ...prevEvent,
            termen: {
              ...prevEvent.termen,
              notificariTermen: data.notificariTermen,
            },
          };
        }
        return prevEvent;
      });
      setSelectedTermenEvent((prevEvent) => {
        if (prevEvent && prevEvent.termen) {
          return {
            ...prevEvent,
            termen: {
              ...prevEvent.termen,
              notificariTermen: data.notificariTermen,
            },
          };
        }
        return prevEvent;
      });
      
    } catch (error) {
      console.error('Eroare la preluare date notificare: ', error);
    }
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setSelectedTermenEvent(null); 
    setAdaugareEvenimentUtilizatorOpen(null);
    setAdminModalOpen(null);
     
    fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
  };

  const handleCreateNotificare = async (tipEveniment) => {
    try {
      let tipNotificare;
  
      if (tipEveniment === 'termen') {
        tipNotificare = 'termen';
      } else if (tipEveniment === 'dosar') {
        tipNotificare = 'dosar';
      } else {
        tipNotificare = 'user';
      }
      const idTermen2 = selectedTermenEvent?.termen?.termen.tipTermen === 'termen' ? selectedTermenEvent?.termen?.termen.id : null;
      const idTermen = selectedEvent?.notificare?.tip === 'termen' ? selectedEvent?.notificare?.idTermen : null;
      const idDosar = selectedEvent?.notificare?.tip === 'dosar' ? selectedEvent?.notificare?.idDosar : null;

      const notificareData = {
        descriere: newNotificare.descriere,
        panaladata: newNotificare.panaladata,
        notificare: newNotificare.notificare,
        via_email: newNotificare.via_email,
        via_push: newNotificare.via_push,
        via_sms: newNotificare.via_sms,
        reminder: newNotificare.reminder,
        recurent: newNotificare.recurent,
        tipNotificare,
        ...(idTermen !== null && { idTermen }), // daca am idTermen de pe api get /notificari pun valoarea idTermen - dar nu il mai folsoesc sa il am preventiv
        ...(idTermen2 !== null && { idTermen: idTermen2 }), // daca am idTermen de pe get /termeneCalendar atunci ii pun idTermen setat de idTermen2
        ...(idDosar !== null && { idDosar }), // daca am notificare pe dosar atunci setez idDosar cu valoarea id-ului dosarului
      };

      console.log(idTermen2);
      // Filtrarea prop obiectului pentru a elimina cele cu valoare null
      const filteredNotificareData = Object.entries(notificareData).reduce((acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});
  
      // creare notificare cu tipul corespunzator
      const response = await fetch(`${window.API_URL}notificari`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filteredNotificareData),
      });
  
      if (!response.ok) {
        console.error('Eroare la crearea notificării: ', response.statusText);
        return;
      }
  
      // Actualizare evenimente
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
      

      if (tipEveniment === 'termen' && selectedTermenEvent?.termen?.termen.id) {
        fetchNotificariTermen(selectedTermenEvent?.termen?.termen.id);   
      } 

      if (selectedEvent?.notificare?.id) {
        fetchNotificariDosar();
      }
      // închidere modal de creare notificare
      handleCreateModalClose();
    } catch (error) {
      console.error('Eroare la crearea notificării: ', error);
    }
    
  };
  
  
  const handleDeleteNotificare = (idNotificare) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteNotificareOk(idNotificare);
      }
    });
  };

  const handleDeleteNotificareOk = async (idNotificare) => {
    try {
      // Ștergere notificare
      const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la ștergerea notificării: ', response.statusText);
        return;
      }
      
      // Actualizare evenimente
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
      await fetchNotificariDosar(); 
      
    } catch (error) {
      console.error('Eroare la ștergerea notificării: ', error);
    }
  };

  const handleDeleteNotificareTermen = (idNotificare, eveniment) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteNotificareTermenOk(idNotificare, eveniment);
      }
    });
  };
  const handleDeleteNotificareTermenOk = async (idNotificare, eveniment) => {
    try {
      // Ștergere notificare
      const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la ștergerea notificării: ', response.statusText);
        return;
      }
  
      // Actualizare evenimente
      
      console.log('aici intra');
      await fetchNotificariTermen(eveniment);
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
    } catch (error) {
      console.error('Eroare la ștergerea notificării: ', error);
    }
  };
  
  
  const handleEditNotificare = async (idNotificare, updates) => {
    try {
      const id = idNotificare;
  
      const response = await fetch(`${window.API_URL}notificari/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updates),
      });
  
      if (!response.ok) {
        console.error('Eroare la editarea notificării: ', response.statusText);
        return;
      }
  
      // Actualizare evenimente
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
      await fetchNotificariDosar();
    } catch (error) {
      console.error('Eroare la editarea notificării: ', error);
    }
  };
  


  const handleSaveEdit = async () => {
    try {
      const updates = {
        id: notificareId,
        descriere: editedNotificare.descriere,
        panaladata: editedNotificare.panaladata,
        notificare: editedNotificare.notificare,
        via_email: editedNotificare.via_email,
        via_push: editedNotificare.via_push,
        via_sms: editedNotificare.via_sms,
        reminder: editedNotificare.reminder,
        recurent: editedNotificare.recurent,
      };
  
      console.log('Valorile notificării actualizate:', updates);
  
      const response = await fetch(`${window.API_URL}notificari/${updates.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updates),
      });
  
      if (!response.ok) {
        console.error('Eroare la salvarea editării notificării: ', response.statusText);
        return;
      }
  
      console.log('Notificarea a fost actualizată cu succes.');
  
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
      fetchUserNotificari();
      await fetchNotificariDosar();
      setEditModalOpen(false);
    } catch (error) {
      console.error('Eroare la salvarea editării notificării: ', error);
    }
  };
  
  
  const handleSaveEditTermen = async (eveniment) => {
    try {
      const updates = {
        id: notificareId,
        descriere: editedNotificare.descriere,
        panaladata: editedNotificare.panaladata,
        notificare: editedNotificare.notificare,
        via_email: editedNotificare.via_email,
        via_push: editedNotificare.via_push,
        via_sms: editedNotificare.via_sms,
        reminder: editedNotificare.reminder,
        recurent: editedNotificare.recurent,
      };
  
      console.log('Valorile notificării actualizate:', updates);
  
      const response = await fetch(`${window.API_URL}notificari/${updates.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updates),
      });
  
      if (!response.ok) {
        console.error('Eroare la salvarea editării notificării: ', response.statusText);
        return;
      }
  
      console.log('Notificarea a fost actualizată cu succes.');
  
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);

      if (selectedEvent?.notificare?.id) {
        fetchNotificariDosar();
      }

      console.log('aici intra2');
      if (eveniment) {
        await fetchNotificariTermen(eveniment);
      }
      
      if (adminModalOpen) {
        fetchUserNotificari();
      }

      setEditModalOpen(false); 

    } catch (error) {
      console.error('Eroare la salvarea editării notificării: ', error);
    }
  };


  const handleInputChange = (event) => {
    // Pentru inputurile standard, precum descrierea
    if (event.target) {
      const { name, value } = event.target;
      setEditedNotificare(prev => ({
        ...prev,
        [name]: value
      }));
      setNewNotificare(prev => ({
        ...prev,
        [name]: value
      }));
    }
};
  

  const handleCreateModalOpen = () => {
    setCreateModalOpen(true); 
    setEditedNotificare({
      descriere: '',
      panaladata: '',
      notificare: 0,
      via_email: 0,
      via_push: 0,
      via_sms: 0,
      reminder: 1,
      recurent: 0,
      tipNotificare: '',
    });
	setNewNotificare({
      descriere: '', 
      panaladata: format(new Date(), 'dd.MM.yyyy'),
      notificare: 0,
      via_email: 0,
      via_push: 0,
      via_sms: 0,
      reminder: 1,
      recurent: 0,
      tipNotificare: '',
    });
  };

  const handleCreateModalOpen2 = () => {
    setCreateModalOpen2(true);  
    setNewNotificare({
        descriere: '',
        panaladata: getCurrentDate(),
        notificare: 0,
        via_email: 0,
        via_push: 0,
        via_sms: 0,
        reminder: 1,
        recurent: 0,
        tipNotificare: '',
      });
    };
  
 
  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
    setCreateModalOpen2(false);
    fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
  };

  const handleEditModalOpen = async (idNotificare) => {
    try {
      const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error('Eroare la preluare date notificare: ', response.statusText);
        return;
      }
  
      const { data } = await response.json();
  
      setEditedNotificare({
        ...editedNotificare,
        id: data.notificare.id,
        descriere: data.notificare.descriere || '',
        panaladata: data.notificare.panaladata || '',
        notificare: data.notificare.notificare || 0,
        via_email: data.notificare.via_email || 0,
        via_push: data.notificare.via_push || 0,
        via_sms: data.notificare.via_sms || 0,
        reminder: data.notificare.reminder || 1,
        recurent: data.notificare.recurent || 0,
      });
      setSelectedNotificareId(idNotificare);
      setEditModalOpen(true);
   
      if (selectedEvent?.notificare?.id) {
        fetchNotificariDosar();
      }
        
    } catch (error) {
      console.error('Eroare la preluare date notificare: ', error);
    }
  };
  
  const handleEditModalOpenTermen = async (idNotificare, eveniment) => {
   console.log('N', idNotificare);
   console.log('E', eveniment);
   try {
    const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error('Eroare la preluare date notificare: ', response.statusText);
      return;
    }
    console.log(response);
    const { data } = await response.json();

    setEditedNotificare({
      ...editedNotificare,
      id: data.notificare.id,
      descriere: data.notificare.descriere || '',
      panaladata: data.notificare.panaladata || '',
      notificare: data.notificare.notificare || 0,
      via_email: data.notificare.via_email || 0,
      via_push: data.notificare.via_push || 0,
      via_sms: data.notificare.via_sms || 0,
      reminder: data.notificare.reminder || 1,
      recurent: data.notificare.recurent || 0,
    });
    setSelectedNotificareId(idNotificare);
    setEditModalOpen(true);

    console.log('aici intra3');
    await fetchNotificariTermen(eveniment);
    fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);

  } catch (error) {
    console.error('Eroare la preluare date notificare: ', error);
  }

  };
  
  
   
 
  const handleEditModalClose = () => {
    setEditModalOpen(false); 
    fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua);
  };

  
  
  const handleExportToGoogleCalendar = () => {
    if (selectedTermeneExport) {
      const formattedStart = selectedTermeneExport.start.toISOString().replace(/-|:|\.\d{3}/g, '');

      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(selectedTermeneExport.title)}&dates=${formattedStart}/${formattedStart}&details=${encodeURIComponent(selectedTermeneExport.description)}`;
     window.open(googleCalendarUrl, '_blank');
    }
  };
  const handleExportToIosCalendar = () => {
    if (selectedTermeneExport) {
      const iCalEvent = {
        title: selectedTermeneExport.title,
        start: selectedTermeneExport.start, 
        description: selectedTermeneExport.description
      };

      const iCalData = encodeURIComponent([
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${window.location.href}`,
        `DTSTART:${moment(iCalEvent.start).format('YYYYMMDDTHHmmss')}`, 
        `SUMMARY:${iCalEvent.title}`,
        `DESCRIPTION:${iCalEvent.description}`,
        'END:VEVENT',
        'END:VCALENDAR'
      ].join('\n'));

      const iCalBlob = new Blob([iCalData], { type: 'text/calendar;charset=utf-8' });
      const iCalUrl = URL.createObjectURL(iCalBlob);
      const link = document.createElement('a');
      link.href = iCalUrl;
      link.download = 'event.ics';
      link.click();
    }
  };

  const handleExportToGoogleCalendar2 = () => {

    if (selectedEventExport) {
      const dataInceput = selectedEventExport._def.extendedProps.dataInceput;
      const dataSfarsit = selectedEventExport._def.extendedProps.dataSfarsit;

      const formattedStart = dataInceput.toISOString().replace(/-|:|\.\d{3}/g, '');
      const formattedEnd = dataSfarsit.toISOString().replace(/-|:|\.\d{3}/g, '');
 
      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(selectedEventExport.title)}&dates=${formattedStart}/${formattedEnd}&details=${encodeURIComponent(selectedEventExport.description)}`;
   
      window.open(googleCalendarUrl, '_blank');
    }
  };
  const handleExportToIosCalendar2 = () => {
    if (selectedEventExport) {
      const iCalEvent = {
        title: selectedEventExport.title,
        start: selectedEventExport.start, 
        description: selectedEventExport.description
      };

      const iCalData = encodeURIComponent([
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${window.location.href}`,
        `DTSTART:${moment(iCalEvent.start).format('YYYYMMDDTHHmmss')}`, 
        `SUMMARY:${iCalEvent.title}`,
        `DESCRIPTION:${iCalEvent.description}`,
        'END:VEVENT',
        'END:VCALENDAR'
      ].join('\n'));

      const iCalBlob = new Blob([iCalData], { type: 'text/calendar;charset=utf-8' });
      const iCalUrl = URL.createObjectURL(iCalBlob);
      const link = document.createElement('a');
      link.href = iCalUrl;
      link.download = 'event.ics';
      link.click();
    }
  };


  
  const dayCellContent = (args) => {
    const cellDate = args.date;
    const isHovered = cellDate === hoveredDate; 
    return (
      <div
        onMouseEnter={() => handleCellMouseEnter(args)}
        onMouseLeave={handleCellMouseLeave}
      >
        
        {args.dayNumberText}
        {isHovered && (
          
          <button onClick={() => handleAddEvent(cellDate)}>Adaugă eveniment</button>
        )}
      </div>
    );
  };
  
  const handleDateMouseEnter = (date) => {
    setHoveredDate(date);
  };

  const handleDateMouseLeave = () => {
    setHoveredDate(null);
  };
  const handleAddEvent = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
  
    setNewNotificare({
      descriere: '',
      panaladata: formattedDate,
      notificare: 0,
      via_email: 0,
      via_push: 0,
      via_sms: 0,
      reminder: 1,
      recurent: 0,
      tipNotificare: 'user',
    });
  
    setAdaugareEvenimentUtilizatorOpen(true);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${window.API_URL}notificari`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newNotificare),
      });
      if (!response.ok) throw new Error('Eroare la adăugarea notificării');
      const responseData = await response.json();
      console.log(responseData);
      setAdaugareEvenimentUtilizatorOpen(false);
      fetchEvenimenteMultiple(dataInceputNoua, dataSfarsitNoua); // reincarcare evenimente
      fetchUserNotificari();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwitchChange = (event) => {
    setNewNotificare({
      ...newNotificare,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleCellMouseEnter = (args) => {
    setHoveredDate(args.date);
    console.log('Hovered Date:', args.date);
  };
  const handleCellMouseLeave = () => {
    setHoveredDate(null);
  };
  


  // aduc notificarile utilizatorului
const fetchUserNotificari = async () => {
  try {
    const response = await fetch(`${window.API_URL}notificariUser`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error('Eroare la preluare notificări utilizator');
      return;
    }

    const data = await response.json();
    setUserNotificari(data.data.notificari);
  } catch (error) {
    console.error('Eroare la preluare notificări utilizator: ', error);
  }
};

// initiez functia de aducere notificari
useEffect(() => {
  if (adminModalOpen) {
    fetchUserNotificari();  
  }
}, [adminModalOpen]);

// administrez modalele pe utilizator
const handleAdminModalOpen = () => {
  setAdminModalOpen(true);
};

 

const handleDelete = (id) => {
  Swal({
    title: 'Sigur doriți ștergerea?',
    text: 'Această acțiune nu poate fi anulată!',
    icon: 'warning',
    buttons: ['Anulează', 'Da, șterge!'],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      handleDeleteOk(id);
    }
  });
};

const handleDeleteOk = async (id) => {
  try {
    const response = await fetch(`${window.API_URL}notificari/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.ok) {
 
      const updatedNotificari = userNotificari.filter(notif => notif.id !== id);

      setUserNotificari(updatedNotificari);
      
      Swal("Operațiune realizată cu succes!", "", "success");
      
      handleCloseModal();
      


      fetchNotificariDosar();
    } else {
      console.error(`Failed to delete notification with id ${id}`);
    }
  } catch (error) {
    console.error(`Error deleting notification with id ${id}: `, error);
  }
}
 
const fetchNotificariDosar = async () => {
  try {
    const response = await fetch(`${window.API_URL}notificari/${selectedEvent?.notificare.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error('Eroare la preluare notificări dosar: ', response.statusText);
      return;
    }

    const { data } = await response.json();
      console.log("modif2", data);
    // actualizez lista de notificari pentru dosar
    setSelectedEvent((prevEvent) => ({
      ...prevEvent,
      notificare: {
        ...prevEvent.notificare,
        notificariDosar: data.notificariDosar,
      },
    }));
  } catch (error) {
    console.error('Eroare la preluare notificări dosar: ', error);
  }
};

const filteredEvents = events
  .filter(event => 
    ((event.tip === 'termen' && showTerms) ||
    (event.tip === 'dosar' && showFolders) ||
    (event.tip === 'user' && showUserNotifications)) &&
    event.title.toLowerCase().includes(filterText.toLowerCase())
  )
  .sort((a, b) => {
    const isAAtentionare = a.title.includes('🗣 AT -');
    const isBAtentionare = b.title.includes('🗣 AT -');

    if (isAAtentionare && !isBAtentionare) {
      return 1; // Mutăm 'AT' la final
    } else if (!isAAtentionare && isBAtentionare) {
      return -1; // Lăsăm celelalte înainte
    } else {
      return 0; // Nu schimbăm ordinea pentru alte evenimente
    }
  });

const handleDateChange = (newValue) => {
  const newDateString = format(newValue, 'dd.MM.yyyy');
  setNewNotificare({ ...newNotificare, panaladata: newDateString });
  
};
const handleDateChangeNou = (newValue) => {
  setNewNotificare({ ...newNotificare, panaladata: newValue });
};

const handleDateChangeTermen = (newValue) => {
  const newDateStringForPanaladata = format(newValue, 'yyyy-MM-dd');
  setNewNotificare({ ...newNotificare, panaladata: newDateStringForPanaladata });
  setSelectedDateFinal(newValue);  
  
};


if (isLoading) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh" className="container card p-3 mt-5">
      <CircularProgress />
    </Box>
  );
}


  return (
    <div className="container card p-3 mt-5">
    
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> 
    <div style={{ backgroundColor: preferinte.colorCT, width: '20px', height: '20px' }}></div>
          <p>
          🏛 Termen 
            <Tooltip title={showTerms ? "Ascundeți evenimentele de tip Termen" : "Arătați evenimentele de tip Termen"} arrow>
            <Switch 
              type="checkbox" 
              checked={showTerms} 
              onChange={() => setShowTerms(!showTerms)} 
            />
            </Tooltip>
          </p>

          <div style={{ backgroundColor: preferinte.colorCA, width: '20px', height: '20px' }}></div>
          <p>
          🗀 Dosar 
            <Tooltip title={showFolders ? "Ascundeți evenimentele de tip Dosar" : "Arătați evenimentele de tip Dosar"} arrow>
            <Switch 
              type="checkbox" 
              checked={showFolders} 
              onChange={() => setShowFolders(!showFolders)} 
            />
            </Tooltip>
          </p>

          <div style={{ backgroundColor: preferinte.colorCU, width: '20px', height: '20px' }}></div>
          <p>
          🗣 Utilizator 
            <Tooltip title={showUserNotifications ? "Ascundeți evenimentele de tip Utilizator" : "Arătați evenimentele de tip Utilizator"} arrow>
            <Switch 
              type="checkbox" 
              checked={showUserNotifications} 
              onChange={() => setShowUserNotifications(!showUserNotifications)} 
            />
            </Tooltip>
          </p>
 
          </div>

          
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
    
     <TextField
            label="Filtrare rapidă evenimente"
            variant="outlined"
            size="small"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginRight: '10px' }}  
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Administrare evenimentele proprii">
            <Button startIcon={<SettingsIcon />} variant="outlined" color="primary" size="small" onClick={handleAdminModalOpen}>
              Evenimente Proprii
            </Button>
          </Tooltip>
          <Tooltip title="Adăugare eveniment propriu">
            <Button startIcon={<AddIcon />}  variant="outlined" color="secondary" size="small" onClick={handleAddEvent}>
              Eveniment Propriu
            </Button>
          </Tooltip>

          <HelpDialog
            title="EVENIMENTE ÎN CALENDAR"
            contentType="video"
            content="https://www.ctce.ro/important/juristpronext/tutoriale/notificari-calendar.mp4" 
            tooltip="Ajutor: Cum se adaugă evenimente în calendar?"
          />  


        </div>
      </div>
      
      
{areDrept(AdaugareUtilizatoriDrept) ? (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}> 
<Autocomplete
  multiple
  id="tags-outlined"
  options={groupedOptions}
  groupBy={(option) => {
    // Dacă este utilizator, trebuie să-i grupăm în fiecare grup în care se află
    if (option.type === 'user') {
      // Iterăm prin fiecare grup al utilizatorului și găsim grupul corespunzător
      const userGroups = option.groups.map(groupId => {
        const group = groupedOptions.find(opt => opt.type === 'group' && opt.id === groupId);
        return group ? group.name : 'Fără grup';
      });
      return userGroups.length > 0 ? userGroups[0] : 'Fără grup';
    } else {
      return 'Grupuri'; // Grupăm opțiunile de tip grup împreună
    }
  }}
  
  getOptionLabel={(option) => option.name}
  filterOptions={(options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter(option => option.name.toLowerCase().includes(inputValue));
  }}
  onChange={(event, newValue) => {
    const selectedUsers = [];
    const selectedUserIds = new Set();
  
    newValue.forEach(option => {
      if (option.type === 'user') {
        // Adăugăm utilizatorul dacă nu este deja selectat
        if (!selectedUserIds.has(option.id)) {
          selectedUsers.push(option);
          selectedUserIds.add(option.id);
        }
      } else if (option.type === 'group') {
        if (option.id === 0) { // ID pentru 'Fără grup'
          // Adăugăm toți utilizatorii fără grup
          const usersWithoutGroup = users.filter(user => user.groups.length === 0);
          usersWithoutGroup.forEach(user => {
            if (!selectedUserIds.has(user.id)) {
              selectedUsers.push({
                type: 'user',
                id: user.id,
                name: user.fullname,
                groups: [0], // grupul 'Fără grup'
              });
              selectedUserIds.add(user.id);
            }
          });
        } else if (users && users.length > 0) {
          // Obținem utilizatorii din grupul selectat
          const usersInGroup = users.filter(user =>
            user.groups.some(group => group.id === option.id)
          );
          console.log('Users in group:', usersInGroup);
          usersInGroup.forEach(user => {
            if (!selectedUserIds.has(user.id)) {
              selectedUsers.push({
                type: 'user',
                id: user.id,
                name: user.fullname,
                groups: user.groups.map(group => group.id),
              });
              selectedUserIds.add(user.id);
            }
          });
        }
      }
    });
  
    // Setăm utilizatorii selectați
    setSelectedUsers(selectedUsers);
  }}
 
  
  
  renderOption={(props, option) => {
    const userGroupsString = option.type === 'user' ? option.groups.join(',') : '';
    const key = option.type === 'user'
      ? `${option.id}-${userGroupsString}-${props['data-option-index']}` // Cheie unică
      : `group-${option.id}`;

    return (
      <li {...props} key={key}>
        {option.type === 'group' ? `Grup: ${option.name}` : option.name}
      </li>
    );
  }}
renderTags={(value, getTagProps) =>
  value.map((option, index) => (
    <Chip label={option.name} {...getTagProps({ index })} />
  ))
}

  renderInput={(params) => (
    <TextField
      {...params}
      label="Selectați utilizatori sau grupuri"
      placeholder="Utilizatori sau grupuri"
      fullWidth
    />
  )}
  size="small"
  className="mt-3"
  sx={{ flex: 1 }}
/>




        <Button variant="contained"  color="primary" onClick={() => fetchEvenimenteMultiple(startDate, endDate)} sx={{ mt: 2, mr: 1 }} type="button">       
          Aplicare filtru
        </Button>
    </Box>
  ) : null }

<hr></hr> 
<FullCalendar 
    ref={calendarRef}
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]} 
    //initialView="dayGridMonth" 
    initialView={view}
    initialDate={new Date()}
    events={filteredEvents}
    eventOrder="title,-start" 
    eventClick={handleEventClick} 
    eventMouseEnter={handleDateMouseEnter}
    eventMouseLeave={handleDateMouseLeave} 
    dayCellContent={dayCellContent}
    datesSet={(dateInfo) => {
      const startDate = dateInfo.startStr.split('T')[0];
      const endDate = dateInfo.endStr.split('T')[0];
      fetchEvenimenteMultiple(startDate, endDate);  
    }}
    locale={roLocale}
    eventContent={renderEventContent}
    dayMaxEventRows={3} // afisez 3 evenimente pe zi 
    customButtons={{
      myDayButton: {
        text: 'Zi',
        click: () => handleViewChange('listDay')
      },
      myWeekButton: {
        text: 'Săptămână',
        click: () => handleViewChange('listWeek')
      },
      myMonthButton: {
        text: 'Lună',
        click: () => handleViewChange('dayGridMonth')
      }
    }}
    headerToolbar={{
      left: 'myMonthButton,myWeekButton,myDayButton', //timeGridWeek,timeGridDay,
      center: 'title',
      right: 'prev,next,today'
    }}
  />

<Dialog open={adminModalOpen} onClose={handleCloseModal} fullWidth maxWidth="lg">
  <DialogTitle className='titluModal mb-3'>

  <Typography variant="h6">Evenimente proprii</Typography>
  <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          
  </DialogTitle>
  <DialogContent>
        
          
            
  <TableContainer style={{ maxHeight: '350px' }}>
  <Table stickyHeader>
    <TableHead>
      <TableRow>
        <TableCell>Descriere</TableCell>
        <TableCell className='text-center'>Începând cu data</TableCell>
        <TableCell className='text-center'>Până la data</TableCell>
        <TableCell className='text-center'>Notificare</TableCell>
        <TableCell className='text-center'>SMS</TableCell>
        <TableCell className='text-center'>Email</TableCell>
        <TableCell className='text-center'>Push</TableCell>
        <TableCell className='text-center'>Reminder</TableCell>
        <TableCell className='text-center'>Reamintire zilnică</TableCell>
        <TableCell className='text-center'>Acțiuni</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {userNotificari.map((notif, idx) => (
        <TableRow key={idx}>
          <TableCell>{notif.descriere}</TableCell>
          <TableCell className='text-center'>{moment(notif.dataInceput, "DD-MM-YYYY HH:mm").format('DD.MM.YYYY')}</TableCell>
          <TableCell TableCell className='text-center'>{moment(notif.dataSfarsit, "DD-MM-YYYY HH:mm").format('DD.MM.YYYY')}</TableCell>
          <TableCell className='text-center'>{notif.notificare === 1 ? "Da" : "Nu"}</TableCell>
          <TableCell className='text-center'>{notif.via_sms === 1 ? "Da" : "Nu"}</TableCell>
          <TableCell className='text-center'>{notif.via_email === 1 ? "Da" : "Nu"}</TableCell>
          <TableCell className='text-center'>{notif.via_push === 1 ? "Da" : "Nu"}</TableCell>
          <TableCell className='text-center'>{notif.reminder === 1 ? "1 zi" : `${notif.reminder} zile`}</TableCell>
          <TableCell className='text-center'>{notif.recurent === 1 ? "Da" : "Nu"}</TableCell> 
          <TableCell>
            <Tooltip title="Editare" arrow>
              <IconButton onClick={() => handleEditModalOpen(notif.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ștergere" arrow>
              <IconButton onClick={() => handleDelete(notif.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>              
            
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer> 
  </DialogContent>
</Dialog>
 

      <Dialog open={adaugareEvenimentUtilizatorOpen} onClose={() => setAdaugareEvenimentUtilizatorOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle className='titluModal mb-3'>
       <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <h4>Adăugare eveniment propriu</h4>
      </DialogTitle>
       
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField fullWidth label="Descriere" required name="descriere" value={newNotificare.descriere} onChange={handleInputChange} />
           </Box>
            <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Dată" 
                value={newNotificare.panaladata ? new Date(newNotificare.panaladata) : new Date()}
                onChange={handleDateChangeNou}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="dd.MM.yyyy" 
                format="dd.MM.yyyy" 
                mask="__.__.____"
                name="panaladata" 
                className='maxWidth mt-2' 
              />
            </LocalizationProvider>

            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box>
              <Typography variant="subtitle2">Notificare</Typography>
              <FormControlLabel control={<Switch checked={newNotificare.notificare} onChange={handleSwitchChange} name="notificare"  />}   />
             </Box>
             <Box>
              <Typography variant="subtitle2">SMS</Typography>
              <FormControlLabel control={<Switch checked={newNotificare.via_sms} onChange={handleSwitchChange} name="via_sms" disabled={newNotificare.notificare !== 1} />} />
             </Box>
             <Box>
              <Typography variant="subtitle2">E-Mail</Typography>
              <FormControlLabel control={<Switch checked={newNotificare.via_email} onChange={handleSwitchChange} name="via_email" disabled={newNotificare.notificare !== 1} />}  />
             </Box>
             <Box>
              <Typography variant="subtitle2">Push</Typography>
              <FormControlLabel control={<Switch checked={newNotificare.via_push} onChange={handleSwitchChange} name="via_push" disabled={newNotificare.notificare !== 1} />}  />
             </Box>
             </Box>
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
              <InputLabel id="reminder-label">Reminder</InputLabel>
              <Select  labelId="reminder-label" name="reminder" value={newNotificare.reminder} onChange={handleInputChange}>
                <MenuItem value={1}>1 zi</MenuItem>
                <MenuItem value={3}>3 zile</MenuItem>
                <MenuItem value={7}>7 zile</MenuItem>
                <MenuItem value={14}>14 zile</MenuItem>
                <MenuItem value={21}>21 zile</MenuItem>
                <MenuItem value={30}>30 zile</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Repetare zilnică</Typography>
            <FormControlLabel control={<Switch checked={newNotificare.recurent} onChange={handleSwitchChange} name="recurent" />} label="Recurent" />
            </Box>
            <input type="hidden" name="tipNotificare" value={newNotificare.tipNotificare} />

            
        </DialogContent>
        <DialogActions>
        <Button type="submit" variant="contained" color="success" fullWidth>
              Trimite
            </Button>
        </DialogActions>
        </form>
    </Dialog>


<Dialog id="modalTermene" open={selectedTermenEvent !== null} onClose={handleCloseModal} maxWidth="lg" fullWidth>
  <DialogTitle className='titluModal'>
    Termen {selectedTermenEvent?.termen?.termen.dataTermen ? format(parseISO(selectedTermenEvent?.termen?.termen.dataTermen), 'dd.MM.yyyy hh:mm') : ''} dosar {selectedTermenEvent?.termen?.termen.numarDosar} ({selectedTermenEvent?.termen?.termen.userName})
    <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
    <DialogContent className='mt-3'>
    <div className="headerTermen">
      <div>
        <p>Soluție:</p>
        <p style={{maxHeight:'100px', overflow:'auto', border:'1px solid #CCC', background:'#FAFAFA', padding:'10px', marginBottom:'10px'}}>{selectedTermenEvent?.termen?.termen.solutie}</p>
        <table className='tabelInfoDosar'>
  <tbody>
  <tr>
      <td>Sumar soluție:</td>
      <td>{selectedTermenEvent?.termen.termen?.sumarSolutie}</td>
    </tr>
    <tr>
      <td>Complet:</td>
      <td>{selectedTermenEvent?.termen.termen?.complet}</td>
    </tr>
    <tr>
      <td>Dată termen:</td>
      <td>{selectedTermenEvent?.termen?.termen.dataTermen ? format(parseISO(selectedTermenEvent?.termen?.termen.dataTermen), 'dd.MM.yyyy hh:mm') : ''}</td> 
    </tr>
    <tr>
      <td>Număr dosar:</td>
      <td>
        <a target='_blank' href={`/editare-dosar/${selectedTermenEvent?.termen?.termen.idDosar}`}>
          {selectedTermenEvent?.termen?.termen.numarDosar}
        </a>
      </td>
    </tr> 
    <tr>
      <td>Client dosar:</td>
      <td>{selectedTermenEvent?.termen?.termen.clientDosar}</td>
    </tr> 
    <tr>
      <td>Parte adversă:</td>
      <td>{selectedTermenEvent?.termen?.termen.parteAdversa}</td>
    </tr>
    <tr>
      <td>Instanță:</td>
      <td>{selectedTermenEvent?.termen?.termen.instanta}</td>
    </tr>
  </tbody>
</table>

      </div>


      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        size="small"
                        className="google-button"
                        onClick={handleExportToGoogleCalendar}
                        style={{ marginRight: '10px' }}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faGoogle} />
                        </Icon>
                        <Typography variant=" ">Export în Google Calendar</Typography>
                      </Button> 

                      <Button
                        variant="contained"
                        size="small"
                        className="ios-button"
                        onClick={handleExportToIosCalendar}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faApple} />
                        </Icon>
                        <Typography variant=" ">Export în iOS Calendar</Typography>
                      </Button>

                    </Box>

    </div>
    <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
        <Grid item>
        <Typography variant="body1">Administrare notificări pe termen</Typography>
      </Grid>
      <Grid item> 
      
      { termenTrecut  ? (
          <Alert severity="warning">Nu se mai pot adăuga atenționări pentru termene trecute.</Alert>
        ) : (
          <Button variant="contained" color="warning"  size='small' startIcon={<AddCircleOutlineIcon />} onClick={handleCreateModalOpen2}>
            Adăugare notificare pentru termen
          </Button>
        )}
      </Grid>
      </Grid>
  
      {selectedTermenEvent?.termen?.notificariTermen && selectedTermenEvent.termen.notificariTermen.length > 0 ? (
            <TableContainer style={{maxHeight:'300px'}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow> 
                    <TableCell>Descriere</TableCell>
                    <TableCell className='text-center'>De la data</TableCell>
                    <TableCell className='text-center'>Până la data</TableCell>                              
                    <TableCell className="text-center">Notificare SMS/Email?</TableCell>
                    <TableCell className="text-center">Înainte cu câte zile</TableCell>
                    <TableCell className="text-center">În fiecare zi?</TableCell>

                    <TableCell className='text-center'>Acțiuni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                 {selectedTermenEvent?.termen?.notificariTermen.map((notificare) => (
                    <TableRow key={notificare.id}>  
                      <TableCell>{notificare.descriere}</TableCell>
                      <TableCell className='text-center'>{notificare.deladata}</TableCell>
                      <TableCell className='text-center'>{notificare.panaladata}</TableCell>
                      
                      <TableCell className='text-center'>{notificare.notificare === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.reminder === 1 ? "1 zi" : `${notificare.reminder} zile`}</TableCell>
                      <TableCell className='text-center'>{notificare.recurent === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>
                      <Tooltip title="Editare" arrow>
                        <IconButton onClick={() => handleEditModalOpenTermen(notificare.id, selectedTermenEvent?.termen?.termen.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ștergere" arrow>
                      <IconButton onClick={() => handleDeleteNotificareTermen(notificare.id, selectedTermenEvent?.termen?.termen.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>              
                 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> 

      ) : (
        selectedTermenEvent?.termen?.notificariTermen ? (
        <div className='text-center'>
              <Alert severity="info">Nu există notificări definite pentru acest termen.</Alert>
         </div>
         ) : null
     )}
    <Button variant=" " className="pull-right mt-4" size="small" onClick={handleCloseModal}>
      Închidere eveniment
    </Button>
  </DialogContent>
   
   
</Dialog>
 
<Dialog open={editModalOpen} onClose={handleEditModalClose} fullWidth maxWidth="sm">
    
    <DialogTitle className='titluModal mb-3'>
       <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleEditModalClose}>
            <CloseIcon />
          </IconButton>

          <Typography variant="subtitle1">Editare notificare</Typography>
    </DialogTitle>
        <DialogContent>
      

          <TextField
            label="Descriere"
            variant="outlined"
            name="descriere"
            value={editedNotificare.descriere}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />

 
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Dată notificare"
              value={editedNotificare.panaladata ? parse(editedNotificare.panaladata, 'yyyy-MM-dd', new Date()) : null}
              onChange={(newValue) => {
                setEditedNotificare(prev => ({
                  ...prev,
                  panaladata: newValue ? format(newValue, 'yyyy-MM-dd') : null  
                }));
              }}
              name="panaladata"
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              inputFormat="dd.MM.yyyy" 
              format="dd.MM.yyyy"  
              maxDate={dataConvertitaTermen}
              className='maxWidth mt-2'
            />
          </LocalizationProvider>

            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
             <Box>
              <Typography variant="subtitle2">Notificare</Typography>
              <Switch
                checked={editedNotificare.notificare === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, notificare: value }));
                }}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">SMS</Typography>
              <Switch
                checked={editedNotificare.via_sms === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: value }));
                }}
                disabled={editedNotificare.notificare !== 1}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">E-Mail</Typography>
              <Switch
                checked={editedNotificare.via_email === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_email: value }));
                }}
                disabled={editedNotificare.notificare !== 1}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">Push</Typography>
              <Switch
                checked={editedNotificare.via_push === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_push: value }));
                }}
                disabled={editedNotificare.notificare !== 1}
              />
              </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select
            fullWidth
              value={editedNotificare.reminder}
              onChange={(event) => {
                const value = event.target.value;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, reminder: value }));
              }}
            >
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              checked={editedNotificare.recurent === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, recurent: value }));
              }}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          
        <Button variant="contained" color="success" fullWidth 
          onClick={() => handleSaveEditTermen(selectedTermenEvent?.termen?.termen.id)}>
            Salvare
          </Button>

        </DialogActions>
      </Dialog>
      
{/*MODALA PENTRU CREARE TERMENE*/}
<Dialog open={createModalOpen2} onClose={handleCreateModalClose} maxWidth="sm" fullWidth>
  <DialogTitle className='titluModal mb-3'>
      <Typography variant="h6">Creare notificare termen</Typography>

      <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCreateModalClose}>
        <CloseIcon />
      </IconButton>
  </DialogTitle>
<DialogContent>

          <div className="createNotificare">
            <Box>
            <TextField 
              name="descriere"
              label="Descriere"
              value={newNotificare.descriere}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            </Box>
            <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Dată"
                value={selectedDateFinal}
                onChange={handleDateChangeTermen}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="dd.MM.yyyy" 
                format="dd.MM.yyyy" 
                mask="__.__.____"
                name="panaladata" 
                className='maxWidth mt-2'
                maxDate={selectedDateFinal}
            
              />
            </LocalizationProvider>
             </Box>
             <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
             <Box>
                <Typography variant="subtitle2">Notificare</Typography>
                <Switch
                  name="notificare"
                  checked={newNotificare.notificare === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, notificare: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">SMS</Typography>
                <Switch
                  name="via_sms"
                  checked={newNotificare.via_sms === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1}
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">E-Mail</Typography>
                <Switch
                  name="via_email"
                  checked={newNotificare.via_email === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_email: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1}
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">Push</Typography>
                <Switch
                  name="via_push"
                  checked={newNotificare.via_push === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_push: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1}
                />
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select name="reminder" value={newNotificare.reminder} onChange={handleInputChange} fullWidth margin="normal">
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              name="recurent"
              checked={newNotificare.recurent === 1}
              onChange={(e) =>
                setNewNotificare((prevNotificare) => ({ ...prevNotificare, recurent: e.target.checked ? 1 : 0 }))
              }
              color="primary"
            />
            </Box> 
          </div>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" fullWidth color="primary" onClick={() => handleCreateNotificare('termen')}>
                Creează notificare pentru termen
              </Button> 
        </DialogActions>
      </Dialog>


  
      <Dialog open={selectedEvent !== null} onClose={handleCloseModal} maxWidth="lg" fullWidth>
      <DialogTitle className='titluModal'>
          {selectedEvent?.notificare?.tip === 'dosar' ? `Eveniment dosar ${selectedEvent?.notificare?.numarDosar && selectedEvent?.notificare?.numarDosar.length > 4 ? `${selectedEvent?.notificare?.numarDosar}` : `${selectedEvent?.notificare?.numarIntern}`} 
          ` : 'Eveniment utilizator '} 
          
          ({selectedEvent?.notificare?.userName})
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
 
        <DialogContent >
          <div className="headerTermen mt-2">
            <div>
              {selectedEvent?.notificare?.tip === 'dosar' && (
                <>
                    <div>
                        <p>Descriere:</p>
                        <p style={{maxHeight:'100px', overflow:'auto', border:'1px solid #CCC', background:'#FAFAFA', padding:'10px', marginBottom:'10px'}}>{selectedEvent?.notificare?.descriere}</p>
                        <table className='tabelInfoDosar'>
                            <tbody>
                          
                              <tr>
                                <td>{selectedEvent?.notificare?.numarDosar && selectedEvent?.notificare?.numarDosar.length > 4 ? 
                                    `Număr dosar:` : 
                                    `Număr intern dosar:`}</td>
                                <td>
                                  <a target='_blank' href={`/editare-dosar/${selectedEvent?.notificare?.idDosar}`}>
                                    {selectedEvent?.notificare?.numarDosar && selectedEvent?.notificare?.numarDosar.length > 4 ? 
                                    `${selectedEvent?.notificare?.numarDosar}` : 
                                    `${selectedEvent?.notificare?.numarIntern}`}
                                  </a>
                                </td>
                              </tr> 
                              <tr>
                                <td>Client dosar:</td>
                                <td>{selectedEvent?.notificare?.clientDosar}</td>
                              </tr> 
                              <tr>
                                <td>Parte adversă:</td>
                                <td>{selectedEvent?.notificare?.parteAdversa}</td>
                              </tr>
                              <tr>
                                <td>Instanță:</td>
                                <td>{selectedEvent?.notificare?.instanta}</td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                </>
              )} 
              {selectedEvent?.notificare?.tip === 'user' && (
                <div>
                  <Typography variant="body1">Descriere: {selectedEvent?.notificare.descriere}</Typography><Typography variant="body1">
                    Începând cu data: {selectedEvent?.notificare.deladata ? format(parseISO(selectedEvent.notificare.deladata), 'dd.MM.yyyy') : ''}
                  </Typography>
                  <Typography variant="body1">
                    Până la data: {selectedEvent?.notificare.panaladata ? format(parseISO(selectedEvent.notificare.panaladata), 'dd.MM.yyyy') : ''}
                  </Typography>
                  <Typography variant="body1">Notificare: {selectedEvent?.notificare.notificare === 1 ? "Da" : "Nu"}</Typography>
                  <Typography variant="body1">Reamintire înainte cu: {selectedEvent?.notificare.reminder === 1 ? "1 zi" : `${selectedEvent?.notificare.reminder} zile`}</Typography>
                  <Typography variant="body1">Reamintire zilnică: {selectedEvent?.notificare.recurent === 1 ? "Da" : "Nu"}</Typography>
 


                </div>
              )}
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        size="small"
                        className="google-button"
                        onClick={handleExportToGoogleCalendar2}
                        style={{ marginRight: '10px' }}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faGoogle} />
                        </Icon>
                        <Typography variant=" ">Export în Google Calendar</Typography>
                      </Button> 

                      <Button
                        variant="contained"
                        size="small"
                        className="ios-button"
                        onClick={handleExportToIosCalendar2}
                      >
                        <Icon sx={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faApple} />
                        </Icon>
                        <Typography variant=" ">Export în iOS Calendar</Typography>
                      </Button>

                    </Box>
            
          </div>
          
                    
                    
                    {selectedEvent?.notificare?.tip === 'dosar' && (
                    <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
                      <Grid item>
                      <Typography variant="body1">Administrare notificări pe dosar</Typography>
                    </Grid>
                    <Grid item> 
                       <Button variant="contained" size="small" color="primary" onClick={handleCreateModalOpen}>
                        Adaugă notificare pentru dosar
                      </Button>
                      </Grid>
                      </Grid>
                    )}
                    {selectedEvent?.notificare?.tip === 'termen' && (
                      <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
                        <Grid item>
                       <Typography variant="body1">Administrare notificări pe termen</Typography>
                     </Grid>
                     <Grid item> 
                     { termenTrecut  ? (
                        <Alert severity="warning">Nu se mai pot adăuga atenționări pentru termene trecute.</Alert>
                      ) : (
                        <Button variant="contained" color="primary" onClick={handleCreateModalOpen}>
                          Adaugă notificare pentru termen
                        </Button>
                      )}
                      </Grid>
                      </Grid>
                    )}
                   
                  
          {selectedEvent?.notificare?.tip === 'dosar' && selectedEvent?.notificare?.notificariDosar && selectedEvent.notificare.notificariDosar.length > 0 && (
            <TableContainer style={{maxHeight:'300px'}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow> 
                    <TableCell>Descriere</TableCell>
                    <TableCell className='text-center'>Începând cu data</TableCell>
                    <TableCell className='text-center'>Până la data</TableCell>                              
                    <TableCell className="text-center">Notificare SMS/Email?</TableCell>
                    <TableCell className="text-center">Înainte cu câte zile</TableCell>
                    <TableCell className="text-center">În fiecare zi?</TableCell>

                    <TableCell className='text-center'>Acțiuni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEvent.notificare.notificariDosar.map((notificare) => (
                    <TableRow key={notificare.id}> 
                      <TableCell>{notificare.descriere}</TableCell>
                      <TableCell className='text-center'>{notificare.deladata}</TableCell>
                      <TableCell className='text-center'>{notificare.panaladata}</TableCell>
                      
                      <TableCell className='text-center'>{notificare.notificare === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.reminder === 1 ? "1 zi" : `${notificare.reminder} zile`}</TableCell>
                      <TableCell className='text-center'>{notificare.recurent === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>
                      <Tooltip title="Editare" arrow>
                                    <IconButton onClick={() => handleEditModalOpen(notificare.id)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                      <Tooltip title="Ștergere" arrow>
                        <IconButton onClick={() => handleDeleteNotificare(notificare.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>              
                 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          

{selectedEvent?.notificare?.tip === 'user' && (
  <div className='text-center mt-4'> 
 
<Button variant="outlined" color="primary" className="mt-2 mr-3" size="small" onClick={() => handleEditModalOpen(selectedEvent?.notificare.id)}>
<EditIcon /> Editare eveniment
</Button>

<Button variant="outlined" color="warning" className=" mt-2 ml-4" size="small" onClick={() => handleDelete(selectedEvent?.notificare.id)}>
<DeleteIcon /> Ștergere eveniment
</Button>
</div>
)}

{/*CREARE NOTIFICARE DOSAR*/}
<Dialog open={createModalOpen} onClose={handleCreateModalClose} maxWidth="sm" fullWidth>
  <DialogTitle className='titluModal mb-2'> 
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCreateModalClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Creare notificare dosar</Typography>

  </DialogTitle>
<DialogContent>
          
          <div className="createNotificare">
            <Box sx={{ mt: 1 }}>
            <TextField 
              name="descriere"
              label="Descriere"
              value={newNotificare.descriere}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            </Box>  
            <Box sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Dată"
                value={newNotificare.panaladata ? parse(newNotificare.panaladata, 'dd.MM.yyyy', new Date()) : new Date()}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="dd.MM.yyyy" 
                format="dd.MM.yyyy" 
                mask="__.__.____"
                name="panaladata" 
                className='maxWidth mt-2'
            
              />
            </LocalizationProvider>
             </Box> 
             <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
             <Box>
                <Typography variant="subtitle2">Notificare</Typography>
                <Switch
                  name="notificare"
                  checked={newNotificare.notificare === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, notificare: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">SMS</Typography>
                <Switch
                  name="via_sms"
                  checked={newNotificare.via_sms === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1} 
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">E-Mail</Typography>
                <Switch
                  name="via_email"
                  checked={newNotificare.via_email === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_email: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1} 
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">Push</Typography>
                <Switch
                  name="via_push"
                  checked={newNotificare.via_push === 1}
                  onChange={(e) =>
                    setNewNotificare((prevNotificare) => ({ ...prevNotificare, via_push: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={newNotificare.notificare !== 1} 
                />
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select name="reminder" value={newNotificare.reminder} onChange={handleInputChange} fullWidth margin="normal">
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              name="recurent"
              checked={newNotificare.recurent === 1}
              onChange={(e) =>
                setNewNotificare((prevNotificare) => ({ ...prevNotificare, recurent: e.target.checked ? 1 : 0 }))
              }
              color="primary"
            />
            </Box> 
          </div>
        </DialogContent>
        <DialogActions>
           <Button variant="contained" fullWidth color="primary" onClick={() => handleCreateNotificare('dosar')}>
                Creează notificare pentru dosar
              </Button>
        </DialogActions>
      </Dialog>
 

        {/*
          <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            p: 2,
            outline: 'none',
          }}
        >
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleEditModalClose}>
            <CloseIcon />
          </IconButton>

          <Typography variant="subtitle1">Editează notificare</Typography>

          <TextField
            label="Descriere"
            variant="outlined"
            name="descriere"
            value={editedNotificare.descriere}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Până la"
            type="date"
            variant="outlined"
            name="panaladata"
            value={editedNotificare.panaladata}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box>
              <Typography variant="subtitle2">Notificare</Typography>
              <Switch
                checked={editedNotificare.notificare === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, notificare: value }));
                }}
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">SMS</Typography>
              <Switch
                checked={editedNotificare.via_sms === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_sms: value }));
                }}
                disabled={editedNotificare.notificare !== 1} 
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">E-Mail</Typography>
              <Switch
                checked={editedNotificare.via_email === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_email: value }));
                }}
                disabled={editedNotificare.notificare !== 1} 
              />
              </Box>
              <Box>
              <Typography variant="subtitle2">Push</Typography>
              <Switch
                checked={editedNotificare.via_push === 1}
                onChange={(event) => {
                  const value = event.target.checked ? 1 : 0;
                  setEditedNotificare((prevNotificare) => ({ ...prevNotificare, via_push: value }));
                }}
                disabled={editedNotificare.notificare !== 1} 
              />
              </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reminder</Typography>
            <Select fullWidth
              value={editedNotificare.reminder}
              onChange={(event) => {
                const value = event.target.value;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, reminder: value }));
              }}
            >
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Recurent</Typography>
            <Switch
              checked={editedNotificare.recurent === 1}
              onChange={(event) => {
                const value = event.target.checked ? 1 : 0;
                setEditedNotificare((prevNotificare) => ({ ...prevNotificare, recurent: value }));
              }}
            />
          </Box>

          <Button variant="contained" color="primary" onClick={handleSaveEdit}>
            Salvare
          </Button>
        </Box>
      </Modal>
      */}
                 <DialogActions>
                 <Button variant=" " className="pull-right mt-2" size="small" onClick={handleCloseModal}>
                    Închidere eveniment
                  </Button>
                 </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CalendarEvenimenteMultiple;  