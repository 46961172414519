import { useState, useEffect } from 'react';
import verificareToken from '../VerificareToken';

export default function useUserPrefService() {
  const [userPrefsData, setUserPrefsData] = useState(null);

    const { token } = verificareToken();

  useEffect(() => {
    const getUserPrefs = async () => {
      try {
        const response = await fetch(`${window.API_URL}getUserprefs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const { data } = await response.json();
        setUserPrefsData(data.userPrefsData);
      } catch (error) {
        console.error("A problem occurred while fetching user preferences: ", error);
      }
    };

    getUserPrefs();
  }, [token]);

  return userPrefsData;
}
