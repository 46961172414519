import React from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ro } from 'date-fns/locale';
import ClearIcon from '@mui/icons-material/Clear';

const DateFilterComponent = ({ item, applyValue }) => {
  const handleChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  const handleClear = () => {
    applyValue({ ...item, value: null }); 
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
      <Box display="flex" alignItems="center">
        <DatePicker
          label="Filtrare dată"
          value={item.value || null} 
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} size="small" />}
          inputFormat="dd.MM.yyyy"
          className='inputMicMic2'
        />
        <IconButton size="small" onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      </Box>
    </LocalizationProvider>
  );
};

export default DateFilterComponent;
