import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Autocomplete, TextField, Button, DialogActions, Grid, Alert } from '@mui/material';
import { DataGridPro, roRO } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro'; 
import verificareToken from '../VerificareToken';
import { CircularProgress } from '@mui/material';
LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const JurisprudentaAsociataTermen = ({ open, onClose, id }) => {
  const [data, setData] = useState(null);
  
    const [isLoading, setIsLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null); //new Date().getFullYear()
    const [selectedAn, setSelectedAn] = useState('');
    const [selectedMaterie, setSelectedMaterie] = useState([]);
    const [selectedObiect, setSelectedObiect] = useState([]);
    const [selectedJudet, setSelectedJudet] = useState([]);
    const [spete, setSpete] = useState([]);
    const [years] = useState(Array.from({ length: new Date().getFullYear() - 1990 + 1 }, (v, k) => 1990 + k));
  
    const [oknext, setOknext] = useState(false);

    const { token } = verificareToken(); 

  const fetchInitialData = async () => {
    try {
      const response = await fetch(`${window.API_URL}showJurisprudentaAsociataDosar/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const json = await response.json();
      setData(json.data);
  
      setSelectedAn(parseInt(json.data.an, 10));
      setSelectedMaterie(json.data.materii.find(m => m.id === parseInt(json.data.idMaterie)) || {});
      setSelectedObiect(json.data.obiecte.find(o => o.id === parseInt(json.data.idObiect)) || {});
      setSelectedJudet(json.data.judete.find(j => j.id === parseInt(json.data.idJudet)) || {});
    
      
      setOknext(true);
 
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  
 const fetchDosarDetails = async () => {
  try {
      const response = await fetch(`${window.API_URL}dosare/${id}`, {
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      });
      const json = await response.json();
      if (json && json.data && json.data.sumarDosar) {
 
        //setSelectedYear(json.data.sumarDosar.andosar);
        setSelectedYear(parseInt(json.data.sumarDosar.andosar, 10));
        console.log('an dosar:',selectedYear);
        
      }
  } catch (error) {
      console.error('Failed to fetch dosar details:', error);
  }
};

useEffect(() => {
  if (open) {
    fetchDosarDetails();
    fetchInitialData();
  }
}, [open, id]);

useEffect(() => {
  if (selectedYear && selectedMaterie && selectedObiect && selectedJudet && selectedJudet.id) {
    console.log(selectedJudet.id);
    fetchSpete(selectedYear, selectedMaterie.id, selectedObiect.id, selectedJudet.id);
  }
}, [selectedYear, selectedMaterie, selectedObiect, selectedJudet]);





  const fetchSpete = async (selectedYear, materie, obiect, judet) => {
    setIsLoading(true); 
    try {
      const queryParams = new URLSearchParams({
        denumireObiect: obiect || '',
        idMaterie: materie || '',
        an: selectedYear || '',
        idJudet: judet || '',
      });
      const response = await fetch(`${window.API_URL}infoJurisprudentaAsociata/575?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setSpete(result.data.spete);
    } catch (error) {
      console.error('Failed to fetch spete:', error);
      setIsLoading(false);  
    } finally {
      setIsLoading(false);  
    }
  };
 
  
 
  const handleFormChange = (field, value) => {
    switch (field) {
      case 'an':
        setSelectedYear(value);
        break;
      case 'materie':
        setSelectedMaterie(value);
        break;
      case 'obiect':
        setSelectedObiect(value);
        break;
      case 'judet':
        setSelectedJudet(value);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setData(null);
    setSelectedYear(new Date().getFullYear());
    setSelectedMaterie({});
    setSelectedObiect({});
    setSelectedJudet({});
    setSpete([]);
    onClose();
  };

    return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle className='titluModal'>Jurisprudență asociată</DialogTitle>
      <DialogContent>
      <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <Alert severity='warning'>Pentru a vizualiza conținutul integral al hotărârii judecătorești vă rugăm să accesați <a href="http://www.rejust.ro" target="_blank">www.rejust.ro</a></Alert>  
        </Grid>
        <Grid item xs={2}>
        <Autocomplete
            options={years}
            size="small"
            value={selectedYear}
            getOptionLabel={(option) => typeof option === 'number' ? option.toString() : ''}
            renderInput={(params) => (
                <TextField {...params} label="An" margin="normal" fullWidth />
            )}
            disabled={isLoading}
            onChange={(event, newValue) => {
                setSelectedYear(newValue);
                handleFormChange(newValue, selectedMaterie, selectedObiect, selectedJudet);
            }}
            />
        </Grid>
        <Grid item xs={3}>
            <Autocomplete
                options={data?.materii || []}
                size="small"
                value={selectedMaterie}
                getOptionLabel={(option) => option && option.nume ? option.nume : ''}
                renderInput={(params) => (
                    <TextField {...params} label="Materie" margin="normal" fullWidth />
                )}
                disabled={isLoading}
                onChange={(event, newValue) => {
                    setSelectedMaterie(newValue);
                }}
            />
        </Grid>
        <Grid item xs={3}>
        <Autocomplete
            options={data?.obiecte || []}
            size="small"
            value={selectedObiect}
            getOptionLabel={(option) => option && option.nume ? option.nume : ''}
            renderInput={(params) => (
                <TextField {...params} label="Obiect" margin="normal" fullWidth />
            )}
            disabled={isLoading}
            onChange={(event, newValue) => {
                setSelectedObiect(newValue || {});
                handleFormChange(selectedYear, selectedMaterie, newValue, selectedJudet);
            }}
            />
        </Grid>
        <Grid item xs={3}>

            <Autocomplete
                options={data?.judete || []}
                size="small"
                value={selectedJudet}
                getOptionLabel={(option) => option && option.nume ? option.nume : ''}
                renderInput={(params) => (
                    <TextField {...params} label="Judet" margin="normal" fullWidth />
                )}
                disabled={isLoading}
                onChange={(event, newValue) => {
                    setSelectedJudet(newValue || {});
                }}
            />
        </Grid>
        <Grid item xs={1}>
            <Button disabled={isLoading} className='mt-1' variant='contained' onClick={() => fetchSpete(selectedYear, selectedMaterie.id, selectedObiect.id, selectedJudet.id)}>Căutare</Button>
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
        {isLoading ? (
         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         <CircularProgress />
         <span style={{ marginTop: '20px' }}>Vă rugăm așteptați, căutăm în peste 16 milioane de spețe</span>
        </div>
     
        ) : (
            <DataGridPro
                rows={spete}
                getRowId={(row, index) => row.id || `row-${index}`}
                columns={[
                    //{ field: 'id', headerName: 'ID', width: 90 },
                    { field: 'tipact', headerName: 'Tip act', width: 150 },
                    { field: 'numaract', headerName: 'Număr act', width: 110 },
                    { field: 'anact', headerName: 'An act', width: 100 },
                    //{ field: 'instanta', headerName: 'Instanță', width: 150 },
                    { field: 'materie', headerName: 'Materie', width: 150 },
                    { field: 'obiect', headerName: 'Obiect', width: 200 },
                    { field: 'judet', headerName: 'Județ', width: 100 },
                    {
                        field: 'caleafisarepj',
                        headerName: '',
                        width: 150,
                        renderCell: (params) => (
                          <Button 
                            color="success"
                            size='small'
                            onClick={() => window.open(params.value, '_blank')}
                          >
                            Deschidere act
                          </Button>
                        ),
                      },
                ]}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Export',
                    toolbarExportLabel: 'Export',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                }
                pageSize={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                density="compact"
                stickyHeader
                pagination
                paginationMode="client"  
                initialState={{
                  pagination: { paginationModel: { pageSize: 15 } }, 
                }}
            />
        )}
        </Grid>
</Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Închidere</Button>
      </DialogActions>
     
    </Dialog>
  );
};

export default JurisprudentaAsociataTermen;
