import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import verificareToken from '../VerificareToken';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'; 
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Alert, Tooltip, Grid, Typography, IconButton, Button, TextField, Switch, Select, MenuItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, FormControl, FormControlLabel, InputLabel, CircularProgress } from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, parse, isPast, endOfDay  } from 'date-fns';
import { ro } from 'date-fns/locale';


import Swal from 'sweetalert'; 


const NotificareDosar = ({ open, onClose, newNotificare, selectedRowId }) => {
    const { token } = verificareToken();
    const [notificareId, setSelectedNotificareId] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [adaugaModal, setAdaugaModal] = useState(false);
    
    const [selectedEvent, setSelectedEvent] = useState(null); 
    const [currentNotificare, setCurrentNotificare] = useState({
      descriere: '',
      panaladata: format(new Date(), 'dd.MM.yyyy'),    
      notificare: 0,
      via_email: 0,
      via_push: 0,
      via_push: 0,
      reminder: 1,
      recurent: 0,
      tipNotificare: '',
    });

    const handleCloseModal = () => {
      setSelectedEvent(null); 
      setEditModalOpen(false);
      fetchNotificariDosar();
    };
  

  const [isLoading, setLoading] = useState(true); 
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editedNotificare, setEditedNotificare] = useState({
    descriere: '',
    panaladata: '',
    notificare: 0,
    via_email: 0,
    via_push: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
    tipNotificare: '',
  });

  const initialState = {
    descriere: '',
    panaladata: format(new Date(), 'dd.MM.yyyy'),
    notificare: 0,
    via_sms: 0,
    via_email: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
  };


  const [formData, setFormData] = useState(initialState); 
useEffect(() => {
  if (open) {
    fetchNotificariDosar();
  }
}, [open]);

const fetchNotificariDosar = async () => {
  const idDosar = getIdDosarDinUrl() || selectedRowId;
  try {
    const notificariResponse = await fetch(`${window.API_URL}notificariDosar/${idDosar}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
 
    if (!notificariResponse.ok) {
      console.error('Eroare la preluare date evenimente');
      return;
    }

    const notificariData = await notificariResponse.json(); 
    setSelectedEvent(notificariData.data);
    console.log('selecteEvent', selectedEvent);

    setLoading(false);
  } catch (error) {
    console.error('Eroare la preluare date evenimente multiple: ', error);
  }
};


const handleDeleteNotificare = (idNotificare) => {
  Swal({
    title: 'Sigur doriți ștergerea?',
    text: 'Această acțiune nu poate fi anulată!',
    icon: 'warning',
    buttons: ['Anulează', 'Da, șterge!'],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      handleDeleteNotificareOk(idNotificare);
    }
  });
};

const handleDeleteNotificareOk = async (idNotificare) => {
  try {
    // Ștergere notificare
    const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error('Eroare la ștergerea notificării: ', response.statusText);
      return;
    }
    
    await fetchNotificariDosar(); 
    
  } catch (error) {
    console.error('Eroare la ștergerea notificării: ', error);
  }
};


useEffect(() => {
  if (open) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      panaladata: new Date(),  
    }));
  }
}, [open]);

  const handleCreateModalOpen = () => {
    setAdaugaModal(true);
  };

  const handleCreateModalClose = () => {
    setAdaugaModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleCreateNotificare = async (tipNotificare) => {
    
    const idDosar = getIdDosarDinUrl() || selectedRowId;

    if (formData.descriere !== '') {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          idDosar, 
          descriere: formData.descriere,
          deladata: formData.deladata,
          panaladata: formData.panaladata,
          notificare: formData.notificare,
          via_sms: formData.via_sms,
          via_email: formData.via_email,
          via_push: formData.via_push,
          reminder: formData.reminder,
          recurent: formData.recurent,
          tipNotificare, 
        }),
      };
  
      try {
        const response = await fetch(`${window.API_URL}notificari`, requestOptions);
        if (response.ok) {
          Swal('Succes', 'Notificare adăugată cu succes!', 'success');
          setFormData({
            descriere: '',
            panaladata: format(new Date(), 'dd.MM.yyyy'),
            notificare: 0,
            via_sms: 0,
            via_email: 0,
            via_push: 0,
            reminder: 1,
            recurent: 0,
          });
          setFormData({
            ...formData,
            panaladata: new Date()
          });
          fetchNotificariDosar();
          handleCreateModalClose();
        } else {
          Swal('Atenționare', 'A apărut o eroare la adăugarea notificării!', 'warning');
        }
      } catch (error) {
        Swal('Eroare de rețea', 'A apărut o eroare de rețea!', 'warning');
        console.error('A apărut o eroare de rețea:', error);
      }
    } else {
      Swal('Atenționare', 'Introduceți o descriere pentru notificare!', 'warning');
    }
  };

  const getIdDosarDinUrl = () => {
    const url = window.location.pathname;
    const parts = url.split('/');
    const idDosar = parts[parts.length - 1];
    return isNaN(idDosar) ? null : idDosar;
  };
  

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (newValue) => {
    setFormData({ ...formData, panaladata: newValue });
  };
  
  const handleEditDateChange = (newValue) => {
    setCurrentNotificare({ ...currentNotificare, panaladata: newValue });
  };

 const handleEditModalOpen = async (idNotificare) => {
    try {
        const response = await fetch(`${window.API_URL}notificari/${idNotificare}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Eroare la preluare date notificare');
        }

        const { data } = await response.json();
        setCurrentNotificare(data.notificare);  
        setCurrentNotificare({
          ...data.notificare,
          panaladata: new Date(data.notificare.panaladata)  
        });
        setEditModalOpen(true);  
    } catch (error) {
        console.error('Eroare la preluare date notificare: ', error);
        Swal('Atenție!', 'Nu s-au putut încărca datele notificării pentru editare.', 'warning');
    }
};

const handleUpdateNotificare = async () => {
  if (!currentNotificare?.id) {
    Swal('Atenție!', 'Notificarea selectată este invalidă.', 'warning');
    return;
  }

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      descriere: currentNotificare.descriere,
      panaladata: currentNotificare.panaladata,
      notificare: currentNotificare.notificare,
      via_sms: currentNotificare.via_sms,
      via_email: currentNotificare.via_email,
      via_push: currentNotificare.via_push,
      reminder: currentNotificare.reminder,
      recurent: currentNotificare.recurent,
    }),
  };

  try {
    const response = await fetch(`${window.API_URL}notificari/${currentNotificare.id}`, requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Eroare la actualizarea notificării.');
    }

    Swal('Succes', 'Notificarea a fost actualizată cu succes.', 'success');
    setEditModalOpen(false); 
    fetchNotificariDosar();  
  } catch (error) {
    Swal('Atenție!', `Nu s-a putut actualiza notificarea: ${error.message}`, 'warning');
  }
};

  return (
<>

  
<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className='titluModal'>
          Administrare notificări dosar
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
 
        <DialogContent >
        
 
        <Grid className='mt-3 pb-2' container alignItems="center" spacing={1} justifyContent="space-between">
          <Grid item>
        </Grid>
        <Grid item> 
            <Button variant="contained" size="small" color="warning" onClick={handleCreateModalOpen}>
            Adăugare notificare pentru dosar
          </Button>
          </Grid>
          </Grid> 
          {selectedEvent?.notificari?.length > 0 ? (
              <TableContainer style={{maxHeight:'300px'}}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow> 
                    <TableCell>Descriere</TableCell>
                    <TableCell className='text-center'>Începând cu data</TableCell>
                    <TableCell className='text-center'>Până la data</TableCell>                              
                    <TableCell className="text-center">Notificare</TableCell>                             
                    <TableCell className="text-center">SMS</TableCell>                             
                    <TableCell className="text-center">Email?</TableCell>                             
                    <TableCell className="text-center">PUSH</TableCell>
                    <TableCell className="text-center">Înainte cu câte zile</TableCell>
                    <TableCell className="text-center">În fiecare zi?</TableCell>

                    <TableCell className='text-center'>Acțiuni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {selectedEvent?.notificari?.map((notificare) => (
                    <TableRow key={notificare.id}> 
                      <TableCell>{notificare.descriere}</TableCell>
                      <TableCell className='text-center'>{moment(notificare.dataInceput, "DD-MM-YYYY HH:mm").format('DD.MM.YYYY')}</TableCell>
                      <TableCell className='text-center'>{moment(notificare.dataSfarsit, "DD-MM-YYYY HH:mm").format('DD.MM.YYYY')}</TableCell>
                      
                      <TableCell className='text-center'>{notificare.notificare === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_sms === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_email === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.via_push === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>{notificare.reminder === 1 ? "1 zi" : `${notificare.reminder} zile`}</TableCell>
                      <TableCell className='text-center'>{notificare.recurent === 1 ? "Da" : "Nu"}</TableCell>
                      <TableCell className='text-center'>
                      <Tooltip title="Editare" arrow>
                                    <IconButton onClick={() => handleEditModalOpen(notificare.id)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                      <Tooltip title="Ștergere" arrow>
                        <IconButton onClick={() => handleDeleteNotificare(notificare.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>              
                 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> 
          ) : (
            <Alert severity="warning">Nu există notificări pentru acest dosar. Aveți posibilitatea de a adăuga notificări pe dosar prin acționarea butonului <b>"Adăugare notificare pentru dosar"</b></Alert>
          )}
        </DialogContent>
        
        <DialogActions>
        <Button variant=" " className="pull-right mt-2" size="small" onClick={onClose}>
          Închidere
        </Button>
        </DialogActions>
      </Dialog>    




<Dialog open={adaugaModal} onClose={handleCreateModalClose} maxWidth="sm" fullWidth>
  <DialogTitle className='titluModal mb-2'> 
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCreateModalClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Creare notificare dosar</Typography>

  </DialogTitle>
<DialogContent>
          
          <div className="createNotificare">
            <Box sx={{ mt: 1 }}>
            <TextField 
              name="descriere"
              label="Descriere"
              value={formData.descriere}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />
            </Box>  
            <Box sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
              <DatePicker
                label="Dată"
                value={formData.panaladata}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="dd.MM.yyyy" 
                format="dd.MM.yyyy" 
                mask="__.__.____"
                name="panaladata" 
                className='maxWidth mt-2'
            
              />
            </LocalizationProvider>
             </Box> 
             <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
             <Box>
                <Typography variant="subtitle2">Notificare</Typography>
                <Switch
                  name="notificare"
                  checked={formData.notificare === 1}
                  onChange={(e) =>
                    setFormData((prevNotificare) => ({ ...prevNotificare, notificare: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">SMS</Typography>
                <Switch
                  name="via_sms"
                  checked={formData.via_sms === 1}
                  onChange={(e) =>
                    setFormData((prevNotificare) => ({ ...prevNotificare, via_sms: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={formData.notificare !== 1} 
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">E-Mail</Typography>
                <Switch
                  name="via_email"
                  checked={formData.via_email === 1}
                  onChange={(e) =>
                    setFormData((prevNotificare) => ({ ...prevNotificare, via_email: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={formData.notificare !== 1} 
                />
                </Box>
                <Box>
                <Typography variant="subtitle2">Push</Typography>
                <Switch
                  name="via_push"
                  checked={formData.via_push === 1}
                  onChange={(e) =>
                    setFormData((prevNotificare) => ({ ...prevNotificare, via_push: e.target.checked ? 1 : 0 }))
                  }
                  color="primary"
                  disabled={formData.notificare !== 1} 
                />
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select name="reminder" value={formData.reminder} onChange={handleInputChange} fullWidth margin="normal">
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              name="recurent"
              checked={formData.recurent === 1}
              onChange={(e) =>
                setFormData((prevNotificare) => ({ ...prevNotificare, recurent: e.target.checked ? 1 : 0 }))
              }
              color="primary"
            />
            </Box> 
          </div>
        </DialogContent>
        <DialogActions>
           <Button variant="contained" fullWidth color="success" onClick={() => handleCreateNotificare('dosar')}>
                Creare notificare pentru dosar
              </Button>
        </DialogActions>
      </Dialog> 
      

      <Dialog open={editModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle className='titluModal mb-3'>
       <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="subtitle1">Editare notificare</Typography>
    </DialogTitle>
  
  <DialogContent>
      

      <TextField
        label="Descriere"
        variant="outlined"
        name="descriere"
        value={currentNotificare?.descriere || ''}
        onChange={(e) => setCurrentNotificare({ ...currentNotificare, descriere: e.target.value })}
        fullWidth
        margin="normal"
        required
      />


      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
        <DatePicker
          label="Dată notificare"
          onChange={handleEditDateChange}
          value={currentNotificare.panaladata}name="panaladata"
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          inputFormat="dd.MM.yyyy" 
          format="dd.MM.yyyy"    
          className='maxWidth mt-2'
        />
      </LocalizationProvider>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
         <Box>
          <Typography variant="subtitle2">Notificare</Typography>
          <Switch
            checked={currentNotificare?.notificare === 1}
            onChange={(e) => setCurrentNotificare({ ...currentNotificare, notificare: e.target.checked ? 1 : 0 })}
          />
          </Box>
          <Box>
          <Typography variant="subtitle2">SMS</Typography>
          <Switch
            checked={currentNotificare?.via_sms === 1}
            onChange={(e) => setCurrentNotificare({ ...currentNotificare, via_sms: e.target.checked ? 1 : 0 })}
            disabled={currentNotificare.notificare !== 1}
          />
          </Box>
          <Box>
          <Typography variant="subtitle2">E-Mail</Typography>
          <Switch
            checked={currentNotificare?.via_email === 1}
            onChange={(e) => setCurrentNotificare({ ...currentNotificare, via_email: e.target.checked ? 1 : 0 })}
            disabled={currentNotificare.notificare !== 1}
          />
          </Box>
          <Box>
          <Typography variant="subtitle2">Push</Typography>
          <Switch
            checked={currentNotificare?.via_push === 1}
            onChange={(e) => setCurrentNotificare({ ...currentNotificare, via_push: e.target.checked ? 1 : 0 })}
            disabled={currentNotificare.notificare !== 1}
          />
          </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
        <Select
        fullWidth
        name="reminder"
        value={currentNotificare?.reminder || ''}
        onChange={(event) => setCurrentNotificare({ ...currentNotificare, reminder: event.target.value })}
        margin="normal"
      >
        <MenuItem value={1}>1 zi</MenuItem>
        <MenuItem value={3}>3 zile</MenuItem>
        <MenuItem value={7}>7 zile</MenuItem>
        <MenuItem value={14}>14 zile</MenuItem>
        <MenuItem value={21}>21 zile</MenuItem>
        <MenuItem value={30}>30 zile</MenuItem>
      </Select>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">Reamintire zilnică</Typography>
        <Switch
          checked={currentNotificare?.recurent === 1}
          onChange={(e) => setCurrentNotificare({ ...currentNotificare, recurent: e.target.checked ? 1 : 0 })}
        />
      </Box> 
    </DialogContent>
    <DialogActions>
      
    <Button variant="contained" color="success" fullWidth onClick={handleUpdateNotificare}>
        Salvare
      </Button>

    </DialogActions>

</Dialog>

      </>
  );
};

export default NotificareDosar;
