import React, { useState } from 'react';
import { Tabs, Tab, Box, Button } from '@mui/material';

import FirmeMonitorizateBPI from './bpi/firmeMonitorizateBPI';
import CautareBPI from './bpi/cautareBPI'; 
import verificareToken from '../VerificareToken';

export default function FirmeBPI() {
  const [currentTab, setCurrentTab] = useState(0);

  const { token } = verificareToken();
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <div>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="↳ Lista firme monitorizate" />
        <Tab label="↳ Căutare firme / BPI" />
      </Tabs>
      <Box>
        {currentTab === 0 && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="contained" color="secondary" size="small" onClick={() => handleChangeTab(1)}>+ Adăugare firmă nouă</Button>
          </Box>
        )}
        {currentTab === 1 && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="contained" color="secondary" size="small" onClick={() => handleChangeTab(0)}>Revenire la listă firme</Button>
          </Box>
        )}
        {currentTab === 0 && (
          <div>
            <FirmeMonitorizateBPI />
          </div>
        )}
        {currentTab === 1 && (
          <div>
            <CautareBPI />
          </div>
        )} 
      </Box>
    </div>
  );
}
