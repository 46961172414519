import React, { useState } from 'react';
import { Tabs, Tab, Box, Button } from '@mui/material';

import AdaugareDocumente from './documente/adaugareDocumente';
import VizualizareDocumente from './documente/vizualizareDocumente';
import CaDosar from './documente/caDosar';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

export default function DocumenteDosar() {
  const [currentTab, setCurrentTab] = useState(0);
  
    const { token } = verificareToken();

  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  }
  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <div>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="↳ Documente atașate dosarului" />
          <Tab disabled={!areDrept('DreptDeModificareDosare')} label="↳ Adăugare documente" />
        </Tabs>
      </div>
      <div>
        <CaDosar/>
      </div>
    </div>
      <Box>
        
      {currentTab === 0 && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="outlined" color="secondary" size="small" onClick={() => handleChangeTab(1)}>+ Adăugare document nou</Button>
          </Box>
        )}
        {currentTab === 1 && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button variant="outlined" color="secondary" size="small" onClick={() => handleChangeTab(0)}>Revenire la listă documente</Button>
          </Box>
        )}

        {currentTab === 0 && (
          <div>
            <VizualizareDocumente />
          </div>
        )}
        {currentTab === 1 && (
          <div>
            <AdaugareDocumente setCurrentTab={setCurrentTab} />
          </div>
        )} 
      </Box>
    </div>
  );  
}
