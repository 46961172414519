import React, { useState, useEffect } from 'react'; 
import verificareToken from './VerificareToken';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { MenuItem } from '@mui/material';
import Swal from 'sweetalert'; 
import { Document, Packer, Paragraph, TextRun } from 'docx';

export const ExportInExcel = ({ csvData, fileName, showHeader,  showConfidential,  afisamSwal, startDate,  dateFilterType, endDate }) => {
  

    function getDenAppVers() {
        function getCookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
          return null; 
        }
      
        let denAppVers = getCookie('DenAppVers');
      
        if (!denAppVers) {
          denAppVers = localStorage.getItem('DenAppVers');
        }
      
        if (!denAppVers) {
          denAppVers = sessionStorage.getItem('DenAppVers');
        }
      
        return denAppVers || 'V2';  
      }
      
      let denAppVers = getDenAppVers();
      
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
    const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
    const formattedTime2 = currentDate.toLocaleTimeString('ro-RO', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false  
    });
    const { token } = verificareToken(); 
    const [usercurentgrup, setUsercurentgrup] = useState([]);
    const [usercurentnume, setUsercurentnume] = useState([]);
 

    const fileNameWord = `JuristPRO NEXT - Export Word - ${formattedDate} - ${formattedTime}`;

    const exportToWordTest = async () => {
      try {
          // Creăm un document simplu cu o singură secțiune
          const doc = new Document({
              sections: [
                  {
                      children: [
                          new Paragraph({
                              text: "Test document",
                              children: [new TextRun("Acesta este un test")],
                          }),
                      ],
                  },
              ],
          });
  
          const buffer = await Packer.toBlob(doc);
          FileSaver.saveAs(buffer, 'test.docx');
      } catch (error) {
          console.error('Eroare la generarea documentului Word:', error);
      }
  };
// Funcția cu Swal pentru a selecta dacă se afișează antetul și confidențialitatea
const exportToWordSWAL = (csvData, fileName) => {
  let text;

  if (!Array.isArray(csvData)) {
      console.error('csvData nu este un array sau este undefined:', csvData);
      text = 'Nu există rânduri selectate.';
  } else {
      text = `${csvData.length} de rânduri vor fi exportate în Word.`;
  }

  const container = document.createElement('div');
  container.className = 'divswal';

  container.insertAdjacentHTML(
      'beforeend',
      `
      <div class="switch-container">
        <label for="headerSwitch">Afișare antet</label>
        <label class="switch">
          <input type="checkbox" id="headerSwitch">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-container">
        <label for="confidentialSwitch">Afișare "Confidențial"</label>
        <label class="switch">
          <input type="checkbox" id="confidentialSwitch">
          <span class="slider round"></span>
        </label>
      </div>
      `
  );

  Swal({
      title: 'Export Word',
      text: text,
      content: container,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
  }).then((willContinue) => {
      if (willContinue) {
          const headerSwitch = document.getElementById('headerSwitch');
          const confidentialSwitch = document.getElementById('confidentialSwitch');

          exportToWordSWALOK(
              headerSwitch.checked,
              confidentialSwitch.checked,
              csvData,
              fileName
          );
      }
  });
};
const exportToWordSWALOK = async (
  showHeader2,
  showConfidential2,
  csvData,
  fileName,
) => {
  const sections = [];

  let groupNames;
  let fullname;
  try {
      const response = await fetch(`${window.API_URL}user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const {data} = await response.json(); // Modifică pentru a prelua întregul obiect de răspuns
  
      console.log('Răspuns API:', data); // Verifică structura completă a răspunsului
      
      // Accesăm corect `groupNames` și `fullname`
       groupNames = data.user.groupNames;
       fullname = data.user.fullname; 
    } catch (error) {
      console.error("Eroare la preluarea utilizatorilor", error);
    }
  // Afișăm antetul la început dacă este selectat
  if (showHeader2) {
    const formattedDate = new Date().toLocaleDateString('ro-RO');
    const formattedTime2 = new Date().toLocaleTimeString('ro-RO');

    const grupuriString = String(groupNames);
    const grupuri = grupuriString.split(',');
    const grupuriText =
      grupuri.length > 1
        ? `pentru ${grupuri.length} unități (${grupuri.join(', ')})`
        : `pentru unitatea ${grupuri[0]}`;

    const dateFilterTypeDen = startDate
      ? dateFilterType === 'datacreare'
        ? 'data creare dosar'
        : dateFilterType === 'datatermen'
        ? 'data termen dosar'
        : dateFilterType === 'dataum'
        ? 'data actualizare dosar'
        : ''
      : ''; 
    const mesaj1 = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
    const mesaj2 = startDate
      ? `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${endDate || formattedDate} din JuristPRO NEXT ${denAppVers}.`
      : `Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`;

    sections.push({
      children: [
        new Paragraph({
          children: [new TextRun({ text: mesaj1, bold: true })],
        }),
        new Paragraph({
          children: [new TextRun({ text: mesaj2, bold: true })],
        }),
        new Paragraph('-------------------------------------'), // Separator vizual
      ],
    });
  }

  // Adăugăm confidențialitatea dacă este selectată
  if (showConfidential2) {
    sections.push({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: `CONFIDENTIAL utilizator ${fullname}`,
              bold: true,
              color: 'FF0000',
            }),
          ],
        }),
        new Paragraph('-------------------------------------'), // Separator vizual
      ],
    });
  }

  // Parcurgem fiecare rând și creăm secțiunile documentului fără numerotare
  csvData.forEach((row) => {
    sections.push({
      children: [
        ...Object.keys(row).map((key) =>
          new Paragraph(`${key}: ${row[key] ? row[key] : ''}`)
        ),
        new Paragraph('-------------------------------------'),
      ],
    });
  });

  // Creăm documentul cu secțiuni
  const doc = new Document({
    sections: [
      {
        properties: {}, // Proprietăți opționale pentru secțiuni
        children: sections.flatMap((section) => section.children), // Combinăm toate secțiunile într-o singură secțiune mare
      },
    ],
  });

  // Generăm și salvăm documentul
  const buffer = await Packer.toBlob(doc);
  FileSaver.saveAs(buffer, `${fileNameWord}.docx`);
};

// Funcția simplă pentru export fără opțiuni de antet și confidențial
const exportToWord = async (csvData, fileName) => {
  const sections = [];

  // Parcurgem fiecare rând și creăm secțiunile documentului fără numerotare
  csvData.forEach((row) => {
    sections.push({
      children: [
        ...Object.keys(row).map((key) =>
          new Paragraph(`${key}: ${row[key] ? row[key] : ''}`)
        ),
        new Paragraph('-------------------------------------'),
      ],
    });
  });

  const doc = new Document({
    sections: [
      {
        properties: {},  // Proprietăți opționale pentru secțiuni
        children: sections.flatMap((section) => section.children),  // Combinăm toate secțiunile
      },
    ],
  });

  // Generăm și salvăm documentul
  const buffer = await Packer.toBlob(doc);
  FileSaver.saveAs(buffer, `${fileNameWord}.docx`);
};
 
  const exportToCSVSWAL = (
    csvData, 
    fileName, 
  ) => {

   
    let text;

    if (!Array.isArray(csvData)) {
        console.error('selectedRows2 nu este un array sau este undefined:', csvData);
        text = 'Nu există rânduri selectate.';
      } else {
        text = `${csvData.length} de rânduri vor fi exportate în Excel din tabel`;
      }
      
    // Creăm un container HTML pentru switch-uri
    const container = document.createElement('div');
    container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

    // Inserăm HTML pentru switch-uri personalizate stilizate
    container.insertAdjacentHTML(
      'beforeend',
      `
      <div class="switch-container">
        <label for="headerSwitch">Afișare antet</label>
        <label class="switch">
          <input type="checkbox" id="headerSwitch" ${showHeader ? 'checked' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-container">
        <label for="confidentialSwitch">Afișare "Confidențial"</label>
        <label class="switch">
          <input type="checkbox" id="confidentialSwitch" ${showConfidential ? 'checked' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      `
    );

    // Afișăm modalul Swal cu elementul creat
    Swal({
      title: 'Export Excel tabel',
      text: text,
      content: container,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const headerSwitch = document.getElementById('headerSwitch');
        const confidentialSwitch = document.getElementById('confidentialSwitch'); 

        exportToCSVSWALOK(
            headerSwitch.checked, 
            confidentialSwitch.checked,
            csvData, 
            fileName,  
          );
      }
    });
  };

  const exportToCSVSWALOK = async (
    showHeader2,
    showConfidential2,
    csvData,
    fileName,
  ) => {
    let groupNames;
    let fullname;
    try {
        const response = await fetch(`${window.API_URL}user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const {data} = await response.json(); // Modifică pentru a prelua întregul obiect de răspuns
    
        console.log('Răspuns API:', data); // Verifică structura completă a răspunsului
        
        // Accesăm corect `groupNames` și `fullname`
         groupNames = data.user.groupNames;
         fullname = data.user.fullname; 
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');
  
    // Definim variabilele necesare
    const formattedDate = new Date().toLocaleDateString('ro-RO');
    const formattedTime2 = new Date().toLocaleTimeString('ro-RO');  
  
    // Setăm coloanele fără antet (header)
    const columns = Object.keys(csvData[0]).map((key) => ({
      key: key,
      width: 20,
    }));
    worksheet.columns = columns;
  
    let currentRow = 1; // Începem de la primul rând
  
    if (showHeader2) {
      // Construim mesajele
      console.log('usercurentgrup222',groupNames);
      const grupuriString = String(groupNames);
      const grupuri = grupuriString.split(',');
      const grupuriText =
        grupuri.length > 1
          ? `pentru ${grupuri.length} unități (${grupuri.join(', ')})`
          : `pentru unitatea ${grupuri[0]}`;
  
      const dateFilterTypeDen = startDate
        ? dateFilterType === 'datacreare'
          ? 'data creare dosar'
          : dateFilterType === 'datatermen'
          ? 'data termen dosar'
          : dateFilterType === 'dataum'
          ? 'data actualizare dosar'
          : ''
        : '';
  
      const mesaj1 = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
      const mesaj2 = startDate
        ? `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${endDate || formattedDate} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`
        : `Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`;
  
      // Adăugăm rândurile personalizate
      worksheet.insertRow(currentRow, [mesaj1]);
      worksheet.mergeCells(
        `A${currentRow}:` +
          String.fromCharCode(65 + columns.length - 1) +
          `${currentRow}`
      );
      let row1 = worksheet.getRow(currentRow);
      row1.font = { bold: true, color: { argb: 'FF000000' } }; // Text negru bold
      row1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' },
      }; // Gri
      currentRow++;
  
      worksheet.insertRow(currentRow, [mesaj2]);
      worksheet.mergeCells(
        `A${currentRow}:` +
          String.fromCharCode(65 + columns.length - 1) +
          `${currentRow}`
      );
      let row2 = worksheet.getRow(currentRow);
      row2.font = { bold: true, color: { argb: 'FF000000' } }; // Text negru bold
      row2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' },
      }; // Gri
      currentRow++;
    }
  
    if (showConfidential2) {
      worksheet.insertRow(currentRow, [`CONFIDENTIAL utilizator ${fullname}`]);
      worksheet.mergeCells(
        `A${currentRow}:` +
          String.fromCharCode(65 + columns.length - 1) +
          `${currentRow}`
      );
      let row3 = worksheet.getRow(currentRow);
      row3.font = { bold: true, color: { argb: 'FFFF0000' } }; // Text roșu bold
      row3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' },
      }; // Gri
      currentRow++;
    }
  
    // Adăugăm antetul tabelului manual
    worksheet.insertRow(currentRow, Object.keys(csvData[0]));
    let headerRow = worksheet.getRow(currentRow);
    
    // Fixăm antetul și adăugăm filtre
    worksheet.views = [{ state: 'frozen', ySplit: currentRow }];

    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF808080' },
      };
      cell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    currentRow++;
  
    // Adăugăm datele din tabel
    csvData.forEach((row) => {
      worksheet.addRow(row);
    });
  
    // Stilizăm celulele și adăugăm wrap text
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true };
      });
    });
  
    const totalRows = worksheet.rowCount;
    worksheet.autoFilter = {
      from: `A${currentRow - 1}`,
      to: `${String.fromCharCode(65 + worksheet.columns.length - 1)}${totalRows}`,
    };
  
    // Generăm și salvăm fișierul Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(data, fileName + '.xlsx');
  };
  

    const exportToCSV = async (csvData, fileName) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('data');

        // Adăugăm header-ul cu stiluri
        worksheet.columns = Object.keys(csvData[0]).map((key) => ({
            header: key,
            key: key,
            width: 20,
        }));

        // Adăugăm datele
        csvData.forEach((row) => {
            worksheet.addRow(row);
        });

        // Stilizăm header-ul
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        });

        // Adăugăm wrap text pentru toate celulele
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.alignment = { wrapText: true };
            });
        });

        // Fixăm antetul și adăugăm filtre
        worksheet.views = [{ state: 'frozen', ySplit: 1 }];
        worksheet.autoFilter = {
            from: 'A1',
            to: `${String.fromCharCode(65 + worksheet.columns.length - 1)}1`,
        };

        // Generăm fișierul și îl salvăm
        const buffer = await workbook.xlsx.writeBuffer();
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(data, fileName + '.xlsx');
    };

   
return (
  <>
      <MenuItem 
          onClick={afisamSwal 
              ? () => exportToCSVSWAL(csvData, fileName) 
              : () => exportToCSV(csvData, fileName)
          }
      >
          În format Excel
      </MenuItem>
      <MenuItem 
          onClick={afisamSwal 
              ? () => exportToWordSWAL(csvData, fileName) 
              : () => exportToWord(csvData, fileName)
          }
      >
          În format Word
      </MenuItem>  
  </>
);
};
