import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import Swal from 'sweetalert';
import Checkbox from '@mui/material/Checkbox';


import verificareToken from '../VerificareToken';

function AdaugareUtilizatori({ open, onClose }) {
    const { token } = verificareToken();

  const [formData, setFormData] = useState({
    name: '',
    password: '',
    email: '',
    fullname: '',
    telefon: '',
    user_group_ids: [],  
    user_role_ids: [],  
  });
  const handleClose = () => {
    resetFormData();
    onClose(false);
  };

  const resetFormData = () => {
    setFormData({
      name: '',
      password: '',
      email: '',
      fullname: '',
      telefon: '',
      user_group_ids: [],
      user_role_ids: [],
    });
  };

  
  const [userGroups, setUserGroups] = useState([]);  
  const [userRoles, setUserRoles] = useState([]); 

  const handleSelectChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
      
    if (formData.user_role_ids.length === 0) {
      Swal({
        title: 'Atenționare',
        text: 'Trebuie să atribuiți cel puțin un rol pentru utilizatorul nou!',
        icon: 'warning',
      }).then();
      return;
    }


    try {
      // 1. pas 1 - inregistrare utilizator utilizatorul
      const registerResponse = await fetch(`${window.API_URL}register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData.name,
          password: formData.password,
          email: formData.email,
          fullname: formData.fullname,
          telefon: formData.telefon,
          user_group_ids: formData.user_group_ids, 
        }),
      });
  
      if (registerResponse.ok) {
        if (formData.user_role_ids.length > 0) {
        const registerData = await registerResponse.json();
        const userId = registerData.data.user.id;  
  
       // 2. pas 2 - atribuire roluri utilizator pentru userul creat
        const rolesParams = formData.user_role_ids.map(id => `user_role_ids[]=${id}`).join('&');
        const setUserAccessResponse = await fetch(`${window.API_URL}setUserAccess?user_id=${userId}&${rolesParams}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (setUserAccessResponse.ok) { 
          Swal({
            title: 'Succes',
            text: 'Utilizatorul a fost adăugat și rolurile au fost atribuite cu succes!',
            icon: 'success',
          }).then(() => {
            resetFormData();
            onClose(true);  
          });
        } else { 
          const setUserAccessData = await setUserAccessResponse.json();
          const errorMessagesRole = Object.values(setUserAccessData.message)
        .flat()
        .join('\n'); 
        
         throw new Error(errorMessagesRole || 'Eroare la atribuirea rolurilor.');
         
        }
       } else {
        Swal({
          title: 'Succes',
          text: 'Utilizatorul a fost adăugat cu succes dar nu ați selectat nici un rol!',
          icon: 'success',
        }).then(() => {
          resetFormData();
          onClose(true);  
        }); 
        }
      } else { 
        const registerData = await registerResponse.json();
        const errorMessagesRole = Object.values(registerData.message)
        .flat()
        .join('\n'); 
        
         throw new Error(errorMessagesRole || 'Eroare la înregistrarea utilizatorului.');
       

        } 
      } catch (error) { 
      Swal({
        title: 'Atenționare',
        text: error.toString(),
        icon: 'warning',
      });
      console.error('A apărut o eroare la adăugarea utilizatorului și la atribuirea rolurilor: ', error);
    }
  };
  
  useEffect(() => {
    // iau userGroups
    const fetchUserGroups = async () => {
      try {
        const response = await fetch(`${window.API_URL}userGroups`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserGroups(data.data.userGroups);
        } else {
          console.error('A apărut o eroare la aducerea grupurilor de utilizatori.');
        }
      } catch (error) {
        console.error('A apărut o eroare la aducerea grupurilor de utilizatori: ', error);
      }
    };

    // iau userRoles
    const fetchUserRoles = async () => {
      try {
        const response = await fetch(`${window.API_URL}userRoles`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserRoles(data.data.userRoles);
        } else {
          console.error('A apărut o eroare la aducerea rolurilor de utilizatori.');
        }
      } catch (error) {
        console.error('A apărut o eroare la aducerea rolurilor de utilizatori: ', error);
      }
    };

    fetchUserGroups();
    fetchUserRoles();

    if (open) {
        fetchUserGroups();
        fetchUserRoles();
      }
    }, [open, token]); 

     
  
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle className='titluModal'>Adăugare Utilizator</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Completați informațiile utilizatorului nou.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Nume utilizator"
          type="text"
          fullWidth
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="password"
          label="Parolă"
          type="password"
          fullWidth
          value={formData.password}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="fullname"
          label="Nume complet"
          type="text"
          fullWidth
          value={formData.fullname}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="telefon"
          label="Telefon"
          type="text"
          fullWidth
          value={formData.telefon}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="user-groups-label">Grupuri Utilizatori</InputLabel>
          <Select
            labelId="user-groups-label"
            id="user-groups"
            name="user_group_ids"
            label="Grupuri Utilizatori"
            multiple
            value={formData.user_group_ids}
            onChange={(event) => handleSelectChange(event, 'user_group_ids')}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={userGroups.find((group) => group.id === value)?.name} style={{ marginRight: '4px' }} />
                ))}
              </div>
            )}
          >
            {userGroups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={formData.user_group_ids.indexOf(group.id) > -1} />
                {group.name}
            </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel id="user-roles-label">Roluri Utilizatori</InputLabel>
          <Select
            labelId="user-roles-label"
            id="user-roles"
            name="user_role_ids"
            label="Roluri Utilizatori"
            multiple
            value={formData.user_role_ids}
            onChange={(event) => handleSelectChange(event, 'user_role_ids')}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={userRoles.find((role) => role.id === value)?.name} style={{ marginRight: '4px' }} />
                ))}
              </div>
            )}
          >
            {userRoles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
                <Checkbox checked={formData.user_role_ids.indexOf(role.id) > -1} />
                {role.name}
            </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anulare
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Adăugare
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdaugareUtilizatori;
