import React, { useState, useEffect } from 'react'; 
import { Button, FormControlLabel, Switch, InputLabel, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Select, MenuItem, FormControl } from '@mui/material';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';
import EditIcon from '@mui/icons-material/Edit'; 
import DeleteIcon from '@mui/icons-material/Delete'; 

function CampuriCustom() {
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { token } = verificareToken();

  useEffect(() => {
    fetchCustomFields();
  }, []);

  const fetchCustomFields = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${window.API_URL}customFields`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setCustomFields(data.data.customFields);
    } catch (error) {
      console.error('Failed to fetch custom fields:', error);
    }
    setLoading(false);
  };

  const handleOpenDialog = (field = null) => {
    setIsEditing(!!field);
    setCurrentField(field || {
      id: null,  
      name: '',
      label: '',
      type: 'text',
      um: '',
      info: '',
      listing: 1,
      filter: 1,
      export: 1,
      field_order: 1,
      active: 1
    });
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setCurrentField(prev => ({ ...prev, [name]: value }));
  };

  const saveCustomField = async () => {
    const url = isEditing ? `${window.API_URL}updateCustomField/${currentField.id}` : `${window.API_URL}addCustomField`;
    const method = isEditing ? 'PATCH' : 'POST';
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentField)
      });
      if (response.ok) {
        Swal('Succes', `Campul custom a fost ${isEditing ? 'actualizat' : 'creat'} cu succes!`, 'success');
        fetchCustomFields();
        handleCloseDialog();
      } else {
        Swal('Eroare', 'A apărut o eroare la salvarea câmpului custom.', 'warning');
      }
    } catch (error) {
      console.error('Failed to save custom field:', error);
      Swal('Eroare', 'A apărut o eroare la salvarea câmpului custom.', 'warning');
    }
  };

 
  const deleteCustomField = (id) => {
    Swal({
      title: 'Ești sigur?',
      text: "Nu vei putea reveni asupra acestei acțiuni!",
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        performDelete(id);
      }
    });
  };


  const performDelete = async (id) => {
    try {
      const response = await fetch(`${window.API_URL}deleteCustomField/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        Swal('Șters!', 'Câmpul a fost șters.', 'success');
        fetchCustomFields();
      } else {
        Swal('Eroare', 'A apărut o eroare la ștergerea câmpului.', 'warning');
      }
    } catch (error) {
      console.error('Failed to delete custom field:', error);
      Swal('Eroare', 'A apărut o eroare la ștergerea câmpului.', 'warning');
    }
  };

  const toggleFieldState = async (fieldId, fieldName, newValue) => {
    const updatedField = { ...customFields.find(field => field.id === fieldId), [fieldName]: newValue };
  
    try {
      const response = await fetch(`${window.API_URL}updateCustomField/${fieldId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedField)  
      });
      if (response.ok) {
        setCustomFields(customFields.map(field => field.id === fieldId ? updatedField : field));
      } else {
        const errorData = await response.json();
        Swal('Eroare', errorData.message || 'A apărut o eroare la actualizarea câmpului custom.', 'error');
      }
    } catch (error) {
      console.error('Failed to toggle field state:', error);
      Swal('Eroare', 'A apărut o eroare la actualizarea câmpului custom.', 'error');
    }
  };
  
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button  size='small' className='mt-2 mb-2 pull-right' onClick={() => handleOpenDialog()} variant="contained" color="secondary">
            Adăugare câmp nou
          </Button>
          <Table className='table table-bordered table-responsive'>
            <TableHead>
            <TableRow>
                <TableCell>Nume</TableCell>
                <TableCell>Etichetă</TableCell>
                <TableCell>Tip</TableCell>
                <TableCell>Informații</TableCell>
                <TableCell className='text-center'>Afișat în listă</TableCell>
                <TableCell className='text-center'>Afișat la export</TableCell>
                <TableCell className='text-center'>Afișat la filtrare</TableCell>
                <TableCell className='text-center'>Stare</TableCell>
                <TableCell className='text-center'>Acțiuni</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {customFields.map((field) => (
            <TableRow key={field.id} className={!field.active ? 'row-inactive' : ''}>
                <TableCell>{field.name}</TableCell>
                <TableCell>{field.label}</TableCell>
                <TableCell>{field.type}</TableCell>
                <TableCell>{field.info}</TableCell>
                <TableCell className='text-center'>
                    <Switch checked={field.listing === 1} onChange={(e) => toggleFieldState(field.id, 'listing', e.target.checked ? 1 : 0)} />
                </TableCell>
                <TableCell className='text-center'>
                    <Switch checked={field.export === 1} onChange={(e) => toggleFieldState(field.id, 'export', e.target.checked ? 1 : 0)} />
                </TableCell>
                <TableCell className='text-center'>
                    <Switch checked={field.filter === 1} onChange={(e) => toggleFieldState(field.id, 'filter', e.target.checked ? 1 : 0)} />
                </TableCell>
                <TableCell className='text-center'>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={field.active === 1}
                            onChange={(e) => toggleFieldState(field.id, 'active', e.target.checked ? 1 : 0)}
                        />
                        }
                        label={
                        <span style={{ fontWeight: 'bold', color: field.active === 1 ? 'green' : 'red' }}>
                            {field.active === 1 ? "Activ" : "Inactiv"}
                        </span>
                        }
                    />
                    </TableCell>

                <TableCell className='text-center'>
                    <IconButton onClick={() => handleOpenDialog(field)}><EditIcon /></IconButton>
                    <IconButton onClick={() => deleteCustomField(field.id)}><DeleteIcon /></IconButton>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle className='titluModal'>{isEditing ? 'Editează' : 'Creează'} câmp custom</DialogTitle>
      <DialogContent className='mt-2'>
        <Grid container spacing={2} className='mt-1'>
          {currentField && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Nume"
                  name="name"
                  fullWidth
                  value={currentField.name}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Etichetă"
                  name="label"
                  fullWidth
                  value={currentField.label}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Tip</InputLabel>
                  <Select
                    name="type"
                    label="Tip"
                    value={currentField.type}
                    onChange={handleFieldChange}
                  >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="textarea">Textarea</MenuItem>
                    <MenuItem value="date">Date</MenuItem>
                    <MenuItem value="numeric">Numeric</MenuItem>
                    <MenuItem value="file">File</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Unitate de măsură"
                  name="um"
                  fullWidth
                  value={currentField.um}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Informații"
                  name="info"
                  fullWidth
                  value={currentField.info}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={currentField.listing} onChange={(e) => handleFieldChange({ target: { name: 'listing', value: e.target.checked } })} />}
                  label="Afișat la lista de dosare"
                />
                <FormControlLabel
                  control={<Switch checked={currentField.export} onChange={(e) => handleFieldChange({ target: { name: 'export', value: e.target.checked } })} />}
                  label="Afișat la export"
                />
                <FormControlLabel
                  control={<Switch checked={currentField.filter} onChange={(e) => handleFieldChange({ target: { name: 'filter', value: e.target.checked } })} />}
                  label="Afișat la filtrare"
                />
                <FormControlLabel
                  control={<Switch checked={currentField.active} onChange={(e) => handleFieldChange({ target: { name: 'active', value: e.target.checked } })} />}
                  label="Activ"
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Anulează
        </Button>
        <Button onClick={saveCustomField} color="primary">
          Salvează
        </Button>
      </DialogActions>
    </Dialog>

        </>
      )}
    </div>
  );
}

export default CampuriCustom;
