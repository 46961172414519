import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IstoricDosar from './dosar/istoricDosar';

const ModalComponentIstoric = ({ open, onClose, idDosar }) => {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle className='titluModal'>
          Istoric activitate dosar
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#FFFFFF',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='mt-4'>
          <IstoricDosar idProp={idDosar} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Închidere
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default ModalComponentIstoric;
