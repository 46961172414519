const roLocale = {
    code: 'ro',
    week: {
      dow: 1, // Luni este prima zi a săptămânii
      doy: 4  // Prima săptămână care conține 4 ianuarie este considerată prima săptămână a anului
    },
    buttonText: {
      prev: 'Anterior',
      next: 'Următor',
      today: 'Astăzi',
      month: 'Lună',
      week: 'Săptămână',
      day: 'Zi',
      list: 'Listă'
    },
    allDayText: 'Toată ziua',
    moreLinkText: 'mai multe',
    noEventsText: 'Nu există evenimente de afișat',
    weekNumberTitle: 'Săptămâna',
    eventLimitText: 'mai multe',
    eventLimitClick: 'vezi toate', 
    eventDurationText: 'Durată',
    editButtonTitle: 'Editează eveniment',
    deleteButtonTitle: 'Șterge eveniment',
    confirmDeletionTitle: 'Confirmă ștergerea',
    confirmDeletionMessage: 'Ești sigur că vrei să ștergi acest eveniment?',
    views: {
      month: 'Lună',
      week: 'Săptămână',
      day: 'Zi',
      list: 'Listă',
      today: 'Astăzi',
      previous: 'Anterior',
      next: 'Următor'
    },
    monthNames: [
      'Ianuarie',
      'Februarie',
      'Martie',
      'Aprilie',
      'Mai',
      'Iunie',
      'Iulie',
      'August',
      'Septembrie',
      'Octombrie',
      'Noiembrie',
      'Decembrie'
    ],
    monthNamesShort: [
      'Ian',
      'Feb',
      'Mar',
      'Apr',
      'Mai',
      'Iun',
      'Iul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    dayNames: [
      'Duminică',
      'Luni',
      'Marți',
      'Miercuri',
      'Joi',
      'Vineri',
      'Sâmbătă'
    ],
    dayNamesShort: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm'], 
    
  };
  
  export default roLocale;
  