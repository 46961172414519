import React, { useState, useEffect } from 'react';
import { Alert, CircularProgress, Button, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert'; 
import { Chip, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import verificareToken from './VerificareToken';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import StorageIcon from '@mui/icons-material/Storage';
function BackupRestore() {
   //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
   window.dispatchEvent(new Event('authChange'));
  
   const [backups, setBackups] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { token } = verificareToken();
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isRestoring, setIsRestoring] = useState(false);
    const [golireDosare, setGolireDosare] = useState(false);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFile(null);
        setFileName('');
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    const handleDeleteBackup = () => {
        Swal({
          title: 'Sigur doriți golirea bazei de date?',
          text: 'Această acțiune nu poate fi anulată iar baza de date actuală va fi golită!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, confirm!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            handleDeleteBackupOK();
          }
        });
      };
    const handleDeleteBackupOK = () => {
        setGolireDosare(true);
        fetch(`${window.API_URL}clearDatabase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            setGolireDosare(false);
            Swal('Golire completă', 'Golirea bazei de date s-a efectuat cu succes.', 'success');
            
            fetchBackupuri();

        })
        .catch(error => {
            setIsRestoring(false);
            Swal('Golire întreruptă', 'Golirea bazei de date nu s-a finalizat.', 'warning');
        });
    };

    const handleRestoreFromList = (filename) => {
        Swal({
          title: 'Sigur doriți restaurarea acestui backup?',
          text: 'Această acțiune nu poate fi anulată iar baza de date actuală și fișierele încărcate vor fi suprascrise!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, confirm!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            handleRestoreFromListOK(filename);
          }
        });
      };
    const handleRestoreFromListOK = (filename) => {
        setIsRestoring(true);
        fetch(`${window.API_URL}restore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ file: filename })
        })
        .then(response => response.json())
        .then(data => {
            setIsRestoring(false);
            Swal('Restaurare completă', 'Restaurarea bazei de date și a fișierelor s-a efectuat cu succes.', 'success');

        })
        .catch(error => {
            setIsRestoring(false);
            Swal('Restaurare întreruptă', 'Restaurarea bazei de date și a fișierelor nu s-a finalizat.', 'warning');
        });
    };

    
    const handleRestore = () => {
        if (!file) {
            Swal('Atenție', 'Vă rugăm să selectați un fișier ZIP pentru a continua.', 'warning');
            return;
        }
        setIsRestoring(true);
        handleClose();
        const formData = new FormData();
        formData.append('backup_file', file);
    
        fetch(`${window.API_URL}restoreUpload`, {  
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            handleClose();
            setIsRestoring(false);
            Swal('Restaurare completă', 'Restaurarea bazei de date și a fișierelor s-a efectuat cu succes.', 'success');
        })
        .catch(error => {
            handleClose();
            setIsRestoring(false);
            Swal('Restaurare întreruptă', 'Restaurarea bazei de date și a fișierelor nu s-a finalizat.', 'warning');
        });
    };
    
    const fetchBackupuri = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${window.API_URL}listBackups`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            method: 'GET',
          });
          const data = await response.json();
          const sortedBackups = data.backups.sort((a, b) => new Date(b.data + ' ' + b.ora) - new Date(a.data + ' ' + a.ora));
          setBackups(sortedBackups);
         
          setLoading(false);
        } catch (error) {
          console.error('Error fetching stari:', error);
        }
      };

    const efectuareBackup = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${window.API_URL}backup`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
          });
          fetchBackupuri();
          setLoading(false);
        } catch (error) {
          console.error('Eroare la efectuarea backup-ului:', error);
        }
      };

      
    useEffect(() => {
        fetchBackupuri();
    }, []);  

    const deleteBackup = (userId) => {
        Swal({
          title: 'Sigur doriți ștergerea acestui backup?',
          text: 'Această acțiune nu poate fi anulată iar backup-ul nu poate fi recuperat!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, șterge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteBackupOK(userId);
          }
        });
      };
  
    const deleteBackupOK = (filename) => {
        fetch(`${window.API_URL}deleteBackup`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ filename })
        })
        .then(response => {
            if (response.ok) {
                setBackups(backups.filter(backup => backup.name !== filename));
                fetchBackupuri();
            } else {
                throw new Error('Eroare la ștergere backup');
            }
        })
        .catch(err => {
            setError(err);
        });
    };

    function formatDate(dateString, timeString) {
        const dateTime = `${dateString}T${timeString}`;
        const date = new Date(dateTime);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');  
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`;
    }
    
    const handleDownload = (filename) => {
        const filePath = `./fisiere/backups/${filename}`;
        window.open(filePath, '_blank');
    };

    function getBackupAgeLabel(dateString) {
        const today = new Date();
        const backupDate = new Date(dateString);
        const diffTime = today - backupDate;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
        if (diffDays === 0) {
            return { label: 'Astăzi', color: 'success' };
        } else if (diffDays === 1) {
            return { label: 'Ieri', color: 'info' };
        } else {
            return { label: `De ${diffDays} zile`, color: 'warning' };
        }
    }
    
    
    return (
        <div className="container card p-3 mt-5">
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
                <span style={{ marginLeft: '10px' }}> Se încarcă backup-urile...</span>
                </div>
            ) : (
            <>
                <div style={{ borderBottom:'1px solid #ccc', paddingBottom:'10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <b>
                        <CloudSyncIcon /> Administrare backup și restaurare
                    </b>
                    <div>
                        <Button onClick={efectuareBackup} className='text-bold' size="small" variant='contained' color='secondary'>
                            <CloudDoneIcon style={{marginRight:'5px'}}/> Efectuare backup
                        </Button> 
                        
                        <Button style={{marginLeft:'10px'}} onClick={handleClickOpen} className='text-bold' size="small" variant='contained' color='primary'>
                            <RestoreIcon  style={{marginRight:'5px'}}/> Restaurare din fișier
                        </Button>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle className='titluModal' id="form-dialog-title">Restaurare bază de date și fișiere din fișier </DialogTitle>
                            <DialogContent className='mt-3'>
                                <input
                                    accept=".zip"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="upload-file"
                                />
                                <label htmlFor="upload-file">
                                    <Button color='primary' size="small" variant='contained' component="span" startIcon={<CloudUploadIcon />}>
                                        Alegeți fișierul
                                    </Button>
                                </label>
                                {fileName && <Typography variant="subtitle1" style={{ marginTop: '20px' }}>{fileName}</Typography>}
                            </DialogContent>
                            <DialogActions>
                                <Button variant='text' color="error" onClick={handleClose}>
                                    Anulare
                                </Button>
                                <Button variant='text' color="success" onClick={handleRestore}>
                                    Restaurare
                                </Button>
                            </DialogActions>
                        </Dialog>

                        
                        <Button style={{marginLeft:'15px'}} onClick={handleDeleteBackup} className='text-bold' size="small" variant='contained' color='error'>
                            <StorageIcon style={{marginRight:'5px'}}/> Golire bază de date
                        </Button> 
                    </div>

                </div>
                {backups.length > 0 ? (
                    <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                    <Table stickyHeader className='table table-responsive table-bordered table-striped'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Backup</TableCell>
                                <TableCell>Dată</TableCell>
                                <TableCell>Oră</TableCell>
                                <TableCell>Dimensiune</TableCell>
                                <TableCell>Vechime</TableCell>
                                <TableCell>Acțiune</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {backups.map(backup => {
                                const ageLabel = getBackupAgeLabel(backup.data);
                                return (
                                    <TableRow key={backup.name}>
                                        <TableCell>{backup.name}</TableCell>
                                        <TableCell>{formatDate(backup.data, backup.ora)}</TableCell>
                                        <TableCell>{backup.ora}</TableCell>
                                        <TableCell>{backup.size}</TableCell>
                                        <TableCell>
                                            <Chip label={ageLabel.label} className="vechime" color={ageLabel.color} />
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={`Descărcare backup din data ${formatDate(backup.data, backup.ora)} ora ${backup.ora}`}>
                                                <IconButton onClick={() => handleDownload(backup.name)}>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={`Restaurare backup din data ${formatDate(backup.data, backup.ora)} ora ${backup.ora}`}>
                                                <IconButton onClick={() => handleRestoreFromList(backup.name)}>
                                                    <RestoreIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={`Ștergere backup din data ${formatDate(backup.data, backup.ora)} ora ${backup.ora}`}>
                                                <IconButton onClick={() => deleteBackup(backup.name)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    </TableContainer>
                ) : (
                    <>
                    <Alert severity="warning">Nu aveți nici un backup efectuat. <Button onClick={efectuareBackup} className='text-bold' size="small" variant='text' color='success'>Efectuați un backup acum</Button>
                   </Alert>
                     </>
                )}
            </>
            )}
            {isRestoring && (
                <div style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={80} />
                        <Typography variant="h5" style={{ color: 'white', marginTop: 20 }}>
                            Restaurarea este în curs de procesare, vă rugăm așteptați...
                        </Typography>
                    </div>
                </div>
            )}

            {golireDosare && (
                <div style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={80} />
                        <Typography variant="h5" style={{ color: 'white', marginTop: 20 }}>
                            Golirea bazei de date de dosare este în curs procesare, vă rugăm așteptați...
                        </Typography>
                    </div>
                </div>
            )}

        </div>
    );
}

export default BackupRestore;
