import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Alert, Box, List, ListItem, ListItemText, CircularProgress, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp'; 

const DocPartajate = ({ cheie: propCheie }) => {
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
  const { cheie: urlCheie } = useParams();
  const cheie = propCheie || urlCheie;
  const [documente, setDocumente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [descriere, setDescriere] = useState(''); 
  const [fullname, setFullname] = useState(''); 
  const [datacreare, setDatacreare] = useState('');  
  const [iddosar, setIddosar] = useState(''); 
  const [numardosar, setNumardosar] = useState(''); 

  // Funcția pentru a prelua documentele partajate
  const fetchDocumentePartajate = async () => {
    const apiUrl = window.API_URL;

    try {
      const response = await fetch(`${apiUrl}vizualizareDocumente/${cheie}`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
      });
     
      if (!response.ok) {
        throw new Error('Eroare la preluarea documentelor');
      }

      const data = await response.json();
      setDocumente(data.documente);
      setDescriere(data.descriere);  
      setFullname(data.fullname);   
      setIddosar(data.iddosar);  
      setDatacreare(data.datacreare);  
      setNumardosar(data.numardosar);  
    } catch (error) {
      setError('Accesul la documentele partajate a fost șters sau a expirat!');
    } finally {
      setLoading(false);
    }
  };
    
  useEffect(() => {
    fetchDocumentePartajate(); 
  }, [cheie]);

  // Funcția pentru descărcarea fișierului
  const handleDownload = (docName, category) => {
    const appUrl = window.APP_URL;
    const downloadUrl = `${appUrl}/fisiere/in/${iddosar}/${category}/${docName}`; 
    window.open(downloadUrl, '_blank');
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2); 
    return `${sizeInMB} MB`;
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}.${month}.${year} ora ${hours}:${minutes}`;
  };
  
  return (
    <div 
        style={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',  
          transition: 'max-width 0.3s ease-in-out' 
        }} 
        className="container card p-3 mt-3"  
      >
      
      {loading ? (
        <div style={{margin:'0 auto', padding:'50px'}}><CircularProgress /></div>
      ) : error ? (
        <Alert severity='warning'>{error}</Alert>
      ) : (
        documente && (
          <>
          <Typography variant="h5" gutterBottom className='titluComponenta mt-2'>
            Documente partajate de {fullname} în data de {datacreare} pentru dosarul {numardosar}
            <hr />
          </Typography>

          <Typography variant='p' className='titluComponentaOK'>
              Documente partajate de {fullname} în data de {datacreare} pentru dosarul {numardosar}
            <hr />
            </Typography>
          <Alert severity="info" className='mb-3'>Descriere: {descriere} </Alert>
          
          <Box className='listaDocumente'>
            <Typography variant="h6" className='document-category-header'>Continut dosar:</Typography>
            <List>
              {documente.continut.length > 0 ? (
                documente.continut.map((doc, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={doc.name} secondary={`Dimensiune: ${formatFileSize(doc.size)}, Ultima modificare: ${formatDate(doc.last_modified)}`} />
                    <IconButton onClick={() => handleDownload(doc.name, 'continut')}>
                      <GetAppIcon />
                    </IconButton>
                  </ListItem>
                ))
              ) : (
                <Alert severity='warning'>Nu există documente atașate la conținut dosar.</Alert>
              )}
            </List>

            <Typography variant="h6" className='document-category-header'>Documente prealabile:</Typography>
            <List>
              {documente.prealabil.length > 0 ? (
                documente.prealabil.map((doc, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={doc.name} secondary={`Dimensiune: ${formatFileSize(doc.size)}, Ultima modificare: ${formatDate(doc.last_modified)}`} /> 
                    <IconButton onClick={() => handleDownload(doc.name, 'prealabil')}>
                      <GetAppIcon />
                    </IconButton>
                  </ListItem>
                ))
              ) : (
                <Alert severity='warning'>Nu există documente atașate pentru dosarul prealabil.</Alert>
              )}
            </List>

            <Typography variant="h6" className='document-category-header'>Proveniență dosar:</Typography>
            <List>
              {documente.prov.length > 0 ? (
                documente.prov.map((doc, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={doc.name} secondary={`Dimensiune: ${formatFileSize(doc.size)}, Ultima modificare: ${formatDate(doc.last_modified)}`} />
                    <IconButton onClick={() => handleDownload(doc.name, 'prov')}>
                      <GetAppIcon />
                    </IconButton>
                  </ListItem>
                ))
              ) : (
                <Alert severity='warning'>Nu există documente atașate pentru proveniență dosar.</Alert>
              )}
            </List>
          </Box>
          </>
        )
      )}
    </div>
  );
};

export default DocPartajate;
 