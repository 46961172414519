import React, { useState, useEffect, useRef } from 'react';
import mammoth from 'mammoth';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box, InputBase, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import _ from 'lodash'; 
import mark from 'mark.js'; 

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  toc: {
    flex: '1 0 20%',
    padding: '10px',
    borderRight: '1px solid #ccc',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  content: {
    flex: '3 0 80%',
    padding: '10px',
    overflowY: 'auto',
    maxHeight: '70vh',
    position: 'relative',
  },
  root: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& p img:only-child': {
      display: 'block',
      margin: '0 auto',
    },
    '& p.single-image': {
      textAlign: 'center',
    },
    '& p.single-image img': {
      display: 'block',
      margin: '0 auto',
    },
    '& p.inline img': {
      display: 'inline',
      margin: '0',
    },
    '& p img.small-image': {
      display: 'inline',
      margin: '0',
    },
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
    },
    '& th, & td': {
      border: '1px solid #ccc',
      padding: '8px',
      textAlign: 'left',
    },
    '& .center': {
      textAlign: 'center',
      marginBottom: '20px',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .highlight': {
      backgroundColor: 'yellow',
    },
    '& .activ-highlight': {
      backgroundColor: 'yellow',
      textDecoration: 'underline',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
  },
  searchInput: {
    marginLeft: '8px',
    flex: 1,
  },
  searchControls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}));

const ManualUtilizare = ({ open, onClose }) => {
  const classes = useStyles();
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [tocContent, setTocContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [allMatches, setAllMatches] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [highlightAll, setHighlightAll] = useState(true);
  const contentRef = useRef();
  const originalContentRef = useRef('');

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch('/help/manual.docx');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const arrayBuffer = await response.arrayBuffer();

        mammoth.convertToHtml({ arrayBuffer }, {
          styleMap: [
            "p[style-name='Heading 1'] => h1:fresh",
            "p[style-name='Heading 2'] => h2:fresh",
            "p[style-name='Heading 3'] => h3:fresh",
            "p[style-name='Heading 4'] => h4:fresh",
            "p[style-name='Heading 5'] => h5:fresh",
            "p[style-name='Heading 6'] => h6:fresh",
            "p[style-name='Normal'] => p:fresh",
            "p[style-name='Caption'] => p.center:fresh",
            "p[style-name='Image Caption'] => p.center:fresh",
            "table => table:fresh",
            "tr => tr:fresh",
            "td => td:fresh",
            "th => th:fresh",
            "ul => ul:fresh",
            "ol => ol:fresh",
            "li => li:fresh",
            "strong => strong",
            "em => em",
            "u => u",
            "span[style-name='Emphasis'] => em",
            "span[style-name='Strong'] => strong",
            "p[style-name='center'] => p.center:fresh",
            "p[style-name='right'] => p.right:fresh",
          ],
          convertImage: mammoth.images.imgElement((image) => {
            return image.read("base64").then((imageBuffer) => {
              return {
                src: `data:${image.contentType};base64,${imageBuffer}`
              };
            });
          })
        })
          .then((result) => {
            let htmlString = result.value; // HTML output
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            // Apply the class for paragraphs containing only images or images with captions
            const paragraphs = doc.querySelectorAll('p');
            paragraphs.forEach(paragraph => {
              const imgs = paragraph.querySelectorAll('img');
              const text = paragraph.textContent.trim();
              imgs.forEach(img => {
                if (img.width < 100) {
                  img.classList.add('small-image');
                }
              });
              if (imgs.length === 1 && text === '') {
                paragraph.classList.add('single-image');
              } else if (imgs.length === 1 && text !== '') {
                paragraph.classList.add('inline');
              }
            });

            // Separate TOC and content
            const tocParagraphs = Array.from(doc.body.querySelectorAll('p, h1, h2, h3, h4, h5, h6')).slice(0, 48);
            const contentParagraphs = Array.from(doc.body.children).slice(48);

            const tocHtml = tocParagraphs.map((p, index) => {
              const id = `toc-${index}`;
              p.setAttribute('id', id);
              return p.outerHTML;
            }).join('');

            const tocTitles = tocParagraphs.reduce((acc, p) => {
              const aTag = p.querySelector('a[href]');
              if (aTag) {
                const id = aTag.getAttribute('href').substring(1);
                acc[id] = aTag.textContent.trim();
              }
              return acc;
            }, {});

            const contentHtml = contentParagraphs.map(p => {
              if (p.tagName.match(/^H[1-6]$/)) {
                const aTags = p.querySelectorAll('a[id]');
                aTags.forEach(aTag => {
                  const id = aTag.getAttribute('id');
                  if (tocTitles[id]) {
                    p.innerHTML = `<a id="${id}"></a>${tocTitles[id]}`;
                  }
                });
              }
              return p.outerHTML;
            }).join('');

            setTocContent(tocHtml);
            setHtmlContent(contentHtml);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Conversion error: ", error);
            setLoading(false);
          });
      } catch (error) {
        console.error("Fetch error: ", error);
        setLoading(false);
      }
    };

    if (open) {
      fetchFile();
    }
  }, [open]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  
    const content = contentRef.current;
    if (value.trim() === '') {
      const instance = new mark(content);
      instance.unmark();   
      handleResetSearch();   
    } else {
      performSearch(value);
    }
  };
  

  const handleResetSearch = () => {
    setSearchTerm('');   
    restoreOriginalContent();  
  };
  
  const restoreOriginalContent = () => {
    if (originalContentRef.current) {
      setHtmlContent(originalContentRef.current); 
    }
    setAllMatches([]);
    setCurrentMatchIndex(0);
  };
  

  const performSearch = (searchTerm) => {
    const content = contentRef.current;
    if (!content) return;

    const instance = new mark(content);
    instance.unmark({
      done: () => {
        if (searchTerm) {
          let matches = [];
          instance.mark(searchTerm, {
            separateWordSearch: false,
            done: (totalMatches) => {
              console.log(`${totalMatches} found`);
              matches = document.querySelectorAll('mark');
              setAllMatches(matches);
              if (matches.length > 0) {
                highlightCurrentMatch(0);
              }
            },
            debug: false,
          });
        }
      }
    });
  };

  const highlightCurrentMatch = (index) => {
    allMatches.forEach((match, i) => {
      match.style.backgroundColor = 'yellow';
      match.style.textDecoration = i === index ? 'underline' : 'none';
      if (i === index) {
        match.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
    setCurrentMatchIndex(index);
  };

  const handleNextMatch = () => {
    if (allMatches.length > 0) {
      const nextIndex = (currentMatchIndex + 1) % allMatches.length;
      highlightCurrentMatch(nextIndex);
    }
  };

  const handlePreviousMatch = () => {
    if (allMatches.length > 0) {
      const prevIndex = (currentMatchIndex - 1 + allMatches.length) % allMatches.length;
      highlightCurrentMatch(prevIndex);
    }
  };

  const handleToggleHighlightAll = (event) => {
    setHighlightAll(event.target.checked);
    if (allMatches.length > 0) {
      if (event.target.checked) {
        // Show all highlights
        allMatches.forEach((match, i) => {
          match.style.backgroundColor = 'yellow';
          match.style.textDecoration = i === currentMatchIndex ? 'underline' : 'none';
        });
      } else {
        // Show only the current highlight
        highlightCurrentMatch(currentMatchIndex);
      }
    }
  };

  const handleTocClick = (event) => {
    const target = event.target.closest('a');
    if (target) {
      event.preventDefault();
      const targetId = target.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleNextMatch();  
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [allMatches, currentMatchIndex]);   

  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle className='titluModal modalHelp' style={{ backgroundColor: '#333333', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <HelpIcon /> Manual de utilizare
        <div className={classes.searchContainer}></div>
     
        <div className={classes.searchContainer}>
          <InputBase
            placeholder="Căutare..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={classes.searchInput}
          />
          <IconButton onClick={handleResetSearch} style={{color:'#333 !important'}}>
            <CloseIcon style={{color:'#333 !important'}}/>
          </IconButton>
          <FormControlLabel
            className='toateManual'
            control={
              <Checkbox
                checked={highlightAll}
                onChange={handleToggleHighlightAll}
                name="highlightAll"
                color="primary"
              />
            }
            label="Toate"
            style={{display:'none'}}
          />
          <IconButton onClick={handlePreviousMatch} disabled={allMatches.length === 0}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={handleNextMatch} disabled={allMatches.length === 0}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
        
      </DialogTitle>
      <DialogContent className={`${classes.dialogContent}`}>
        <Box className={`${classes.toc} stangaManual`} onClick={handleTocClick}>
          {loading ? <CircularProgress /> : <div dangerouslySetInnerHTML={{ __html: tocContent }} />}
        </Box>
        <Box className={`${classes.content} dreaptaManual`} ref={contentRef}>
          {loading ? <CircularProgress /> : <div className={classes.root} dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Închidere
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualUtilizare;
