import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button, Switch, FormControlLabel, FormGroup, Divider, Grid, Box, Typography, Tooltip, IconButton } from '@mui/material';
import Swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ResetareParola = () => {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [message, setMessage] = useState('');
 
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [criteriaMet, setCriteriaMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const { token } = useParams();  

 
  const validatePassword = (password) => {
    const length = password.length >= 10;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[^a-zA-Z0-9]/.test(password);

    setCriteriaMet({ length, uppercase, lowercase, number, specialChar });

    const strength = [length, uppercase, lowercase, number, specialChar].filter(Boolean).length;
    setPasswordStrength(strength);

    return strength === 5;
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };
  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);
  
  const generatePassword = () => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()';
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];

    const password = [
      getRandomChar(uppercaseChars),
      getRandomChar(lowercaseChars),
      getRandomChar(numberChars),
      getRandomChar(specialChars),
    ];

    for (let i = 4; i < 10; i++) {
      password.push(getRandomChar(allChars));
    }

    setGeneratedPassword(password.sort(() => Math.random() - 0.5).join(''));
  };

  const chooseGeneratedPassword = () => {
    setNewPassword(generatedPassword);
    setConfirmPassword(generatedPassword);
    validatePassword(generatedPassword);
  };

  const renderStrengthBlocks = () => {
    const colors = ['#FF3E3E', '#FF8C00', '#FFD700', '#ADFF2F', '#32CD32'];
    return (
      <Box display="flex" gap={1} mt={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            width="20%"
            height="10px"
            bgcolor={index < passwordStrength ? colors[index] : '#E0E0E0'}
          />
        ))}
      </Box>
    );
  };

  const renderCriteria = () => (
    <Box mt={2}>
      <Typography variant="h6">Criterii pentru o parolă validă:</Typography>
      <ul style={{ paddingLeft: '10px', marginBottom: '20px' }}>
        {[
          { text: ' - minim 10 caractere', met: criteriaMet.length },
          { text: ' - cel puțin o literă mare', met: criteriaMet.uppercase },
          { text: ' - cel puțin o literă mică', met: criteriaMet.lowercase },
          { text: ' - cel puțin o cifră', met: criteriaMet.number },
          { text: ' - cel puțin un caracter special (!@#$%^&*)', met: criteriaMet.specialChar },
        ].map((criterion, index) => (
          <li
            key={index}
            style={{
              color: criterion.met ? 'green' : 'red',
              fontWeight: criterion.met ? 'bold' : 'normal',
            }}
          >
            {criterion.text}
          </li>
        ))}
      </ul>
    </Box>
  );
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleResetPassword = async () => {
    if (!validateEmail(email)) {
      setMessage('Adresa de email nu este validă.');
      return;
    }

    try {
      const response = await fetch(`${window.API_URL}recoverPasswordSendEmail?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal('Resetare parolă', 'Un email de resetare a parolei a fost trimis la adresa specificată.', 'success');
      } else if (response.status === 422) {
        const data = await response.json();
        if (data.message && data.message.email && data.message.email[0] === "Câmpul email selectat nu este valid.") {
          Swal('Atenționare', 'Nu există nici un cont pentru această adresă de email.', 'warning');
        } else {
          Swal('Atenționare', 'A apărut o eroare la trimiterea emailului de resetare.', 'warning');
        }
      } else {
        Swal('Atenționare', 'A apărut o eroare la trimiterea emailului de resetare.', 'warning');
      }
    } catch (error) {
      setMessage('Eroare la conectarea la server.');
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  const verifyToken = async (token) => {
    try {
      const response = await fetch(`${window.API_URL}recoverPasswordVerifyToken?token=${encodeURIComponent(token)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        setUser(data.data.user);  
      } else {
        Swal('Atenționare', data.message, 'warning');
        window.location.href = `/resetare-parola`;
      }
    } catch (error) {
      Swal('Atenționare', 'Eroare la conectarea la server.', 'warning');
    }
  };
 

  const handleResetPasswordSubmit = async () => {
    if (newPassword !== confirmPassword || newPassword.length < 6) {
      Swal('Atenționare', 'Parolele nu coincid sau sunt prea scurte.', 'warning');
      return;
    }

    try {
      const response = await fetch(`${window.API_URL}recoverPasswordUpdateNewPassword?user_id=${user.id}&password=${newPassword}&password_confirmation=${confirmPassword}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal('Succes', 'Parola a fost resetată cu succes.', 'success');
        window.location.href = `/autentificare`;
      } else {
        Swal('Atenționare', 'Nu s-a putut reseta parola.', 'warning');
      }
    } catch (error) {
      Swal('Atenționare', 'Eroare la conectarea la server.', 'warning');
    }
  };

  const renderResetForm = () => {
    const isSubmitEnabled =
      Object.values(criteriaMet).every((met) => met) && newPassword === confirmPassword;
  
    return (
    <div className="wrap-login100">
      <h2 className="mb-2 mt-2" style={{ color: '#FFFFFF', textAlign: 'center' }}>
        Introduceți noua parolă
      </h2>
      <p className="mb-3 mt-3" style={{ color: '#FFFFFF' }}>
        Vă rugăm să introduceți o nouă parolă.
      </p>

      <div className='card p-3 mb-3' >
      <Grid container spacing={2} alignItems="center" className='mb-2'>
        <Grid item xs="12">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{
            padding: '8px',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            border: '1px solid #ddd',
            fontFamily: 'monospace',
            fontSize: '14px',
          }}
        >
          <span>{generatedPassword || 'Parola va apărea aici'}</span>
          {generatedPassword && (
        
           <Tooltip title="Copiere parolă generată">
             <IconButton
            variant="outlined"
            onClick={() => navigator.clipboard.writeText(generatedPassword)}
            disabled={!generatedPassword} 
            size='small'
          ><ContentCopyIcon style={{fontSize:'14px'}} /></IconButton>
           </Tooltip>
          )}
        </Box>
        </Grid>
        <Grid item xs="6">
          <Button variant="outlined" onClick={generatePassword} size="small">
            Generare parolă nouă
          </Button>
        </Grid>
        <Grid item xs="6">
          <Button
            variant="contained"
            color="primary"
            onClick={chooseGeneratedPassword}
            disabled={!generatedPassword}
            size="small"
          >
            Alegere parolă generată
          </Button>
        </Grid>
      </Grid>

      <div className="wrap-input100 validate-input">
        <TextField 
          type="password"
          fullWidth
          label="Parolă nouă"
          value={newPassword}
          onChange={handleNewPasswordChange}
          required
        />
        <span className="focus-input100">
          <FontAwesomeIcon icon={faLock} className="mr-2" />
        </span>
      </div>

      <div className="wrap-input100 validate-input">
        <TextField 
          fullWidth
          type="password"
          label="Confirmare parolă"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <span className="focus-input100">
          <FontAwesomeIcon icon={faLock} className="mr-2" />
        </span>
      </div>
      {renderStrengthBlocks()}
      {renderCriteria()}
      </div>
      <div className="container-login100-form-btn">
        <Button variant='contained' color='warning' onClick={handleResetPasswordSubmit} 
          disabled={!isSubmitEnabled}>
          Schimbare parolă
        </Button>
      </div>
    </div>

);
};
 
  return ( 
    <div className="limiter">
    <div className="container-login100">
    {!user && (
         <div className="wrap-login100">
         <h2 className='mb-2 mt-2' style={{color:'#FFFFFF', textAlign:'center'}}>Resetare parolă</h2>
           <p className='mb-3 mt-3'  style={{color:'#FFFFFF'}}>Vă rugăm să introduceți adresa de email pentru a primi instrucțiunile de resetare a parolei.</p>
           <div className="wrap-input100 validate-input">
             <input
               className="input100"
               type="email"
               placeholder="Adresa de email"
               id="name"
               value={email} 
               onChange={handleEmailChange} 
               required
             />
             <span className="focus-input100">
               <FontAwesomeIcon icon={faUser} className="mr-2" />
             </span>
           </div>
       
             {message && <p>{message}</p>}
           <div className="container-login100-form-btn">
             <button className="login100-form-btn" onClick={handleResetPassword}> 
               Resetare parolă
             </button>
           </div>
     
           <div className="text-center p-t-90">
             <br />
             <a href='autentificare' style={{color:'#FFFFFF', textDecoration:'none'}}>V-ați amintit parola?</a>
           </div>
       </div>
    )}
      {user && renderResetForm()}
      
      </div>
    </div> 
    
    
      );
};

export default ResetareParola;
