import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, List, ListItem, ListItemText, Typography, CircularProgress, Button, IconButton, TextField, Alert, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import verificareToken from '../../VerificareToken';
import DrepturiUtilizator from '../../utilizator/drepturiUtilizator'; 

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import { format } from 'date-fns';

export default function VizualizareDocumente() {
 

  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { token } = verificareToken();
  
 
  const handleFileDrop = (acceptedFiles) => {
    setEditedFile(acceptedFiles[0]);
    setFileChanged(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileDrop });

  const [isSaving, setIsSaving] = useState(false);

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [documenteDosar, setDocumenteDosar] = useState([]);
  const [calitati, setCalitati] = useState([]);
  const [tipuriDocumente, setTipuriDocumente] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [editedDataDocument, setEditedDataDocument] = useState(new Date());
  const [editedParteDocument, setEditedParteDocument] = useState('');
  const [editedFile, setEditedFile] = useState(null);
  const [editedTipDocument, setEditedTipDocument] = useState('');
  const [editedDescriere, setEditedDescriere] = useState('');  
  const [fileChanged, setFileChanged] = useState(false);
  const [tipToAdd, setTipToAdd] = useState('');

  const [isLoadingTipuri, setIsLoadingTipuri] = useState(false);
 
  const [tipuri, setTipuri] = useState([]); 
  const [openModal, setOpenModal] = useState(false); 
  const [tipToEdit, setTipToEdit] = useState(null);
  const [editedTip, setEditedTip] = useState('');
  const [idStadiu, setIdStadiu] = useState(null);
  const [tipDosarData, setTipDosarData] = useState(null);

  useEffect(() => {
    const fetcTiphDosar = async () => {
      try {
        const response = await fetch(`${window.API_URL}getTipDosar/${id}`, {
           method: 'GET',
           headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`,
           },
        });
  
        if (!response.ok) {
          throw new Error('Error fetching dosar data');
        }
  
        const data = await response.json();
        setTipDosarData(data.data.instanta);
      } catch (error) {
        console.error(error);
      }
    };
  
    
    fetcTiphDosar(); 
  }, [id, token]);

  const fetchInfoDocumenteDosar = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${window.API_URL}infoDocumenteDosar/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const {data} = await response.json();
      if (selectedDocument) {
        const selectedDoc = documenteDosar.find(doc => doc.id === selectedDocument.id);
        if (selectedDoc) {
          const calitate = data.calitati.find(c => c.id === selectedDoc.idcalitatedocument);
          const tipDocument = data.tipuriDocumente.find(t => t.id === selectedDoc.idtipdocument);
          const convertedDate = new Date(selectedDoc.datadocument);
          setEditedDataDocument(convertedDate);
          setEditedParteDocument(calitate ? calitate.denumire : '');
          setEditedTipDocument(tipDocument ? tipDocument.denumire : '');
          setEditedDescriere(selectedDoc.descriere);
          
      
        }
       
      }
        
      setDocumenteDosar(data.documenteDosar);
      setCalitati(data.calitati);
      setTipuriDocumente(data.tipuriDocumente);
      setSelectedDocument(null);
      setEditedDataDocument(null);
      setEditedParteDocument('');
      setEditedFile(null);
      setEditedTipDocument('');
      setEditedDescriere('');


      setCalitati(data.calitati);
      setTipuri(data.tipuriDocumente);
      setIdStadiu(data.idStadiu);
      // fac update la nume, descriere, parte, tip document in stanga dupa salvare
      const selectedDoc = data.documenteDosar.find(doc => doc.id === selectedDocument?.id);
      if (selectedDoc) {
        const convertedDate = new Date(selectedDoc.datadocument);
        setEditedDataDocument(convertedDate);
        setEditedParteDocument(selectedDoc.idcalitatedocument);
        setEditedTipDocument(selectedDoc.idtipdocument);
        setEditedDescriere(selectedDoc.descriere);
      }
    } catch (error) {
      console.error('A problem occurred while fetching the data: ', error);
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    fetchInfoDocumenteDosar();
  }, [id, token]);


  const handleDocumentSelection = (document) => {
    setSelectedDocument(document);
    const convertedDate = new Date(document.datadocument);
    setEditedDataDocument(convertedDate);
    setEditedParteDocument(document.idcalitatedocument);
    setEditedFile(null); 
    setEditedDescriere(document.descriere);  
    setEditedTipDocument(document.idtipdocument);

    if (document.numefisier) {
      // daca documentul are un fisier asociat -> actualizez starea cu numele fisierului salvat din api de la magda
      setEditedFile({ name: document.numefisier });
    }
  };

  const handleSaveDocument = async () => {
    if (!selectedDocument) {
      return;
    }
  
    if (!tipDosarData) {
      if (!editedDataDocument || !editedTipDocument || !editedDescriere) {
        Swal({
          icon: 'warning',
          title: 'Atenționare',
          text: 'Vă rugăm completați toate câmpurile obligatorii!',
        });
        return;
      }
    } else {
      if (!editedDataDocument || !editedParteDocument || !editedTipDocument || !editedDescriere) {
        Swal({
          icon: 'warning',
          title: 'Atenționare',
          text: 'Vă rugăm completați toate câmpurile obligatorii!',
        });
        return;
      }
    }

   
    setIsSaving(true);
  
    let tipDocumentID = editedTipDocument;
  
    // daca valoarea idtipdocument se regaseste in lista de tipuri documente de pe stadiu
    const existingTip = tipuriDocumente.find((tip) => tip.id === editedTipDocument);
    if (existingTip) {
      tipDocumentID = existingTip.id;
    } else {
      // daca  valoarea idtipdocument nu se regaseste in lista de tipuri documente de pe stadiu
      const newTip = tipuriDocumente.find((tip) => tip.denumire === editedTipDocument);
      if (newTip) {
        tipDocumentID = newTip.id; // preiau valoarea noua din dropdown
      }
    }
    console.log('tipuriDocumente:', tipuriDocumente);
    console.log('Valoarea editedTipDocument:', editedTipDocument);  
  
    const formData = new FormData();
    if (editedFile && (!selectedDocument.numefisier || editedFile.name !== selectedDocument.numefisier)) {
      formData.append('fisier', editedFile);
    }
    console.log('editedTipDocument la trimitere:', tipDocumentID);
    const formattedDate = format(editedDataDocument, 'yyyy-MM-dd');
    formData.append('idTipDocument', tipDocumentID);
    formData.append('idDosar', id);
    formData.append('data', formattedDate);
    formData.append('descriere', editedDescriere);
    formData.append('dataDocument', selectedDocument.datadocument);
    if (tipDosarData) { formData.append('idcalitatedocument', editedParteDocument); }

 

    try {
 
      const response = await fetch(`${window.API_URL}editDocumentDosar/${selectedDocument.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      console.log(data);
  
      const updatedDocument = { ...selectedDocument, ...data };
      const updatedDocumenteDosar = documenteDosar.map((doc) =>
        doc.id === selectedDocument.id ? updatedDocument : doc
      );
      setDocumenteDosar(updatedDocumenteDosar);
      setSelectedDocument(updatedDocument);
  
      const convertedDate = new Date(updatedDocument.datadocument);
      setEditedDataDocument(convertedDate);
      setEditedParteDocument(updatedDocument.idcalitatedocument);
      setEditedFile(null);
      setEditedTipDocument(updatedDocument.idtipdocument);
      setEditedDescriere(updatedDocument.descriere);
  
      fetchInfoDocumenteDosar();
      setIsSaving(false);
    } catch (error) {
      console.error('A problem occurred while saving the edited document: ', error);
      setIsSaving(false);
    }
  };
  
  
  

  const handleDeleteDocument = async () => {
    Swal({
      title: 'Sunteți sigur?',
      text: 'Odată șters, nu veți mai putea recupera acest document!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`${window.API_URL}stergeDocument/${selectedDocument.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          console.log('Răspuns ștergere: ', response);
          setDocumenteDosar((prevDocumenteDosar) => prevDocumenteDosar.filter((doc) => doc.id !== selectedDocument.id));
          setSelectedDocument(null);
          Swal('Documentul a fost șters.', {
            icon: 'success',
          });
          
        } catch (error) {
          console.error('Problemă ștergere document: ', error);
          Swal('A apărut o eroare la ștergerea documentului.', {
            icon: 'warning',
          });
        }
      } else {
        Swal('Documentul nu a fost șters!');
      }
    });
  };
 


  const handleAddTip = async (event) => {
    event.preventDefault();
  
    setIsLoadingTipuri(true);
  
    try {
      const response = await fetch(`${window.API_URL}adaugaTip`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idstadiu: idStadiu,
          denumire: tipToAdd,
        }),
      });
  
      if (!response.ok) {
        throw new Error('A avut loc o problemă la adăugarea tipului de document.');
      }
  
      const responseData = await response.json();
      const newTip = responseData.data.tipDocument;  
  
      setTipuriDocumente((prevTipuriDocumente) => [...prevTipuriDocumente, newTip]);
      setTipuri((prevTipuri) => [...prevTipuri, newTip]);
      setTipToAdd('');
  
      if (!selectedDocument) {
        setEditedTipDocument(newTip.id);
      }
    } catch (error) {
      console.error('A apărut o eroare: ', error);
    } finally {
      setIsLoadingTipuri(false);
    }
  };
  
  

const handleEditTip = (tip) => {
setTipToEdit(tip);
setEditedTip(tip.denumire);
};

const saveEditedTip = async () => {
  if (editedTip.trim() === '') {
    return;
  }

  try {
    const response = await fetch(`${window.API_URL}editeazaTip/${tipToEdit.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        idstadiu: idStadiu,
        denumire: editedTip,
      }),
    });

    const responseData = await response.json();
    const updatedTip = responseData.data.tipDocument;  

    console.log(updatedTip);

    setTipuri((prevTipuri) =>
      prevTipuri.map((tip) => (tip.id === updatedTip.id ? { ...updatedTip } : tip))  
    );

    setTipuriDocumente((prevTipuriDocumente) =>
      prevTipuriDocumente.map((tip) =>
        tip.id === updatedTip.id ? { ...updatedTip } : tip
      )
    );

    setTipToEdit(null);
    setEditedTip('');

  } catch (error) {
    console.error("A problem occurred while saving the edited tip: ", error);
  }
};



const handleDeleteTip = async (tip) => {
Swal({
  title: "Sunteți sigur?",
  text: "Odată șters, nu veți mai putea recupera acest tip de document!",
  icon: "warning",
  buttons: true,
  dangerMode: true,
})
.then(async (willDelete) => {
  if (willDelete) {
    try {
      const response = await fetch(`${window.API_URL}stergeTip/${tip.id}`, {
          method: 'DELETE',
          headers: {
          'Authorization': `Bearer ${token}`,
          },
      });
      console.log("Raspuns stergere: ", response);
      setTipuri((prevTipuri) => prevTipuri.filter((t) => t !== tip));
      Swal("Tipul de document a fost șters.", {
        icon: "success",
      });
      setTipuriDocumente((prevTipuriDocumente) =>
        prevTipuriDocumente.filter((t) => t.id !== tip.id)
      );

    } catch (error) {
      console.error("Problema stergere tipDocument: ", error);
      Swal("A apărut o eroare la ștergerea tipului de document.", {
        icon: "error",
      });
    }
  } else {
    Swal("Tipul de document nu a fost șters!");
  }
});
};

 

const handleModalOpen = () => {
setOpenModal(true);
};

const handleModalClose = () => {
setOpenModal(false);
setTipToAdd('');
};

const urlLaravel = window.API_URL.replace('/api/', '');
const fileSuffix = '/fisiere/in/';

if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {isSaving && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

        <Grid item xs={4}>
        <List component="nav">
            {documenteDosar.length > 0 ? (
            documenteDosar.map((document) => {
                const calitate = calitati.find(c => c.id === document.idcalitatedocument);
                const tipDocument = tipuriDocumente.find(t => t.id === document.idtipdocument);
                return (
                <ListItem
                    key={document.id}
                    button
                    selected={selectedDocument?.id === document.id}
                    onClick={() => handleDocumentSelection(document)}
                >
                    <ListItemText 
                    primary={ 
                        <Box>
                        <Typography variant="subtitle1">{document.numefisier}</Typography>
                        <Typography variant="body2">{document.descriere}</Typography>
                        <Grid container spacing={1}>
                            <Grid item>
                            <Typography variant="caption" color="textSecondary">{tipDocument ? tipDocument.denumire : ''}</Typography>
                            </Grid>
                            <Grid item>
                                 | 
                            </Grid>
                            <Grid item>
                            <Typography variant="caption" color="textSecondary">{calitate ? calitate.denumire : ''}</Typography>
                            </Grid>
                        </Grid>
                        </Box>
                    }
                    /> 
                   
                </ListItem> 
                )
            })
            ) : (
            <Typography variant="subtitle1">Nu există documente în dosar.</Typography>
            )}
        </List>
        </Grid>
      <Grid item xs={8}>
        {!selectedDocument ? (
          
            <Alert severity="info">Selectați un document pentru a vizualiza detalii.</Alert>
         
        ) : (
          <Box> 
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
              <DatePicker
                  label="Data document"
                  value={editedDataDocument}
                  onChange={(newValue) => {
                      setEditedDataDocument(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  required
                  disabled={!areDrept(DreptDeModificareDosare)}
                  inputFormat="dd/MM/yyyy" 
                  format="dd/MM/yyyy" 
                  mask="__/__/____"
                  className='maxWidth'
              />
          </LocalizationProvider>


          {tipDosarData? (
            <TextField
              style={{ marginTop: '10px' }}
              fullWidth
              id="parteDocument"
              label="Parte document"
              select
              value={editedParteDocument}
              onChange={(e) => setEditedParteDocument(e.target.value)}
              required
              disabled={!areDrept(DreptDeModificareDosare)}
              initialState={{
                pagination: { paginationModel: { pageSize: 15 } },
              }}
              
            >
              {calitati.map((calitate) => (
                <MenuItem key={calitate.id} value={calitate.id}>
                  {calitate.denumire}
                </MenuItem>
              ))}
            </TextField> 
            ): null }
            {areDrept(DreptDeModificareDosare) ? ( 
              <Box
                {...getRootProps()}
                textAlign="center"
                p={4}
                border="1px dashed grey"
                style={{ cursor: 'pointer', marginTop: '10px' }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography>Trageți fișierul aici...</Typography>
                ) : (
                  <Typography>
                    Trageți fișierul aici sau apăsați pentru a selecta un fișier
                  </Typography>
                )}
              </Box>
            ) : null }
            {editedFile && (
              <Box mt={2}>
                <Typography variant="subtitle1">Fișier selectat: {editedFile.name} <a className='linkapp' href={urlLaravel + fileSuffix + id + '/continut/' + encodeURIComponent(editedFile.name)} target={'_blank'}>Vizualizare fișier <span role="img" aria-label="ochi">👁️</span></a> </Typography> 
                {areDrept(DreptDeModificareDosare) ? ( 
                <Typography
                  variant="subtitle2"
                  style={{ cursor: 'pointer', color: 'blue' }} className="mb-3"
                  onClick={() => setEditedFile(null)}
                >
                  Alegeți alt fișier
                </Typography>
                ) : null }
              </Box>
            )}
          
            {!areDrept(DreptDeModificareDosare) ? ( 
              <>
                <Box display="flex">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            style={{ marginTop: '10px' }}
                            select
                            label="Tip Document"
                            value={editedTipDocument}
                            onChange={(event) => setEditedTipDocument(event.target.value)}
                            disabled={!areDrept(DreptDeModificareDosare)}
                            >
                            {tipuriDocumente.length > 0 ? (
                                tipuriDocumente.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.denumire}
                                </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" onClick={handleModalOpen}>
                                Nu aveți nici un tip document definit, definiți acum
                                </MenuItem>
                            )}
                            </TextField> 
                    </Grid> 
                </Grid>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex">
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            style={{ marginTop: '10px' }}
                            select
                            label="Tip Document"
                            value={editedTipDocument}
                            onChange={(event) => setEditedTipDocument(event.target.value)}
                            disabled={!areDrept(DreptDeModificareDosare)}
                            >
                            {tipuriDocumente.length > 0 ? (
                                tipuriDocumente.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.denumire}
                                </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" onClick={handleModalOpen}>
                                Nu aveți nici un tip document definit, definiți acum
                                </MenuItem>
                            )}
                            </TextField> 
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            style={{ marginLeft: '10px', marginTop: '10px' }}
                            onClick={handleModalOpen}
                            disabled={!areDrept(DreptDeModificareDosare)}
                            >
                            Configurare Tipuri Documente
                        </Button>
                    </Grid>
                </Grid>
                </Box>
              </>
            ) } 

            
                <TextField
                fullWidth
                style={{ marginTop: '10px' }}
                id="descriere"
                label="Descriere"
                multiline
                rows={4}
                value={editedDescriere}
                onChange={(e) => setEditedDescriere(e.target.value)}
                disabled={!areDrept(DreptDeModificareDosare)}
                required
            />


            <Box display="flex" justifyContent="flex-end" marginTop="10px">
              
            {areDrept(DreptDeModificareDosare) ? ( 
              <>
                <Button variant="contained" color="success" onClick={handleSaveDocument}>
                  Salvare
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteDocument}
                  style={{ marginLeft: '10px' }}
                >
                  Ștergere
                </Button>
              </>
            ) : null } 



              <Dialog open={openModal} onClose={handleModalClose}>
                <DialogTitle>Administrare Tipuri Documente</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                    <TextField
                        label="Adăugare tip document"
                        value={tipToAdd}
                        onChange={(e) => setTipToAdd(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        size="small"
                        disabled={isLoadingTipuri}
                    />

                    <IconButton color="primary" onClick={handleAddTip} disabled={isLoadingTipuri}>
                        {isLoadingTipuri ? (
                        <CircularProgress size={24} />
                        ) : (
                        <CheckIcon />
                        )}
                    </IconButton>
                    </Box>

                    <Box style={{ overflowY: 'scroll', maxHeight: '300px' }}>
                    {tipuri.map((tip) => (
                        <Box key={tip.id} display="flex" alignItems="center">
                        {tipToEdit === tip ? (
                            <>
                            <TextField
                                value={editedTip}
                                onChange={(e) => setEditedTip(e.target.value)}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                size="small"
                            />
                            <IconButton color="primary" onClick={saveEditedTip}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() => { setTipToEdit(null); setEditedTip(''); }}>
                                <CloseIcon />
                            </IconButton>
                            </>
                        ) : (
                            <>
                            <IconButton color="primary" onClick={() => handleEditTip(tip)}>
                                <EditIcon />
                            </IconButton>

                            <Typography>{tip.denumire}</Typography>

                            <IconButton color="error" onClick={() => handleDeleteTip(tip)}>
                                <DeleteIcon />
                            </IconButton>
                            </>
                        )}
                        </Box>
                    ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Renunțare</Button>
                </DialogActions>
                </Dialog>
   
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
