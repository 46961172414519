import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ShakaPlayer from 'shaka-player-react';  
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Alert from '@mui/material/Alert'; 
import 'shaka-player/dist/controls.css'; 

const HelpDialog = ({ title, contentType, content, tooltip }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip || 'Ajutor'}>
        <IconButton onClick={handleClickOpen} sx={{ padding: '5px', fontSize: '1rem' }}>
          <HelpOutlineIcon sx={{ fontSize: '1.2rem' }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: '1100px',
            width: '100%',
          },
        }}
      >
        <DialogTitle className='titluModal' sx={{ m: 0, p: 2 }}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {contentType === 'video' && (
            <>
              <div onContextMenu={(e) => e.preventDefault()} style={{ position: 'relative' }}>
                <ShakaPlayer src={content} autoPlay={open} controls />
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }} />
              </div>
              <Alert className="mt-2" severity="info" style={{ marginBottom: '20px' }}>
                Puteți expanda tutorialul folosind butonul
                <span style={{ padding: '0 5px', fontFamily: 'monospace' }}>
                  [⛶]
                </span>
                pentru a-l viziona pe tot ecranul.
              </Alert>
            </>
          )}
          {contentType === 'text' && (
            <Typography variant="body1">{content}</Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

HelpDialog.propTypes = {
  title: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf(['video', 'text']).isRequired,
  content: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
};

HelpDialog.defaultProps = {
  tooltip: 'Ajutor',
};

export default HelpDialog;
