import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Button, Box, Typography, Alert, TextField  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import { format } from 'date-fns';
import { isValid } from 'date-fns';

function InfoActeContestate() {
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    
      const { token } = verificareToken();
    const { id } = useParams();
    const [acteContestate, setActeContestate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentActContestat, setCurrentActContestat] = useState(null);
    
    const currentDate = new Date().toISOString().split('T')[0];

    const [formState, setFormState] = useState({
        data: currentDate,
        decizieaccesorii: '',
        decizieimpunere: '',
        somatii: '',
        titluexecutoriu: '',
        poprire: '',
        contract: '',
        decizieCD: '',
        decizieCA: '',
        deciziesolutionarecontestatie: '',
    });
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);

    const apiUrl = window.API_URL;  

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}infoActeContestate/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                  },
            });
            const data = await response.json();
            setActeContestate(data.data.acteContestate);
        } catch (error) {
            console.error('A apărut o eroare!', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id, apiUrl]);

    const handleOpen = (actContestat) => {
        if (actContestat) {
            setFormState({
                data: actContestat.data || currentDate,
                decizieaccesorii: actContestat.decizieaccesorii || '',
                decizieimpunere: actContestat.decizieimpunere || '',
                somatii: actContestat.somatii || '',
                titluexecutoriu: actContestat.titluexecutoriu || '',
                poprire: actContestat.poprire || '',
                contract: actContestat.contract || '',
                decizieCD: actContestat.decizieCD || '',
                decizieCA: actContestat.decizieCA || '',
                deciziesolutionarecontestatie: actContestat.deciziesolutionarecontestatie || '',
            });
        } else {
            setFormState({
                decizieaccesorii: '',
                decizieimpunere: '',
                somatii: '',
                titluexecutoriu: '',
                poprire: '',
                contract: '',
                decizieCD: '',
                decizieCA: '',
                deciziesolutionarecontestatie: '',
                data: currentDate,
            });
        }
        setCurrentActContestat(actContestat);
        setOpen(true);
    };
    

    const handleClose = () => {
        setCurrentActContestat(null);
        setFormState({
            decizieaccesorii: '',
            decizieimpunere: '',
            somatii: '',
            titluexecutoriu: '',
            poprire: '',
            contract: '',
            decizieCD: '',
            decizieCA: '',
            deciziesolutionarecontestatie: '',
        });
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    

    const handleAdaugaSauEditeaza = async () => {
        setIsAddingOrEditing(true);

        const dataActiuneFormatted = format(new Date(formState.data), 'yyyy-MM-dd');

        const queryParams = new URLSearchParams({
            data: dataActiuneFormatted,
            decizieaccesorii: formState.decizieaccesorii,
            decizieimpunere: formState.decizieimpunere,
            somatii: formState.somatii,
            titluexecutoriu: formState.titluexecutoriu,
            poprire: formState.poprire,
            contract: formState.contract,
            decizieCD: formState.decizieCD,
            decizieCA: formState.decizieCA,
            deciziesolutionarecontestatie: formState.deciziesolutionarecontestatie,
        });

        try {
            if (currentActContestat) {
                const response = await fetch(`${apiUrl}acteContestate/${currentActContestat.id}?iddosar=${id}&${queryParams}`, {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token}`,
                      },
                });
                const data = await response.json();
                setActeContestate((prevActeContestate) => {
                    return prevActeContestate.map((actContestat) => {
                        if (actContestat.id === currentActContestat.id) {
                            return data.data.actContestatDosar;
                        }
                        return actContestat;
                    });
                });
            } else {
                const response = await fetch(`${apiUrl}acteContestate?iddosar=${id}&${queryParams}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                      },
                });
                const data = await response.json();
                setActeContestate((prevActeContestate) => [...prevActeContestate, data.data.actContestatDosar]);
            }
            handleClose();
            fetchData();
        } catch (error) {
            console.error('A apărut o eroare!', error);
        } finally {
            setIsAddingOrEditing(false);
            setCurrentActContestat(null);
            fetchData();
        }
    };

    const handleSterge = async (actContestatId) => {
        setIsAddingOrEditing(true);

        Swal({
            title: 'Sigur doriți ștergerea?',
            text: 'Această acțiune nu poate fi anulată!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              try {
                await fetch(`${apiUrl}acteContestate/${actContestatId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                      },
                });
                setActeContestate(acteContestate.filter((item) => item.id !== actContestatId));
                Swal('Șters!', 'Actul contestat a fost șters.', 'success');
              } catch (error) {
                console.error('A apărut o eroare la ștergere!', error);
              } finally {
                setIsAddingOrEditing(false);
              }
            } else {
                setIsAddingOrEditing(false);
            }
        });
    };


    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>;
    }

    return (
        <div>
            <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }}>Acte contestate dosar</Typography>
                {areDrept(DreptDeModificareDosare) ? ( 
                    <Button
                        disabled={!areDrept(DreptDeModificareDosare)}
                        onClick={() => handleOpen(null)}
                        variant="contained"
                        size="small"
                        color="secondary"
                        className='pull-right'
                        startIcon={<AddIcon />}
                    >
                        Adăugare act contestat nou
                    </Button>
                ) : null } 
            </div>
            {acteContestate.length === 0 ? (
                <Alert severity="info">Nu există acte contestate atașate dosarului.</Alert>
            ) : (
                <List>
                    {acteContestate.map((actContestat, index) => (
                        <ListItem
                            key={actContestat.id}
                            className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
                        >
                            <ListItemText
                                primary={`Act contestat - ${actContestat.data ? format(new Date(actContestat.data), 'dd-MM-yyyy', { locale: ro }) : 'Data nepecificată'}`}
                                secondary={
                                    <Typography variant="body2">
                                        <div>
                                            Decizie accesorii: {actContestat.decizieaccesorii}
                                        </div>
                                        <div>
                                            Decizie impunere: {actContestat.decizieimpunere}
                                        </div>
                                        <div>
                                            Somatii: {actContestat.somatii}
                                        </div>
                                        <div>
                                            Titlu executoriu: {actContestat.titluexecutoriu}
                                        </div>
                                        <div>
                                            Poprire: {actContestat.poprire}
                                        </div>
                                        <div>
                                            Contract: {actContestat.contract}
                                        </div>
                                        <div>
                                            Decizie CD: {actContestat.decizieCD}
                                        </div>
                                        <div>
                                            Decizie CA: {actContestat.decizieCA}
                                        </div>
                                        <div>
                                            Decizie soluționare contestație: {actContestat.deciziesolutionarecontestatie || '-'}
                                        </div>
                                    </Typography>
                                }
                            />
                                    {areDrept(DreptDeModificareDosare) ? ( 
                                    <>
                                        <Tooltip title="Editare">
                                            <IconButton  disabled={!areDrept(DreptDeModificareDosare)} aria-label="editeaza" onClick={() => handleOpen(actContestat)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ștergere">
                                            <IconButton  disabled={!areDrept(DreptDeModificareDosare)} aria-label="sterge" onClick={() => handleSterge(actContestat.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    ) : null } 
                        </ListItem>
                    ))}
                </List>
            )}
            {/* Dialog de adăugare și editare */}
            <Dialog  fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className='titluModal' id="form-dialog-title">{currentActContestat ? 'Editare act contestat' : 'Adăugare act contestat'}</DialogTitle>
                <DialogContent className='mt-3' style={{ minWidth: '300px' }}>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Dată Act contestat</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                        <DatePicker
                            label="Data Act contestat"
                            value={formState.data ? new Date(formState.data) : null} 
                            onChange={(newValue) => {
                                setFormState((prevState) => ({
                                    ...prevState,
                                    data: newValue,
                                }));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="dd/MM/yyyy" 
                            format="dd/MM/yyyy" 
                            mask="__/__/____"
                            className='mt-2 maxWidth mb-3'
                        />
                    </LocalizationProvider>

                        <Typography variant="h6" className="input-section-title">Decizie accesorii</Typography>
                        <TextareaAutosize
                            autoFocus
                            id="decizieaccesorii"
                            placeholder="Decizie Accesorii"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.decizieaccesorii}
                            onChange={handleInputChange}
                            name="decizieaccesorii"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Decizie impunere</Typography>
                        <TextareaAutosize
                            id="decizieimpunere"
                            placeholder="Decizie Impunere"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.decizieimpunere}
                            onChange={handleInputChange}
                            name="decizieimpunere"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Somații</Typography>
                        <TextareaAutosize
                            id="somatii"
                            placeholder="Somatii"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.somatii}
                            onChange={handleInputChange}
                            name="somatii"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Titlu executoriu</Typography>
                        <TextareaAutosize
                            id="titluexecutoriu"
                            placeholder="Titlu Executoriu"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.titluexecutoriu}
                            onChange={handleInputChange}
                            name="titluexecutoriu"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Poprire</Typography>
                        <TextareaAutosize
                            id="poprire"
                            placeholder="Poprire"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.poprire}
                            onChange={handleInputChange}
                            name="poprire"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Contract</Typography>
                        <TextareaAutosize
                            id="contract"
                            placeholder="Contract"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.contract}
                            onChange={handleInputChange}
                            name="contract"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Decizie CD</Typography>
                        <TextareaAutosize
                            id="decizieCD"
                            placeholder="Decizie CD"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.decizieCD}
                            onChange={handleInputChange}
                            name="decizieCD"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Decizie CA</Typography>
                        <TextareaAutosize
                            id="decizieCA"
                            placeholder="Decizie CA"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.decizieCA}
                            onChange={handleInputChange}
                            name="decizieCA"
                            className="textarea-style"
                        />
                    </div>
                    <div className="input-section">
                        <Typography variant="h6" className="input-section-title">Decizie soluționare contestație</Typography>
                        <TextareaAutosize
                            id="deciziesolutionarecontestatie"
                            placeholder="Decizie Soluționare Contestație"
                            minRows={3}
                            maxRows={6}
                            fullWidth
                            value={formState.deciziesolutionarecontestatie}
                            onChange={handleInputChange}
                            name="deciziesolutionarecontestatie"
                            className="textarea-style"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAdaugaSauEditeaza}
                        color="primary"
                        disabled={isAddingOrEditing}
                    >
                        {isAddingOrEditing ? 'Se procesează...' : (currentActContestat ? 'Salvare' : 'Adăugare')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Anulare
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InfoActeContestate;
