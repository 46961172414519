import React, { useState, useEffect } from 'react';
import { Alert, CircularProgress, Button, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert'; 
import { Chip, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import verificareToken from './VerificareToken';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InventoryIcon from '@mui/icons-material/Inventory';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import StorageIcon from '@mui/icons-material/Storage';
function ExportDownload() {
   //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
   window.dispatchEvent(new Event('authChange'));
  
    const [exports, setExports] = useState([]);
    const [inLucru, setInLucru] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const [error, setError] = useState(null);
    const { token } = verificareToken();
    const getIdUser = () => {
        const sessionIdUser = sessionStorage.getItem('iduser');
        if (sessionIdUser) {
          return sessionIdUser;
        }
      
        const localIdUser = localStorage.getItem('iduser');
        if (localIdUser) {
          return localIdUser;
        }
      
        const cookies = document.cookie.split('; ');
        const cookieIdUser = cookies.find(cookie => cookie.startsWith('_iduserCjpc='));
        if (cookieIdUser) {
          return cookieIdUser.split('=')[1];
        }
      
        return null;
      };
      
      const iduser = getIdUser();

      const convertirelaWord = async (exp) => {
        console.log('Function convertirelaWord called with exp:', exp); // Verificăm dacă intrăm în funcție
        setLoading(true);
        try {
            const response = await fetch(`${window.API_URL}convert-excel-to-docx`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: 'POST',
                body: JSON.stringify({
                    iduser: iduser,
                    denumirefisier: exp.name.replace('.xlsx', '') // Trimiți doar denumirea fără extensia .xlsx
                }),
            });
    
            if (!response.ok) {
                throw new Error('Something went wrong with the API call');
            }
    
            const data = await response.json();
            console.log('API response:', data);
            
            // Deschide fișierul Word generat
            window.open(data.path, '_blank');
    
        } catch (error) {
            console.error('Error fetching stari:', error);
        } finally {
            setLoading(false);
        }
    };


    const convertirelaExcel = async (exp) => {
      console.log('Function convertirelaWord called with exp:', exp); // Verificăm dacă intrăm în funcție
      setLoading(true);
      try {
          const response = await fetch(`${window.API_URL}convert-excel-to-docx`, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
              method: 'POST',
              body: JSON.stringify({
                  iduser: iduser,
                  denumirefisier: exp.name.replace('.xlsx', '') // Trimiți doar denumirea fără extensia .xlsx
              }),
          });
  
          if (!response.ok) {
              throw new Error('Something went wrong with the API call');
          }
  
          const data = await response.json();
          console.log('API response:', data);
          
          // Deschide fișierul Word generat
          window.open(data.path, '_blank');
  
      } catch (error) {
          console.error('Error fetching stari:', error);
      } finally {
          setLoading(false);
      }
  };
    
    
  const convertirelaXml = async (exp) => {
    console.log('Function convertirelaWord called with exp:', exp); // Verificăm dacă intrăm în funcție
    setLoading(true);
    try {
        const response = await fetch(`${window.API_URL}convert-excel-to-docx`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                iduser: iduser,
                denumirefisier: exp.name.replace('.xlsx', '') // Trimiți doar denumirea fără extensia .xlsx
            }),
        });

        if (!response.ok) {
            throw new Error('Something went wrong with the API call');
        }

        const data = await response.json();
        console.log('API response:', data);
        
        // Deschide fișierul Word generat
        window.open(data.path, '_blank');

    } catch (error) {
        console.error('Error fetching stari:', error);
    } finally {
        setLoading(false);
    }
};  
const convertirelaPdf = async (exp) => {
  console.log('Function convertirelaWord called with exp:', exp); // Verificăm dacă intrăm în funcție
  setLoading(true);
  try {
      const response = await fetch(`${window.API_URL}convert-excel-to-docx`, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          method: 'POST',
          body: JSON.stringify({
              iduser: iduser,
              denumirefisier: exp.name.replace('.xlsx', '') // Trimiți doar denumirea fără extensia .xlsx
          }),
      });

      if (!response.ok) {
          throw new Error('Something went wrong with the API call');
      }

      const data = await response.json();
      console.log('API response:', data);
      
      // Deschide fișierul Word generat
      window.open(data.path, '_blank');

  } catch (error) {
      console.error('Error fetching stari:', error);
  } finally {
      setLoading(false);
  }
};

      const fetchExporturi = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${window.API_URL}listExports?userId=${iduser}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            method: 'GET',
          });
          const data = await response.json();
          const sortedExports = data.exports.sort((a, b) => new Date(`${b.data}T${b.ora}`) - new Date(`${a.data}T${a.ora}`));
          setExports(sortedExports);
          setInLucru(data.inlucru);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching stari:', error);
        }
      };

      const fetchExporturi2 = async () => {
        try {
          const response = await fetch(`${window.API_URL}listExports?userId=${iduser}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            method: 'GET',
          });
          const data = await response.json();
          const sortedExports = data.exports.sort((a, b) => new Date(`${b.data}T${b.ora}`) - new Date(`${a.data}T${a.ora}`));
          setExports(sortedExports);
          setInLucru(data.inlucru);
        } catch (error) {
          console.error('Error fetching stari:', error);
        }
      };

 

      useEffect(() => {
        fetchExporturi();
        const intervalId = setInterval(fetchExporturi2, 5000);  
    
        return () => clearInterval(intervalId); 
      }, []);
    

      const deleteExportGenerare = (filename) => {
        Swal({
          title: 'Sigur doriți oprirea acestui export?',
          text: 'Această acțiune nu poate fi anulată iar exportul-ul nu poate fi recuperat!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, șterge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteExportOK(filename);
          }
        });
      };
  
      const deleteExport = (filename) => {
        Swal({
          title: 'Sigur doriți ștergerea acestui export?',
          text: 'Această acțiune nu poate fi anulată iar exportul-ul nu poate fi recuperat!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, șterge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteExportOK(filename);
          }
        });
      };
  

    const deleteExportOK = (filename) => {
        fetch(`${window.API_URL}deleteExport?userId=${iduser}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ filename })
        })
        .then(response => {
            if (response.ok) {
                setExports(exports.filter(exp => exp.name !== filename));
                fetchExporturi();
            } else {
                throw new Error('Eroare la ștergere backup');
            }
        })
        .catch(err => {
            setError(err);
        });
    };

    function formatDate(dateString, timeString) {
        const dateTime = `${dateString}T${timeString}`;
        const date = new Date(dateTime);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');  
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`;
    }
    

    const handleDownload = (filename, fileType) => {
      const cleanFilename = filename.replace('.xlsx', '');
    
      const extension = fileType ? `.${fileType}` : '';
      const filePath = `./fisiere/export/${iduser}/${cleanFilename}${extension}`;
      window.open(filePath, '_blank');
    };
    

    function getExportAgeLabel(dateString) {
      const today = new Date();
      const exportDate = new Date(dateString);
      const diffTime = today - exportDate;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
      if (diffDays === 0) {
          return { label: 'Astăzi', color: 'success' };
      } else if (diffDays === 1) {
          return { label: 'Ieri', color: 'info' };
      } else {
          return { label: `De ${diffDays} zile`, color: 'warning' };
      }
  }
  
  const handleGenerateAndDownload = async (exp, fileType) => {
    setLoading(true);
    let apiUrl = '';

    // Alege API-ul corect în funcție de tipul de fișier
    switch (fileType) {
        case 'docx':
            apiUrl = `${window.API_URL}convert-excel-to-docx`;
            break;
        case 'pdf':
            apiUrl = `${window.API_URL}convert-excel-to-pdf`;
            break;
        case 'xml':
            apiUrl = `${window.API_URL}convert-excel-to-xml`;
            break;
        default:
            throw new Error('Tip de fișier necunoscut');
    }

    try {
        // Trimite cererea la API pentru a genera fișierul și a obține conținutul binar
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                iduser: iduser,
                denumirefisier: exp.name.replace('.xlsx', '') // Trimiți doar denumirea fără extensia .xlsx
            }),
        });

        if (!response.ok) {
            throw new Error('Eroare la generarea fișierului');
        }

        // Obține conținutul fișierului în format blob (pentru fișiere binare)
        const blob = await response.blob();
        const fileName = `${exp.name.replace('.xlsx', '')}.${fileType}`;

        // Creează un URL temporar pentru fișierul generat
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Setează numele fișierului pentru descărcare

        // Adaugă și simulează click-ul pentru descărcare
        document.body.appendChild(link);
        link.click();

        // Elimină link-ul după descărcare
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error during file generation:', error);
    } finally {
        setLoading(false);
    }
};

const handleDownloadExcel = (exp) => {
  const filePath = `./fisiere/export/${iduser}/${exp.name}`;
  window.open(filePath, '_blank');
};

    return (
        <div className="container card p-3 mt-5">
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
                <span style={{ marginLeft: '10px' }}> Se încarcă export-urile...</span>
                </div>
            ) : (
            <>
                <div style={{ borderBottom:'1px solid #ccc', paddingBottom:'10px', marginBottom: '20px' }}>
                    <b>
                        <InventoryIcon /> Exporturi generate
                    </b>
                </div>
                <div>
                    {inLucru && inLucru.map((file, index) => (
                        <Alert key={index} severity="info" className='mb-2'>
                            Exportul din data {formatDate(file.data, file.ora)} ora {file.ora} este în lucru...
                            <Tooltip title={`Renunțați la generarea exportului`}>
                                <Button size='small' variant='text' onClick={() => deleteExportGenerare(file.name)} style={{float:'right', marginTop:'-5px'}}>
                                  Renunțare 
                                </Button>
                            </Tooltip>
                        </Alert>
                    ))}
                    {exports.length > 0 ? (
                    <>
                    <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                    <Table stickyHeader className='table table-responsive table-bordered table-striped'>
                      <TableHead>
                        <TableRow>
                            <TableCell>Export</TableCell>
                            <TableCell>Dată</TableCell>
                            <TableCell>Oră</TableCell>
                            <TableCell>Dimensiune</TableCell>
                            <TableCell>Vechime</TableCell>
                            <TableCell>Acțiune</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
  {exports.map(exp => {
    const ageLabel = getExportAgeLabel(`${exp.data}T${exp.ora}`);
    return (
      <TableRow key={exp.name}>
        <TableCell>{exp.name.replace('.xlsx', '')}</TableCell>
        <TableCell>{formatDate(exp.data, exp.ora)}</TableCell>
        <TableCell>{exp.ora}</TableCell>
        <TableCell>{exp.size}</TableCell>
        <TableCell>
          <Chip label={ageLabel.label} className="vechime" color={ageLabel.color} />
        </TableCell>
        <TableCell>
          {/* Descărcare pentru fișierul Excel */}
          <Tooltip title={`Descărcare export Excel`}>
            <IconButton onClick={() => handleDownloadExcel(exp)}>
              <i class="fa fa-file-excel"></i>
            </IconButton>
          </Tooltip>
      {/* 
    
          {exp.word ? (
          <Tooltip title={exp.word ? `Descărcare export Word` : `Exportul în Word este în curs de generare`}>
            <IconButton onClick={() => handleDownload(exp.name, 'docx')}>
                <i class="fa fa-file-word"></i>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={exp.word ? `Descărcare export Word` : `Exportul în Word este în curs de generare`}>
              <IconButton onClick={() => handleGenerateAndDownload(exp, 'docx')}>
                <i class="fa fa-file-word"></i>
              </IconButton>
            </Tooltip>
          )}

           
          {exp.pdf ? (
          <Tooltip title={exp.word ? `Descărcare export PDF` : `Exportul în PDF este în curs de generare`}>
            <IconButton onClick={() => handleDownload(exp.name, 'pdf')} disabled={!exp.pdf}>
                <i class="fa fa-file-pdf"></i>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={exp.word ? `Descărcare export PDF` : `Exportul în PDF este în curs de generare`}>
              <IconButton onClick={() => handleGenerateAndDownload(exp, 'pdf')} disabled={!exp.pdf}>
                <i class="fa fa-file-pdf"></i>
              </IconButton>
            </Tooltip>
          )}

         
          {exp.xml ? (
            <Tooltip title={exp.word ? `Descărcare export XML` : `Exportul în XML este în curs de generare`}>
              <IconButton onClick={() => handleDownload(exp.name, 'xml')}  disabled={!exp.xml}>
                <i class="fa fa-file"></i>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={exp.word ? `Descărcare export XML` : `Exportul în XML este în curs de generare`}>
              <IconButton onClick={() => handleGenerateAndDownload(exp, 'xml')} disabled={!exp.xml}>
                <i class="fa fa-file"></i>
              </IconButton>
            </Tooltip>
          )}
*/}
          {/* Ștergere export */}
          <Tooltip title={`Ștergere export`}>
            <IconButton onClick={() => deleteExport(exp.name)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>


                    </Table>
                    </TableContainer>
                    </>
                ) : (
                    <>
                    <Alert severity="warning">Nu aveți nici un export efectuat.</Alert>
                     </>
                )}
                
                </div>
                 
            </>
            )} 
        </div>
    );
}

export default ExportDownload;
