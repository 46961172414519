import React, { useState, useEffect, useRef } from 'react'; 
import { CircularProgress, Grid, Button, Box, MenuItem,  } from '@mui/material';
import { DataGridPro, useGridApiRef, GridToolbarColumnsButton, GridToolbarExportContainer, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, roRO } from '@mui/x-data-grid-pro';

import { styled } from '@mui/system';
import Swal from 'sweetalert';
import { LicenseInfo } from '@mui/x-license-pro';
import { format } from 'date-fns';
import verificareToken from '../VerificareToken';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'; 

import { ExportInExcel } from '../ExportInExcel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { callAddFont } from '../fonts/DejaVuSans-normal';


LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
 
function LogActivitate() {
    //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
    window.dispatchEvent(new Event('authChange'));
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFieldName, setDateFieldName] = useState('any');
  function getDenAppVers() {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null; 
    }
  
    let denAppVers = getCookie('DenAppVers');
  
    if (!denAppVers) {
      denAppVers = localStorage.getItem('DenAppVers');
    }
  
    if (!denAppVers) {
      denAppVers = sessionStorage.getItem('DenAppVers');
    }
  
    return denAppVers || 'V2';  
  }
  
  let denAppVers = getDenAppVers(); 
const [dosareFiltrate, setDosareFiltrate] = React.useState([]);
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
const formattedTime2 = currentDate.toLocaleTimeString('ro-RO', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false  
});
  const [showHeader, setShowHeader] = useState(false);
  const [showConfidential, setShowConfidential] = useState(false); 
  const [usercurentgrup, setUsercurentgrup] = useState([]);
  const [usercurentnume, setUsercurentnume] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    numarDosar: true,
    description: true,
    userName: true,
    created_at: true,
    time: true,
  });

  const { token } = verificareToken();
  useEffect(() => {
    const fetchUsercurent = async () => {
      try {
        const response = await fetch(`${window.API_URL}user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        const {data} = await response.json();
        setUsercurentgrup(data.user.groupNames);
        setUsercurentnume(data.user.fullname);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };
  
    fetchUsercurent();
  }, [token]);
  
  
  useEffect(() => {
    const fetchActivityLog = async () => {
      try {
        const response = await fetch(`${window.API_URL}activityLog`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching activity log');
        }

        const data = await response.json();
        if (data && data.data && data.data.activities) {
          setActivityLog(data.data.activities);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivityLog();
  }, [token]);

  const capitalizeAndRemoveUnderscores = (str) => {
    return str.split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
  };

  function getKeysFromLookup(obj: GridFilterState['filteredRowsLookup']) {
    const keys = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        keys.push(key);
      }
    }
    return keys;
  }


  const handleExportExcel = async () => {
    setLoading(true);
    try {
      // Apelează API-ul cu tot=1 pentru a obține toate datele
      const response = await fetch(`${window.API_URL}activityLog?tot=1`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching activity log');
      }

      const data = await response.json();
      const activities = data.data.activities;

      // Crează un workbook Excel și un sheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Activity Log');

      // Adaugă anteturi în Excel (aceleași coloane din DataGrid)
      worksheet.columns = [
        //{ header: 'Activitate', key: 'log_name', width: 30 },
        { header: 'Număr dosar', key: 'numarDosar', width: 20 },
        { header: 'Descriere', key: 'description', width: 40 },
        { header: 'Utilizator', key: 'userName', width: 20 },
        //{ header: 'Eveniment', key: 'event', width: 20 },
        { header: 'Dată', key: 'created_at', width: 15 },
        { header: 'Oră', key: 'time', width: 10 },
      ];

      // Adaugă datele în sheet
      activities.forEach(activity => {
        const date = new Date(activity.created_at);
        worksheet.addRow({
          log_name: activity.log_name,
          numarDosar: activity.numarDosar,
          description: activity.description,
          userName: activity.userName,
          event: activity.event === 'created' ? 'Creare' : (activity.event === 'updated' ? 'Editare' : activity.event),
          created_at: format(date, 'dd.MM.yyyy'),
          time: format(date, 'HH:mm:ss')
        });
      });

      // Generează fișierul Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Descarcă fișierul
      saveAs(blob, fileNameExcelComplet);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      setLoading(false);
    }
  };



 
///functii
const [filterModel, setFilterModel] = useState({
  items: [], 
});
const handleFilterModelChange = (newModel) => {

  const newItems = newModel.items.map((item) => {

    return item;
  });

  setFilterModel({ ...newModel, items: newItems });
};


const [isExportDosareOpen, setIsExportDosareOpen] = useState(false);
const [isExportDosareExcelOpen, setIsExportDosareExcelOpen] = useState(false); 
const [isTipExport, setIsTipExport] = useState(''); 
const handleOpenExportDosare = () => {
  let text;

  if (selectedRows2.length > 10) {
    text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate? Acest proces poate dura mai mult timp.`;
  } else if (selectedRows2.length > 0) {
    text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate?`;
  } else {
    text = `Nu există dosare selectate! Generarea listei cu ${dosareFinal.length} dosare poate dura câteva minute. Continuați?`;
  }

  Swal({
    title: 'Imprimare detaliată',
    text: text,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      handleOpenExportDosareOK();
    }
  });
};

const handleOpenExportDosareOK = () => {
  setIsExportDosareOpen(true);
};

const handleCloseExportDosare = () => {
  setIsExportDosareOpen(false);
};

const handleExporturiGenerate = () => {
  window.location.href = "/export-dosare";
};
const fileNameExcel = `JuristPRO NEXT - Export EXCEL - ${formattedDate} - ${formattedTime}`;
const fileNameExcelComplet = `JuristPRO NEXT - Export complet Log Activitate - ${formattedDate} - ${formattedTime}.xlsx`;

const fileNameCSV = `JuristPro NEXT - CSV Export lista dosare - ${formattedDate} - ${formattedTime}`;
const formatUserGroups = (userGroups) => {
  if (!userGroups || userGroups.length === 0) return 'Fără grup';
  return userGroups.map(group => group.name).join(', ');
};




const handleExportXML = () => {
  const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

  if (visibleColumns.length === 0 || dosareFinal.length === 0) {
    console.error('Nu există date disponibile pentru a genera XML-ul.');
    return;
  }

  // Construim structura XML
  let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n';
  xmlContent += '<Dosare>\n';

  dosareFinal.forEach(row => {
    xmlContent += '  <Dosar>\n';
    visibleColumns.forEach(col => {
      const fieldName = col.headerName.replace(/\s+/g, '_'); // înlocuim spațiile din denumirea coloanei cu '_'
      let cellValue;

      // Formatarea specială pentru user_groups
      if (col.field === 'user_groups') {
        cellValue = formatUserGroups(row[col.field]); // Folosește funcția de formatare
      } else {
        cellValue = row[col.field] || '';
      }

      xmlContent += `    <${fieldName}>${cellValue}</${fieldName}>\n`;
    });
    xmlContent += '  </Dosar>\n';
  });

  xmlContent += '</Dosare>';

  // Creăm fișierul XML și oferim descărcarea
  const blob = new Blob([xmlContent], { type: 'application/xml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'export_dosare.xml';
  link.click();
};

const dateFilterType = dateFieldName;
const prepareDataForExport = (data, columns, columnVisibilityModel) => {
  const columnInfoMap = columns.reduce((acc, column) => {
    if (columnVisibilityModel[column.field] !== false) {  
      acc[column.field] = column.headerName || column.field;
    }
    return acc;
  }, {});

  const orderedColumnFields = columns
    .filter(col => columnVisibilityModel[col.field] !== false)
    .map(col => col.field);

  return data.map(row => {
    const filteredRow = {};
    orderedColumnFields.forEach(key => {
      if (key !== '__check__') {
        filteredRow[columnInfoMap[key]] = row[key];
      }
    });
    return filteredRow;
  });
};
const dataGridRef = useRef(null);

const handleExportPDFComand = () => {

setShowHeader(false);
setShowConfidential(false);
let text;

text = `${selectedRows2.length} de rânduri vor fi exportate în PDF din tabel`;

// Creăm un container HTML pentru switch-uri
const container = document.createElement('div');
container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

// Inserăm HTML pentru switch-uri personalizate stilizate
container.insertAdjacentHTML(
  'beforeend',
  `
  <div class="switch-container">
    <label for="headerSwitch">Afișare antet</label>
    <label class="switch">
      <input type="checkbox" id="headerSwitch" ${showHeader ? 'checked' : ''}>
      <span class="slider round"></span>
    </label>
  </div>
  <div class="switch-container">
    <label for="confidentialSwitch">Afișare "Confidențial"</label>
    <label class="switch">
      <input type="checkbox" id="confidentialSwitch" ${showConfidential ? 'checked' : ''}>
      <span class="slider round"></span>
    </label>
  </div>
  `
);

// Afișăm modalul Swal cu elementul creat
Swal({
  title: 'Export PDF tabel',
  text: text,
  content: container,
  icon: 'warning',
  buttons: ['Anulează', 'Da, continui!'],
  dangerMode: true,
}).then((willContinue) => {
  if (willContinue) {
    const headerSwitch = document.getElementById('headerSwitch');
    const confidentialSwitch = document.getElementById('confidentialSwitch'); 

    handleExportPDF(headerSwitch.checked, confidentialSwitch.checked);
  }
});
};

const handleExportPDF = (showHeader1, showConfidential1) => { 
const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

if (visibleColumns.length === 0 ) {
  console.error('Nu există date disponibile pentru a genera PDF-ul.');
  return;
}

let pageFormat = 'a4';  // Format implicit

if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
  pageFormat = 'a3';
} else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
  pageFormat = 'a2';
} else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
  pageFormat = 'a1';
} else if (visibleColumns.length > 15) {
  pageFormat = 'a0';
}

// Inițializăm documentul PDF
const doc = new jsPDF({
  orientation: 'landscape',  // Orientare landscape
  format: pageFormat,  
              // Dimensiunea paginii
});

// Adăugăm fontul DejaVu Sans
callAddFont(doc); // Adăugăm fontul personalizat
doc.setFont('DejaVuSans'); // Setăm fontul pentru text

// Adăugăm un antet, dacă este selectat
let startY = 20;
if (showHeader1) {
  doc.setFontSize(12);
  doc.setTextColor('#333');
  
  // Verificăm dacă `usercurentgrup` conține mai multe grupuri
  const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
  let grupuriText;

  if (grupuri.length > 1) {
    // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
    grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
  } else {
    // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
    grupuriText = `pentru unitatea ${usercurentgrup}`;
  }

  const dateFilterTypeDen = startDate ? 
  (dateFilterType === 'datacreare' ? 'data creare dosar' :
  dateFilterType === 'datatermen' ? 'data termen dosar' :
  dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
: '';

  let mesaj;

   // Cazul în care nu trimiți `startDate`
   mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
   doc.text(mesaj, 14, startY);
  if (!startDate) {
    doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
  } else {
    // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
    const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
    mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${dataSfarsit} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
    doc.text(mesaj, 14, startY + 10);
  }

startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
}

// Adăugăm un mesaj confidențial, dacă este selectat
if (showConfidential1) {
  doc.setFontSize(14);
  doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
  doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
  startY += 10;  // Mutăm mai jos pentru a face loc tabelului
}

// Creăm anteturile și rândurile tabelului
const tableColumnHeaders = visibleColumns.map(col => col.headerName);
const tableRows = dosareFinal.map((row) =>
visibleColumns.map((col) => {
  if (col.field === 'user_groups') {
    return formatUserGroups(row[col.field]); // Aplica funcția de formatare
  }
  return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
})
);

if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
  console.error('Nu există date pentru a genera tabelul.');
  return;
}

// Creăm tabelul cu `word wrap` și paginare
doc.autoTable({
  head: [tableColumnHeaders],
  body: tableRows,
  startY: startY + 10, // Începem tabelul după antet și confidențial
  theme: 'grid',
  styles: {
    font: 'DejaVuSans',
    fontSize: 10,
    cellPadding: 3,
    overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
    cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
  },
  tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
  didDrawPage: (data) => {
    // Adăugăm paginare
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
    doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
  },
});
// Salvăm PDF-ul pe dispozitivul utilizatorului
doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

};
const handlePrintComand = (showHeader, showConfidential) => { 
  setShowHeader(false);
  setShowConfidential(false);
  let text;

  text = `Au fost selectate ${selectedRows2.length} dosare pentru a fi imprimate din tabel`;

  // Creăm un container HTML pentru switch-uri
  const container = document.createElement('div');
  container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

  // Inserăm HTML pentru switch-uri personalizate stilizate
  container.insertAdjacentHTML(
    'beforeend',
    `
    <div class="switch-container">
      <label for="headerSwitch">Afișare antet</label>
      <label class="switch">
        <input type="checkbox" id="headerSwitch" ${showHeader ? '' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    <div class="switch-container">
      <label for="confidentialSwitch">Afișare "Confidențial"</label>
      <label class="switch">
        <input type="checkbox" id="confidentialSwitch" ${showConfidential ? '' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    `
  );

  // Afișăm modalul Swal cu elementul creat
  Swal({
    title: 'Imprimare tabel',
    text: text,
    content: container,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      const headerSwitch = document.getElementById('headerSwitch');
      const confidentialSwitch = document.getElementById('confidentialSwitch'); 
      handlePrint(headerSwitch.checked, confidentialSwitch.checked);
    }
  });
};

const handlePrint = (showHeader1, showConfidential1) => { 
  const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

  if (visibleColumns.length === 0 || dosareFinal.length === 0) {
    console.error('Nu există date disponibile pentru a genera PDF-ul.');
    return;
  }

  
  let pageFormat = 'a4';  // Format implicit

  if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
    pageFormat = 'a3';
  } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
    pageFormat = 'a2';
  } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
    pageFormat = 'a1';
  } else if (visibleColumns.length > 15) {
    pageFormat = 'a0';
  }

  // Inițializăm documentul PDF
  const doc = new jsPDF({
    orientation: 'landscape',  // Orientare landscape
    format: pageFormat,  
                // Dimensiunea paginii
  });


      // Adăugăm fontul DejaVu Sans
      callAddFont(doc); // Adăugăm fontul personalizat
      doc.setFont('DejaVuSans'); // Setăm fontul pentru text
  
      // Adăugăm un antet, dacă este selectat
      let startY = 20;
      if (showHeader1) {
        doc.setFontSize(12);
        doc.setTextColor('#333');
        
        // Verificăm dacă `usercurentgrup` conține mai multe grupuri
        const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
        let grupuriText;
  
        if (grupuri.length > 1) {
          // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
          grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
        } else {
          // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
          grupuriText = `pentru unitatea ${usercurentgrup}`;
        }
    
        const dateFilterTypeDen = startDate ? 
          (dateFilterType === 'datacreare' ? 'data creare dosar' :
          dateFilterType === 'datatermen' ? 'data termen dosar' :
          dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
          : '';
      
        let mesaj;
  
         // Cazul în care nu trimiți `startDate`
         mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
         doc.text(mesaj, 14, startY);
        if (!startDate) {
          doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
        } else {
          // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
          const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
          mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${dataSfarsit} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
          doc.text(mesaj, 14, startY + 10);
        }
  
      startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
      }

  // Adăugăm un mesaj confidențial, dacă este selectat
  if (showConfidential1) {
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
    doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
    startY += 10;  // Mutăm mai jos pentru a face loc tabelului
  }

  // Creăm anteturile și rândurile tabelului
  const tableColumnHeaders = visibleColumns.map(col => col.headerName);
  const tableRows = dosareFinal.map((row) =>
  visibleColumns.map((col) => {
    if (col.field === 'user_groups') {
      return formatUserGroups(row[col.field]); // Aplica funcția de formatare
    }
    return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
  })
);

  if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
    console.error('Nu există date pentru a genera tabelul.');
    return;
  }

  // Creăm tabelul cu `word wrap` și paginare
  doc.autoTable({
    head: [tableColumnHeaders],
    body: tableRows,
    startY: startY + 10, // Începem tabelul după antet și confidențial
    theme: 'grid',
    styles: {
      font: 'DejaVuSans',
      fontSize: 10,
      cellPadding: 3,
      overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
      cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
    },
    tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
    didDrawPage: (data) => {
      // Adăugăm paginare
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
      doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    },
  });
  
  // Salvăm PDF-ul pe dispozitivul utilizatorului
  doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

  // Creăm un URL temporar pentru a deschide PDF-ul într-o fereastră nouă
  const pdfBlob = doc.output('blob');
  const url = URL.createObjectURL(pdfBlob);
  window.open(url); 

};



const filteredDosare = activityLog.filter((dosar) => {
  const dosarDateStr = dosar[dateFilterType] ? dosar[dateFilterType].split(' ')[0] : null;

  return dosarDateStr;
});
 
const selectedRows2 = activityLog;
const dosareFinal = dosareFiltrate;

const prepareDataForExport2 = (data, columns, columnVisibilityModel) => {
  return data.map((row) => {
    const formattedRow = {};
    
    // Iterează prin coloanele definite
    columns.forEach((col) => {
      if (columnVisibilityModel[col.field]) {
        const displayName = col.headerName; // Denumirea din columns
        
        // Aici adăugăm logica pentru formatarea corectă a datelor
        if (col.field === 'created_at') {
          const date = new Date(row[col.field]);
          formattedRow[displayName] = format(date, 'dd.MM.yyyy'); // Formatează data
        } else if (col.field === 'time') {
          const date = new Date(row['created_at']); // Calculăm ora din created_at
          formattedRow[displayName] = format(date, 'HH:mm:ss'); // Formatează ora
        } else if (col.field === 'numarDosar') {
          formattedRow[displayName] = row[col.field] || ''; // Adaugă numărul dosarului
        } else if (col.field === 'userName') {
          formattedRow[displayName] = row[col.field] || 'N/A'; // Nume utilizator
        } else {
          // Pentru celelalte coloane, utilizează valoarea originală
          formattedRow[displayName] = row[col.field] || '';
        }
      }
    });

    return formattedRow; // Returnează rândul formatat
  });
};


let formattedStartDate = '';

if (startDate) {
  const startDateObj1 = new Date(startDate);
  formattedStartDate = startDateObj1.toLocaleDateString('ro-RO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
} else {
    formattedStartDate = '';
}

let formattedEndDate = '';

if (endDate) {
  const endDateObj = new Date(endDate);
  formattedEndDate = endDateObj.toLocaleDateString('ro-RO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
} else {
    formattedEndDate = '';
}
 

const columns = [
  /*{
    field: 'log_name',
    headerName: 'Activitate',
    flex: 1,
    wordWrap: true,
    renderCell: (params) => capitalizeAndRemoveUnderscores(params.value)
  },*/
  { field: 'numarDosar', headerName: 'Număr dosar', flex: 1, wordWrap: true },
  { field: 'description', headerName: 'Descriere', flex: 1, wordWrap: true },
  { field: 'userName', headerName: 'Utilizator', flex: 1, wordWrap: true },
  //{ field: 'subject_type', headerName: 'Subject Type' }, 
    /*
    {
    field: 'event',
    headerName: 'Eveniment',
    flex: 1,
    wordWrap: true,
    renderCell: (params) => {
      if (params.value === 'created') {
        return 'Creare';
      } else if (params.value === 'updated') {
        return 'Editare';
      } else {
        return params.value;   
      }
    }
  },
  */ 
  //{ field: 'subject_id', headerName: 'Subject ID', flex: 1, wordWrap: true },
  //{ field: 'causer_type', headerName: 'Causer Type' },
  //{ field: 'causer_id', headerName: 'ID Utilizator', flex: 1, wordWrap: true },
  {
    field: 'created_at',
    headerName: 'Dată',
    flex: 1,
    wordWrap: true,
    renderCell: (params) => {
      const date = new Date(params.value);
      return format(date, 'dd.MM.yyyy');
    }
  },
  {
    field: 'time',
    headerName: 'Oră',
    flex: 1,
    valueGetter: (params) => {
      const date = new Date(params.row.created_at);
      return format(date, 'HH:mm:ss');
    }
  },
  //{ field: 'updated_at', headerName: 'Updated At' },
];


    const csvOptions = {
      // Alte opțiuni CSV, dacă este necesar
      fileName: fileNameExcel,
      utf8WithBom: true,
      data: prepareDataForExport2(
        selectedRows2.length > 0 ? selectedRows2 : dosareFinal,
        columns,
        columnVisibilityModel
      ),
    };
    
    

const apiRef2 = useGridApiRef();
const rowsToDisplay = selectedRows2 ? selectedRows2 : dosareFiltrate;

const handleStateChange = React.useCallback(
  (state: GridState) => {
    const filteredKeys = getKeysFromLookup(state.filter.filteredRowsLookup);
    const rows = filteredKeys.map((key) => apiRef2.current.getRow(key));
    
    //console.log('Rows calculated: ', rows);
    
    if (JSON.stringify(rows) !== JSON.stringify(dosareFiltrate)) {
      //console.log('Updating dosareFiltrate with new rows');
      setDosareFiltrate(rows);
    }
  },
  [apiRef2, dosareFiltrate],
);
 
const FlexContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});



if (loading) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
      <CircularProgress />
    </div>
  );
}

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
      <FlexContainer>
      <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}>
      <GridToolbarColumnsButton /> 
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      
      <GridToolbarExportContainer >
          <MenuItem onClick={handlePrintComand}>
          Imprimare tabel
          </MenuItem>

          <MenuItem onClick={handleExportPDFComand}>
          Export PDF tabel
          </MenuItem>

          
          <ExportInExcel
              csvData={prepareDataForExport2(
                  selectedRows2.length > 0 ? selectedRows2 : dosareFinal,
                  columns,
                  columnVisibilityModel
              )} 
              fileName={fileNameExcel}
              showHeader={showHeader}
              showConfidential={showConfidential}
              afisamSwal={true} 
              startDate={formattedStartDate}
              dateFilterType={dateFilterType} 
              endDate={formattedEndDate}
              />

           
          <MenuItem onClick={handleExportXML}>
               În format XML
          </MenuItem>

      </GridToolbarExportContainer>
              

      </Box>
      </FlexContainer>
    </GridToolbarContainer>
    );
  };


  return (
    <div id="logActivitate">
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <DataGridPro   ref={dataGridRef}
            rows={activityLog}
            columns={columns}
            apiRef={apiRef2}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
            paginationMode="client"
                    onStateChange={handleStateChange}
                    onFilterModelChange={handleFilterModelChange}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    columnVisibilityModel={columnVisibilityModel}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            
            components={{
              Toolbar: CustomToolbar
            }} 
           localeText={{ 
             ...roRO.components.MuiDataGrid.defaultProps.localeText, 
             headerFilterOperatorContains: 'Filtrare rapidă',
             filterPanelRemoveAll: 'Resetare filtre',
             toolbarExport: 'Export',
             toolbarExportLabel: 'Export',
             toolbarExportCSV: 'În format CSV',
             toolbarExportPrint: 'Imprimare',
             toolbarExportExcel: 'În format Excel',
           }}
          />
           <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                onClick={handleExportExcel}
                disabled={loading}
              >
                {loading ? 'Se generează exportul Excel. Vă rugăm să așteptați ...' : 'Exportare raport activități complet în Excel'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LogActivitate;
