import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert';

import verificareToken from './VerificareToken';

const SchimbareLicenta = ({ open, handleClose }) => {

      const { token } = verificareToken();
    const [newSerialNo, setNewSerialNo] = useState('');
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await fetch(`${window.API_URL}getLicense`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const data = await response.json();
        if (data.data?.licenta?.serialno) {
          setNewSerialNo(data.data.licenta.serialno);
        }
      } catch (error) {
        console.error('Error fetching license data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchLicenseData();
    }
  }, [open, token]);

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${window.API_URL}updateLicense?serialno=${newSerialNo}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (result.status === 200 && result.data?.licenta) {
        Swal('Succes!', 'Licența a fost modificată cu succes!', 'success');
        setTimeout(() => {
            window.location.href = '/licentiere';
          }, 1000);
        handleClose();

      } else {
        Swal('Atenționare!', 'Licența este invalidă!', 'warning');
        sessionStorage.setItem('ok2go', false);
        localStorage.setItem('ok2go', false);
        document.cookie = `ok2go=false; path=/; max-age=86400;`;
          window.location.href = '/licentiere';
      }
    } catch (error) {
      console.error('Error updating license:', error);
      Swal('Atenție!', 'A apărut o eroare la modificarea licenței.', 'warning');
    } finally {
      
    }
  };

 
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle className='titluModal'>Modificare Licență</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Număr de Serie Licență"
          type="text"
          fullWidth
          variant="standard"
          value={newSerialNo}
          onChange={(e) => setNewSerialNo(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Anulează</Button>
        <Button onClick={handleUpdate}>Modifică</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SchimbareLicenta;
